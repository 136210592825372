import React from 'react';
import { Route,Redirect } from 'react-router-dom';
export const getAccessStorage = () => localStorage.getItem('Infodata');
export const isAuthenticated = () => !!getAccessStorage()
export const getAccessToken = () => localStorage.getItem('Token');

export const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        !isAuthenticated()
            ? <Component {...props} />
            : <Redirect to='/' />
    )} />
);


export const AuthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        isAuthenticated()
            ? <Component {...props} />
            : <Redirect to='/login' />
    )} />
);

export const PrivateRoute = ({
    component: Component,
    layout: Layout,
    ...rest
  }) =>(
    <Route
      {...rest}
      render={props =>
        localStorage.getItem("Userdata")?(
            <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
  