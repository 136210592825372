import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CommonConfig } from "../../CommonConfig.js";
import SimpleBackdrop from "../../utils/general";
import api from "../../utils/api";
import cogoToast from "cogo-toast";
import APIConstant from "../../utils/constant";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import Loader from "components/Shared/Loader/Loader";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
class LocationMaster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      MasterID: "",
      loading: false,
      UserId: "",
      Token: "",
      GetMasterList: [],
      rowsPerPageOptions: CommonConfig.dataTableConfig.rowsPerPageOptions,
      rowsPerPage: CommonConfig.dataTableConfig.rowsPerPage,
      search: null,
      MasterLabelName: "",
      MasterLabelNameErr: false,
      MasterLabelNameErrText: "",
      AddMasterModal: false,
      EditMasterTypeModal: false,
      DeleteMasterTypeModal: false,
      userPageAccess: [],
    };
  }

  componentDidMount = async () => {
    let data = localStorage.getItem("Infodata");
    let Userrole = JSON.parse(localStorage.getItem("Userdata"));
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");
    // if (lockstatus == 0) {
    if (token !== null) {
      this.setState({
        UserId: data,
        Token: token,
        userPageAccess: userPageAccess,
      });
      await this.GetMasterListByType(data);
    } else {
      this.props.history.push("/login");
    }
    // } else {
    //     this.props.history.push('/auth/lockscreen')
    // }
  };

  actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  actionTemplate = (rowData, props) => {
    return (
      <div class="bill-action icon-only-btn" style={{ display: "flex" }}>
        {this.state.userPageAccess.includes("Edit Location Master") ? (
          <div onClick={(e) => this.handleOpen(rowData, "EditMasterType")}>
            {" "}
            <i class="icon-editImage"></i>{" "}
          </div>
        ) : null}
        {this.state.userPageAccess.includes("Delete Location Master") ? (
          <div onClick={(e) => this.handleOpen(rowData, "DeleteMasterType")}>
            {" "}
            <i class="icon-delete"></i>{" "}
          </div>
        ) : null}
      </div>
    );
  };

  GetMasterListByType = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetLocationMasterList, params)
      .then(async (res) => {
        let response = await res;
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserID != "Admin");

          this.setState({ GetMasterList: filterArry });
        }
      })
      .catch((err) => {});
  };

  GetLocationMasterByID = async (MasterId) => {
    Loader.show();
    let params = {
      ID: MasterId,
    };
    api
      .post(APIConstant.path.GetLocationMasterByID, params)
      .then(async (res) => {
        let response = await res;
        if (response.Success === 1) {
          Loader.hide();

          this.setState({
            MasterLabelName: response.Data.LocationName,
            MasterID: response.Data._id,
          });
        } else {
          Loader.hide();
        }
      })
      .catch((err) => {
        Loader.hide();
      });
  };

  handleOpen = (item, type) => {
    if (type === "AddMaster") {
      this.setState({ AddMasterModal: true });
    } else if (type === "EditMasterType") {
      this.GetLocationMasterByID(item.value);
      this.setState({
        AddMasterModal: true,
        MasterLabelName: item.label,
        MasterID: item.value,
      });
    } else if (type === "DeleteMasterType") {
      this.setState({ DeleteMasterTypeModal: true, MasterID: item.value });
    }
  };

  handleClose = (type) => {
    if (type === "AddMaster") {
      this.setState({
        AddMasterModal: false,
        MasterLabelName: "",
        MasterLabelNameErr: false,
        MasterLabelNameErrText: "",
        MasterID: "",
      });
    } else if (type === "EditMasterType") {
      this.setState({ EditMasterTypeModal: false });
    } else if (type === "DeleteMasterType") {
      this.setState({ DeleteMasterTypeModal: false, MasterID: "" });
    }
  };

  handleChange = (e, type) => {
    if (type === "MasterLabelName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          MasterLabelName: e.target.value,
          MasterLabelNameErr: true,
          MasterLabelNameErrText: `Please enter Type.`,
        });
      } else {
        this.setState({
          MasterLabelName: e.target.value,
          MasterLabelNameErr: false,
          MasterLabelNameErrText: "",
        });
      }
    } else if (type === "masterLabelName") {
      if (CommonConfig.isEmpty(e.description)) {
        this.setState({
          MasterLabelName: e.description,
          MasterLabelNameErr: true,
          MasterLabelNameErrText: `Location name is required.`,
        });
      } else {
        this.setState({
          MasterLabelName: e.description,
          MasterLabelNameErr: false,
          MasterLabelNameErrText: "",
        });
      }
    }
  };

  validation = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(this.state.MasterLabelName)) {
      this.setState({
        MasterLabelName: "",
        MasterLabelNameErr: true,
        MasterLabelNameErrText: "Type name is required.",
      });
      formIsValid = false;
    } else {
      this.setState({ MasterLabelNameErr: false, MasterLabelNameErrText: "" });
    }
    return formIsValid;
  };

  AddMaster = () => {
    if (this.validation()) {
      this.setState({ loading: true });

      let params = {
        LocationName: this.state.MasterLabelName,
        UserID: this.state.UserId,
        LocationMasterID: this.state.MasterID,
        LocationType: "",
      };
      api.post(APIConstant.path.AddUpdateLocationMaster, params).then((res) => {
        if (res.Success == 1) {
          this.setState({
            loading: false,
            AddMasterModal: false,
            MasterLabelName: "",
            MasterLabelNameErr: false,
            MasterLabelNameErrText: "",
            MasterID: "",
          });
          this.GetMasterListByType(this.state.UserId);
          cogoToast.success(res.Message);
        } else {
          this.setState({ loading: false });
          cogoToast.error(res.Message);
        }
      });
    }
  };

  DeleteMasterType = () => {
    this.setState({ loading: true });
    let params = {
      ID: this.state.MasterID,
      UserId: this.state.UserId,
    };
    api.post(APIConstant.path.DeleteLocationMaster, params).then((res) => {
      if (res.Success == 1) {
        this.setState({
          loading: false,
          DeleteMasterTypeModal: false,
          MasterID: "",
        });
        this.GetMasterListByType(this.state.UserId);
        cogoToast.success(res.Message);
      } else {
        this.setState({ loading: false });
        cogoToast.error(res.Message);
      }
    });
  };

  render() {
    const {
      UserId,
      search,
      GetMasterList,
      isNewLocation,
      userPageAccess,
      MasterLabelName,
      MasterLabelNameErr,
      MasterLabelNameErrText,
      AddMasterModal,
      EditMasterTypeModal,
      DeleteMasterTypeModal,
      MasterID,
    } = this.state;

    let filterGetMasterList = [];
    if (GetMasterList.length) {
      filterGetMasterList = GetMasterList;
      if (search) {
        filterGetMasterList = filterGetMasterList.filter((x) => {
          return x.label.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        });
      }
    }

    return (
      <div className="main-access-contianer">
        <div className="heading-area">
          <h1>Location Master</h1>
          <div className="heading-area-right">
            <div className="searchbar-area p-as-center">
              <InputText
                type="search"
                onInput={(e) => this.setState({ search: e.target.value })}
                placeholder="Global Search"
                size="30"
                style={{ marginRight: 10 }}
              />
              <i className="icon-search"></i>
            </div>
            {userPageAccess.includes("Add Location Master") ? (
              <div>
                <div
                  className="add-btn1"
                  onClick={(e) => this.handleOpen(e, "AddMaster")}
                >
                  <i className="icon-add"></i> Add Location Master
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="main-access-card-master">
          {this.state.loading === true ? (
            <div className="loading">
              <SimpleBackdrop />
            </div>
          ) : null}

          <DataTable
            className="main-table table-td"
            value={filterGetMasterList}
            editMode="row"
            dataKey="id"
            responsiveLayout="scroll"
            paginator={true}
            rows={this.state.rowsPerPage}
            totalRecords={filterGetMasterList.length}
            rowsPerPageOptions={this.state.rowsPerPageOptions}
            globalFilter={search}
            emptyMessage="No records found"
            responsive={false}
            resizableColumns={true}
            columnResizeMode="fit"
            scrollable={true}
            scrollWidth="auto"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          >
            <Column
              field="Index"
              header="Sr.No"
              body={this.actionBodyTemplateSrNo}
            ></Column>
            <Column field="LocationName" header="Location Name"></Column>
            <Column
              field="Action"
              header="Action"
              body={this.actionTemplate}
              headerStyle={{ width: "10%", minWidth: "8rem" }}
              bodyStyle={{ textAlign: "center" }}
            ></Column>
          </DataTable>

          {/* Add Edit BL Type */}
          <Dialog
            open={AddMasterModal}
            onClose={() => this.handleClose("AddMaster")}
            aria-labelledby="cha-form-dialog-title"
          >
            <DialogTitle
              id="cha-form-dialog-title"
              className="custom-dialog-header"
            >
              <div>{MasterID !== "" ? "Edit" : "Add"} Location Master</div>
              <div
                className="dialog-close"
                onClick={(e) => this.handleClose("AddMaster")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            {/* <DialogContent className="CHA-dialog-content-area">
                            <GridContainer>
                                <GridItem md={12}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        name="Location"
                                        // id="chaNo"
                                        label="Location*"
                                        type="text"
                                        value={MasterLabelName}
                                        onChange={(e) => this.handleChange(e, "MasterLabelName")}
                                        error={MasterLabelNameErr}
                                        helperText={MasterLabelNameErrText}
                                        fullWidth
                                    />
                                </GridItem>
                            </GridContainer>
                        </DialogContent> */}
            <DialogContent className="CHA-dialog-content-area">
              <GridContainer>
                <GridItem md={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="Location"
                    // id="chaNo"
                    label="Location*"
                    type="text"
                    value={MasterLabelName}
                    onChange={(e) => this.handleChange(e, "MasterLabelName")}
                    error={MasterLabelNameErr}
                    helperText={MasterLabelNameErrText}
                    fullWidth
                  />
                  {/* <div className="p-d-flex">
                                        <button
                                            className="filter-btn cursor-pointer"
                                            onClick={(e) => this.setState({ isNewLocation: isNewLocation == true ? false : true })}
                                        >
                                            {" "}
                                            <i className="icon-add"></i>{" "}
                                        </button>
                                    </div> */}
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={(e) => this.handleClose("AddMaster")}
              >
                Close
              </Button>
              <Button
                className="primary-btn"
                onClick={(e) => this.AddMaster(e)}
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          {/* Modal For Delete */}
          <Dialog
            open={DeleteMasterTypeModal}
            onClose={() => this.handleClose("DeleteMasterType")}
            aria-labelledby="cha-form-dialog-title"
          >
            <DialogTitle
              id="cha-form-dialog-title"
              className="custom-dialog-header"
            >
              <div>Delete Location Master</div>
              <div
                className="dialog-close"
                onClick={(e) => this.handleClose("DeleteMasterType")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <DialogContent className="CHA-dialog-content-area">
              <h6>Are you sure want to delete?</h6>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={(e) => this.handleClose("DeleteMasterType")}
              >
                Close
              </Button>
              <Button
                className="primary-btn"
                onClick={(e) => this.DeleteMasterType(e)}
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}
export default LocationMaster;
