import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CommonConfig } from "../../CommonConfig.js";
import SimpleBackdrop from "../../utils/general";
import api from "../../utils/api";
import cogoToast from "cogo-toast";
import APIConstant from "../../utils/constant";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import Loader from "components/Shared/Loader/Loader";

class MakeMaster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      MasterID: "",
      loading: false,
      UserId: "",
      Token: "",
      GetMasterList: [],
      rowsPerPageOptions: CommonConfig.dataTableConfig.rowsPerPageOptions,
      rowsPerPage: CommonConfig.dataTableConfig.rowsPerPage,
      search: null,

      makeName: "",
      makeNameErr: false,
      makeNameErrText: "",

      AddMasterModal: false,
      EditMasterTypeModal: false,
      DeleteMasterTypeModal: false,
      cargoTypeList: [],
      userPageAccess: [],
    };
  }

  componentDidMount = async () => {
    let data = localStorage.getItem("Infodata");
    let Userrole = JSON.parse(localStorage.getItem("Userdata"));
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");
    // if (lockstatus == 0) {
    if (token !== null) {
      this.setState({
        UserId: data,
        Token: token,
        userPageAccess: userPageAccess,
      });
      await this.GetMakeMaster(data);
    } else {
      this.props.history.push("/login");
    }
    // } else {
    //     this.props.history.push('/auth/lockscreen')
    // }
  };

  actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  actionTemplate = (rowData, props) => {
    return (
      <div class="bill-action icon-only-btn" style={{ display: "flex" }}>
        {this.state.userPageAccess.includes("Edit") ? (
          <div onClick={(e) => this.handleOpen(rowData, "EditMasterType")}>
            {" "}
            <i class="icon-editImage"></i>{" "}
          </div>
        ) : null}
        {this.state.userPageAccess.includes("Delete") ? (
          <div onClick={(e) => this.handleOpen(rowData, "DeleteMasterType")}>
            {" "}
            <i class="icon-delete"></i>{" "}
          </div>
        ) : null}
      </div>
    );
  };

  GetMakeMaster = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetMakeMaster, params)
      .then(async (res) => {
        let response = await res;
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserId != "Admin");

          this.setState({ GetMasterList: filterArry });
        }
      })
      .catch((err) => {});
  };

  GetMakeMasterById = async (MasterId) => {
    Loader.show();
    let params = {
      MasterId,
    };
    api
      .post(APIConstant.path.GetMakeMasterById, params)
      .then((response) => {
        console.log(response)
        if (response.Success === 1) {
          Loader.hide();
          this.setState({
            makeName: response.Data.MakeName,
            MasterID: response.Data.MakeMasterId,
          });
        } else {
          Loader.hide();
        }
      })
      .catch((err) => {
        Loader.hide();
      });
  };

  handleOpen = (item, type) => {
    if (type === "AddMaster") {
      this.setState({ AddMasterModal: true });
    } else if (type === "EditMasterType") {
      this.GetMakeMasterById(item.MakeMasterId);
      this.setState({
        AddMasterModal: true,
        MasterLabelName: item.label,
        MasterID: item.MakeMasterId,
      });
    } else if (type === "DeleteMasterType") {
      this.setState({
        DeleteMasterTypeModal: true,
        MasterID: item.MakeMasterId,
      });
    }
  };

  handleClose = (type) => {
    if (type === "AddMaster") {
      this.setState({
        AddMasterModal: false,

        makeName: "",
        makeNameErr: false,
        makeNameErrText: "",

        MasterID: "",
      });
    } else if (type === "EditMasterType") {
      this.setState({ EditMasterTypeModal: false });
    } else if (type === "DeleteMasterType") {
      this.setState({ DeleteMasterTypeModal: false, MasterID: "" });
    }
  };

  handleChange = (e, type) => {
    if (type === "makeName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          makeName: e.target.value,
          makeNameErr: true,
          makeNameErrText: `Please enter makeName.`,
        });
      } else {
        this.setState({
          makeName: e.target.value,
          makeNameErr: false,
          makeNameErrText: "",
        });
      }
    } 
  };

  validation = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(this.state.makeName)) {
      this.setState({
        makeName: "",
        makeNameErr: true,
        makeNameErrText: "name is required.",
      });
      formIsValid = false;
    } else {
      this.setState({ makeNameErr: false, makeNameErrText: "" });
    }
   
    return formIsValid;
  };

  AddMaster = () => {
    if (this.validation()) {
      this.setState({ loading: true });

      let params = {
        MakeName: this.state.makeName,
        MakeMasterID: this.state.MasterID,
        UserID: this.state.UserId,
      };
      api.post(APIConstant.path.AddUpdateMakeMaster, params).then((res) => {
        if (res.Success == 1) {
          this.setState({ loading: false });
          this.handleClose("AddMaster");
          this.GetMakeMaster(this.state.UserId);
          cogoToast.success(res.Message);
        } else {
          this.setState({ loading: false });
          cogoToast.error(res.Message);
        }
      });
    }
  };

  DeleteMasterType = () => {
    this.setState({ loading: true });
    let params = {
      ID: this.state.MasterID,
      UserId: this.state.UserId,
    };
    api.post(APIConstant.path.DeleteMakeMaster, params).then((res) => {
      if (res.Success == 1) {
        this.setState({
          loading: false,
          DeleteMasterTypeModal: false,
          MasterID: "",
        });
        this.GetMakeMaster(this.state.UserId);
        cogoToast.success(res.Message);
      } else {
        this.setState({ loading: false });
        cogoToast.error(res.Message);
      }
    });
  };

  render() {
    const {
      UserId,
      search,
      userPageAccess,
      GetMasterList,
      MasterLabelName,
      MasterLabelNameErr,
      MasterLabelNameErrText,
      AddMasterModal,
      EditMasterTypeModal,
      DeleteMasterTypeModal,
      MasterID,
      makeName,
      makeNameErr,
      makeNameErrText,
    } = this.state;

    let filterGetMasterList = [];
    if (GetMasterList.length) {
      filterGetMasterList = GetMasterList;
      if (search) {
        filterGetMasterList = filterGetMasterList.filter((x) => {
          return (
            x.MakeName.toLowerCase().indexOf(search.toLowerCase()) !== -1
          );
        });
      }
    }

    return (
      <div className="main-access-contianer">
        <div className="heading-area">
          <h1>Make Master</h1>
          <div className="heading-area-right">
            <div className="searchbar-area p-as-center">
              <InputText
                type="search"
                onInput={(e) => this.setState({ search: e.target.value })}
                placeholder="Global Search"
                size="30"
                style={{ marginRight: 10 }}
              />
              <i className="icon-search"></i>
            </div>
            {userPageAccess.includes("Add") ? (
              <div>
                <div
                  className="add-btn1"
                  onClick={(e) => this.handleOpen(e, "AddMaster")}
                >
                  <i className="icon-add"></i> Add Make Master
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="main-access-card-master">
          {this.state.loading === true ? (
            <div className="loading">
              <SimpleBackdrop />
            </div>
          ) : null}
          {userPageAccess.includes("View Make Master") ? (
            <DataTable
              className="main-table table-td"
              value={filterGetMasterList}
              editMode="row"
              dataKey="id"
              responsiveLayout="scroll"
              paginator={true}
              rows={this.state.rowsPerPage}
              totalRecords={filterGetMasterList.length}
              rowsPerPageOptions={this.state.rowsPerPageOptions}
              globalFilter={search}
              emptyMessage="No records found"
              responsive={false}
              resizableColumns={true}
              columnResizeMode="fit"
              scrollable={true}
              scrollWidth="auto"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            >
              <Column
                field="Index"
                header="Sr.No"
                body={this.actionBodyTemplateSrNo}
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="MakeName"
                header="Make Name"
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="Action"
                header="Action"
                body={this.actionTemplate}
                headerStyle={{ width: "10%", minWidth: "8rem" }}
                bodyStyle={{ textAlign: "center" }}
              ></Column>
            </DataTable>
          ) : null}
          {/* Add Edit BL Type */}
          <Dialog
            open={AddMasterModal}
            onClose={() => this.handleClose("AddMaster")}
            aria-labelledby="cha-form-dialog-title"
          >
            <DialogTitle
              id="cha-form-dialog-title"
              className="custom-dialog-header"
            >
              <div>{MasterID !== "" ? "Edit" : "Add"} Make Master</div>
              <div
                className="dialog-close"
                onClick={(e) => this.handleClose("AddMaster")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <DialogContent className="CHA-dialog-content-area">
              <GridContainer>
                <GridItem md={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="MakeName"
                    // id="chaNo"
                    label="Make Name*"
                    type="text"
                    value={makeName}
                    onChange={(e) => this.handleChange(e, "makeName")}
                    error={makeNameErr}
                    helperText={makeNameErrText}
                    fullWidth
                  />
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={(e) => this.handleClose("AddMaster")}
              >
                Close
              </Button>
              <Button
                className="primary-btn"
                onClick={(e) => this.AddMaster(e)}
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          {/* Modal For Delete */}
          <Dialog
            open={DeleteMasterTypeModal}
            onClose={() => this.handleClose("DeleteMasterType")}
            aria-labelledby="cha-form-dialog-title"
          >
            <DialogTitle
              id="cha-form-dialog-title"
              className="custom-dialog-header"
            >
              <div>Delete Make Master</div>
              <div
                className="dialog-close"
                onClick={(e) => this.handleClose("DeleteMasterType")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <DialogContent className="CHA-dialog-content-area">
              <h6>Are you sure want to delete?</h6>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={(e) => this.handleClose("DeleteMasterType")}
              >
                Close
              </Button>
              <Button
                className="primary-btn"
                onClick={(e) => this.DeleteMasterType(e)}
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}
export default MakeMaster;
