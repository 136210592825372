/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Notifications from "@material-ui/icons/Notifications";
import SimpleBackdrop from "../../utils/general";
import vessel from "../../assets/images/vessel.png";
import userImg from "../../assets/equator-img/User-circle.svg";
import upArrow from "../../assets/equator-img/up-arrow.svg";
import downArrow from "../../assets/equator-img/down-arrow.svg";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import api from "../../utils/api";
import { apiBase } from "../../config";
// core components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import { individual_profile_image_link } from "../../config";
import { set } from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";
import cogoToast from "cogo-toast";
import { validate } from "@material-ui/pickers";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import moment from "moment";

import { Dropdown } from "primereact/dropdown";
import { OverlayPanel } from "primereact/overlaypanel";
import User from "../../assets/images/user.jpg";
import Fade from "@material-ui/core/Fade";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { CommonConfig } from "../../CommonConfig.js";
import APIConstant from "../../utils/constant";
import { SelectButton } from "primereact/selectbutton";
import { InputSwitch } from "primereact/inputswitch";
import Loader from "components/Shared/Loader/Loader";
import Axios from "axios";
import { InputTextarea } from "primereact/inputtextarea";
const useStyles = makeStyles(styles);
let tempSelectedNotificationType = {
  name: "All",
  code: "All",
  RoleId: "62",
  fkParentServiceId: "62",
};
import UpgradeUserPlan from "../UpgradeUserPlan/UpgradeUserPlan";

export default function HeaderLinks(props) {
  const initialState = {
    openPassword: false,
    showConfirmPin: false,
    // password: '',
    passwordErr: false,
    passwordErrText: "",
    // confirmPassword: '',
    confirmPasswordErr: "",
    confirmPasswordErrText: "",
    loading: false,
    currentPassword: "",
    showPassword: false,
    click: false,
    openHandlerVesselRequest: false,
    handlerVesselRequestItem: {},
    handlerIssueDo: {},
    opendo: false,
    userDetails: {},
    openCompanyRequest: false,
    lat: null,
    lng: null,
    locationAddress: "",
    userRole: "",
  };

  const [inputHandle, setInputHandle] = useState(initialState);
  const [InputHandleIsuueDo, setInputHandleIsuueDo] = useState(initialState);
  // const [InputHandleCompany, setInputHandleCompany] = useState(initialState)
  const [password, setpassword] = useState("");

  const [confirmPassword, setconfirmPassword] = useState("");

  const [notification, setNotifiction] = React.useState("");
  let data = JSON.parse(localStorage.getItem("Userdata"));
  let UserId = localStorage.getItem("Infodata");
  let userdata = {
    UserID: localStorage.getItem("Infodata"),
  };
  let name = "";
  let ProfilePic = "";
  if (data) {
    name = data.Name;
    ProfilePic = data.ProfilePic;
  }
  // const [setProfilePic] = useState(individual_profile_image_link + ProfilePic);
  const [profilePic, setProfilePic] = useState(null);
  const [userName, setUserName] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const [userDetail, setUserDetail] = useState({});
  const [serviceList, setserviceList] = React.useState([]);
  const [openNotification, setopenNotification] = React.useState(null);
  let [buyclick, setbuyclick] = React.useState(false);
  let [purchaseclick, setpurchaseclick] = React.useState(false);
  let [locationclick, setlocationclick] = React.useState(false);
  let [vesselclick, setvesselclick] = React.useState(false);
  let [truckclick, settruckclick] = React.useState(false);
  let [transporterclick, settransporterclick] = React.useState(false);
  let [driverclick, setdriverclick] = React.useState(false);
  let [open, setopen] = React.useState(false);
  let [openlock, setopenlock] = React.useState(false);
  let [showOldPin, setshowOldPin] = React.useState(false);
  let [showNewPin, setshowNewPin] = React.useState(false);
  let [showConfirmPin, setshowConfirmPin] = React.useState(false);
  let [open1, setopen1] = React.useState(false);
  let [click, setclick] = React.useState(false);
  let [loading, setloading] = React.useState(false);
  let [click1, setclick1] = React.useState(false);
  let [currentpin, setcurrentpin] = React.useState("");
  // const [name, setName] = useState('');
  let [oldpin, setoldpin] = React.useState("");
  let [oldpinErr, setoldpinErr] = React.useState(false);
  let [oldpinhelperText, setoldpinhelperText] = React.useState("");
  let [newpin, setnewpin] = React.useState("");
  let [newpinErr, setnewpinErr] = React.useState(false);
  let [newpinhelperText, setnewpinhelperText] = React.useState("");
  let [confirmpin, setconfirmpin] = React.useState("");
  let [confirmpinErr, setconfirmpinErr] = React.useState(false);
  let [confirmpinhelperText, setconfirmpinhelperText] = React.useState("");
  let [sellingrate, setsellingrate] = React.useState("");
  let [sellingrateErr, setsellingrateErr] = React.useState(false);
  let [sellingratehelperText, setsellingratehelperText] = React.useState("");
  let [isChecked, setisChecked] = React.useState(false);
  let [isCheckedErr, setisCheckedErr] = React.useState(false);
  let [isCheckedhelperText, setisCheckedhelperText] = React.useState("");
  let [isChecked1, setisChecked1] = React.useState(false);
  let [isChecked1Err, setisChecked1Err] = React.useState(false);
  let [isChecked1helperText, setisChecked1helperText] = React.useState("");
  let [requestrate, setrequestrate] = React.useState("");
  let [requestrateErr, setrequestrateErr] = React.useState(false);
  let [requestratehelperText, setrequestratehelperText] = React.useState("");
  let [message, setmessage] = React.useState("");
  let [messageErr, setmessageErr] = React.useState(false);
  let [messagehelperText, setmessagehelperText] = React.useState("");
  let [Rate, setRate] = React.useState("");
  let [purchasearr, setpurchasearr] = React.useState([]);
  var [openProfile, setOpenProfile] = React.useState(null);
  var [openRateNotification, setopenRateNotification] = React.useState(null);
  var [openRequestNotification, setopenRequestNotification] = React.useState(
    null
  );
  var [buyList, setbuyList] = React.useState([]);
  var [friendList, setfriendList] = React.useState([]);
  var [vesselInfo, setvesselInfo] = React.useState([]);
  var [stockInfo, setstockInfo] = React.useState([]);
  var [locationInfo, setlocationInfo] = React.useState([]);
  var [driverInfo, setdriverInfo] = React.useState([]);
  var [truckInfo, settruckInfo] = React.useState([]);
  var [transporterInfo, settransporterInfo] = React.useState([]);
  var [subscribe, setsubscribe] = React.useState([]);
  var [purchaseInfo, setpurchaseInfo] = React.useState([]);
  const [userAccess, setUserAccess] = React.useState([]);
  const [count, setcount] = React.useState(false);
  // const handleClickNotification = React.useRef(null);
  const friendRequest = React.useRef(null);

  const openGeneral = React.useRef(null);
  const openGeneralInfo = React.useRef(null);
  const [selectedNotificationType, setSelectedNotificationType] = useState();
  const [notificationDetailArray, setNotificationDetailArray] = React.useState(
    []
  );
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationTypeListData, setNotificationTypeListData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let [showPassword, setshowPassword] = React.useState(false);
  let [showConfirmPassword, setshowConfirmPassword] = React.useState(false);
  const [inputCompanyHandle, setInputCompanyHandle] = useState([]);
  const [locationValue, setlocationValue] = useState("OUT");
  const [trackId, setTrackId] = useState(null);
  const [navbarAccess, setNavbarAccess] = useState([]);
  const [vesselDeclarationModal, setVesselDeclarationModal] = useState(false);
  const [vcnNo, setvcnNo] = useState("");
  const [tradeType, setTradeType] = useState("");
  const [vesselName, setVesselName] = useState("");
  const [port, setPort] = useState("");
  const [countryName, setCountryName] = useState("");
  const [commodity, setCommodity] = useState("");
  const [vesselDeclarationId, setVesselDeclarationId] = useState("");
  const [vesselDeclarationStatus, setVesselDeclarationStatus] = useState("");
  const [remark, setRemark] = useState("");
  const [remarkErr, setRemarkErr] = useState(false);
  const [remarkErrText, setRemarkErrText] = useState("");
  const [remarkModal, setRemarkModal] = useState(false);
  const [vesselDeclarationRemark, setVesselDeclarationRemark] = useState("");
  const [childNotificationList, setChildNotificationList] = useState([]);

  const ProfileOpen = Boolean(anchorEl);

  const getSelectBEList = async (VesselDisplayId, PartyToID) => {
    var params = {
      VesselDisplayId: VesselDisplayId,
      CHAID: localStorage.getItem("Infodata"),
      PartyToID: PartyToID,
    };
    await api
      .post(APIConstant.path.GetSelectBEListByPartyID, params)
      .then(async (res) => {
        if (res.Success === 1) {
          setInputHandleIsuueDo((InputHandleIsuueDo) => ({
            ...InputHandleIsuueDo,
            BESelectList: res.Info.BEList,
          }));
        }
      })
      .catch((err) => {});
  };
  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const handleEditProfile = () => {
    const { history } = props;
    history.push("/admin/editprofile");
    setAnchorEl(null);
  };

  const handleChangePassword = (e) => {
    setInputHandle((inputHandle) => ({ ...inputHandle, openPassword: true }));
    setAnchorEl(null);
  };

  const handleChange = (e, type) => {
    e.preventDefault();
    if (type === "password") {
      // setInputHandle(inputHandle => ({ ...inputHandle, [e.target.name]: e.target.value }));
      if (e.target.value === "" || e.target.value === null) {
        setInputHandle((inputHandle) => ({
          ...inputHandle,
          passwordErr: true,
          passwordErrText: "Please enter password",
        }));
        setpassword(e.target.value);
      } else if (e.target.value.trim() !== e.target.value) {
        setInputHandle((inputHandle) => ({
          ...inputHandle,
          passwordErr: true,
          passwordErrText: "Please enter a valid password",
        }));
        setpassword(e.target.value);
      } else {
        setInputHandle((inputHandle) => ({
          ...inputHandle,
          passwordErr: false,
          passwordErrText: "",
        }));
        setpassword(e.target.value);
      }
    } else {
      if (e.target.value === "" || e.target.value === null) {
        setInputHandle((inputHandle) => ({
          ...inputHandle,
          confirmPasswordErr: true,
          confirmPasswordErrText: "Please enter confirm password",
        }));
        setconfirmPassword(e.target.value);
      } else if (password !== e.target.value) {
        setInputHandle((inputHandle) => ({
          ...inputHandle,
          confirmPasswordErr: true,
          confirmPasswordErrText: "Password does not match",
        }));
        setconfirmPassword(e.target.value);
      } else {
        setInputHandle((inputHandle) => ({
          ...inputHandle,
          confirmPasswordErr: false,
          confirmPasswordErrText: "",
        }));
        setconfirmPassword(e.target.value);
      }
    }
  };

  const handleClickShowPassword = (e, type) => {
    if (type == "password") {
      setshowPassword(!showPassword);
    } else if (type == "confirmPassword") {
      setshowConfirmPassword(!showConfirmPassword);
    }
  };

  const handlerforenter = (event) => {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const validatePassword = (e) => {
    let formIsValid = true;
    if (password === "" || password === null) {
      setInputHandle((inputHandle) => ({
        ...inputHandle,
        passwordErr: true,
        passwordErrText: "Please enter password",
      }));
      formIsValid = false;
    } else if (password.trim() !== password) {
      setInputHandle((inputHandle) => ({
        ...inputHandle,
        passwordErr: true,
        passwordErrText: "Please enter a valid password",
      }));
      formIsValid = false;
    } else {
      setInputHandle((inputHandle) => ({
        ...inputHandle,
        passwordErr: false,
        passwordErrText: "",
      }));
    }

    if (confirmPassword === "" || confirmPassword === null) {
      setInputHandle((inputHandle) => ({
        ...inputHandle,
        confirmPasswordErr: true,
        confirmPasswordErrText: "Please enter confirm password",
      }));
      formIsValid = false;
    } else if (password !== confirmPassword) {
      setInputHandle((inputHandle) => ({
        ...inputHandle,
        confirmPasswordErr: true,
        confirmPasswordErrText: "Password does not match",
      }));
      formIsValid = false;
    } else {
      setInputHandle((inputHandle) => ({
        ...inputHandle,
        confirmPasswordErr: false,
        confirmPasswordErrText: "",
      }));
    }
    return formIsValid;
  };

  const ChangePassword = (e) => {
    e.preventDefault();
    if (validatePassword(e)) {
      var params = {
        UserId: localStorage.getItem("Infodata"),
        Password: password,
        CurrentPassword: inputHandle.currentPassword,
      };
      // console.log("Pass data", params)
      if (params) {
        setInputHandle((inputHandle) => ({
          ...inputHandle,
          loading: true,
        }));
        api
          .post(apiBase + "/ajax/player.php?action=ChangePassword", params)
          .then((res) => {
            if (res.Success === 1) {
              setInputHandle((inputHandle) => ({
                ...inputHandle,
                passwordErrText: "",
                passwordErr: false,
                confirmPasswordErr: "",
                confirmPasswordErrText: "",
                loading: false,
                openPassword: false,
              }));
              setpassword("");
              setconfirmPassword("");
              cogoToast.success("Password changed successfully");
            } else {
              setInputHandle((inputHandle) => ({
                ...inputHandle,
                loading: false,
              }));
              cogoToast.error("Something happened bad");
            }
          })
          .catch((err) => {});
      }
    }
  };

  var params = {
    UserId: localStorage.getItem("Infodata"),
    token: localStorage.getItem("Token"),
  };
  var paramsrequest = {
    UserId: localStorage.getItem("Infodata"),
    Type: "request",
    token: localStorage.getItem("Token"),
  };
  var paramslocation = {
    UserId: localStorage.getItem("Infodata"),
    Type: "location",
    token: localStorage.getItem("Token"),
  };
  var paramsvessels = {
    UserId: localStorage.getItem("Infodata"),
    Type: "vessels",
    token: localStorage.getItem("Token"),
  };
  var paramstruck = {
    UserId: localStorage.getItem("Infodata"),
    Type: "truck",
    token: localStorage.getItem("Token"),
  };
  var paramstransporter = {
    UserId: localStorage.getItem("Infodata"),
    Type: "transporter",
    token: localStorage.getItem("Token"),
  };
  var paramsdriver = {
    UserId: localStorage.getItem("Infodata"),
    Type: "driver",
    token: localStorage.getItem("Token"),
  };

  const googleTranslateElementInit = () => {
    // new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT }, 'google_translate_element')
    new window.google.translate.TranslateElement(
      { pageLanguage: "en" },
      "google_translate_element"
    );
  };

  useEffect(() => {
    GetTrackingByID(localStorage.getItem("Infodata"));
    GetUserDetails();
    getNavbarAccess();

    // let navbarAccessData = localStorage.getItem('NavbarAccess');
    // setNavbarAccess(navbarAccessData ? navbarAccessData : []);
    let data = JSON.parse(localStorage.getItem("Userdata"));
    setUserAccess(JSON.parse(localStorage.getItem("PageAccess")));
    setInputHandle((inputHandle) => ({
      ...inputHandle,
      currentPassword: data.Password,
      userRole: data.UserRoll,
    }));

    getNotificationDetail(tempSelectedNotificationType);

    const interval = window.setInterval(async () => {
      await getNotificationDetail(tempSelectedNotificationType);

      await api
        .post(apiBase + "/ajax/player.php?action=subscribeVesselList", params)
        .then((res) => {
          if (res.Success == 1) {
            setsubscribe(
              res.Info.SubscribeList.filter((x) => x.CurrentRate !== x.LastRate)
            );
          } else if (res.Success == -1) {
            let data = localStorage.getItem("Infodata");
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        })
        .catch((err) => {});
      await api
        .post(apiBase + "/ajax/player.php?action=FriendRequestList", params)
        .then((res) => {
          if (res.Success == 1) {
            setfriendList(
              res.Info.request.sort(function(a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.

                return (
                  new Date(b.UpdatedOn == "" ? b.CreatedOn : b.UpdatedOn) -
                  new Date(a.UpdatedOn == "" ? a.CreatedOn : a.UpdatedOn)
                );
              })
            );
          } else if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        })
        .catch((err) => {});
    }, 50000);

    if (count == false) {
      var addScript = document.createElement("script");
      addScript.setAttribute(
        "src",
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
      getNotificationPermission();
      if (params != null) {
        api.post(APIConstant.path.GetUserDetailsById, params).then((res) => {
          if (res.Success == 1) {
            if (res.Info) {
              let currentpin = res.Info.UserDetail.UnlockPin;
              setcurrentpin(currentpin);
            }
          } else if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        });
      }
      api
        .post(
          apiBase + "/ajax/player.php?action=getNotificationServiceRoll",
          userdata
        )
        .then((res) => {
          var servicelist = [];

          if (res.Info) {
            for (let i = 0; i < res.Info.service.length; i++) {
              servicelist.push(res.Info.service[i].ServiceId);
            }
            setserviceList(servicelist);
          }
        })
        .catch((err) => {});

      api
        .post(apiBase + "/ajax/player.php?action=subscribeVesselList", params)
        .then((res) => {
          if (res.Success == 1) {
            setsubscribe(
              res.Info.SubscribeList.filter((x) => x.CurrentRate !== x.LastRate)
            );
          } else if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        })
        .catch((err) => {});
      api
        .post(apiBase + "/ajax/player.php?action=FriendRequestList", params)
        .then((res) => {
          if (res.Success == 1) {
            setfriendList(
              res.Info.request.sort(function(a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.

                return (
                  new Date(b.UpdatedOn == "" ? b.CreatedOn : b.UpdatedOn) -
                  new Date(a.UpdatedOn == "" ? a.CreatedOn : a.UpdatedOn)
                );
              })
            );
          } else if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        })
        .catch((err) => {});
      setcount(!count);
    }

    return () => window.clearInterval(interval);
  }, []);

  useEffect(() => {
    setInterval(() => {
      const tempProfle = localStorage.getItem("ProfilePicture");

      if (tempProfle) {
        setProfilePic(tempProfle);
      } else {
        setProfilePic(User);
      }
    }, 2000);
  }, []);

  const GetUserDetails = async () => {
    var params = {
      UserId: localStorage.getItem("Infodata"),
      token: localStorage.getItem("Token"),
    };

    let ProfilePic = "";
    let currentpin = "";
    let name = "";
    if (params != null) {
      await api
        .post(APIConstant.path.GetUserDetailsById, params)
        .then(async (res) => {
          if (res.Success == 1) {
            if (res.Info && res.Info?.UserDetail?.length) {
              currentpin = await res.Info?.UserDetail[0]?.UnlockPin;
              name = res.Info?.UserDetail[0]?.Name;
              let accountType = res.Info?.UserDetail[0]?.AccountType;
              if (
                res.Info?.UserDetail[0]?.ProfilePic === "" ||
                res.Info?.UserDetail[0]?.ProfilePic === null ||
                res.Info?.UserDetail[0]?.ProfilePic === undefined
              ) {
                setProfilePic(User);
              } else {
                setProfilePic(
                  individual_profile_image_link +
                    res.Info.UserDetail[0].ProfilePic
                );
                localStorage.setItem(
                  "ProfilePicture",
                  individual_profile_image_link +
                    res.Info.UserDetail[0].ProfilePic
                );
              }
              setUserName(name);
              setAccountType(accountType);
              setcurrentpin(currentpin);
              // setName(name)
              setUserDetail(res?.Info?.UserDetail[0]);
            }
          } else if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {

            localStorage.clear();
            const { history } = props;
            history.push("/login");

            //     }
            // })
          }
        });
    }
  };

  const getNotificationPermission = async () => {
    var params = {
      UserId: localStorage.getItem("Infodata"),
      token: localStorage.getItem("Token"),
    };
    await api
      .post(
        apiBase + "/ajax/player.php?action=getNotificationPermission",
        params
      )
      .then((response) => {
        if (response.Success == 1) {
          let All = [
            {
              name: "All",
              code: "All",
              RoleId: "62",
              fkParentServiceId: "62",
            },
          ];
          let notificationData = response.Info.NotificationPermissionDetail[0];
          setChildNotificationList(
            response.Info.NotificationPermissionDetail[1]
          );
          setNotificationTypeListData(notificationData);

          // setSelectedNotificationType({
          //     "name": "All",
          //     "code": "All",
          //     "RoleId": "62",
          //     "fkParentServiceId": "62"
          // });
          // tempSelectedNotificationType = {
          //     "name": "All",
          //     "code": "All",
          //     "RoleId": "62",
          //     "fkParentServiceId": "62"
          // };
          // getNotificationDetail({
          //     "name": "All",
          //     "code": "All",
          //     "RoleId": "62",
          //     "fkParentServiceId": "62"
          // });
        } else if (i.Success == -1) {
          // let data = localStorage.getItem('Infodata');
          // var params = {
          //     UserId: data
          // }
          // // setUserName: data });
          // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
          //     if (res.Success == 1) {
          localStorage.clear();
          const { history } = props;
          history.push("/login");
          //     }
          // })
        }
      })
      .catch((err) => {});
  };

  const getNotificationDetail = async (type) => {
    var params = {
      UserId: localStorage.getItem("Infodata"),
      Type:
        type && childNotificationList.filter((x) => x.value == type)[0]?.label
          ? childNotificationList.filter((x) => x.value == type)[0]?.label
          : "All",
      token: localStorage.getItem("Token"),
    };
    await api
      .post(APIConstant.path.GetNotificationDetails, params)
      .then((response) => {
        if (response.Success == 1) {
          setNotificationDetailArray(
            response.Info.NotificationDetail !== true
              ? response.Info.NotificationDetail
              : []
          );
          let Data = response.Info.NotificationDetail;
          const count = Data.filter((obj) => obj.IsNew).length;
          setNotificationCount(count);
        } else if (response.Success == -1) {
          // let data = localStorage.getItem('Infodata');
          // var params = {
          //     UserId: data
          // }
          // // setUserName: data });
          // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
          //     if (res.Success == 1) {
          localStorage.clear();
          const { history } = props;
          history.push("/login");
          //     }
          // })
        }
      })
      .catch((err) => {});
  };

  const handleClickNotification = (event, type) => {
    if (type === "general") {
      if (openNotification && openNotification.contains(event.target)) {
        setopenNotification(null);
      } else {
        setopenNotification(event.currentTarget);
      }
    } else {
      if (
        openRequestNotification &&
        openRequestNotification.contains(event.target)
      ) {
        setopenRequestNotification(null);
      } else {
        setopenRequestNotification(event.currentTarget);
      }
    }
  };

  const handleCloseNotification = (event, type) => {
    if (type === "general") {
      setopenNotification(null);
    } else {
      setopenRequestNotification(null);
    }
  };

  const closeNotification = async (e,data) => {
    e.stopPropagation()
    var params = {
      UserId: localStorage.getItem("Infodata"),
      NotificationId: data.NotificationId,
    };
    await api
      .post(APIConstant.path.DeleteNotification, params)
      .then((response) => {
        if (response.Success == 1) {
          getNotificationDetail();
        }
      })
      .catch((err) => {});
  };

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
    const { history } = props;
    history.push("/admin/editprofile");
  };

  const handleRateNotification = (event) => {
    if (openRateNotification && openRateNotification.contains(event.target)) {
      setopenRateNotification(null);
    } else {
      setopenRateNotification(event.currentTarget);
    }
  };

  const handleRateClose = () => {
    setopenRateNotification(null);
  };

  const validaterate = (type) => {
    let formIsValid = true;
    if (type === "sellingrate") {
      if (sellingrate === "" || sellingrate === null) {
        setsellingrateErr(true);
        setsellingratehelperText("Please Enter Selling Rate.");
        formIsValid = false;
      } else {
        setsellingrateErr(false);
        setsellingratehelperText("");
      }
    } else if (type === "requestrate") {
      if (requestrate === "" || requestrate === null) {
        setrequestrateErr(true);
        setrequestratehelperText("Please Enter Request Rate.");
        formIsValid = false;
      } else {
        setrequestrateErr(false);
        setrequestratehelperText("");
      }
    } else {
      if (message === "" || message === null) {
        setmessageErr(true);
        setmessagehelperText("Please input message.");
        formIsValid = false;
      } else {
        setmessageErr(true);
        setmessagehelperText("");
      }
    }
    return formIsValid;
  };

  const handlePurchaseClick = (event, idx, type) => {
    if (type === "addtovessel") {
      if (validaterate("sellingrate")) {
        setloading(true);
        var params = {
          UserId: UserId,
          Type: "purchase",
          rate: sellingrate,
          PurchaseId: event.Id,
          fkUserId: event.PurchaseUserId,
          VesselId: event.fkVesselId,
          VesselUserId: event.VesselUserId,
          Quantity: event.Quantity,
          VesselStatus: 1,
          token: localStorage.getItem("Token"),
          // message: ""
        };
        api
          .post(
            apiBase + "/ajax/player.php?action=updateNotificationStatus",
            params
          )
          .then((res) => {
            if (res.Success === 1) {
              setloading(false);
              setopen(false);
              setclick(false);
              setsellingrate("");
              cogoToast.success("Rate Updated and Vessel added to list");
            } else if (res.Success == -1) {
              // let data = localStorage.getItem('Infodata');
              // var params = {
              //     UserId: data
              // }
              // // setUserName: data });
              // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
              //     if (res.Success == 1) {
              localStorage.clear();
              const { history } = props;
              history.push("/login");
              //     }
              // })
            } else {
              setloading(false);
              setopen(false);
              setclick(false);
              setsellingrate("");
              cogoToast.error("Rate update failed");
            }
          });
      }
    } else if (type === "forself") {
      setloading(true);
      var params = {
        UserId: UserId,
        Type: "self",
        rate: sellingrate,
        PurchaseId: event.Id,
        fkUserId: event.PurchaseUserId,
        VesselId: event.fkVesselId,
        VesselUserId: event.VesselUserId,
        Quantity: event.Quantity,
        VesselStatus: 0,
        token: localStorage.getItem("Token"),
        // message: ""
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then((res) => {
          if (res.Success === 1) {
            setloading(false);
            setopen(false);
            cogoToast.success("Vessel added to Stock");
          } else if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          } else {
            setloading(false);
            setopen(false);
            cogoToast.error("Rate update failed");
          }
        });
    } else if (type === "requestrate") {
      if (validaterate("requestrate")) {
        setloading(true);
        var params = {
          UserId: UserId,
          Type: "request",
          rate: requestrate,
          PurchaseId: event.Id,
          fkUserId: event.PurchaseUserId,
          VesselId: event.fkVesselId,
          VesselUserId: event.VesselUserId,
          Quantity: event.Quantity,
          VesselStatus: 0,
          token: localStorage.getItem("Token"),
          // message: ""
        };
        api
          .post(
            apiBase + "/ajax/player.php?action=updateNotificationStatus",
            params
          )
          .then((res) => {
            if (res.Success === 1) {
              setloading(false);
              setopen1(false);
              setclick(false);
              setrequestrate("");
              cogoToast.success("Request for the vessel sent successfully");
            } else if (res.Success == -1) {
              // let data = localStorage.getItem('Infodata');
              // var params = {
              //     UserId: data
              // }
              // // setUserName: data });
              // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
              //     if (res.Success == 1) {
              localStorage.clear();
              const { history } = props;
              history.push("/login");
              //     }
              // })
            } else {
              setloading(false);
              setopen1(false);
              setclick(false);
              setrequestrate("");
              cogoToast.error("Request failed");
            }
          });
      }
    } else {
      if (validaterate("message")) {
        setloading(true);
        var params = {
          // rate: null,
          UserId: UserId,
          Type: "message",
          PurchaseId: event.Id,
          fkUserId: event.PurchaseUserId,
          VesselId: event.fkVesselId,
          VesselUserId: event.VesselUserId,
          VesselStatus: 1,
          message: message,
          token: localStorage.getItem("Token"),
        };
        api
          .post(
            apiBase + "/ajax/player.php?action=updateNotificationStatus",
            params
          )
          .then((res) => {
            if (res.Success === 1) {
              setloading(false);
              setopen(false);
              cogoToast.success("Vessel added successfully");
            } else if (res.Success == -1) {
              // let data = localStorage.getItem('Infodata');
              // var params = {
              //     UserId: data
              // }
              // // setUserName: data });
              // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
              //     if (res.Success == 1) {
              localStorage.clear();
              const { history } = props;
              history.push("/login");
              //     }
              // })
            } else {
              setloading(false);
              setopen(false);
              cogoToast.error("Rate update failed");
            }
          });
      }
    }
    getNotificationDetail(selectedNotificationType);
  };

  const handleCheckbox = (e, type) => {
    if (type === "forself") {
      const isChecked = e.target.checked;
      if (isChecked) {
        setclick(true);
        setclick1(false);
        setsellingrate(Rate);
      } else {
        setclick(false);
      }
    } else {
      const isChecked1 = e.target.checked;
      if (isChecked1) {
        setclick1(true);
        setclick(false);
        setmessage("Contact us for best deal");
      } else {
        setclick1(false);
      }
    }
  };

  const handleRequestClick = (event, idx, type) => {
    handleCloseNotification(event, "request");

    if (type == "network") {
      var index = friendList.findIndex((x) => x.NetworkId === event.NetworkId);
      var requestlist = friendList;
      requestlist[index]["RequestStatus"] = "Inactive";
      setfriendList(requestlist);
      var params = {
        UserId: UserId,
        NetworkId: event.NetworkId,
        token: localStorage.getItem("Token"),
      };
      var Id = 1;
      props.history.push({
        pathname: "/admin/Network",
        // state: {
        //     Id: Id,
        // }
      });
      api
        .post(apiBase + "/ajax/player.php?action=updateRequestStatus", params)
        .then((res) => {
          if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        });
    } else if (type == "networkrequest") {
      var index = friendList.findIndex((x) => x.NetworkId === event.NetworkId);
      var requestlist = friendList;
      requestlist[index]["RequestStatus"] = "Inactive";
      setfriendList(requestlist);
      var params = {
        UserId: UserId,
        NetworkId: event.NetworkId,
        token: localStorage.getItem("Token"),
      };
      var Id = 1;
      props.history.push({
        pathname: "/admin/request",
        // state: {
        //     Id: Id,
        // }
      });
      api
        .post(apiBase + "/ajax/player.php?action=updateRequestStatus", params)
        .then((res) => {
          if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        });
    } else if (type === "transporter") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      settruckInfo(requestlist);
      var params = {
        UserId: UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "receiveractive",
        token: localStorage.getItem("Token"),
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then((res) => {
          if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        });
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/request/",
        state: {
          Id: Id,
        },
      });
    } else if (type === "driveractive") {
      var index = driverInfo.findIndex((x) => x.TruckId === event.TruckId);
      var requestlist = driverInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      setdriverInfo(requestlist);
      var params = {
        UserId: UserId,
        TruckId: requestlist[index]["TruckId"],
        Type: "driveractive",
        token: localStorage.getItem("Token"),
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then((res) => {
          if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        });
      var Id = "driver";
      props.history.push({
        pathname: "/admin/drivertruck/",
        state: {
          Id: Id,
        },
      });
    } else if (type === "transporterinactive") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      settruckInfo(requestlist);
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/request/",
        state: {
          Id: Id,
        },
      });
    } else if (type === "transporterinfo") {
      handleCloseNotification(event, "general");
      var index = transporterInfo.findIndex(
        (x) => x.RequestId === event.RequestId
      );
      var requestlist = transporterInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      requestlist[index]["status"] = "Pending";
      settransporterInfo(requestlist);
      var params = {
        UserId: UserId,
        vesselid: requestlist[index]["vesselid"],
        RequestId: requestlist[index]["RequestId"],
        AssignToUser: requestlist[index]["CreatedBy"],
        // rate: requestlist[index]["Rate"],
        IsTransporterStatus: 0,
        Type: "requestdifferent",
        token: localStorage.getItem("Token"),
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=AcceptTruckRequestStatus",
          params
        )
        .then((res) => {
          if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        });
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/request/",
        state: {
          Id: Id,
        },
      });
    } else if (type === "transporterinfoinactive") {
      handleCloseNotification(event, "general");
      var index = transporterInfo.findIndex(
        (x) => x.RequestId === event.RequestId
      );
      var requestlist = transporterInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      settransporterInfo(requestlist);
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/request/",
        state: {
          Id: Id,
        },
      });
    } else if (type === "truckinactive") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      settruckInfo(requestlist);
      var Id = "receiver";
      props.history.push({
        pathname: "/admin/request/",
        state: {
          Id: Id,
        },
      });
    } else if (type === "trucklistactive") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      settruckInfo(requestlist);
      var params = {
        UserId: UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "receiveractive",
        token: localStorage.getItem("Token"),
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then((res) => {
          if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        });
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else if (type === "trucklistinactive") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      settruckInfo(requestlist);
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else if (type === "viewtruckactive") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      settruckInfo(requestlist);
      var params = {
        UserId: UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "receiveractive",
        token: localStorage.getItem("Token"),
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then((res) => {
          if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        });
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/view-truck",
        state: {
          Id: Id,
        },
      });
    } else if (type === "viewtruckinactive") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      settruckInfo(requestlist);
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/view-truck",
        state: {
          Id: Id,
        },
      });
    } else if (type === "trucklistcancelledactive") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      settruckInfo(requestlist);
      var params = {
        UserId: UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "receiveractive",
        token: localStorage.getItem("Token"),
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then((res) => {
          if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        });
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else if (type === "trucklistcancelledinactive") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      settruckInfo(requestlist);
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else if (type === "trucklistrejectedactive") {
      handleCloseNotification(event, "general");
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      settruckInfo(requestlist);
      var params = {
        UserId: UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "receiveractive",
        token: localStorage.getItem("Token"),
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then((res) => {
          if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        });
      cogoToast.error(
        "Truck request rejected by" + " " + requestlist[index]["name"]
      );
      // var Id = "transporter";
      // props.history.push({
      //   path"name": '/admin/trucklist',
      //   state: {
      //     Id: Id,
      //   }
      // });
    } else if (type === "trucklistrejectedinactive") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      settruckInfo(requestlist);
      cogoToast.error("Truck request rejected");
      // var Id = "transporter";
      // props.history.push({
      //   pathname: '/admin/trucklist',
      //   state: {
      //     Id: Id,
      //   }
      // });
    } else if (type === "viewtruckcancelledactive") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      settruckInfo(requestlist);
      var params = {
        UserId: UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "receiveractive",
        token: localStorage.getItem("Token"),
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then((res) => {
          if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        });
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/view-truck",
        state: {
          Id: Id,
        },
      });
    } else if (type === "viewtruckcancelledinactive") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      settruckInfo(requestlist);
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/view-truck",
        state: {
          Id: Id,
        },
      });
    } else if (type === "loadingtruckactive") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["LoadingPersonStatus"] = "Inactive";
      settruckInfo(requestlist);
      var params = {
        UserId: UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "loadingactive",
        token: localStorage.getItem("Token"),
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then((res) => {
          if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        });
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else if (type === "loadingtruckinactive") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["LoadingPersonStatus"] = "Inactive";
      settruckInfo(requestlist);
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else if (type === "unloadingtruckactive") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["OTPStatus"] = "Inactive";
      settruckInfo(requestlist);
      var params = {
        UserId: UserId,
        AcceptId: requestlist[index]["AcceptId"],
        TruckId: requestlist[index]["Truck"],
        Type: "unloadingactive",
        token: localStorage.getItem("Token"),
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then((res) => {
          if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        });
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else if (type === "unloadingtruckinactive") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["OTPStatus"] = "Inactive";
      settruckInfo(requestlist);
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else if (type === "grouptruckactive") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["LoadingPersonStatus"] = "Inactive";
      settruckInfo(requestlist);
      var params = {
        UserId: UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "loadingactive",
        token: localStorage.getItem("Token"),
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then((res) => {
          if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        });
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else if (type === "grouptruckinactive") {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["LoadingPersonStatus"] = "Inactive";
      settruckInfo(requestlist);
      var Id = "transporter";
      props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else {
      var index = truckInfo.findIndex((x) => x.AcceptId === event.AcceptId);
      var requestlist = truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      settruckInfo(requestlist);
      var params = {
        UserId: UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "receiveractive",
        token: localStorage.getItem("Token"),
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then((res) => {
          if (res.Success == -1) {
            // let data = localStorage.getItem('Infodata');
            // var params = {
            //     UserId: data
            // }
            // // setUserName: data });
            // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
            //     if (res.Success == 1) {
            localStorage.clear();
            const { history } = props;
            history.push("/login");
            //     }
            // })
          }
        });
      var Id = "receiver";
      props.history.push({
        pathname: "/admin/request/",
        state: {
          Id: Id,
        },
      });
    }
  };

  const handleBuyClick = (event, idx) => {
    //
    handleCloseNotification(event, "general");
    var index = buyList.findIndex((x) => x.PuchaseId === event.PuchaseId);
    var buylist = buyList;
    buylist[index]["BuyStatus"] = "Inactive";
    setbuyList(buylist);
    var params = {
      UserId: UserId,
      PurchaseId: event.PuchaseId,
      token: localStorage.getItem("Token"),
    };
    api
      .post(apiBase + "/ajax/player.php?action=updateBuyStatus", params)
      .then((res) => {
        if (res.Success == -1) {
          // let data = localStorage.getItem('Infodata');
          // var params = {
          //     UserId: data
          // }
          // // setUserName: data });
          // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
          //     if (res.Success == 1) {
          localStorage.clear();
          const { history } = props;
          history.push("/login");
          //     }
          // })
        }
      });
    props.history.push("/admin/request");
  };
  const PARTYID = (ID) => {
    setInputHandleIsuueDo((InputHandleIsuueDo) => ({
      ...InputHandleIsuueDo,
      PartyToID: InputHandleIsuueDo.BESelectList.filter((x) => x.value == ID)[0]
        .PartyToID,
      PartyName: InputHandleIsuueDo.BESelectList.filter((x) => x.value == ID)[0]
        .PartyName,
      BEQty: InputHandleIsuueDo.BESelectList.filter((x) => x.value == ID)[0]
        .BEQty,
      BERemainingQty: InputHandleIsuueDo.BESelectList.filter(
        (x) => x.value == ID
      )[0].Remaining,
    }));
  };
  const handleRateClick = (event, idx) => {
    handleRateClose();
    var index = subscribe.findIndex((x) => x.SubscribeId === event.SubscribeId);
    var sublist = subscribe;
    sublist[index]["SubscribeStatus"] = "Inactive";
    setsubscribe(sublist);
    var params = {
      UserId: UserId,
      SubscribeId: event.SubscribeId,
      SubscribeRate: event.CurrentRate,
      token: localStorage.getItem("Token"),
    };
    var Id = event.VesselUserId;
    props.history.push({
      pathname: "/admin/VesselInfo/" + Id,
      state: {
        Id: Id,
      },
    });
    cogoToast.success(
      "Rate Changed from : " +
        event.SubscribeRate +
        " to : " +
        event.CurrentRate
    );
    api
      .post(apiBase + "/ajax/player.php?action=updateSubscribeStatus", params)
      .then((res) => {
        if (res.Success == -1) {
          // let data = localStorage.getItem('Infodata');
          // var params = {
          //     UserId: data
          // }
          // // setUserName: data });
          // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
          //     if (res.Success == 1) {
          localStorage.clear();
          const { history } = props;
          history.push("/login");
          //     }
          // })
        }
      });
  };

  const handleClickOpen = (event, idx, type) => {
    var purchaselist = purchaseInfo.filter(
      (x) => x.PuchaseId === event.PuchaseId
    );
    if (type === "accepted") {
      setRate(event.Rate);
      setopen(true);
      setpurchasearr(purchaselist);
    } else if (type === "acceptedbuyerseller") {
      var index = purchaseInfo.findIndex(
        (x) => x.PuchaseId === event.PuchaseId
      );
      var requestlist = purchaseInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      setpurchaseInfo(requestlist);
      var params = {
        UserId: UserId,
        // vesselid: requestlist[index]["vesselid"],
        PurchaseId: requestlist[index]["PuchaseId"],
        // AssignToUser: requestlist[index]["CreatedBy"],
        // rate: requestlist[index]["Rate"],
        // IsTransporterStatus: 0,
        Type: "acceptedbuyerseller",
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
      var Id = "buyer";
      props.history.push({
        pathname: "/admin/request/",
        state: {
          Id: Id,
        },
      });
    } else if (type === "acceptedbuyer") {
      var index = purchaseInfo.findIndex(
        (x) => x.PuchaseId === event.PuchaseId
      );
      var requestlist = purchaseInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      setpurchaseInfo(requestlist);
      var params = {
        UserId: UserId,
        // vesselid: requestlist[index]["vesselid"],
        PurchaseId: requestlist[index]["PuchaseId"],
        // AssignToUser: requestlist[index]["CreatedBy"],
        // rate: requestlist[index]["Rate"],
        // IsTransporterStatus: 0,
        Type: "acceptedbuyer",
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
      // var Id = "buyer";
      // this.props.history.push({
      //   pathname: '/admin/request/',
      //   state: {
      //     Id: Id,
      //   }
      // });
      props.history.push("/admin/request");
    } else if (type === "acceptedlocation") {
      handleCloseNotification();
      var index = locationInfo.findIndex((x) => x.id === event.id);
      var locationInfo = locationInfo;
      locationInfo[index]["Notification"] = "Inactive";
      setlocationInfo(locationInfo);
      var params = {
        id: event.id,
        Type: "acceptedlocation",
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
    } else if (type === "activevessel") {
      var index = vesselInfo.findIndex(
        (x) => x.VesselUserId == event.VesselUserId
      );
      var sublist = vesselInfo;
      sublist[index]["NotifyStatus"] = "Inactive";
      setvesselInfo(sublist);
      var params = {
        UserId: UserId,
        VesselUserId: event.VesselUserId,
        Type: "activevessel",
      };
      var Id = event.VesselUserId;
      props.history.push({
        pathname: "/admin/VesselInfo/" + Id,
        state: {
          Id: Id,
        },
      });
      // cogoToast.success("Rate Changed from : " + event.vesselInfoRate + " to : " + event.CurrentRate);
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
    } else if (type === "inactivevessel") {
      var Id = event.VesselUserId;
      props.history.push({
        pathname: "/admin/VesselInfo/" + Id,
        state: {
          Id: Id,
        },
      });
      // cogoToast.success("Rate Changed from : " + event.vesselInfoRate + " to : " + event.CurrentRate);
    } else {
      props.history.push("/admin/request");
      // setRate: event.Rate, open1: true, purchasearr: purchaselist });
    }
  };

  const handleClose = (e, type) => {
    if (type === "accept") {
      setopen(false);
      setopen(false);
      setclick(false);
    } else if (type === "IssueDo") {
      setInputHandleIsuueDo((InputHandleIsuueDo) => ({
        ...InputHandleIsuueDo,
        opendo: false,
        BESelectID: "",
        loading: false,
        phoneclick: 0,
        DoNo: "",
        IssuedQty: "",
        BESelectID: "",
        BERemainingQty: "",
        IssuedQtyErr: false,
        IssuedQtyErrText: "",
        BESelectList: [],
      }));
    } else if (type === "lock") {
      setopenlock(false);
      setoldpin("");
      setoldpinErr(false);
      setoldpinhelperText("");
      setnewpin("");
      setnewpinErr(false);
      setnewpinhelperText("");
      setconfirmpin("");
      setconfirmpinErr(false);
      setconfirmpinhelperText("");
    } else if (type === "changePassword") {
      setInputHandle((inputHandle) => ({
        ...inputHandle,
        openPassword: false,
        password: "",
        passwordErrText: "",
        PasswordErr: false,
        confirmPassword: "",
        confirmPasswordErr: "",
        confirmPasswordErrText: "",
      }));
    } else if (type === "HandlerVesselRequest") {
      setInputHandle((inputHandle) => ({
        ...inputHandle,
        openHandlerVesselRequest: false,
      }));
    } else if (type === "CompanyRequest") {
      setInputHandle((inputHandle) => ({
        ...inputHandle,
        openCompanyRequest: false,
      }));
    } else if (type == "vesselDeclarationModal") {
      setVesselDeclarationModal(false);
      setvcnNo("");
      setCountryName("");
      setPort("");
      setCountryName("");
      setTradeType("");
      setVesselDeclarationStatus("");
      setRemark("");
      setVesselDeclarationId("");
    } else if (type == "remarkModal") {
      setRemarkModal(false);
      setRemark("");
    } else {
      setopen1(false);
    }
  };

  const handleClickLock = (e, type) => {
    if (type == "lock") {
      let data = localStorage.getItem("Infodata");
      var params = {
        UserId: data,
      };
      // setUserName: data });
      api.post(apiBase + "/ajax/player.php?action=AppLock", params).then({});
      localStorage.removeItem("LockStatus");
      localStorage.setItem("LockStatus", 1);
      props.history.push("/auth/lockscreen");
      setAnchorEl(null);
    } else if (type == "pin") {
      GetUserDetails();
      setopenlock(true);
      setAnchorEl(null);
    } else if (type == "Fullscreen") {
      if (
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (document.msfullscreenElement &&
          document.msfullscreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)
      ) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        }
        setAnchorEl(null);
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        setAnchorEl(null);
      }
    } else {
      if (
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (document.msfullscreenElement &&
          document.msfullscreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)
      ) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    }
  };

  const handleChange_value1 = (e, type) => {
    // set[e.target.name](e.target.value.replace(/\D/, ''));
    if (type === "sellingrate") {
      if (e.target.name === "sellingrate") {
        if (e.target.value === "" && e.target.value === null) {
          setsellingrate(e.target.value);
          setsellingrateErr(true);
          setsellingratehelperText("Please Enter Selling Rate.");
        } else {
          setsellingrate(e.target.value);
          setsellingrateErr(false);
          setsellingratehelperText("");
        }
      }
    } else if (type === "requestrate") {
      if (e.target.value === "" && e.target.value === null) {
        setrequestrate(e.target.value);
        setrequestrateErr(true);
        setrequestratehelperText("Please Enter Request Rate.");
      } else {
        setrequestrate(e.target.value);
        setrequestrateErr(false);
        setrequestratehelperText("");
      }
    } else {
      if (e.target.value === "" || e.target.value === null) {
        setmessage(e.target.value);
        setmessageErr(true);
        setmessagehelperText("Please input message.");
      } else {
        setmessage(e.target.value);
        setmessageErr(true);
        setmessagehelperText("");
      }
    }
  };

  const handlePinChange = (e, type) => {
    let regExp = /^([0-9]+\s?)*$/g;
    if (type == "old") {
      if (e.target.value === "" || e.target.value === null) {
        setoldpin(e.target.value);
        setoldpinErr(true);
        setoldpinhelperText("Please enter old pin.");
      } else if (
        e.target.value.trim() !== e.target.value ||
        !e.target.value.match(regExp)
      ) {
        setoldpin(oldpin);
      } else if (e.target.value.length > 10 || e.target.value.length < 6) {
        setoldpin(e.target.value);
        setoldpinErr(true);
        setoldpinhelperText("Pin should be minimum 6 and maximum 10 digit");
      } else if (e.target.value != currentpin) {
        setoldpin(e.target.value);
        setoldpinErr(true);
        setoldpinhelperText("Entered Old Pin is Incorrect");
      } else {
        setoldpin(e.target.value);
        setoldpinErr(false);
        setoldpinhelperText("");
      }
    } else if (type == "new") {
      if (e.target.value === "" || e.target.value === null) {
        setnewpin(e.target.value);
        setnewpinErr(true);
        setnewpinhelperText("Please enter new pin.");
      } else if (
        e.target.value.trim() !== e.target.value ||
        !e.target.value.match(regExp)
      ) {
        setnewpin(newpin);
      } else if (e.target.value.length > 10 || e.target.value.length < 6) {
        setnewpin(e.target.value);
        setnewpinErr(true);
        setnewpinhelperText("Pin should be minimum 6 and maximum 10 digit");
      } else {
        setnewpin(e.target.value);
        setnewpinErr(false);
        setnewpinhelperText("");
      }
    } else {
      if (e.target.value === "" || e.target.value === null) {
        setconfirmpin(e.target.value);
        setconfirmpinErr(true);
        setconfirmpinhelperText("Please enter confirm pin.");
      } else if (
        e.target.value.trim() !== e.target.value ||
        !e.target.value.match(regExp)
      ) {
        setconfirmpin(confirmpin);
      } else if (e.target.value.length > 10 || e.target.value.length < 6) {
        setconfirmpin(e.target.value);
        setconfirmpinErr(true);
        setconfirmpinhelperText("Pin should be minimum 6 and maximum 10 digit");
      } else if (e.target.value !== newpin) {
        setconfirmpin(e.target.value);
        setconfirmpinErr(true);
        setconfirmpinhelperText("Confirm Pin and New Pin must be same");
      } else {
        setconfirmpin(e.target.value);
        setconfirmpinErr(false);
        setconfirmpinhelperText("");
      }
    }
  };

  const handleClickShowPin = (e, type) => {
    if (type == "old") {
      setshowOldPin(!showOldPin);
    } else if (type == "new") {
      setshowNewPin(!showNewPin);
    } else {
      setshowConfirmPin(!showConfirmPin);
    }
  };

  const validatepin = () => {
    let formIsValid = true;
    if (!CommonConfig.isEmpty(currentpin)) {
      if (!oldpin) {
        setloading(false);
        setoldpinErr(true);
        setoldpinhelperText("Please enter old pin.");
        formIsValid = false;
      } else if (oldpin != currentpin) {
        setloading(false);
        setoldpinErr(true);
        setoldpinhelperText("Entered Old Pin is Incorrect");
        formIsValid = false;
      } else if (oldpin.length < 6 || oldpin.length > 10) {
        setloading(false);
        setoldpinErr(true);
        setoldpinhelperText("Pin should be minimum 6 and maximum 10 digit");
        formIsValid = false;
      }
    } else {
      setoldpinErr(false);
      setoldpinhelperText("");
    }
    if (!CommonConfig.isEmpty(currentpin)) {
      if (oldpin != currentpin) {
        setloading(false);
        setoldpinErr(true);
        setoldpinhelperText("Entered Old Pin is Incorrect");
        formIsValid = false;
      } else {
        if (!newpin) {
          setloading(false);
          setnewpinErr(true);
          setnewpinhelperText("Please enter new pin.");
          formIsValid = false;
        } else if (newpin.length < 6 || newpin.length > 10) {
          setloading(false);
          setnewpinErr(true);
          setnewpinhelperText("Pin should be minimum 6 and maximum 10 digit");
          formIsValid = false;
        } else {
          setnewpinErr(false);
          setnewpinhelperText("");
        }
        if (!confirmpin) {
          setloading(false);
          setconfirmpinErr(true);
          setconfirmpinhelperText("Please enter confirm pin.");
          formIsValid = false;
        } else if (confirmpin !== newpin) {
          setloading(false);
          setconfirmpinErr(true);
          setconfirmpinhelperText("Confirm Pin and New Pin must be same");
          formIsValid = false;
        } else if (confirmpin.length < 6 || confirmpin.length > 10) {
          setloading(false);
          setconfirmpinErr(true);
          setconfirmpinhelperText(
            "Pin should be minimum 6 and maximum 10 digit"
          );
          formIsValid = false;
        } else {
          setconfirmpinErr(false);
          setconfirmpinhelperText("");
        }
      }
    } else {
      if (!newpin) {
        setloading(false);
        setnewpinErr(true);
        setnewpinhelperText("Please enter new pin.");
        formIsValid = false;
      } else if (newpin.length < 6 || newpin.length > 10) {
        setloading(false);
        setnewpinErr(true);
        setnewpinhelperText("Pin should be minimum 6 and maximum 10 digit");
        formIsValid = false;
      } else {
        setnewpinErr(false);
        setnewpinhelperText("");
      }
      if (!confirmpin) {
        setloading(false);
        setconfirmpinErr(true);
        setconfirmpinhelperText("Please enter confirm pin.");
        formIsValid = false;
      } else if (confirmpin !== newpin) {
        setloading(false);
        setconfirmpinErr(true);
        setconfirmpinhelperText("Confirm Pin and New Pin must be same");
        formIsValid = false;
      } else if (confirmpin.length < 6 || confirmpin.length > 10) {
        setloading(false);
        setconfirmpinErr(true);
        setconfirmpinhelperText("Pin should be minimum 6 and maximum 10 digit");
        formIsValid = false;
      } else {
        setconfirmpinErr(false);
        setconfirmpinhelperText("");
      }
    }
    return formIsValid;
  };

  const handlePinSubmit = (e) => {
    e.preventDefault();

    if (validatepin()) {
      var params = {
        UserId: UserId,
        OldPin: oldpin,
        NewPin: newpin,
        ConfirmPin: confirmpin,
      };

      if (params !== null) {
        setloading(true);
        api
          .post(apiBase + "/ajax/player.php?action=SetupPin", params)
          .then((res) => {
            if (res.Success === 1) {
              setloading(false);
              setopenlock(false);
              setoldpin("");
              setoldpinErr(false);
              setoldpinhelperText("");
              setnewpin("");
              setnewpinErr(false);
              setnewpinhelperText("");
              setconfirmpin("");
              setconfirmpinErr(false);
              setconfirmpinhelperText("");
              cogoToast.success("Pin created successfully");
            } else {
              setloading(false);
              setopenlock(false);
              setoldpin("");
              setoldpinErr(false);
              setoldpinhelperText("");
              setnewpin("");
              setnewpinErr(false);
              setnewpinhelperText("");
              setconfirmpin("");
              setconfirmpinErr(false);
              setconfirmpinhelperText("");
              cogoToast.error("Pin create failed");
            }
          });
      }
    }
  };

  const handleClickAway = () => {
    setOpenProfile(null);
  };

  const signOut = () => {
    let data = localStorage.getItem("Infodata");
    var params = {
      UserId: data,
      IsLogout: true,
    };
    api
      .post(apiBase + "/ajax/player.php?action=AppLogout", params)
      .then((res) => {
        if (res.Success == 1) {
          localStorage.clear();
          const { history } = props;
          history.push("/login");
        }
      });
    // <Redirect to="/login" />
  };

  const getHandlerVesselRequestByID = (Id) => {
    var params = {
      HandlerVesselRequestID: Id,
    };
    api
      .post(APIConstant.path.GetHandlerVesselByID, params)
      .then((res) => {
        if (res.Success === 1) {
          setInputHandle((inputHandle) => ({
            ...inputHandle,
            handlerVesselRequestItem: res.Info.HandlerVessel,
          }));
        }
      })
      .catch((err) => {});
  };
  const getHandlerGeneralVesselByID = (Id) => {
    var params = {
      HandlerVesselRequestID: Id,
    };
    api
      .post(APIConstant.path.GetHandlerGeneralVesselByID, params)
      .then((res) => {
        if (res.Success === 1) {
          setInputHandle((inputHandle) => ({
            ...inputHandle,
            handlerVesselRequestItem: res.Info.HandlerVessel,
          }));
        }
      })
      .catch((err) => {});
  };
  const getHandlerIssueDoByID = (Id) => {
    var params = {
      ID: Id,
    };
    api
      .post(APIConstant.path.GetHandlerIssueDoByID, params)
      .then((res) => {
        if (res.Success === 1) {
          getSelectBEList(
            res.Info.HandlerIssueDO[0].VesselDisplayID,
            res.Info.HandlerIssueDO[0].PartyToID
          );
          setInputHandleIsuueDo((InputHandleIsuueDo) => ({
            ...InputHandleIsuueDo,
            handlerIssueDo: res.Info.HandlerIssueDO[0],
            opendo: true,
          }));
        }
      })
      .catch((err) => {});
  };
  const validate = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(InputHandleIsuueDo.BESelectID)) {
      setInputHandleIsuueDo((InputHandleIsuueDo) => ({
        ...InputHandleIsuueDo,
        BESelectIDErr: true,
        BESelectIDErrText: "Please select BENo..",
      }));

      formIsValid = false;
    } else {
      setInputHandleIsuueDo((InputHandleIsuueDo) => ({
        ...InputHandleIsuueDo,
        BESelectIDErr: false,
        BESelectIDErrText: "",
      }));
    }

    if (
      InputHandleIsuueDo.handlerIssueDo.IssuedQty === "" ||
      InputHandleIsuueDo.handlerIssueDo.IssuedQty === null
    ) {
      setInputHandleIsuueDo((InputHandleIsuueDo) => ({
        ...InputHandleIsuueDo,
        IssuedQtyErr: true,
        IssuedQtyErrText: "Please enter issued qty.",
      }));

      formIsValid = false;
    } else if (
      !InputHandleIsuueDo.handlerIssueDo.IssuedQty.match(
        CommonConfig.RegExp.decimalNumber
      )
    ) {
      setInputHandleIsuueDo((InputHandleIsuueDo) => ({
        ...InputHandleIsuueDo,
        IssuedQtyErr: true,
        IssuedQtyErrText: "Please enter valid quantity.",
      }));
      formIsValid = false;
    }
    if (
      Number(InputHandleIsuueDo.handlerIssueDo.IssuedQty) >
      Number(InputHandleIsuueDo.BERemainingQty)
    ) {
      setInputHandleIsuueDo((InputHandleIsuueDo) => ({
        ...InputHandleIsuueDo,
        IssuedQtyErr: true,
        IssuedQtyErrText: "IssueDO entered exceeds the BEQty.",
      }));

      formIsValid = false;
    } else {
      setInputHandleIsuueDo((InputHandleIsuueDo) => ({
        ...InputHandleIsuueDo,
        IssuedQtyErr: false,
        IssuedQtyErrText: "",
      }));
    }
    return formIsValid;
  };
  const handleVesselIssueDO = (e, item) => {
    e.preventDefault();
    if (validate()) {
      var params = {
        BEID: InputHandleIsuueDo.BESelectID,
        IssueDOID: InputHandleIsuueDo.handlerIssueDo.IssueDOID,
        UserId: UserId,
      };
      api
        .post(APIConstant.path.UpdateNotificationIssueDo, params)
        .then((res) => {
          if (res.Success === 1) {
            cogoToast.success(res.Message);
            setInputHandleIsuueDo((InputHandleIsuueDo) => ({
              ...InputHandleIsuueDo,
              opendo: false,
              items: [],
              loading: false,
              phoneclick: 0,
              DoNo: "",
              IssuedQty: "",
            }));
            props.history.push({
              pathname: "/admin/issue-do",
            });

            getNotificationDetail(selectedNotificationType);
          } else {
            cogoToast.error(res.Message);
          }
        })
        .catch((err) => {});
    }
  };
  const handleChangeIssueDo = (e, item, type) => {
    if (type === "DoNo") {
      if (e.target.value === "" || e.target.value === null) {
        setInputHandleIsuueDo((InputHandleIsuueDo) => ({
          ...InputHandleIsuueDo,
          DoNo: "",
          DoNoErr: true,
          DoNoErrText: "Please Enter DoNo.",
        }));
      } else {
        setInputHandleIsuueDo((InputHandleIsuueDo) => ({
          ...InputHandleIsuueDo,
          DoNo: e.target.value,
          DoNoErr: false,
          DoNoErrText: "",
        }));
      }
    } else if (type === "IssuedQty") {
      if (e.target.value === "" || e.target.value === null) {
        setInputHandleIsuueDo((InputHandleIsuueDo) => ({
          ...InputHandleIsuueDo,
          IssuedQty: e.target.value,
          IssuedQtyErr: true,
          IssuedQtyErrText: "Please enter issued qty.",
        }));
      } else if (!e.target.value.match(CommonConfig.RegExp.decimalNumber)) {
        setInputHandleIsuueDo((InputHandleIsuueDo) => ({
          ...InputHandleIsuueDo,
          IssuedQty: e.target.value,
          IssuedQtyErr: true,
          IssuedQtyErrText: "Please enter valid quantity.",
        }));
      } else if (Number(e.target.value) > InputHandleIsuueDo.BERemainingQty) {
        setInputHandleIsuueDo((InputHandleIsuueDo) => ({
          ...InputHandleIsuueDo,
          IssuedQty: e.target.value,
          IssuedQtyErr: true,
          IssuedQtyErrText: "IssueDO entered exceeds the BEQty.",
        }));
      } else {
        setInputHandleIsuueDo((InputHandleIsuueDo) => ({
          ...InputHandleIsuueDo,
          IssuedQty: e.target.value,
          IssuedQtyErr: false,
          IssuedQtyErrText: "",
        }));
      }
    }
  };

  const handleVesselApprovalReject = (e, Type, Id) => {
    e.preventDefault();
    var params = {
      UserId: localStorage.getItem("Infodata"),
      Type: Type,
      EntityID: Id,
      VesselType: clickType,
    };
    api
      .post(APIConstant.path.ApproveRejectHandlerVesselRequest, params)
      .then((res) => {
        if (res.Success === 1) {
          setInputHandle((inputHandle) => ({
            ...inputHandle,
            handlerVesselRequestItem: {},
            openHandlerVesselRequest: false,
          }));
          let resText =
            Type == "Accept"
              ? "Vessel request approved successfully"
              : "Vessel request rejected successfully";
          cogoToast.success(resText);
        }
      })
      .catch((err) => {});
  };
  const handleCompanyApprovalReject = (e, Type, Id) => {
    e.preventDefault();
    var params = {
      UserId: localStorage.getItem("Infodata"),
      Type: Type,
      EntityID: Id,
    };
    api
      .post(APIConstant.path.ApproveRejectCompany, params)
      .then((res) => {
        if (res.Success === 1) {
          setInputHandle((inputHandle) => ({
            ...inputHandle,
            userDetails: {},
            openCompanyRequest: false,
          }));
          let resText =
            Type == "Accept"
              ? "Company request approved successfully"
              : "Company request rejected successfully";
          cogoToast.success(resText);
        }
      })
      .catch((err) => {});
  };
  const getVesselDeclarationByID = (ID) => {
    let params = {
      ID: ID,
    };
    Axios.post(
      "https://dpa.portlinksindia.com/PortLinkBack/api/AdminNew.php?action=GetVesselDeclarationById",
      params
    )
      .then(async (res) => {
        let response = await res.data;
        if (response.Success === 1) {
          Loader.hide();
          let VesselData = response.Info.VesselDeclaration;
          setvcnNo(VesselData?.VCNNo);
          setVesselName(VesselData?.VesselTypeName);
          setTradeType(VesselData?.TradeTypeName);
          setPort(VesselData?.PortName);
          setCountryName(VesselData?.CountryName);
          setCommodity(VesselData?.CommodityName);
          setVesselDeclarationStatus(VesselData?.RequestStatus);
          setVesselDeclarationId(VesselData?.VesselDeclarationId);
          setVesselDeclarationRemark(VesselData?.Remark);
        } else {
          Loader.hide();
        }
      })
      .catch((err) => {
        Loader.hide();
        console.log(err);
      });
  };

  const validateVesselDeclarationRequest = () => {
    let IsFormValid = true;
    if (CommonConfig.isEmpty(remark)) {
      IsFormValid = false;
      setRemarkErr(true);
      setRemarkErrText("Please enter remark");
    } else {
      setRemarkErr(false);
      setRemarkErrText("");
    }
    return IsFormValid;
  };
  const handleVesselDeclaration = (e, type, ID) => {
    e.preventDefault();
    if (remarkModal) {
      if (validateVesselDeclarationRequest()) {
        updateVesselDeclarationStatus(type, ID);
      }
    } else {
      updateVesselDeclarationStatus(type, ID);
    }
  };

  const updateVesselDeclarationStatus = (type, ID) => {
    try {
      let params = {
        ID: ID,
        Type: type,
        Remark: remark,
        UserId: localStorage.getItem("Infodata"),
        VCNNo: vcnNo,
      };
      api
        .post(
          "https://uat.portlinksindia.com/PortLinkBack/api/AdminNew.php?action=UpdateVesselDeclarationStatus",
          params
        )
        .then(async (res) => {
          let response = await res;
          if (response.Success === 1) {
            cogoToast.success(response.Message);
            Loader.hide();

            setVesselDeclarationModal(false);
            setvcnNo("");
            setCountryName("");
            setPort("");
            setCountryName("");
            setTradeType("");
            setVesselDeclarationStatus("");
            setRemark("");
            setVesselDeclarationId("");
            setRemarkModal(false);
            setRemark("");
          } else {
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const classes = useStyles();
  const { rtlActive } = props;
  const searchButton =
    classes.top +
    " " +
    classes.searchButton +
    " " +
    classNames({
      [classes.searchRTL]: rtlActive,
    });
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });

  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });

  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  const handleClick = (event, actionType, type) => {
    if (type === "Sell") {
      if (event.IsNew === "1") {
        setopenNotification(null);
        var params = {
          UserId: UserId,
          PurchaseId: event.Id,
          token: localStorage.getItem("Token"),
        };
        console.log("Sell Param", params);
        api
          .post(apiBase + "/ajax/player.php?action=updateBuyStatus", params)
          .then((res) => {
            if (res.Success == 1) {
              props.history.push({
                pathname: "/admin/order",
                state: { tabValue: 1 },
              });
            } else if (res.Success == -1) {
              // let data = localStorage.getItem('Infodata');
              // var params = {
              //     UserId: data
              // }
              // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
              //     if (res.Success == 1) {
              localStorage.clear();
              const { history } = props;
              history.push("/login");
              //     }
              // })
            }
          });
      } else {
        props.history.push({
          pathname: "/admin/order",
          state: { tabValue: 1 },
        });
      }
    } else if (type === "Purchase") {
      if (event.IsNew === "1") {
        if (event.ClickType === "accepted") {
          setRate(event.Rate);
          setopen(true);
          setpurchasearr([event]);
        } else if (event.ClickType === "acceptedbuyerseller") {
          var params = {
            UserId: UserId,
            PurchaseId: event.Id,
            Type: "acceptedbuyerseller",
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateNotificationStatus",
              params
            )
            .then({});
          var Id = "buyer";
          props.history.push("/admin/order");
        } else if (event.ClickType === "acceptedbuyer") {
          var params = {
            UserId: UserId,
            PurchaseId: event.Id,
            Type: "acceptedbuyer",
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateNotificationStatus",
              params
            )
            .then({});
          props.history.push({
            pathname: "/admin/order",
            state: { tabValue: 1 },
          });
        } else if (event.ClickType === "Rerequested") {
          var params = {
            UserId: UserId,
            PurchaseId: event.Id,
            Type: "Rerequested",
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateNotificationStatus",
              params
            )
            .then({});
          props.history.push({ pathname: "/admin/order" });
        } else {
          props.history.push("/admin/order");
        }
      } else {
        props.history.push("/admin/order");
      }
    } else if (type === "Location") {
      if (event.IsNew === "1") {
        setopenNotification(null);
        var params = {
          UserId: UserId,
          id: event.Id,
          Type: "acceptedlocation",
        };
        api
          .post(
            apiBase + "/ajax/player.php?action=updateNotificationStatus",
            params
          )
          .then({});
        props.history.push("/admin/request");
      }
    } else if (event.ClickType === "General Vessel") {
      getHandlerGeneralVesselByID(event.EntityId);
      setInputHandle((inputHandle) => ({
        ...inputHandle,
        openHandlerVesselRequest: true,
      }));
      var params = {
        UserId: UserId,
        Id: event.Id,
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateMainNotification",
          params
        )
        .then({});
    } else if (type === "Vessel") {
      if (event.IsNew === "1") {
        if (event.ClickType == "New Vessel") {
          var params = {
            UserId: UserId,
            VesselUserId: event.Id,
            Type: "activevessel",
          };
          var Id = event.Id;
          props.history.push({
            pathname: "/admin/VesselInfo/" + Id,
            state: {
              Id: Id,
            },
          });
          api
            .post(
              apiBase + "/ajax/player.php?action=updateNotificationStatus",
              params
            )
            .then({});
        } else if (event.ClickType === "Handler") {
          getHandlerVesselRequestByID(event.EntityId);
          setInputHandle((inputHandle) => ({
            ...inputHandle,
            openHandlerVesselRequest: true,
          }));
          var params = {
            UserId: UserId,
            Id: event.Id,
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateMainNotification",
              params
            )
            .then({});
        } else if (event.ClickType === "HandlerIssueDo") {
          getHandlerIssueDoByID(event.Id);

          var params = {
            UserId: UserId,
            IssueDoID: event.Id,
            Type: event.ClickType,
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateNotificationStatus",
              params
            )
            .then({});
        } else if (event.ClickType === "HandlerIssueDoImpoter") {
          var params = {
            UserId: UserId,
            IssueDoID: event.Id,
            Type: event.ClickType,
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateNotificationStatus",
              params
            )
            .then({});
        } else {
          var params = {
            UserId: UserId,
            Id: event.Id,
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateMainNotification",
              params
            )
            .then({});
        }
      } else {
        if (event.ClickType === "Handler") {
          getHandlerVesselRequestByID(event.EntityId);
          setInputHandle((inputHandle) => ({
            ...inputHandle,
            openHandlerVesselRequest: true,
          }));
          var params = {
            UserId: UserId,
            Id: event.Id,
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateMainNotification",
              params
            )
            .then({});
        } else if (event.ClickType === "General Vessel") {
          getHandlerGeneralVesselByID(event.EntityId);
          setInputHandle((inputHandle) => ({
            ...inputHandle,
            openHandlerVesselRequest: true,
          }));
          var params = {
            UserId: UserId,
            Id: event.Id,
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateMainNotification",
              params
            )
            .then({});
        } else if (event.ClickType === "HandlerIssueDo") {
          getHandlerIssueDoByID(event.Id);
        } else if (event.ClickType === "HandlerIssueDoImpoter") {
        } else {
          var Id = event.Id;
          props.history.push({
            pathname: "/admin/VesselInfo/" + Id,
            state: {
              Id: Id,
            },
          });
        }
      }
    } else if (type === "Transporter") {
      if (event.IsNew === "1") {
        setopenNotification(null);
        var params = {
          UserId: UserId,
          vesselid: event.VesselId,
          RequestId: event.Id,
          AssignToUser: event.CreatedBy,
          IsTransporterStatus: 0,
          Type: "requestdifferent",
          token: localStorage.getItem("Token"),
        };
        api
          .post(
            apiBase + "/ajax/player.php?action=AcceptTruckRequestStatus",
            params
          )
          .then((res) => {
            if (res.Success == -1) {
              // let data = localStorage.getItem('Infodata');
              // var params = {
              //     UserId: data
              // }
              // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
              //     if (res.Success == 1) {
              localStorage.clear();
              const { history } = props;
              history.push("/login");
              //     }
              // })
            }
          });
        var Id = "transporter";
        props.history.push({
          pathname: "/admin/request/",
          state: {
            Id: Id,
          },
        });
      } else {
        var Id = "transporter";
        props.history.push({
          pathname: "/admin/request/",
          state: {
            Id: Id,
          },
        });
      }
    } else if (type === "Truck") {
      if (event.IsNew === "1") {
        if (event.ClickType === "transporter") {
          var params = {
            UserId: UserId,
            AcceptId: event.Id,
            Type: "receiveractive",
            token: localStorage.getItem("Token"),
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateNotificationStatus",
              params
            )
            .then((res) => {
              if (res.Success == -1) {
                // let data = localStorage.getItem('Infodata');
                // var params = {
                //     UserId: data
                // }
                // // setUserName: data });
                // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                //     if (res.Success == 1) {
                localStorage.clear();
                const { history } = props;
                history.push("/login");
                //     }
                // })
              }
            });
          var Id = "transporter";
          props.history.push({
            pathname: "/admin/request/",
            state: {
              Id: Id,
            },
          });
        } else if (event.ClickType === "driver") {
          var params = {
            UserId: UserId,
            TruckId: event.Id,
            Type: "driveractive",
            token: localStorage.getItem("Token"),
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateNotificationStatus",
              params
            )
            .then((res) => {
              if (res.Success == -1) {
                // let data = localStorage.getItem('Infodata');
                // var params = {
                //     UserId: data
                // }
                // // setUserName: data });
                // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                //     if (res.Success == 1) {
                localStorage.clear();
                const { history } = props;
                history.push("/login");
                //     }
                // })
              }
            });
          var Id = "driver";
          props.history.push({
            pathname: "/admin/drivertruck/",
            state: {
              Id: Id,
            },
          });
        } else if (event.ClickType === "trucklist") {
          var params = {
            UserId: UserId,
            AcceptId: event.Id,
            Type: "receiveractive",
            token: localStorage.getItem("Token"),
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateNotificationStatus",
              params
            )
            .then((res) => {
              if (res.Success == -1) {
                // let data = localStorage.getItem('Infodata');
                // var params = {
                //     UserId: data
                // }
                // // setUserName: data });
                // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                //     if (res.Success == 1) {
                localStorage.clear();
                const { history } = props;
                history.push("/login");
                //     }
                // })
              }
            });
          var Id = "transporter";
          props.history.push({
            pathname: "/admin/trucklist",
            state: {
              Id: Id,
            },
          });
        } else if (event.ClickType === "view-truck") {
          var params = {
            UserId: UserId,
            AcceptId: event.Id,
            Type: "receiveractive",
            token: localStorage.getItem("Token"),
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateNotificationStatus",
              params
            )
            .then((res) => {
              if (res.Success == -1) {
                // let data = localStorage.getItem('Infodata');
                // var params = {
                //     UserId: data
                // }
                // // setUserName: data });
                // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                //     if (res.Success == 1) {
                localStorage.clear();
                const { history } = props;
                history.push("/login");
                //     }
                // })
              }
            });
          var Id = "transporter";
          props.history.push({
            pathname: "/admin/view-truck",
            state: {
              Id: Id,
            },
          });
        } else if (event.ClickType === "trucklistcancelled") {
          var params = {
            UserId: UserId,
            AcceptId: event.Id,
            Type: "receiveractive",
            token: localStorage.getItem("Token"),
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateNotificationStatus",
              params
            )
            .then((res) => {
              if (res.Success == -1) {
                // let data = localStorage.getItem('Infodata');
                // var params = {
                //     UserId: data
                // }
                // // setUserName: data });
                // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                //     if (res.Success == 1) {
                localStorage.clear();
                const { history } = props;
                history.push("/login");
                //     }
                // })
              }
            });
          var Id = "transporter";
          props.history.push({
            pathname: "/admin/trucklist",
            state: {
              Id: Id,
            },
          });
        } else if (event.ClickType === "trucklistrejected") {
          var params = {
            UserId: UserId,
            AcceptId: event.Id,
            Type: "receiveractive",
            token: localStorage.getItem("Token"),
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateNotificationStatus",
              params
            )
            .then((res) => {
              if (res.Success == -1) {
                // let data = localStorage.getItem('Infodata');
                // var params = {
                //     UserId: data
                // }
                // // setUserName: data });
                // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                //     if (res.Success == 1) {
                localStorage.clear();
                const { history } = props;
                history.push("/login");
                //     }
                // })
              }
            });
          cogoToast.error(
            "Truck request rejected by" + " " + requestlist[index]["name"]
          );
          // var Id = "transporter";
          // props.history.push({
          //   path"name": '/admin/trucklist',
          //   state: {
          //     Id: Id,
          //   }
          // });
        } else if (event.ClickType === "viewtruckcancelled") {
          var params = {
            UserId: UserId,
            AcceptId: event.Id,
            Type: "receiveractive",
            token: localStorage.getItem("Token"),
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateNotificationStatus",
              params
            )
            .then((res) => {
              if (res.Success == -1) {
                // let data = localStorage.getItem('Infodata');
                // var params = {
                //     UserId: data
                // }
                // // setUserName: data });
                // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                //     if (res.Success == 1) {
                localStorage.clear();
                const { history } = props;
                history.push("/login");
                //     }
                // })
              }
            });
          var Id = "transporter";
          props.history.push({
            pathname: "/admin/view-truck",
            state: {
              Id: Id,
            },
          });
        } else if (event.ClickType === "loadingtruck") {
          var params = {
            UserId: UserId,
            AcceptId: event.Id,
            Type: "loadingactive",
            token: localStorage.getItem("Token"),
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateNotificationStatus",
              params
            )
            .then((res) => {
              if (res.Success == -1) {
                // let data = localStorage.getItem('Infodata');
                // var params = {
                //     UserId: data
                // }
                // // setUserName: data });
                // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                //     if (res.Success == 1) {
                localStorage.clear();
                const { history } = props;
                history.push("/login");
                //     }
                // })
              }
            });
          var Id = "transporter";
          props.history.push({
            pathname: "/admin/trucklist",
            state: {
              Id: Id,
            },
          });
        } else if (event.ClickType === "unloadingtruck") {
          var params = {
            UserId: UserId,
            AcceptId: event.Id,
            TruckId: event.TruckId,
            Type: "unloadingactive",
            token: localStorage.getItem("Token"),
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateNotificationStatus",
              params
            )
            .then((res) => {
              if (res.Success == -1) {
                // let data = localStorage.getItem('Infodata');
                // var params = {
                //     UserId: data
                // }
                // // setUserName: data });
                // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                //     if (res.Success == 1) {
                localStorage.clear();
                const { history } = props;
                history.push("/login");
                //     }
                // })
              }
            });
          var Id = "transporter";
          props.history.push({
            pathname: "/admin/trucklist",
            state: {
              Id: Id,
            },
          });
        } else if (event.ClickType === "grouptruck") {
          var params = {
            UserId: UserId,
            AcceptId: event.Id,
            Type: "loadingactive",
            token: localStorage.getItem("Token"),
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateNotificationStatus",
              params
            )
            .then((res) => {
              if (res.Success == -1) {
                // let data = localStorage.getItem('Infodata');
                // var params = {
                //     UserId: data
                // }
                // // setUserName: data });
                // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                //     if (res.Success == 1) {
                localStorage.clear();
                const { history } = props;
                history.push("/login");
                //     }
                // })
              }
            });
          var Id = "transporter";
          props.history.push({
            pathname: "/admin/trucklist",
            state: {
              Id: Id,
            },
          });
        } else {
          var params = {
            UserId: UserId,
            AcceptId: event.Id,
            Type: "receiveractive",
            token: localStorage.getItem("Token"),
          };
          api
            .post(
              apiBase + "/ajax/player.php?action=updateNotificationStatus",
              params
            )
            .then((res) => {
              if (res.Success == -1) {
                // let data = localStorage.getItem('Infodata');
                // var params = {
                //     UserId: data
                // }
                // // setUserName: data });
                // api.post(apiBase + '/ajax/player.php?action=AppLogout', params).then(res => {
                //     if (res.Success == 1) {
                localStorage.clear();
                const { history } = props;
                history.push("/login");
                //     }
                // })
              }
            });
          var Id = "receiver";
          props.history.push({
            pathname: "/admin/request/",
            state: {
              Id: Id,
            },
          });
        }
      } else {
        if (event.ClickType === "transporter") {
          var Id = "transporter";
          props.history.push({
            pathname: "/admin/request/",
            state: {
              Id: Id,
            },
          });
        } else if (event.ClickType === "truck") {
          var Id = "receiver";
          props.history.push({
            pathname: "/admin/request/",
            state: {
              Id: Id,
            },
          });
        } else if (event.ClickType === "trucklist") {
          var Id = "transporter";
          props.history.push({
            pathname: "/admin/trucklist",
            state: {
              Id: Id,
            },
          });
        } else if (event.ClickType === "view-truck") {
          var Id = "transporter";
          props.history.push({
            pathname: "/admin/view-truck",
            state: {
              Id: Id,
            },
          });
        } else if (event.ClickType === "trucklistcancelled") {
          var Id = "transporter";
          props.history.push({
            pathname: "/admin/trucklist",
            state: {
              Id: Id,
            },
          });
        } else if (event.ClickType === "trucklistrejected") {
          cogoToast.error("Truck request rejected");
        } else if (event.ClickType === "viewtruckcancelled") {
          var Id = "transporter";
          props.history.push({
            pathname: "/admin/view-truck",
            state: {
              Id: Id,
            },
          });
        } else if (event.ClickType === "loadingtruck") {
          var Id = "transporter";
          props.history.push({
            pathname: "/admin/trucklist",
            state: {
              Id: Id,
            },
          });
        } else if (event.ClickType === "unloadingtruck") {
          var Id = "transporter";
          props.history.push({
            pathname: "/admin/trucklist",
            state: {
              Id: Id,
            },
          });
        } else if (event.ClickType === "grouptruck") {
          var Id = "transporter";
          props.history.push({
            pathname: "/admin/trucklist",
            state: {
              Id: Id,
            },
          });
        }
      }
    } else if (type === "Export Job") {
      if (event.IsNew === "1") {
        var params = {
          UserId: UserId,
          Id: event.Id,
        };
        api
          .post(
            apiBase + "/ajax/player.php?action=updateMainNotification",
            params
          )
          .then({});
      } else {
        var Id = event.Id;

        props.history.push({
          pathname: "/admin/view-container",
          state: {
            MasterContainerID: event.EntityId,
          },
        });
      }
    } else if (type === "Company") {
      getUserDetailByID(event.EntityId);
    } else if (type === "Vessel Declaration") {
      getVesselDeclarationByID(
        event.EntityId ? event?.EntityId : event?.EntityID
      );
      var params = {
        UserId: UserId,
        Id: event.Id,
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateMainNotification",
          params
        )
        .then({});
      setVesselDeclarationModal(true);
    } else if (type === "Trip File") {
      getTripFile(event?.EntityID);
      var params = {
        UserId: UserId,
        Id: event.Id,
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateMainNotification",
          params
        )
        .then({});
    } else if (type === "Reminder") {
      var params = {
        UserId: UserId,
        NotificationId: event.NotificationId,
      };
      api.post(APIConstant.path.UpdateNewNotification, params).then({});
    }

    getNotificationDetail(selectedNotificationType);
  };

  const getTripFile = async (ID) => {
    Loader.show();
    try {
      let data = {
        ID: ID,
      };
      await api
        .post(APIConstant.path.GetSingleTripReportFile, data)
        .then(async (response) => {
          let res = await response;
          if (res.Success == 1) {
            Loader.hide();
            let fileUrl = res?.Info?.Driver?.data[0]?.FileURL;
            const link = document.createElement("a");
            link.href = await fileUrl;
            link.setAttribute("download", fileUrl); // Set the 'download' attribute to trigger download

            document.body.appendChild(link);
            await link.click(); // Simulate a click on the link to start downloading
            document.body.removeChild(link);
          } else {
            Loader.hide();
            cogoToast.error("File not found");
          }
        })
        .catch((err) => {});
    } catch (err) {
      console.log(err);
    }
  };
  const GetTrackingByID = (ID) => {
    let data = {
      ID: ID,
    };
    api
      .post(APIConstant.path.GetTrackingByID, data)
      .then((res) => {
        if (res.Success === 1) {
          setlocationValue(res?.Info?.Tracking[0]?.TrackingType);
          setTrackId(res?.Info?.Tracking[0]?.trackId);
        }
      })
      .catch((err) => {});
  };

  const getNavbarAccess = async () => {
    let params = {
      UserId: localStorage.getItem("Infodata"),
      ServiceType: "Navbar",
    };
    await api
      .post(APIConstant.path.getUserAccessEquator, params)
      .then(async (response) => {
        if (response.success) {
          let Data = response.data;

          var tempNavbarAccess = [];
          for (let i = 0; i < Data.length; i++) {
            tempNavbarAccess.push(Data[i]?.ServiceName);
          }

          localStorage.setItem(
            "NavbarAccess",
            tempNavbarAccess?.length ? JSON.stringify(tempNavbarAccess) : []
          );
          setNavbarAccess(
            tempNavbarAccess?.length ? JSON.stringify(tempNavbarAccess) : []
          );
        } else {
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const handleLocationChange = (e, type) => {
    e.preventDefault();
    if (type == "PunchInOut") {
      if (!navigator.geolocation) {
        console.log("Geolocation is not supported by your browser");
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setlocationValue(locationValue == "OUT" ? "IN" : "OUT");
            setInputHandle((inputHandle) => ({
              ...inputHandle,
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }));
            var latlng = new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            // This is making the Geocode request
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({ latLng: latlng }, (results, status) => {
              if (status !== google.maps.GeocoderStatus.OK) {
                console.log(status);
              }
              // This is checking to see if the Geoeode Status is OK before proceeding
              if (status == google.maps.GeocoderStatus.OK) {
                setInputHandle((inputHandle) => ({
                  ...inputHandle,
                  locationAddress: results[0].formatted_address,
                }));
                addPunchInPunchOut(
                  e,
                  position.coords.latitude,
                  position.coords.longitude,
                  results[0].formatted_address,
                  trackId
                );
              }
            });
          },
          () => {
            cogoToast.error("Please enable your location first.");
          }
        );
      }
    }
  };

  const addPunchInPunchOut = (e, latitude, longitude, address, trackId) => {
    Loader.show();
    e.preventDefault();
    var params = {
      UserID: JSON.parse(localStorage.getItem("Userdata")).UserId,
      TrackID: trackId,
      Longitude: longitude,
      Latitude: latitude,
      TrackingType: locationValue == "IN" ? "OUT" : "IN",
      TrackingAddress: address,
    };

    api
      .post(APIConstant.path.AddTracking, params)
      .then((res) => {
        if (res.Success === 1) {
          setInputHandle((inputHandle) => ({
            ...inputHandle,
            lng: null,
            lat: null,
            locationAddress: "",
          }));

          Loader.hide();
          cogoToast.success(res.Info.Tracking[0].Message);
          // window.location.reload();
          GetTrackingByID(localStorage.getItem("Infodata"));
        } else {
          cogoToast.error("Something happened bad");
          Loader.hide();
        }
      })
      .catch((err) => {
        Loader.hide();
      });
  };

  const getUserDetailByID = (ID) => {
    let data = {
      ID: ID,
    };
    api
      .post(APIConstant.path.getUserDetailByID, data)
      .then((res) => {
        if (res.Success === 1) {
          setInputHandle((inputHandle) => ({
            ...inputHandle,
            userDetails: res.Info.Company[0],
            openCompanyRequest: true,
          }));
        }
      })
      .catch((err) => {});
  };
  if (
    props.history.location.state &&
    props.history.location.state.ProfilePic &&
    props.history.location.state.IsProfileUpdated == true
  ) {
    setProfilePic(props.history.location.state.ProfilePic);
    props.history.push({ state: { IsProfileUpdated: false } });
  }

  // console.log("dada", props.history)
  if (props.history.location.state && props.history.location.state.UserName) {
    setUserName(props.history.location.state.UserName);
    props.history.push({ state: { UserName: undefined } });
  }
  const groupedItemTemplate = (option) => {
    return (
      <div className="flex align-items-between">
        <div>{option.label}</div>
        <i
          className="icon-cancel"
          onClick={(e) => handleClearNotification(e, option.label)}
        ></i>
      </div>
    );
  };
  const itemOptionTemplate = (option) => {
    return (
      <div className="flex align-items-between">
        <div>{option.label}</div>
        <i
          className="icon-cancel"
          onClick={(e) => handleClearNotification(e, option.label)}
        ></i>
      </div>
    );
  };

  const handleClearNotification = (e, type) => {
    try {
      Loader.show();
      e.preventDefault();
      var params = {
        UserID: JSON.parse(localStorage.getItem("Userdata")).UserId,
        Type: type,
      };

      api
        .post(APIConstant.path.ClearNotification, params)
        .then((res) => {
          if (res.Success == 1) {
            Loader.hide();
            setopenNotification(null);
            cogoToast.success(res.Message);
          } else {
            Loader.hide();
            setopenNotification(null);
            cogoToast.error("Something happened bad");
          }
        })
        .catch((err) => {
          Loader.hide();
        });
    } catch (err) {
      console.log(err);
    }
  };
  const { handlerVesselRequestItem, userDetails } = inputHandle;
  return (
    <div className="header-bar">
      <div className="p-d-flex p-ai-center track-switch">
        <div id="google_translate_element"></div>
        {JSON.parse(localStorage.getItem("Userdata"))?.AccountType ===
          "NewMember" &&
        !CommonConfig.isEmpty(
          JSON.parse(localStorage.getItem("Userdata")).CompanyID
        ) &&
        userAccess?.includes("Punched In/Out Track") ? (
          <FormControlLabel
            className="p-ml-2"
            style={{ color: locationValue == "IN" ? "green" : "red" }}
            control={
              <InputSwitch
                className="p-mr-2"
                checked={locationValue == "IN" ? true : false}
                onClick={(e) => handleLocationChange(e, "PunchInOut")}
                name="toggle"
              />
            }
            label={locationValue == "IN" ? "Punched In" : "Punched Out"}
            labelPlacement={"end"}
          />
        ) : null}
      </div>
      <div className="right-nav-buttons p-d-flex">
        {navbarAccess.includes("Friend Request") ? (
          <div>
            <div className="nav-dropdown-outer request-drop">
              <Popper
                open={Boolean(openRequestNotification)}
                anchorEl={openRequestNotification}
                transition
                disablePortal
                placement="bottom"
                className="nav-dropdown "
                style={{ zIndex: "1281" }}
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    id="notification-menu-list"
                    style={{ transformOrigin: "0 0 0" }}
                    className="nav-drop-container frequest-section"
                  >
                    <Paper style={{ width: "285px" }}>
                      <ClickAwayListener
                        onClickAway={(e) =>
                          handleCloseNotification(e, "request")
                        }
                      >
                        <div>
                          <div className="p-d-flex p-ai-center p-jc-between">
                            <div className="dark-blue-color frequest-header">
                              Friend’s Request
                            </div>
                            <div className="notification-info">
                              {/* {notificationCount} */}{" "}
                              {friendList.filter(
                                (x) =>
                                  x.RequestStatus === "Active" &&
                                  x.Status === "Pending" &&
                                  x.AcceptedBy === UserId
                              ).length +
                                friendList.filter(
                                  (x) =>
                                    x.RequestStatus === "Active" &&
                                    x.Status === "Accepted" &&
                                    x.AddedBy === UserId
                                ).length +
                                friendList.filter(
                                  (x) =>
                                    x.RequestStatus === "Active" &&
                                    x.Status === "Rejected" &&
                                    x.AddedBy === UserId
                                ).length >
                              0
                                ? friendList.filter(
                                    (x) =>
                                      x.RequestStatus === "Active" &&
                                      x.Status === "Pending" &&
                                      x.AcceptedBy === UserId
                                  ).length +
                                  friendList.filter(
                                    (x) =>
                                      x.RequestStatus === "Active" &&
                                      x.Status === "Accepted" &&
                                      x.AddedBy === UserId
                                  ).length +
                                  friendList.filter(
                                    (x) =>
                                      x.RequestStatus === "Active" &&
                                      x.Status === "Rejected" &&
                                      x.AddedBy === UserId
                                  ).length
                                : 0}
                            </div>
                          </div>
                          <MenuList role="menu" className="nav-drop-ul">
                            {friendList.filter(
                              (x) =>
                                x.Status === "Pending" && x.AcceptedBy == UserId
                            ).length !== 0 ||
                            friendList.filter(
                              (x) =>
                                x.Status === "Accepted" && x.AddedBy == UserId
                            ).length !== 0 ||
                            friendList.filter(
                              (x) =>
                                x.Status == "Rejected" && x.AddedBy == UserId
                            ).length != 0 ? (
                              friendList.map((res, idx) => {
                                return UserId == res.AcceptedBy ? (
                                  res.RequestStatus === "Active" &&
                                  res.Status === "Pending" ? (
                                    // <a href={window.location.origin + "/admin/request"}>
                                    <MenuItem
                                      onClick={(e) =>
                                        handleRequestClick(
                                          res,
                                          idx,
                                          "networkrequest"
                                        )
                                      }
                                    >
                                      <div className="p-d-flex p-ai-center frequest-list">
                                        <div className="frequest-dot"></div>
                                        <div>
                                          <div className="frequest-details">
                                            <span> {res.Name} </span> has sent
                                            you a network request{" "}
                                          </div>
                                          <div className="frequest-date">
                                            {moment(res.CreatedOn).format(
                                              "DD/MM/YYYY hh:mm A"
                                            )}
                                          </div>
                                        </div>
                                        <div className="frequest-img">
                                          <img
                                            src={
                                              res.ProfilePic == "" ||
                                              res.ProfilePic == null ||
                                              res.ProfilePic == undefined
                                                ? User
                                                : individual_profile_image_link +
                                                  res.ProfilePic
                                            }
                                            onError={(ev) => {
                                              ev.target.src = User;
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </MenuItem>
                                  ) : // </a>

                                  res.RequestStatus === "Inactive" &&
                                    res.Status === "Pending" ? (
                                    // <a href={window.location.origin + "/admin/request"}>
                                    <MenuItem
                                      onClick={(e) =>
                                        handleRequestClick(
                                          res,
                                          idx,
                                          "networkrequest"
                                        )
                                      }
                                    >
                                      {/* <span style={{ marginBottom: "10px" }}>{res.Name} has sent you a network request</span><span style={{ fontSize: "10px", position: "absolute", bottom: "0px", right: "0px" }}>{moment(res.CreatedOn).format("DD/MM/YYYY hh:mm A")}</span> */}
                                      <div className="p-d-flex p-ai-center frequest-list">
                                        {/* <div className="frequest-dot"></div> */}

                                        <div>
                                          <div className="frequest-details">
                                            {" "}
                                            <span> {res.Name} </span> has sent
                                            you a network request{" "}
                                          </div>
                                          <div className="frequest-date">
                                            {moment(res.CreatedOn).format(
                                              "DD/MM/YYYY hh:mm A"
                                            )}
                                          </div>
                                        </div>
                                        <div className="frequest-img">
                                          {" "}
                                          <img
                                            src={
                                              res.ProfilePic == "" ||
                                              res.ProfilePic == null ||
                                              res.ProfilePic == undefined
                                                ? User
                                                : individual_profile_image_link +
                                                  res.ProfilePic
                                            }
                                            onError={(ev) => {
                                              ev.target.src = User;
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </MenuItem>
                                  ) : // </a>
                                  null
                                ) : res.RequestStatus === "Active" &&
                                  res.Status === "Accepted" ? (
                                  <MenuItem
                                    onClick={(e) =>
                                      handleRequestClick(res, idx, "network")
                                    }
                                  >
                                    {/* <span>{res.name} has accepted your request</span>
                                                                                        <span>{moment(res.UpdatedOn).format("DD/MM/YYYY hh:mm A")}</span> */}
                                    <div className="p-d-flex p-ai-center frequest-list">
                                      <div className="frequest-dot"></div>

                                      <div>
                                        <div className="frequest-details">
                                          {" "}
                                          <span> {res.name} </span> has accepted
                                          your request
                                        </div>
                                        <div className="frequest-date">
                                          {moment(res.UpdatedOn).format(
                                            "DD/MM/YYYY hh:mm A"
                                          )}
                                        </div>
                                      </div>
                                      <div className="frequest-img">
                                        <img
                                          src={
                                            res.profilePic == "" ||
                                            res.profilePic == null ||
                                            res.profilePic == undefined
                                              ? User
                                              : individual_profile_image_link +
                                                res.profilePic
                                          }
                                          onError={(ev) => {
                                            ev.target.src = User;
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </MenuItem>
                                ) : res.RequestStatus === "Inactive" &&
                                  res.Status === "Accepted" ? (
                                  <MenuItem
                                    onClick={(e) =>
                                      handleRequestClick(res, idx, "network")
                                    }
                                  >
                                    {/* <span>{res.name} has accepted your request</span>
                                                                                            <span>{moment(res.UpdatedOn).format("DD/MM/YYYY hh:mm A")}</span> */}
                                    <div className="p-d-flex p-ai-center frequest-list">
                                      {/* <div className="frequest-dot"></div> */}

                                      <div>
                                        <div className="frequest-details">
                                          {" "}
                                          <span> {res.name} </span> has accepted
                                          your request
                                        </div>
                                        <div className="frequest-date">
                                          {moment(res.UpdatedOn).format(
                                            "DD/MM/YYYY hh:mm A"
                                          )}
                                        </div>
                                      </div>
                                      <div className="frequest-img">
                                        <img
                                          src={
                                            res.profilePic == "" ||
                                            res.profilePic == null ||
                                            res.profilePic == undefined
                                              ? User
                                              : individual_profile_image_link +
                                                res.profilePic
                                          }
                                          onError={(ev) => {
                                            ev.target.src = User;
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </MenuItem>
                                ) : res.RequestStatus === "Active" &&
                                  res.Status === "Rejected" ? (
                                  <MenuItem>
                                    {/* <span>{res.name} has rejected your request</span>
                                                                                                <span>{moment(res.UpdatedOn).format("DD/MM/YYYY hh:mm A")}</span> */}
                                    <div className="p-d-flex p-ai-center frequest-list">
                                      <div className="frequest-dot"></div>

                                      <div>
                                        <div className="frequest-details">
                                          {" "}
                                          <span> {res.name} </span> has rejected
                                          your request
                                        </div>
                                        <div className="frequest-date">
                                          {moment(res.UpdatedOn).format(
                                            "DD/MM/YYYY hh:mm A"
                                          )}
                                        </div>
                                      </div>
                                      <div className="frequest-img">
                                        <img
                                          src={
                                            res.profilePic == "" ||
                                            res.profilePic == null ||
                                            res.profilePic == undefined
                                              ? User
                                              : individual_profile_image_link +
                                                res.profilePic
                                          }
                                          onError={(ev) => {
                                            ev.target.src = User;
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </MenuItem>
                                ) : res.RequestStatus === "Inactive" &&
                                  res.Status === "Rejected" ? (
                                  <MenuItem /*onClick={(e) => handleBuyClick(res, idx)}*/
                                  >
                                    {/* <span>{res.name} has rejected your request</span>
                                                                                                    <span>{moment(res.UpdatedOn).format("DD/MM/YYYY hh:mm A")}</span> */}
                                    <div className="p-d-flex p-ai-center frequest-list">
                                      {/* <div className="frequest-dot"></div> */}

                                      <div>
                                        <div className="frequest-details">
                                          {" "}
                                          <span> {res.name} </span> has rejected
                                          your request
                                        </div>
                                        <div className="frequest-date">
                                          {moment(res.UpdatedOn).format(
                                            "DD/MM/YYYY hh:mm A"
                                          )}
                                        </div>
                                      </div>
                                      <div className="frequest-img">
                                        <img
                                          src={
                                            res.profilePic == "" ||
                                            res.profilePic == null ||
                                            res.profilePic == undefined
                                              ? User
                                              : individual_profile_image_link +
                                                res.profilePic
                                          }
                                          onError={(ev) => {
                                            ev.target.src = User;
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </MenuItem>
                                ) : null;
                              })
                            ) : (
                              <MenuItem style={{ border: "none" }}>
                                No new notification
                              </MenuItem>
                            )}
                          </MenuList>
                        </div>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
            {/* </OverlayPanel> */}
          </div>
        ) : null}
        {navbarAccess.includes("Rate Updates") ? (
          <div>
            <div className="nav-dropdown-outer">
              <Popper
                open={Boolean(openRateNotification)}
                anchorEl={openRateNotification}
                transition
                disablePortal
                placement="bottom"
                className="nav-dropdown"
                style={{ zIndex: "1281" }}
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    id="notification-menu-list"
                    className="nav-drop-container frequest-section"
                  >
                    <Paper
                      style={{ width: "285px" }}
                      className="noti-container"
                    >
                      <ClickAwayListener onClickAway={handleRateClose}>
                        <div>
                          <div className="p-d-flex p-ai-center p-jc-between">
                            <div className="dark-blue-color frequest-header">
                              Subscribed Request
                            </div>
                            <div className="notification-info">
                              {subscribe.length !== 0 ? subscribe.length : 0}
                            </div>
                          </div>
                          <MenuList role="menu" className="nav-drop-ul">
                            {subscribe.length !== 0 ? (
                              subscribe.map((res, idx) => {
                                return res.SubscribeRate !== res.CurrentRate ? (
                                  res.CurrentRate > res.SubscribeRate ? (
                                    <MenuItem
                                      onClick={(e) => handleRateClick(res, idx)}
                                    >
                                      <div className="p-d-flex p-ai-center frequest-list">
                                        {res.SubscribeStatus == "Active" ? (
                                          <div className="frequest-dot"></div>
                                        ) : null}
                                        <div>
                                          <div className="frequest-details">
                                            Rate of {res.VesselName} increased
                                            by &nbsp;
                                            <span>
                                              ₹
                                              {res.CurrentRate -
                                                res.SubscribeRate}
                                            </span>{" "}
                                          </div>
                                          <div className="frequest-date">
                                            {res.LastModifiedDate}
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            backgroundColor:
                                              "rgba(46, 203, 113, 0.3)",
                                            color: "#2DC433",
                                          }}
                                          className="frequest-img p-d-flex p-ai-center p-jc-center"
                                        >
                                          {CommonConfig.percDiff(
                                            Number(
                                              res.SubscribeRate === "0.00"
                                                ? "0"
                                                : res.SubscribeRate
                                            ),
                                            Number(res.CurrentRate)
                                          )}
                                          <div className="up-arrow-request">
                                            {" "}
                                            <img src={upArrow} />
                                          </div>
                                        </div>
                                      </div>
                                      {/* Rate of {res.VesselName} increased by &nbsp;<span>+{res.CurrentRate - res.SubscribeRate}</span><span>{res.LastModifiedDate}</span>  */}
                                    </MenuItem>
                                  ) : (
                                    <MenuItem
                                      onClick={(e) => handleRateClick(res, idx)}
                                    >
                                      <div className="p-d-flex p-ai-center frequest-list">
                                        {res.SubscribeStatus == "Active" ? (
                                          <div className="frequest-dot"></div>
                                        ) : null}
                                        <div>
                                          <div className="frequest-details">
                                            Rate of {res.VesselName} decreased
                                            by &nbsp;
                                            <span>
                                              ₹
                                              {res.SubscribeRate -
                                                res.CurrentRate}
                                            </span>{" "}
                                          </div>
                                          <div className="frequest-date">
                                            {res.LastModifiedDate}
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            backgroundColor:
                                              "rgba(255, 98, 87, 0.3)",
                                            color: "#F44336",
                                          }}
                                          className="frequest-img p-d-flex p-ai-center p-jc-center"
                                        >
                                          {
                                            -CommonConfig.percDiff(
                                              Number(res.SubscribeRate),
                                              Number(res.CurrentRate)
                                            )
                                          }
                                          <div className="down-arrow-request">
                                            {" "}
                                            <img src={downArrow} />
                                          </div>
                                        </div>
                                      </div>
                                      {/* Rate of {res.VesselName} decreased by&nbsp;<span>-{res.SubscribeRate - res.CurrentRate}</span><span>{res.LastModifiedDate}</span>  */}
                                    </MenuItem>
                                  )
                                ) : res.CurrentRate > res.LastRate ? (
                                  <MenuItem
                                    onClick={(e) => handleRateClick(res, idx)}
                                  >
                                    <div className="p-d-flex p-ai-center frequest-list">
                                      {res.SubscribeStatus == "Active" ? (
                                        <div className="frequest-dot"></div>
                                      ) : null}
                                      <div>
                                        <div className="frequest-details">
                                          Rate of {res.VesselName} increased by
                                          &nbsp;
                                          <span>
                                            ₹
                                            {res.CurrentRate -
                                              res.SubscribeRate}
                                          </span>{" "}
                                        </div>
                                        <div className="frequest-date">
                                          {res.LastModifiedDate}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          backgroundColor:
                                            "rgba(46, 203, 113, 0.3)",
                                          color: "#2DC433",
                                        }}
                                        className="frequest-img p-d-flex p-ai-center p-jc-center"
                                      >
                                        {res.LastRate === ""
                                          ? "0.00"
                                          : CommonConfig.percDiff(
                                              Number(res.LastRate),
                                              Number(res.CurrentRate)
                                            )}
                                        <div className="up-arrow-request">
                                          {" "}
                                          <img src={upArrow} />
                                        </div>
                                      </div>
                                    </div>
                                    {/* Rate of {res.VesselName} increased by &nbsp;<span>+{res.CurrentRate - res.LastRate}</span><span>{res.LastModifiedDate}</span>  */}
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    onClick={(e) => handleRateClick(res, idx)}
                                  >
                                    <div className="p-d-flex p-ai-center frequest-list">
                                      {res.SubscribeStatus == "Active" ? (
                                        <div className="frequest-dot"></div>
                                      ) : null}
                                      <div>
                                        <div className="frequest-details">
                                          Rate of {res.VesselName} decreased by
                                          &nbsp;
                                          <span>
                                            ₹{res.LastRate - res.CurrentRate}
                                          </span>{" "}
                                        </div>
                                        <div className="frequest-date">
                                          {res.LastModifiedDate}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          backgroundColor:
                                            "rgba(255, 98, 87, 0.3)",
                                          color: "#F44336",
                                        }}
                                        className="frequest-img p-d-flex p-ai-center p-jc-center"
                                      >
                                        {res.LastRate === ""
                                          ? "0.00"
                                          : -CommonConfig.percDiff(
                                              Number(res.LastRate),
                                              Number(res.CurrentRate)
                                            )}
                                        <div className="down-arrow-request">
                                          {" "}
                                          <img src={downArrow} />
                                        </div>
                                      </div>
                                    </div>
                                    {/* Rate of {res.VesselName} decreased by&nbsp;<span>-{res.LastRate - res.CurrentRate}</span><span>{res.LastModifiedDate}</span>   */}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem style={{ border: "none" }}>
                                No new notification
                              </MenuItem>
                            )}
                          </MenuList>
                        </div>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        ) : null}

        <UpgradeUserPlan {...props} />

        {/* Old Designed Notification */}

        {navbarAccess.includes("Notification") ? (
          <div>
            <Button
              color="transparent"
              justIcon
              aria-label="Notifications"
              aria-owns={openNotification ? "notification-menu-list" : null}
              aria-haspopup="true"
              onClick={(e) => handleClickNotification(e, "general")}
              // onClick={(e) => openGeneral.current.toggle(e)}
              className={
                rtlActive
                  ? classes.buttonLinkRTL
                  : classes.buttonLink + " top-icon-btn"
              }
              muiClasses={{
                label: rtlActive ? classes.labelRTL : "",
              }}
            >
              <span className="notification-icon">
                <i className="icon-notification mainDarkBlue"></i>
                {notificationCount ? <span></span> : null}
              </span>
            </Button>

            <Popper
              open={Boolean(openNotification)}
              anchorEl={openNotification}
              transition
              disablePortal
              placement="bottom-end"
              className="notification-section PopperNoti"
            >
              <ClickAwayListener
                onClickAway={(e) => handleCloseNotification(e, "general")}
              >
                <div>
                  <div className="p-d-flex p-ai-center noti-drop-container">
                    <div className="notification-number">
                      {notificationCount}
                    </div>
                    <Dropdown
                      className="card flex justify-content-center "
                      value={selectedNotificationType}
                      options={notificationTypeListData}
                      optionLabel="label"
                      optionGroupLabel="label"
                      optionGroupChildren="childNotification"
                      optionGroupTemplate={groupedItemTemplate}
                      itemTemplate={itemOptionTemplate}
                      onChange={(e) => {
                        tempSelectedNotificationType = e.value;
                        setSelectedNotificationType(e.value);
                        getNotificationDetail(e.value);
                      }}
                      style={{ width: "100%" }}
                      placeholder="Select.."
                    />

                    <div
                      className="notification-info"
                      onClick={(e) => openGeneralInfo.current.toggle(e)}
                    >
                      i
                    </div>
                    <OverlayPanel
                      ref={openGeneralInfo}
                      dismissable
                      className="popup-arrow b-radius-10"
                    >
                      <div className="noti-info-area">
                        <div className="p-d-flex p-ai-center p-py-1">
                          <div className="noti-info-dot bg-orange p-mr-2"></div>
                          <div>Purchase</div>
                        </div>
                        <div className="p-d-flex p-ai-center p-py-1">
                          <div className="noti-info-dot bg-green p-mr-2"></div>
                          <div>Sell</div>
                        </div>
                        <div className="p-d-flex p-ai-center p-py-1">
                          <div className="noti-info-dot bg-red p-mr-2"></div>
                          <div>Location</div>
                        </div>
                        <div className="p-d-flex p-ai-center p-py-1">
                          <div className="noti-info-dot bg-blue p-mr-2"></div>
                          <div>Vessel</div>
                        </div>
                        <div className="p-d-flex p-ai-center p-py-1">
                          <div className="noti-info-dot bg-purple p-mr-2"></div>
                          <div>Truck</div>
                        </div>
                        <div className="p-d-flex p-ai-center p-py-1">
                          <div className="noti-info-dot bg-brown p-mr-2"></div>
                          <div>Transporter</div>
                        </div>
                      </div>
                    </OverlayPanel>
                  </div>
                  <ul className="notification-area">
                    {notificationDetailArray.length ? (
                      notificationDetailArray.map((res, idx) => {
                        return (
                          <div className="notification-details" key={idx}>
                            <li
                              className="notification-each"
                              style={{
                                backgroundColor: res.IsNew
                                  ? "#d3d0d0"
                                  : "#f3f3f3",
                              }}
                              onClick={(e) =>
                                handleClick(res, idx, res.NotificationType)
                              }
                            >
                              <div className="p-d-flex p-ai-center">
                                <div className="notification-each-icon">
                                  <i className={res.Icon}></i>
                                </div>

                                <div className="notification-each-text">
                                  {res.NotificationDescription}
                                </div>

                                <div
                                  className="notification-cross-icon"
                                  onClick={(e) => closeNotification(e, res)}
                                >
                                  <i className="icon-close"></i>
                                </div>
                              </div>

                              <div className="notification-each-date">
                                {res.NotificationDate}
                              </div>
                            </li>
                          </div>
                        );
                      })
                    ) : (
                      <div className="p-py-4 dark-blue-color">
                        No new notifcation
                      </div>
                    )}
                  </ul>
                </div>
              </ClickAwayListener>
            </Popper>

            <OverlayPanel
              ref={openGeneral}
              id="overlay_panel"
              className="notification-section"
              dismissable
            >
              <div className="p-d-flex p-ai-center">
                <div className="notification-number">
                  {notificationDetailArray &&
                  notificationDetailArray.length &&
                  notificationDetailArray.length !== 0
                    ? notificationDetailArray.length
                    : 0}
                </div>
                <Dropdown
                  className="notification-dropdown"
                  value={selectedNotificationType}
                  options={notificationTypeListData}
                  onChange={(e) => {
                    setSelectedNotificationType(e.value);
                    getNotificationDetail(e.value);
                  }}
                  style={{ width: "100%" }}
                  optionLabel="name"
                  placeholder="Select.."
                />

                <div
                  className="notification-info"
                  onClick={(e) => openGeneralInfo.current.toggle(e)}
                >
                  <i className="icon-info"></i>
                </div>
                <OverlayPanel
                  ref={openGeneralInfo}
                  dismissable
                  className="popup-arrow b-radius-10"
                >
                  <div className="noti-info-area">
                    <div className="p-d-flex p-ai-center p-py-1">
                      <div className="noti-info-dot bg-orange p-mr-2"></div>
                      <div>Purchase</div>
                    </div>
                    <div className="p-d-flex p-ai-center p-py-1">
                      <div className="noti-info-dot bg-green p-mr-2"></div>
                      <div>Sell</div>
                    </div>
                    <div className="p-d-flex p-ai-center p-py-1">
                      <div className="noti-info-dot bg-red p-mr-2"></div>
                      <div>Location</div>
                    </div>
                    <div className="p-d-flex p-ai-center p-py-1">
                      <div className="noti-info-dot bg-blue p-mr-2"></div>
                      <div>Vessel</div>
                    </div>
                    <div className="p-d-flex p-ai-center p-py-1">
                      <div className="noti-info-dot bg-purple p-mr-2"></div>
                      <div>Truck</div>
                    </div>
                    <div className="p-d-flex p-ai-center p-py-1">
                      <div className="noti-info-dot bg-brown p-mr-2"></div>
                      <div>Transporter</div>
                    </div>
                  </div>
                </OverlayPanel>
              </div>
              <ul className="notification-area">
                {notificationDetailArray.length !== 0 ? (
                  notificationDetailArray.map((res, idx) => {
                    return (
                      <div className="notification-details">
                        <li className="notification-each" key={idx}>
                          <div className="p-d-flex p-ai-center">
                            <div className="notification-each-icon">
                              <i
                                className={
                                  res.NotificationType === "Sell"
                                    ? "icon-buyNotify"
                                    : res.NotificationType === "Purchase"
                                    ? "icon-sentNotify"
                                    : res.NotificationType === "Location"
                                    ? "icon-locationNotify"
                                    : res.NotificationType === "Vessel"
                                    ? "icon-vessel"
                                    : res.NotificationType === "Truck"
                                    ? "icon-truckNotify"
                                    : res.NotificationType === "Reminder"
                                    ? "icon-notification-fill"
                                    : "icon-transporterNotify"
                                }
                              ></i>
                            </div>

                            <div className="notification-each-text">
                              {res.NotificationDescription}
                            </div>
                          </div>

                          <div className="notification-each-date">
                            {res.NotificationDate}
                          </div>
                        </li>
                      </div>
                    );
                  })
                ) : (
                  <div className="p-py-4">SVG - No new notifcation</div>
                )}
              </ul>
            </OverlayPanel>
          </div>
        ) : null}

        <div>
          <Button
            color="transparent"
            justIcon
            aria-label="Lock Screen"
            aria-owns={
              openRequestNotification ? "notification-menu-list" : null
            }
            onClick={(e) => handleProfileClick(e)}
            aria-haspopup="true"
            className="p-w-auto user-profile"
            muiClasses={{
              label: rtlActive ? classes.labelRTL : "",
            }}
          >
            <Tooltip placement="bottom" title="Profile" aria-label="Profile">
              <div className="p-d-flex p-ai-center">
                <div className="sidebar-user">
                  <img
                    src={profilePic ? profilePic : User}
                    className={classes.avatarImg}
                    alt={User}
                    style={{
                      objectFit: "cover",
                      width: "40px",
                      height: "100%",
                      borderRadius: "50px",
                    }}
                  />
                </div>
                <div className="header-user-name">
                  <div> {userDetail ? userDetail.FullName : null} </div>
                  <div className="badge badge-custom p-d-inline-block">
                    {userDetail && userDetail.UserRoll
                      ? userDetail.UserRoll
                      : null}
                  </div>
                </div>
              </div>
            </Tooltip>
          </Button>

          <Menu
            className="profile-btn"
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={ProfileOpen}
            onClose={handleProfileClose}
            TransitionComponent={Fade}
            style={{ top: "55px" }}
          >
            {/* {serviceList.includes("Profile") ? */}

            {/* <MenuItem onClick={handleEditProfile} className="p-d-md-none">
                            <i className="icon-add-user fs-22" style={{ marginRight: '10px' }} ></i> Request
                        </MenuItem>
                        <MenuItem onClick={handleEditProfile} className="p-d-md-none">
                            <i className="icon-rate-notify fs-22" style={{ marginRight: '10px' }} ></i> Rate
                        </MenuItem>
                        <MenuItem onClick={handleEditProfile} className="p-d-md-none">
                            <i className="icon-notification fs-22" style={{ marginRight: '10px' }} ></i> Notification
                        </MenuItem> */}
            <MenuItem onClick={handleEditProfile}>
              <i
                className="icon-edit-user fs-22"
                style={{ marginRight: "10px" }}
              ></i>{" "}
              Edit Profile
            </MenuItem>
            {/* : null */}
            <MenuItem onClick={(e) => handleChangePassword(e)}>
              <i
                className="icon-edit-password fs-22"
                style={{ marginRight: "10px" }}
              ></i>{" "}
              Edit Password
            </MenuItem>
            <MenuItem onClick={(e) => handleClickLock(e, "pin")}>
              <i
                className="icon-edit-pin fs-22"
                style={{ marginRight: "10px" }}
              ></i>{" "}
              Setup Pin
            </MenuItem>
            <MenuItem onClick={(e) => handleClickLock(e, "lock")}>
              {" "}
              <i
                className="icon-lock fs-22"
                style={{ marginRight: "10px" }}
              ></i>
              Lock
            </MenuItem>
            <MenuItem onClick={(e) => handleClickLock(e, "Fullscreen")}>
              <i
                className="icon-fullscreen"
                style={{ marginRight: "10px" }}
              ></i>
              Full Screen
            </MenuItem>
            <MenuItem onClick={(e) => signOut(e)}>
              {" "}
              <i className="icon-logout" style={{ marginRight: "10px" }}></i>
              Logout
            </MenuItem>
          </Menu>
        </div>
        {/* <div className="navbar-lock">
                    <Button
                        color="transparent"
                        justIcon
                        aria-label="Lock Screen"
                        aria-owns={openRequestNotification ? "notification-menu-list" : null}
                        onClick={(e) => handleClickLock(e, "lock")}
                        aria-haspopup="true"
                        className="lock-disabled"
                        muiClasses={{
                            label: rtlActive ? classes.labelRTL : ""
                        }}
                    >
                        <i className="icon-lock mainDarkBlue" aria-hidden="true"></i><span
                            className={
                                classes.headerLinksSvg +
                                " " +
                                (rtlActive
                                    ? classes.links + " " + classes.linksRTL
                                    : classes.links)
                            }
                        ></span>
                    </Button>
                </div>
                <div className="navbar-fullscreen">
                    <Button
                        color="transparent"
                        justIcon
                        aria-label="Full Screen"
                        aria-owns={openRequestNotification ? "notification-menu-list" : null}
                        aria-haspopup="true"
                        onClick={(e) => handleClickLock(e, "Fullscreen")}
                        className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                        muiClasses={{
                            label: rtlActive ? classes.labelRTL : ""
                        }}
                    >
                        <i className="icon-fullscreen mainDarkBlue" aria-hidden="true" style={{ fontSize: "initial" }}></i><span
                            className={
                                classes.headerLinksSvg +
                                " " +
                                (rtlActive
                                    ? classes.links + " " + classes.linksRTL
                                    : classes.links)
                            }
                        ></span>
                        
                    </Button>
                </div> */}
        {/* <div>
          <Button
            color="transparent"
            aria-label="Person"
            aria-owns={openProfile ? "profile-menu-list" : setProfilePic}
            aria-haspopup="true"
            onClick={handleClickProfile}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ""
            }}
          >
            {
              setProfilePic === "" || setProfilePic === null || setProfilePic === undefined ?
                <Person
                  className={
                    classes.headerLinksSvg +
                    " " +
                    (rtlActive
                      ? classes.links + " " + classes.linksRTL
                      : classes.links)
                  }
                /> :
                <img alt="" src={setProfilePic} height="76" width="26" className={
                  classes.headerLinksSvg +
                  " " +
                  (rtlActive
                    ? classes.links + " " + classes.linksRTL
                    : classes.links)
                } style={{ borderRadius: "10px", marginRight: "8px" }} ></img>}
            <label>{name}</label>

            <Hidden mdUp implementation="css">
              <span onClick={handleClickProfile} className={classes.linkText}>
                {rtlActive ? "الملف الشخصي" : "Profile"}
              </span>
            </Hidden>

          </Button>
          <Popper
            open={Boolean(openProfile)}
            anchorEl={openProfile}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openProfile,
              [classes.popperResponsive]: true,
              [classes.popperNav]: true
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={handleCloseProfile}
                        className={dropdownItem}
                      >
                        {rtlActive ? "الملف الشخصي" : "Edit Profile"}
                      </MenuItem>
                      {/* <MenuItem
                        onClick={handleCloseProfile}
                        className={dropdownItem}
                      >
                        {rtlActive ? "الإعدادات" : "Settings"}
                      </MenuItem>*/}
        {/* <Divider light />  */}
        {/* <MenuItem
                        onClick={handleChangePassword}
                        className={dropdownItem}
                      >
                        {rtlActive ? "الإعدادات" : "Change Password"}
                      </MenuItem>
                      <Divider light />
                      <MenuItem
                        className={dropdownItem}
                        onClick={(e) => handleClickLock(e, "pin")}
                      >
                        Setup Pin
                      </MenuItem>
                      <MenuItem onClick={signOut} className={dropdownItem} >
                        {rtlActive ? "الخروج" : "Log out"}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
       */}
      </div>
      <Dialog
        open={open}
        onClose={(e) => handleClose(e, "accept")}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="custom-dialog-header">
          <div>Enter Selling Rate</div>
          <div
            className="dialog-close"
            onClick={(e) => handleClose(e, "accept")}
          >
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        {purchasearr.map((res, idx) => {
          return (
            <React.Fragment>
              <DialogContent className="dialog-content-area p-mt-2">
                {click1 === false ? (
                  click === false ? (
                    <>
                      <TextField
                        autoFocus
                        margin="dense"
                        name="sellingrate"
                        id="sellingrate"
                        label="Enter Selling Rate"
                        type="text"
                        value={sellingrate}
                        onChange={(e) => handleChange_value1(e, "sellingrate")}
                        error={sellingrateErr}
                        helperText={sellingratehelperText}
                        fullWidth
                      />
                      <label>
                        <p>Current Rate: {res.Rate}</p>
                      </label>
                    </>
                  ) : (
                    <TextField
                      // autoFocus
                      margin="dense"
                      name="sellingrate"
                      id="sellingrate"
                      label="Enter Selling Rate"
                      type="text"
                      value={sellingrate}
                      onChange={(e) => handleChange_value1(e, "sellingrate")}
                      error={sellingrateErr}
                      helperText={sellingratehelperText}
                      fullWidth
                      disabled
                    />
                  )
                ) : (
                  <TextField
                    autoFocus
                    inputProps={{ maxLength: 30 }}
                    margin="dense"
                    name="message"
                    id="message"
                    label="Input Message"
                    type="text"
                    value={message}
                    onChange={(e) => handleChange_value1(e, "message")}
                    error={messageErr}
                    helperText={messagehelperText}
                    fullWidth
                  />
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={click}
                      error={isCheckedErr}
                      helperText={isCheckedhelperText}
                      onChange={(e) => handleCheckbox(e, "forself")}
                    />
                  }
                  label="For self"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={click1}
                      error={isChecked1Err}
                      helperText={isChecked1helperText}
                      onChange={(e) => handleCheckbox(e, "contactbestdeal")}
                    />
                  }
                  label="Contact us for best deal"
                />
              </DialogContent>

              <DialogActions className="p-p-0 dialog-footer1">
                {loading === true ? (
                  <div className="loading">
                    <SimpleBackdrop />
                  </div>
                ) : null}

                {click1 === false ? (
                  click === false ? (
                    <Button
                      className="primary-btn"
                      onClick={(e) =>
                        handlePurchaseClick(res, idx, "addtovessel")
                      }
                      color="primary"
                      disabled={loading}
                    >
                      Add to Vessel list
                    </Button>
                  ) : (
                    <Button
                      className="primary-btn1"
                      onClick={(e) => handlePurchaseClick(res, idx, "forself")}
                      color="primary"
                      disabled={loading}
                    >
                      OK
                    </Button>
                  )
                ) : (
                  <Button
                    className="primary-btn"
                    onClick={(e) => handlePurchaseClick(res, idx, "mesage")}
                    color="primary"
                    disabled={loading}
                  >
                    Add to Vessel list
                  </Button>
                )}
              </DialogActions>
            </React.Fragment>
          );
        })}
      </Dialog>
      <Dialog
        open={open1}
        onClose={(e) => handleClose(e, "reject")}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Resubmit Request</DialogTitle>
        {purchasearr.map((res, idx) => {
          return (
            <React.Fragment>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  name="requestrate"
                  id="requestrate"
                  label="Enter Request Rate"
                  type="text"
                  value={requestrate}
                  onChange={(e) => handleChange_value1(e, "requestrate")}
                  error={requestrateErr}
                  helperText={requestratehelperText}
                  fullWidth
                />
                <label>
                  <p>Current Rate: {res.PurchaseRate}</p>
                </label>
              </DialogContent>

              <DialogActions className="p-p-0 dialog-footer1">
                {loading === true ? (
                  <div className="loading">
                    <SimpleBackdrop />
                  </div>
                ) : null}
                <Button
                  className="primary-btn1"
                  onClick={(e) => handlePurchaseClick(res, idx, "requestrate")}
                  color="primary"
                  disabled={loading}
                >
                  Request
                </Button>
              </DialogActions>
            </React.Fragment>
          );
        })}
      </Dialog>

      <Dialog
        className="pin-modal"
        open={openlock}
        onClose={(e) => handleClose(e, "lock")}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="custom-dialog-header">
          <div>Setup Pin</div>
          <div className="dialog-close" onClick={(e) => handleClose(e, "lock")}>
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        <form onSubmit={(e) => handlePinSubmit(e)}>
          <DialogContent>
            <div className="dialog-content-area p-mt-2">
              <div className="pin-input">
                {!CommonConfig.isEmpty(currentpin) ? (
                  <TextField
                    label="Old Pin"
                    name="oldpin"
                    id="oldpin"
                    className=""
                    onChange={(e) => handlePinChange(e, "old")}
                    type={showOldPin ? "text" : "Password"}
                    value={oldpin}
                    helperText={oldpinhelperText}
                    error={oldpinErr}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {!showOldPin ? (
                            <Tooltip
                              placement="right"
                              title="Show pin"
                              aria-label="Show pin"
                            >
                              <IconButton
                                aria-label="Toggle pin visibility"
                                data-tooltip="show pin"
                                onClick={(e) => handleClickShowPin(e, "old")}
                              >
                                <i className="icon-view" aria-hidden="true"></i>
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              placement="right"
                              title="Hide pin"
                              aria-label="Hide pin"
                            >
                              <IconButton
                                aria-label="Toggle pin visibility"
                                data-tooltip=""
                                onClick={(e) => handleClickShowPin(e, "old")}
                              >
                                <i
                                  className="icon-close-eye"
                                  aria-hidden="true"
                                ></i>
                              </IconButton>
                            </Tooltip>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    autoFocus
                  />
                ) : (
                  <TextField
                    label="Old Pin"
                    name="oldpin"
                    id="oldpin"
                    className=""
                    onChange={(e) => handlePinChange(e, "old")}
                    value={oldpin}
                    helperText={oldpinhelperText}
                    error={oldpinErr}
                    autoFocus
                    disabled
                  />
                )}
              </div>
              <div className="pin-input">
                {currentpin ? (
                  currentpin == oldpin ? (
                    <TextField
                      label="New Pin"
                      name="newpin"
                      id="newpin"
                      className=""
                      onChange={(e) => handlePinChange(e, "new")}
                      type={showNewPin ? "text" : "Password"}
                      value={newpin}
                      helperText={newpinhelperText}
                      error={newpinErr}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!showNewPin ? (
                              <Tooltip
                                placement="right"
                                title="Show pin"
                                aria-label="Show pin"
                              >
                                <IconButton
                                  aria-label="Toggle pin visibility"
                                  data-tooltip="show pin"
                                  onClick={(e) => handleClickShowPin(e, "new")}
                                >
                                  <i
                                    className="icon-view"
                                    aria-hidden="true"
                                  ></i>
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                placement="right"
                                title="Hide pin"
                                aria-label="Hide pin"
                              >
                                <IconButton
                                  aria-label="Toggle pin visibility"
                                  data-tooltip=""
                                  onClick={(e) => handleClickShowPin(e, "new")}
                                >
                                  <i
                                    className="icon-close-eye"
                                    aria-hidden="true"
                                  ></i>
                                </IconButton>
                              </Tooltip>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      // autoFocus
                    />
                  ) : (
                    <TextField
                      label="New Pin"
                      name="newpin"
                      id="newpin"
                      className=""
                      onChange={(e) => handlePinChange(e, "new")}
                      type={showNewPin ? "text" : "Password"}
                      value={newpin}
                      helperText={newpinhelperText}
                      error={newpinErr}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!showNewPin ? (
                              <Tooltip
                                placement="right"
                                title="Show pin"
                                aria-label="Show pin"
                              >
                                <IconButton
                                  aria-label="Toggle pin visibility"
                                  data-tooltip="show pin"
                                  onClick={(e) => handleClickShowPin(e, "new")}
                                >
                                  <i
                                    className="icon-view"
                                    aria-hidden="true"
                                  ></i>
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                placement="right"
                                title="Hide pin"
                                aria-label="Hide pin"
                              >
                                <IconButton
                                  aria-label="Toggle pin visibility"
                                  data-tooltip=""
                                  onClick={(e) => handleClickShowPin(e, "new")}
                                >
                                  <i
                                    className="icon-close-eye"
                                    aria-hidden="true"
                                  ></i>
                                </IconButton>
                              </Tooltip>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      disabled
                      // autoFocus
                    />
                  )
                ) : (
                  <TextField
                    label="New Pin"
                    name="newpin"
                    id="newpin"
                    className=""
                    onChange={(e) => handlePinChange(e, "new")}
                    type={showNewPin ? "text" : "Password"}
                    value={newpin}
                    helperText={newpinhelperText}
                    error={newpinErr}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {!showNewPin ? (
                            <Tooltip
                              placement="right"
                              title="Show pin"
                              aria-label="Show pin"
                            >
                              <IconButton
                                aria-label="Toggle pin visibility"
                                data-tooltip="show pin"
                                onClick={(e) => handleClickShowPin(e, "new")}
                              >
                                <i className="icon-view" aria-hidden="true"></i>
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              placement="right"
                              title="Hide pin"
                              aria-label="Hide pin"
                            >
                              <IconButton
                                aria-label="Toggle pin visibility"
                                data-tooltip=""
                                onClick={(e) => handleClickShowPin(e, "new")}
                              >
                                <i
                                  className="icon-close-eye"
                                  aria-hidden="true"
                                ></i>
                              </IconButton>
                            </Tooltip>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    // autoFocus
                  />
                )}
              </div>
              <div className="pin-input">
                {currentpin ? (
                  currentpin == oldpin ? (
                    <TextField
                      label="Confirm New Pin"
                      name="confirmpin"
                      id="confirmpin"
                      className=""
                      onChange={(e) => handlePinChange(e, "confirm")}
                      type={showConfirmPin ? "text" : "Password"}
                      value={confirmpin}
                      helperText={confirmpinhelperText}
                      error={confirmpinErr}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!showConfirmPin ? (
                              <Tooltip
                                placement="right"
                                title="Show pin"
                                aria-label="Show pin"
                              >
                                <IconButton
                                  aria-label="Toggle pin visibility"
                                  data-tooltip="show pin"
                                  onClick={(e) =>
                                    handleClickShowPin(e, "confirm")
                                  }
                                >
                                  <i
                                    className="icon-view"
                                    aria-hidden="true"
                                  ></i>
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                placement="right"
                                title="Hide pin"
                                aria-label="Hide pin"
                              >
                                <IconButton
                                  aria-label="Toggle pin visibility"
                                  data-tooltip=""
                                  onClick={(e) =>
                                    handleClickShowPin(e, "confirm")
                                  }
                                >
                                  <i
                                    className="icon-close-eye"
                                    aria-hidden="true"
                                  ></i>
                                </IconButton>
                              </Tooltip>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      // autoFocus
                    />
                  ) : (
                    <TextField
                      label="Confirm New Pin"
                      name="confirmpin"
                      id="confirmpin"
                      className=""
                      onChange={(e) => handlePinChange(e, "confirm")}
                      type={showConfirmPin ? "text" : "Password"}
                      value={confirmpin}
                      helperText={confirmpinhelperText}
                      error={confirmpinErr}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!showConfirmPin ? (
                              <Tooltip
                                placement="right"
                                title="Show pin"
                                aria-label="Show pin"
                              >
                                <IconButton
                                  aria-label="Toggle pin visibility"
                                  data-tooltip="show pin"
                                  onClick={(e) =>
                                    handleClickShowPin(e, "confirm")
                                  }
                                >
                                  <i
                                    className="icon-view"
                                    aria-hidden="true"
                                  ></i>
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                placement="right"
                                title="Hide pin"
                                aria-label="Hide pin"
                              >
                                <IconButton
                                  aria-label="Toggle pin visibility"
                                  data-tooltip=""
                                  onClick={(e) =>
                                    handleClickShowPin(e, "confirm")
                                  }
                                >
                                  <i
                                    className="icon-close-eye"
                                    aria-hidden="true"
                                  ></i>
                                </IconButton>
                              </Tooltip>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      disabled
                      // autoFocus
                    />
                  )
                ) : (
                  <TextField
                    label="Confirm New Pin"
                    name="confirmpin"
                    id="confirmpin"
                    className=""
                    onChange={(e) => handlePinChange(e, "confirm")}
                    type={showConfirmPin ? "text" : "Password"}
                    value={confirmpin}
                    helperText={confirmpinhelperText}
                    error={confirmpinErr}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {!showConfirmPin ? (
                            <Tooltip
                              placement="right"
                              title="Show pin"
                              aria-label="Show pin"
                            >
                              <IconButton
                                aria-label="Toggle pin visibility"
                                data-tooltip="show pin"
                                onClick={(e) =>
                                  handleClickShowPin(e, "confirm")
                                }
                              >
                                <i className="icon-view" aria-hidden="true"></i>
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              placement="right"
                              title="Hide pin"
                              aria-label="Hide pin"
                            >
                              <IconButton
                                aria-label="Toggle pin visibility"
                                data-tooltip=""
                                onClick={(e) =>
                                  handleClickShowPin(e, "confirm")
                                }
                              >
                                <i
                                  className="icon-close-eye"
                                  aria-hidden="true"
                                ></i>
                              </IconButton>
                            </Tooltip>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    // autoFocus
                  />
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions className="p-p-0 dialog-footer1">
            {loading === true ? (
              <div className="loading">
                <SimpleBackdrop />
              </div>
            ) : null}
            <Button
              className="primary-btn1"
              onClick={(e) => handleClose(e, "lock")}
              color="default"
              disabled={loading}
            >
              Close
            </Button>
            <Button
              className="primary-btn"
              type="submit"
              color="info"
              disabled={loading}
              onClick={(e) => handlePinSubmit(e)}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        className="pin-modal"
        open={inputHandle.openPassword}
        onClose={(e) => handleClose(e, "changePassword")}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="custom-dialog-header">
          <div>Change Password</div>
          <div
            className="dialog-close"
            onClick={(e) => handleClose(e, "changePassword")}
          >
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        <form onSubmit={(e) => ChangePassword(e)}>
          <DialogContent>
            <div className="dialog-content-area p-mt-2">
              <div className="pin-input">
                <GridContainer>
                  <GridItem xs={12} className="p-p-0">
                    <div className="login-input p-pl-0">
                      <TextField
                        label="Password*"
                        name="password"
                        id="password"
                        className=""
                        // onKeyPress={(e) => handlerforenter(e)}
                        onChange={(e) => handleChange(e, "password")}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          maxLength: 16,
                          startAdornment: (
                            <InputAdornment position="start">
                              <i className="icon-lock" aria-hidden="true"></i>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {!showPassword ? (
                                <Tooltip
                                  placement="right"
                                  title="Show password"
                                  aria-label="show password"
                                >
                                  <IconButton
                                    aria-label="Toggle password visibility"
                                    data-tooltip="show password"
                                    onClick={(e) =>
                                      handleClickShowPassword(e, "password")
                                    }
                                  >
                                    <i
                                      className="icon-view"
                                      aria-hidden="true"
                                    ></i>
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  placement="right"
                                  title="Hide password"
                                  aria-label="Hide password"
                                >
                                  <IconButton
                                    aria-label="Toggle password visibility"
                                    data-tooltip=""
                                    onClick={(e) =>
                                      handleClickShowPassword(e, "password")
                                    }
                                  >
                                    <i
                                      className="icon-close-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </IconButton>
                                </Tooltip>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        helperText={inputHandle.passwordErrText}
                        error={inputHandle.passwordErr}
                        value={password}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
              <div className="pin-input">
                <GridContainer>
                  <GridItem xs={12} className="p-p-0">
                    <div className="login-input p-pl-0">
                      <TextField
                        label="Confirm Password*"
                        name="confirmPassword"
                        id="confirmPassword"
                        className=""
                        onChange={(e) => handleChange(e, "confirmPassword")}
                        inputProps={{ maxLength: 16 }}
                        type={showConfirmPassword ? "text" : "password"}
                        value={confirmPassword}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <i className="icon-lock" aria-hidden="true"></i>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {!showConfirmPassword ? (
                                <Tooltip
                                  placement="right"
                                  title="Show password"
                                  aria-label="show password"
                                >
                                  <IconButton
                                    aria-label="Toggle password visibility"
                                    data-tooltip="show password"
                                    onClick={(e) =>
                                      handleClickShowPassword(
                                        e,
                                        "confirmPassword"
                                      )
                                    }
                                  >
                                    <i
                                      className="icon-view"
                                      aria-hidden="true"
                                    ></i>
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  placement="right"
                                  title="Hide password"
                                  aria-label="Hide password"
                                >
                                  <IconButton
                                    aria-label="Toggle password visibility"
                                    data-tooltip=""
                                    onClick={(e) =>
                                      handleClickShowPassword(
                                        e,
                                        "confirmPassword"
                                      )
                                    }
                                  >
                                    <i
                                      className="icon-close-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </IconButton>
                                </Tooltip>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        helperText={inputHandle.confirmPasswordErrText}
                        error={inputHandle.confirmPasswordErr}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="primary-btn1"
              onClick={(e) => handleClose(e, "changePassword")}
              color="default"
              disabled={inputHandle.loading}
            >
              Close
            </Button>
            <Button
              className="primary-btn"
              type="submit"
              color="info"
              disabled={inputHandle.loading}
              onClick={(e) => ChangePassword(e)}
            >
              Submit
            </Button>
            {inputHandle.loading === true ? (
              <div className="loading">
                <SimpleBackdrop />
              </div>
            ) : null}
          </DialogActions>
        </form>
      </Dialog>

      {/** ------ Handler Approve Request Modal ------ */}

      <Dialog
        className="profile-details-modal"
        open={inputHandle.openHandlerVesselRequest}
        onClose={(e) => handleClose(e, "HandlerVesselRequest")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div className="p-d-flex">
            <div>Vessel Approval</div>
            {handlerVesselRequestItem.RequestStatus == "Pending" ? null : (
              <div
                style={{ width: "fit-content" }}
                className={
                  handlerVesselRequestItem.RequestStatus == "Accepted"
                    ? "p-badge-success p-badge p-ml-2"
                    : "p-badge-danger p-badge p-ml-2"
                }
              >
                {handlerVesselRequestItem.RequestStatus}
              </div>
            )}
          </div>
          <div
            className="dialog-close"
            onClick={(e) => handleClose(e, "HandlerVesselRequest")}
          >
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        <DialogContent className="profile-content">
          <div className="vessel-details-info-area p-mb-5 p-mt-3">
            <GridContainer>
              <GridItem md={4} className="p-mb-2">
                <div className="add-detail-lable">Vessel Name </div>
                <div className="fw-500">
                  {handlerVesselRequestItem.VesselName
                    ? handlerVesselRequestItem.VesselName
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={4} className="p-mb-2">
                <div className="add-detail-lable">Vessel ID </div>
                <div className="fw-500">
                  {handlerVesselRequestItem.VesselDisplayID
                    ? handlerVesselRequestItem.VesselDisplayID
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={4} className="p-mb-2">
                <div className="add-detail-lable">Product Name </div>
                <div className="fw-500">
                  {handlerVesselRequestItem.ProductName
                    ? handlerVesselRequestItem.ProductName
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={4} className="p-mb-2">
                <div className="add-detail-lable">Unit Name </div>
                <div className="fw-500">
                  {handlerVesselRequestItem.UnitName
                    ? handlerVesselRequestItem.UnitName
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={4} className="p-mb-2">
                <div className="add-detail-lable">Total Quantity</div>
                <div className="fw-500">
                  {handlerVesselRequestItem.TotalQty
                    ? handlerVesselRequestItem.TotalQty
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={4} className="p-mb-2">
                <div className="add-detail-lable">IGM No.</div>
                <div className="fw-500">
                  {handlerVesselRequestItem.IGMNo
                    ? handlerVesselRequestItem.IGMNo
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={4} className="p-mb-2">
                <div className="add-detail-lable">C. Date </div>
                <div className="fw-500">
                  {handlerVesselRequestItem.CDate
                    ? handlerVesselRequestItem.CDate
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={4} className="p-mb-2">
                <div className="add-detail-lable">Unloading Date</div>
                <div className="fw-500">
                  {handlerVesselRequestItem.Date
                    ? handlerVesselRequestItem.Date
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={4} className="p-mb-2">
                <div className="add-detail-lable">OTB Date</div>
                <div className="fw-500">
                  {handlerVesselRequestItem.OTBDate
                    ? handlerVesselRequestItem.OTBDate
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={8} className="p-mb-2">
                <div className="add-detail-lable">Address</div>
                <div className="fw-500">
                  {handlerVesselRequestItem.PortName
                    ? handlerVesselRequestItem.PortName
                    : ""}{" "}
                  {handlerVesselRequestItem.PlotName
                    ? ", " + handlerVesselRequestItem.PlotName
                    : ""}
                </div>
              </GridItem>
              <GridItem md={4} className="p-mb-2">
                <div className="add-detail-lable">Is Closed ?</div>
                <div className="fw-500">
                  {handlerVesselRequestItem.IsClose == "1" ? "Yes" : "No"}{" "}
                  {handlerVesselRequestItem.ClosingDate
                    ? " - " + handlerVesselRequestItem.ClosingDate
                    : ""}
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </DialogContent>
        {handlerVesselRequestItem.RequestStatus == "Pending" ? (
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="red-primary-btn"
              onClick={(e) =>
                handleVesselApprovalReject(
                  e,
                  "Reject",
                  handlerVesselRequestItem.HandlerVesselRequestID
                )
              }
            >
              Reject
            </Button>
            <Button
              className="green-primary-btn1"
              onClick={(e) =>
                handleVesselApprovalReject(
                  e,
                  "Accept",
                  handlerVesselRequestItem.HandlerVesselRequestID
                )
              }
            >
              Accept
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <Dialog
        className="dialog-sm"
        open={InputHandleIsuueDo.opendo}
        onClose={(e) => handleClose(e, "IssueDo")}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className="custom-dialog-header" id="form-dialog-title">
          <div>Issue D.O.</div>

          <div
            className="dialog-close"
            onClick={(e) => handleClose(e, "IssueDo")}
          >
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        <React.Fragment>
          <DialogContent className="dialog-content-area p-w-100">
            <GridContainer>
              <GridItem md={4} className="p-mb-2">
                <div className="add-detail-lable">Vessel ID </div>
                <div className="fw-500">
                  {InputHandleIsuueDo.handlerIssueDo.VesselDisplayID}
                </div>
              </GridItem>
              <GridItem md={4} className="p-mb-2">
                <div className="add-detail-lable">DO No.</div>
                <div className="fw-500">
                  {InputHandleIsuueDo.handlerIssueDo.DoNo
                    ? InputHandleIsuueDo.handlerIssueDo.DoNo
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={4} className="p-mb-2 custom-p-dropdown">
                <div className="add-detail-lable">Sold To</div>
                <div className="fw-500">
                  {InputHandleIsuueDo.handlerIssueDo.PartyToName
                    ? InputHandleIsuueDo.handlerIssueDo.PartyToName
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={6} className="p-mt-2 p-float-label">
                <Dropdown
                  className={
                    InputHandleIsuueDo.BESelectIDErr
                      ? "p-w-100 p-invalid block"
                      : "p-w-100"
                  }
                  emptyMessage={"No BENo. available."}
                  value={InputHandleIsuueDo.BESelectID}
                  options={InputHandleIsuueDo.BESelectList}
                  onChange={(e) => {
                    setInputHandleIsuueDo((InputHandleIsuueDo) => ({
                      ...InputHandleIsuueDo,
                      PartyToID: InputHandleIsuueDo.BESelectList.filter(
                        (x) => x.value == e.value
                      )[0].PartyToID,
                      PartyName: InputHandleIsuueDo.BESelectList.filter(
                        (x) => x.value == e.value
                      )[0].PartyName,
                      BEQty: InputHandleIsuueDo.BESelectList.filter(
                        (x) => x.value == e.value
                      )[0].BEQty,
                      BESelectID: e.value,
                      BESelectIDErr: false,
                      BESelectIDErrText: "",
                    }));

                    PARTYID(e.value);
                  }}
                  optionLabel="label"
                />
                <span class="bar"></span>
                <label className="add-detail-lable">BE List</label>
                <small className="p-error block">
                  {InputHandleIsuueDo.BESelectIDErrText}
                </small>
              </GridItem>
              <GridItem md={6} className="p-mt-2">
                <TextField
                  label="Enter Qty. *"
                  type="text"
                  defaultValue={InputHandleIsuueDo.IssuedQty}
                  error={InputHandleIsuueDo.IssuedQtyErr}
                  helperText={InputHandleIsuueDo.IssuedQtyErrText}
                  className=""
                  fullWidth
                  value={InputHandleIsuueDo.handlerIssueDo.IssuedQty}
                  onChange={(e) =>
                    handleChangeIssueDo(e, InputHandleIsuueDo, "IssuedQty")
                  }
                  disabled={true}
                />
                <label>
                  <p>
                    Remaining BE Qty :{" "}
                    {CommonConfig.isEmpty(InputHandleIsuueDo.BERemainingQty)
                      ? 0
                      : parseFloat(
                          Number(InputHandleIsuueDo.BERemainingQty) -
                            Number(InputHandleIsuueDo.handlerIssueDo.IssuedQty)
                        ).toFixed(2) < 0
                      ? 0
                      : parseFloat(
                          Number(InputHandleIsuueDo.BERemainingQty) -
                            Number(InputHandleIsuueDo.handlerIssueDo.IssuedQty)
                        ).toFixed(2)}{" "}
                  </p>
                </label>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="primary-btn1"
              onClick={(e) => handleClose(e, "IssueDo")}
              color="primary"
              disabled={InputHandleIsuueDo.loading}
            >
              Cancel
            </Button>

            {CommonConfig.isObjectEmpty(InputHandleIsuueDo.BESelectList) ? (
              <Button
                className="primary-btn"
                onClick={(e) => {
                  props.history.push({
                    pathname: "/admin/add-bill-of-entry",
                    state: {
                      VesselId:
                        InputHandleIsuueDo.handlerIssueDo.VesselDisplayID,
                      IsEdit: true,
                      ToParty: InputHandleIsuueDo.handlerIssueDo.PartyToID,
                      CHAID: UserId,
                      IsIssueDo: true,
                      IssueDoID: InputHandleIsuueDo.handlerIssueDo.IssueDOID,
                    },
                  });
                  setInputHandleIsuueDo((InputHandleIsuueDo) => ({
                    ...InputHandleIsuueDo,
                    opendo: false,
                  }));
                }}
                color="primary"
                disabled={InputHandleIsuueDo.loading}
              >
                Add Bill Of Entry
              </Button>
            ) : (
              <Button
                className="primary-btn"
                onClick={(e) => handleVesselIssueDO(e, InputHandleIsuueDo)}
                color="primary"
                disabled={InputHandleIsuueDo.loading}
              >
                Submit
              </Button>
            )}
          </DialogActions>
        </React.Fragment>
      </Dialog>

      <Dialog
        className="profile-details-modal"
        open={inputHandle.openCompanyRequest}
        onClose={(e) => handleClose(e, "CompanyRequest")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div className="p-d-flex">
            <div>Company Member Request</div>
            {userDetails.RequestStatus == "Pending" ? null : (
              <div
                style={{ width: "fit-content" }}
                className={
                  userDetails.RequestStatus == "Accepted"
                    ? "p-badge-success p-badge p-ml-2"
                    : "p-badge-danger p-badge p-ml-2"
                }
              >
                {userDetails.RequestStatus}
              </div>
            )}
          </div>
          <div
            className="dialog-close"
            onClick={(e) => handleClose(e, "CompanyRequest")}
          >
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        <DialogContent className="profile-content">
          <div className="vessel-details-info-area p-mb-5 p-mt-3">
            <GridContainer>
              <GridItem md={6} className="p-mb-2">
                <div className="add-detail-lable">User Name </div>
                <div className="fw-500">
                  {!CommonConfig.isObjectEmpty(userDetails) &&
                  !CommonConfig.isEmpty(userDetails.Name)
                    ? userDetails.Name
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={6} className="p-mb-2">
                <div className="add-detail-lable">Email </div>
                <div className="fw-500">
                  {!CommonConfig.isObjectEmpty(userDetails) &&
                  !CommonConfig.isEmpty(userDetails.Email)
                    ? userDetails.Email
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={6} className="p-mb-2">
                <div className="add-detail-lable">Phone Number</div>
                <div className="fw-500">
                  {!CommonConfig.isObjectEmpty(userDetails) &&
                  !CommonConfig.isEmpty(userDetails.PhoneNumber)
                    ? userDetails.PhoneNumber
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={6} className="p-mb-2">
                <div className="add-detail-lable">User Role</div>
                <div className="fw-500">
                  {!CommonConfig.isObjectEmpty(userDetails) &&
                  !CommonConfig.isEmpty(userDetails.UserRoll)
                    ? userDetails.UserRoll
                    : "-"}
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </DialogContent>
        {userDetails.RequestStatus == "Pending" ? (
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="red-primary-btn"
              onClick={(e) =>
                handleCompanyApprovalReject(
                  e,
                  "Reject",
                  userDetails.CompanyDetailID
                )
              }
            >
              Reject
            </Button>
            <Button
              className="green-primary-btn1"
              onClick={(e) =>
                handleCompanyApprovalReject(
                  e,
                  "Accept",
                  userDetails.CompanyDetailID
                )
              }
            >
              Accept
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <Dialog
        className="profile-details-modal"
        open={vesselDeclarationModal}
        onClose={(e) => handleClose(e, "vesselDeclarationModal")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div className="p-d-flex">
            <div>Vessel Declaration Request</div>
            {vesselDeclarationStatus == "Pending" ? null : (
              <div
                style={{ width: "fit-content" }}
                className={
                  vesselDeclarationStatus == "Accepted"
                    ? "p-badge-success p-badge p-ml-2"
                    : "p-badge-danger p-badge p-ml-2"
                }
              >
                {vesselDeclarationStatus}
              </div>
            )}
          </div>
          <div
            className="dialog-close"
            onClick={(e) => handleClose(e, "vesselDeclarationModal")}
          >
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        <DialogContent className="profile-content">
          <div className="vessel-details-info-area p-mb-5 p-mt-3">
            <GridContainer>
              <GridItem md={6} className="p-mb-2">
                <div className="add-detail-lable">Vessel Name </div>
                <div className="fw-500">
                  {!CommonConfig.isObjectEmpty(vesselName) &&
                  !CommonConfig.isEmpty(vesselName)
                    ? vesselName
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={6} className="p-mb-2">
                <div className="add-detail-lable">Trade Type </div>
                <div className="fw-500">
                  {!CommonConfig.isObjectEmpty(tradeType) &&
                  !CommonConfig.isEmpty(tradeType)
                    ? tradeType
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={6} className="p-mb-2">
                <div className="add-detail-lable">VCNNo </div>
                <div className="fw-500">
                  {!CommonConfig.isObjectEmpty(vcnNo) &&
                  !CommonConfig.isEmpty(vcnNo)
                    ? vcnNo
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={6} className="p-mb-2">
                <div className="add-detail-lable">
                  {tradeType == "IMPORT" ? "Origin Port" : "Destination Port"}
                </div>
                <div className="fw-500">
                  {!CommonConfig.isObjectEmpty(port) &&
                  !CommonConfig.isEmpty(port)
                    ? port
                    : "-"}
                </div>
              </GridItem>
              <GridItem md={6} className="p-mb-2">
                <div className="add-detail-lable">
                  {tradeType == "IMPORT"
                    ? "Origin Country"
                    : "Destination Country"}
                </div>
                <div className="fw-500">
                  {!CommonConfig.isObjectEmpty(countryName) &&
                  !CommonConfig.isEmpty(countryName)
                    ? countryName
                    : "-"}
                </div>
              </GridItem>
            </GridContainer>
            <div>
              <p>
                {" "}
                Remark :{" "}
                {vesselDeclarationStatus == "Rejected"
                  ? vesselDeclarationRemark
                  : null}{" "}
              </p>
            </div>
          </div>
        </DialogContent>
        {vesselDeclarationStatus == "Pending" ? (
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="red-primary-btn"
              onClick={(e) =>
                handleVesselDeclaration(e, "Rejected", vesselDeclarationId)
              }
            >
              Reject
            </Button>
            <Button
              className="red-primary-btn"
              onClick={(e) => setRemarkModal(true)}
            >
              Reject with Remark
            </Button>
            <Button
              className="green-primary-btn1"
              onClick={(e) =>
                handleVesselDeclaration(e, "Accepted", vesselDeclarationId)
              }
            >
              Accept
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <Dialog
        className="profile-details-modal"
        open={remarkModal}
        onClose={(e) => handleClose(e, "remarkModal")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div className="p-d-flex">
            <div>Vessel Declaration Remark</div>
          </div>
          <div
            className="dialog-close"
            onClick={(e) => handleClose(e, "remarkModal")}
          >
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        <DialogContent className="profile-content">
          <div className="vessel-details-info-area p-mb-5 p-mt-3">
            <GridContainer>
              <GridItem md={12}>
                <span className="p-float-label">
                  <InputTextarea
                    id="Remark"
                    value={remark}
                    onChange={(e) => {
                      setRemark(e.target.value);
                      setRemarkErr(false);
                      setRemarkErrText("");
                    }}
                    rows={5}
                    cols={30}
                  />
                </span>
              </GridItem>
            </GridContainer>
          </div>
        </DialogContent>
        {vesselDeclarationStatus == "Pending" ? (
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="red-primary-btn"
              onClick={(e) => handleClose(e, "remarkModal")}
            >
              cancel
            </Button>
            <Button
              className="green-primary-btn1"
              onClick={(e) =>
                handleVesselDeclaration(e, "Rejected", vesselDeclarationId)
              }
            >
              Reject
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
