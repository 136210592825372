/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// import Person from "@material-ui/icons/Person";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import api from "../../utils/api";
import firebase from "firebase";
import { apiBase } from "../../config";
import withStyles from "@material-ui/core/styles/withStyles";
import SimpleBackdrop from "../../utils/general";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";

import DashboardIcon from "@material-ui/icons/Dashboard";
import ChatIcon from "@material-ui/icons/Chat";
import classNames from "classnames";
import cogoToast from "cogo-toast";
import User from "../../assets/images/user.jpg";
import logotm from "../../assets/equator-img/logo/portlinkLogo.svg";
import logolg from "../../assets/equator-img/logo/portlinkLogolg.svg";
import { individual_profile_image_link } from "../../config";
import MapIcon from "@material-ui/icons/Map";
import Group from "@material-ui/icons/Group";
import { icon } from "../../icon";
import "../../assets/equator-scss/_icon.scss";

import ShipCard from "../../views/ShipCard/ShipCard";
import APIConstant from "utils/constant";

var ps;

const Components = {
  ShipCard: ShipCard,
};

const Icons = {
  DashboardIcon: DashboardIcon,
  Group: Group,
  ChatIcon: ChatIcon,
  MapIcon: MapIcon,
  icon: icon,
};

class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    const { className, user, headerLinks, links } = this.props;

    return (
      <div className={className + " "} ref={this.sidebarWrapper}>
        {user}
        {/* {headerLinks} */}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicRoutes: [],
      name: "",
      UserId: "",
      open: false,
      open1: false,
      openProfile: null,
      openAvatar: false,
      miniActive: true,
      openNotification: null,
      openRequestNotification: null,
      openRateNotification: null,
      buyList: [],
      friendList: [],
      click: false,
      click1: false,
      subscribe: [],
      purchaseInfo: [],
      driverInfo: [],
      locationInfo: [],
      truckInfo: [],
      transporterInfo: [],
      vesselInfo: [],
      AcceptedBy: "",
      purchasearr: [],
      Rate: 0,
      count: 0,
      message: "",
      messageErr: false,
      messagehelperText: "",
      serviceList: [],
      sellingrate: "",
      sellingrateErr: false,
      sellingratehelperText: "",
      requestrate: "",
      requestrateErr: false,
      requestratehelperText: "",
      setProfilePic: null,
      loading: false,
      buyclick: false,
      purchaseclick: false,
      vesselclick: false,
      transporterclick: false,
      truckclick: false,
      locationclick: false,
      openlock: false,
      oldpin: "",
      oldpinErr: false,
      oldpinhelperText: "",
      newpin: "",
      newpinErr: false,
      newpinhelperText: "",
      confirmpin: "",
      confirmpinErr: false,
      confirmpinhelperText: "",
      showOldPin: false,
      showNewPin: false,
      showConfirmPin: false,

      //change Password State
      openPassword: false,
      showConfirmPin: false,
      password: "",
      passwordhelperText: "",
      PasswordErr: false,
      ConfirmPassword: "",
      ConfirmPasswordErr: "",
      cpasshelperText: "",
      loading: false,
      CurrentPassword: "",
      ...this.getCollapseStates(props.routes),
    };

    this.getNewUserAccess();
  }
  mainPanel = React.createRef();

  componentDidMount() {
    let userdata = {
      UserID: localStorage.getItem("Infodata"),
    };

    let data = JSON.parse(localStorage.getItem("Userdata"));
    this.setState({ UserId: data.UserId, CurrentPassword: data.Password });

    this.GetUserDetails();

    // this.getNewUserAccess();

    if (this.state.count === 0) {
      api
        .post(
          apiBase + "/ajax/player.php?action=getNotificationServiceRoll",
          userdata
        )
        .then((res) => {
          if (res.Success == 1) {
            var servicelist = [];
            if (res.Info) {
              for (let i = 0; i < res.Info.service.length; i++) {
                servicelist.push(res.Info.service[i].ServiceName);
              }
              this.setState({ serviceList: servicelist });
            }
          }
        });
    }

    try {
      firebase.initializeApp({
        // apiKey: "AIzaSyDrpu0F2oQ6AuRK_lIOa_furDY95zHk83o",
        // authDomain: "chatmodule-d1934.firebaseapp.com",
        // databaseURL: "https://chatmodule-d1934.firebaseio.com",
        // projectId: "chatmodule-d1934",
        // storageBucket: "chatmodule-d1934.appspot.com",

        // apiKey: "AIzaSyAf7p6zotM6aYuSslkSmTAwhi2S8VRPuAM",
        // authDomain: "groupchat-28342.firebaseapp.com",
        // databaseURL: "https://groupchat-28342.firebaseio.com",
        // projectId: "groupchat-28342",
        // storageBucket: "groupchat-28342.appspot.com",
        // messagingSenderId: "638308978905",
        // appId: "1:638308978905:web:f379cc71ac41677378aec8",
        // measurementId: "G-4LSYBE8T5V"

        apiKey: "AIzaSyDMzirvneIfz4aaH9w61eHDpdj7u3EUAWU",
        authDomain: "chat123-60e6d.firebaseapp.com",
        databaseURL: "https://chat123-60e6d.firebaseio.com",
        projectId: "chat123-60e6d",
        storageBucket: "chat123-60e6d.appspot.com",
        messagingSenderId: "660912887197",
        appId: "1:660912887197:web:0db707850c391b75cfcb74",
        measurementId: "G-LYYH3Z3H4H",

        // apiKey: "AIzaSyDa26uWEp5PUP8CLLu4TqOCqtTqPOyQ8wY",
        // authDomain: "chat-c801e.firebaseapp.com",
        // databaseURL: "https://chat-c801e.firebaseio.com",
        // projectId: "chat-c801e",
        // storageBucket: "chat-c801e.appspot.com",
        // messagingSenderId: "464373097413",
        // appId: "1:464373097413:web:0abe990ed3dab807b1a1ac",
        // measurementId: "G-6XWGL2J6Y3"
      });
    } catch (err) {
      if (!/already exists/.test(err.message)) {
        // console.error('Firebase initialization error raised', err.stack)
      }
    }
  }

  GetUserDetails = async () => {
    var params = {
      UserId: localStorage.getItem("Infodata"),
      token: localStorage.getItem("Token"),
    };

    let ProfilePic = "";
    let currentpin = "";
    let name = "";
    var setProfilePic;
    if (params != null) {
      await api
        .post(APIConstant.path.GetUserDetailsById, params)
        .then(async (res) => {
          if (res.success) {
            if (res.Info) {
              ProfilePic = res.data.ProfilePic;
              currentpin = res.data.UnlockPin;
              name = res.data.Name;
              if (
                res.Info.UserDetail.ProfilePic === "" ||
                res.Info.UserDetail.ProfilePic === null ||
                res.Info.UserDetail.ProfilePic === undefined
              ) {
                setProfilePic = User;
              } else {
                setProfilePic =
                  individual_profile_image_link + res.data.ProfilePic;
              }

              this.setState({
                setProfilePic: setProfilePic,
                currentpin: currentpin,
                name: name,
              });
            }
          } else if (!res.success) {
            localStorage.clear();
            const { history } = this.props;
            history.push("/login");
          }
        });
    }
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handleClickNotification = (event, type) => {
    if (type === "general") {
      if (
        this.state.openNotification &&
        this.state.openNotification.contains(event.target)
      ) {
        this.setState({ openNotification: null });
      } else {
        this.setState({ openNotification: event.currentTarget });
      }
    } else {
      if (
        this.state.openRequestNotification &&
        this.state.openRequestNotification.contains(event.target)
      ) {
        this.setState({ openRequestNotification: null });
      } else {
        this.setState({ openRequestNotification: event.currentTarget });
      }
    }
  };

  handleCloseNotification = (event, type) => {
    if (type === "general") {
      this.setState({ openNotification: null });
    } else {
      this.setState({ openRequestNotification: null });
    }
  };

  handleRateNotification = (event) => {
    if (
      this.state.openRateNotification &&
      this.state.openRateNotification.contains(event.target)
    ) {
      this.setState({ openRateNotification: null });
    } else {
      this.setState({ openRateNotification: event.currentTarget });
    }
  };

  handleRateClose = () => {
    this.setState({ openRateNotification: null });
  };

  validaterate = (type) => {
    let formIsValid = true;
    if (type === "sellingrate") {
      if (this.state.sellingrate === "" || this.state.sellingrate === null) {
        this.setState({
          sellingrateErr: true,
          sellingratehelperText: "Please enter sellling rate.",
        });
        formIsValid = false;
      } else {
        this.setState({ sellingrateErr: false, sellingratehelperText: "" });
      }
    } else if (type === "requestrate") {
      if (this.state.requestrate === "" || this.state.requestrate === null) {
        this.setState({
          requestrateErr: true,
          requestratehelperText: "Please enter request rate.",
        });
        formIsValid = false;
      } else {
        this.setState({ requestrateErr: false, requestratehelperText: "" });
      }
    } else {
      if (this.state.message === "" || this.state.message === null) {
        this.setState({
          messageErr: true,
          messagehelperText: "Please enter message.",
        });
        formIsValid = false;
      } else {
        this.setState({ messageErr: false, messagehelperText: "" });
      }
    }
    return formIsValid;
  };

  handlePurchaseClick = (event, idx, type) => {
    if (type === "addtovessel") {
      if (this.validaterate("sellingrate")) {
        this.setState({ loading: true });
        var purchaselist = this.state.purchaseInfo;
        var index = this.state.purchaseInfo.findIndex(
          (x) => x.PuchaseId === event.PuchaseId
        );
        purchaselist[index]["NotificationStatus"] = "Inactive";
        this.setState({ purchaseinfo: purchaselist });
        var params = {
          Type: "purchase",
          rate: this.state.sellingrate,
          PurchaseId: event.PuchaseId,
          fkUserId: purchaselist[index]["PurchaseUserId"],
          VesselId: purchaselist[index]["fkVesselId"],
          VesselUserId: purchaselist[index]["VesselUserId"],
          Quantity: purchaselist[index]["Quantity"],
          VesselStatus: 1,
          // message: ""
        };
        api
          .post(
            apiBase + "/ajax/player.php?action=updateNotificationStatus",
            params
          )
          .then((res) => {
            if (res.Success === 1) {
              this.setState({
                loading: false,
                open: false,
                click: false,
                sellingrate: "",
              });
              cogoToast.success("Rate Updated and Vessel added to list");
            } else {
              this.setState({
                loading: false,
                open: false,
                click: false,
                sellingrate: "",
              });
              cogoToast.error("Rate update failed");
            }
          });
      }
    } else if (type === "forself") {
      this.setState({ loading: true });
      var purchaselist = this.state.purchaseInfo;
      var index = this.state.purchaseInfo.findIndex(
        (x) => x.PuchaseId === event.PuchaseId
      );
      purchaselist[index]["NotificationStatus"] = "Inactive";
      this.setState({ purchaseinfo: purchaselist });
      var params = {
        Type: "self",
        rate: this.state.sellingrate,
        PurchaseId: event.PuchaseId,
        fkUserId: purchaselist[index]["PurchaseUserId"],
        VesselId: purchaselist[index]["fkVesselId"],
        VesselUserId: purchaselist[index]["VesselUserId"],
        Quantity: purchaselist[index]["Quantity"],
        VesselStatus: 0,
        // message: ""
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then((res) => {
          if (res.Success === 1) {
            this.setState({ loading: false, open: false });
            cogoToast.success("Vessel added to Stock");
          } else {
            this.setState({ loading: false, open: false });
            cogoToast.error("Rate update failed");
          }
        });
    } else if (type === "requestrate") {
      if (this.validaterate("requestrate")) {
        this.setState({ loading: true });
        var purchaselist = this.state.purchaseInfo;
        var index = this.state.purchaseInfo.findIndex(
          (x) => x.PuchaseId === event.PuchaseId
        );
        purchaselist[index]["NotificationStatus"] = "Inactive";
        this.setState({ purchaseinfo: purchaselist });
        var params = {
          Type: "request",
          rate: this.state.requestrate,
          PurchaseId: event.PuchaseId,
          fkUserId: purchaselist[index]["PurchaseUserId"],
          VesselId: purchaselist[index]["fkVesselId"],
          VesselUserId: purchaselist[index]["VesselUserId"],
          Quantity: purchaselist[index]["Quantity"],
          VesselStatus: 0,
          // message: ""
        };
        api
          .post(
            apiBase + "/ajax/player.php?action=updateNotificationStatus",
            params
          )
          .then((res) => {
            if (res.Success === 1) {
              this.setState({
                loading: false,
                open1: false,
                click: false,
                requestrate: "",
              });
              cogoToast.success("Request for the vessel sent successfully");
            } else {
              this.setState({
                loading: false,
                open1: false,
                click: false,
                requestrate: "",
              });
              cogoToast.error("Request failed");
            }
          });
      }
    } else {
      if (this.validaterate("message")) {
        this.setState({ loading: true });
        var purchaselist = this.state.purchaseInfo;
        var index = this.state.purchaseInfo.findIndex(
          (x) => x.PuchaseId === event.PuchaseId
        );
        purchaselist[index]["NotificationStatus"] = "Inactive";
        this.setState({ purchaseinfo: purchaselist });
        var params = {
          // rate: null,
          Type: "message",
          PurchaseId: event.PuchaseId,
          fkUserId: purchaselist[index]["PurchaseUserId"],
          VesselId: purchaselist[index]["fkVesselId"],
          VesselUserId: purchaselist[index]["VesselUserId"],
          VesselStatus: 1,
          message: this.state.message,
        };
        api
          .post(
            apiBase + "/ajax/player.php?action=updateNotificationStatus",
            params
          )
          .then((res) => {
            if (res.Success === 1) {
              this.setState({ loading: false, open: false });
              cogoToast.success("Vessel added successfully");
            } else {
              this.setState({ loading: false, open: false });
              cogoToast.error("Rate update failed");
            }
          });
      }
    }
  };

  handleCheckbox = (e, type) => {
    if (type === "forself") {
      const isChecked = e.target.checked;
      if (isChecked) {
        this.setState({ click: true, sellingrate: this.state.Rate });
      } else {
        this.setState({ click: false });
      }
    } else {
      const isChecked1 = e.target.checked;
      if (isChecked1) {
        this.setState({ click1: true, message: "Contact us for best deal" });
      } else {
        this.setState({ click1: false });
      }
    }
  };

  handleRequestClick = (event, idx, type) => {
    this.handleCloseNotification();
    if (type == "network") {
      var index = this.state.friendList.findIndex(
        (x) => x.NetworkId === event.NetworkId
      );
      var requestlist = this.state.friendList;
      requestlist[index]["RequestStatus"] = "Inactive";
      this.setState({ friendList: requestlist });
      var params = {
        NetworkId: event.NetworkId,
      };
      var Id = 1;
      this.props.history.push({
        pathname: "/admin/request/",
        state: {
          Id: Id,
        },
      });
      api
        .post(apiBase + "/ajax/player.php?action=updateRequestStatus", params)
        .then({});
    } else if (type === "transporter") {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      var params = {
        UserId: this.state.UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "receiveractive",
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
      var Id = "transporter";
      this.props.history.push({
        pathname: "/admin/request/",
        state: {
          Id: Id,
        },
      });
    } else if (type === "driveractive") {
      var index = this.state.driverInfo.findIndex(
        (x) => x.TruckId === event.TruckId
      );
      var requestlist = this.state.driverInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ driverInfo: requestlist });
      var params = {
        UserId: this.state.UserId,
        TruckId: requestlist[index]["TruckId"],
        Type: "driveractive",
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
      var Id = "driver";
      this.props.history.push({
        pathname: "/admin/drivertruck/",
        state: {
          Id: Id,
        },
      });
    } else if (type === "transporterinactive") {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      var Id = "transporter";
      this.props.history.push({
        pathname: "/admin/request/",
        state: {
          Id: Id,
        },
      });
    } else if (type === "transporterinfo") {
      var index = this.state.transporterInfo.findIndex(
        (x) => x.RequestId === event.RequestId
      );
      var requestlist = this.state.transporterInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ transporterInfo: requestlist });
      var params = {
        UserId: this.state.UserId,
        vesselid: requestlist[index]["vesselid"],
        RequestId: requestlist[index]["RequestId"],
        AssignToUser: requestlist[index]["CreatedBy"],
        // rate: requestlist[index]["Rate"],
        IsTransporterStatus: 0,
        Type: "requestdifferent",
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=AcceptTruckRequestStatus",
          params
        )
        .then({});
      var Id = "transporter";
      this.props.history.push({
        pathname: "/admin/request/",
        state: {
          Id: Id,
        },
      });
    } else if (type === "transporterinfoinactive") {
      var index = this.state.transporterInfo.findIndex(
        (x) => x.RequestId === event.RequestId
      );
      var requestlist = this.state.transporterInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ transporterInfo: requestlist });
      var Id = "transporter";
      this.props.history.push({
        pathname: "/admin/request/",
        state: {
          Id: Id,
        },
      });
    } else if (type === "truckinactive") {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      var Id = "receiver";
      this.props.history.push({
        pathname: "/admin/request/",
        state: {
          Id: Id,
        },
      });
    } else if (type === "trucklistactive") {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      var params = {
        UserId: this.state.UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "receiveractive",
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
      var Id = "transporter";
      this.props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else if (type === "trucklistinactive") {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      var Id = "transporter";
      this.props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else if (type === "viewtruckactive") {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      var params = {
        UserId: this.state.UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "receiveractive",
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
      var Id = "transporter";
      this.props.history.push({
        pathname: "/admin/view-truck",
        state: {
          Id: Id,
        },
      });
    } else if (type === "viewtruckinactive") {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      var Id = "transporter";
      this.props.history.push({
        pathname: "/admin/view-truck",
        state: {
          Id: Id,
        },
      });
    } else if (type === "trucklistcancelledactive") {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      var params = {
        UserId: this.state.UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "receiveractive",
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
      var Id = "transporter";
      this.props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else if (type === "trucklistcancelledinactive") {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      var Id = "transporter";
      this.props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else if (type === "trucklistrejectedactive") {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      var params = {
        UserId: this.state.UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "receiveractive",
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
      cogoToast.error(
        "Truck request rejected by" + " " + requestlist[index]["name"]
      );
      // var Id = "transporter";
      // this.props.history.push({
      //   path"name": '/admin/trucklist',
      //   state: {
      //     Id: Id,
      //   }
      // });
    } else if (type === "trucklistrejectedinactive") {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      cogoToast.error(
        "Truck request rejected by" + " " + requestlist[index]["name"]
      );
      // var Id = "transporter";
      // this.props.history.push({
      //   pathname: '/admin/trucklist',
      //   state: {
      //     Id: Id,
      //   }
      // });
    } else if (type === "viewtruckcancelledactive") {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      var params = {
        UserId: this.state.UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "receiveractive",
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
      var Id = "transporter";
      this.props.history.push({
        pathname: "/admin/view-truck",
        state: {
          Id: Id,
        },
      });
    } else if (type === "viewtruckcancelledinactive") {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      var Id = "transporter";
      this.props.history.push({
        pathname: "/admin/view-truck",
        state: {
          Id: Id,
        },
      });
    } else if (type === "loadingtruckactive") {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["LoadingPersonStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      var params = {
        UserId: this.state.UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "loadingactive",
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
      var Id = "transporter";
      this.props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else if (type === "loadingtruckinactive") {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["LoadingPersonStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      var Id = "transporter";
      this.props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else if (type === "grouptruckactive") {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["LoadingPersonStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      var params = {
        UserId: this.state.UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "loadingactive",
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
      var Id = "transporter";
      this.props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else if (type === "grouptruckinactive") {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["LoadingPersonStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      var Id = "transporter";
      this.props.history.push({
        pathname: "/admin/trucklist",
        state: {
          Id: Id,
        },
      });
    } else {
      var index = this.state.truckInfo.findIndex(
        (x) => x.AcceptId === event.AcceptId
      );
      var requestlist = this.state.truckInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ truckInfo: requestlist });
      var params = {
        UserId: this.state.UserId,
        AcceptId: requestlist[index]["AcceptId"],
        Type: "receiveractive",
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
      var Id = "receiver";
      this.props.history.push({
        pathname: "/admin/request/",
        state: {
          Id: Id,
        },
      });
    }
  };

  handleBuyClick = (event, idx) => {
    this.handleCloseNotification();
    var index = this.state.buyList.findIndex(
      (x) => x.PuchaseId === event.PuchaseId
    );
    var buylist = this.state.buyList;
    buylist[index]["BuyStatus"] = "Inactive";
    this.setState({ buyList: buylist });
    var params = {
      PurchaseId: event.PuchaseId,
    };
    api
      .post(apiBase + "/ajax/player.php?action=updateBuyStatus", params)
      .then({});
  };

  handleRateClick = (event, idx) => {
    this.handleRateClose();
    var index = this.state.subscribe.findIndex(
      (x) => x.SubscribeId === event.SubscribeId
    );
    var sublist = this.state.subscribe;
    sublist[index]["SubscribeStatus"] = "Inactive";
    this.setState({ subscribe: sublist });
    var params = {
      SubscribeId: event.SubscribeId,
      SubscribeRate: event.CurrentRate,
    };
    var Id = event.VesselUserId;
    this.props.history.push({
      pathname: "/admin/VesselInfo/" + Id,
      state: {
        Id: Id,
      },
    });
    cogoToast.success(
      "Rate Changed from : " +
        event.SubscribeRate +
        " to : " +
        event.CurrentRate
    );
    api
      .post(apiBase + "/ajax/player.php?action=updateSubscribeStatus", params)
      .then({});
  };

  handleClickProfile = (event) => {
    if (
      this.state.openProfile &&
      this.state.openProfile.contains(event.target)
    ) {
      this.setState({ openProfile: null });
    } else {
      this.setState({ openProfile: event.currentTarget });
    }
  };

  handleCloseProfile = () => {
    const { history } = this.props;
    history.push("/admin/editprofile");
  };

  handleChangePassword = () => {
    this.setState({ openPassword: true });
    // const { history } = this.props;
    //history.push('/admin/changepassword')
  };

  handleChange = (e, type) => {
    this.setState({ [e.target.name]: e.target.value });
    if (type === "password") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          PasswordErr: true,
          passwordhelperText: "Please enter password",
        });
      } else if (e.target.value.trim() !== e.target.value) {
        this.setState({
          PasswordErr: true,
          passwordhelperText: "Please enter a valid password",
        });
      } else {
        this.setState({ PasswordErr: false, passwordhelperText: "" });
      }
    } else {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          ConfirmPasswordErr: true,
          cpasshelperText: "Please enter confirm password",
        });
      } else if (this.state.password !== e.target.value) {
        this.setState({
          ConfirmPasswordErr: true,
          cpasshelperText: "Password does not match",
        });
      } else {
        this.setState({ ConfirmPasswordErr: false, cpasshelperText: "" });
      }
    }
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleClickShowPasswordConfirm = () => {
    this.setState((state) => ({ showPassword_conf: !state.showPassword_conf }));
  };

  handlerforenter = (event) => {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  validatePassword = (e) => {
    let formIsValid = true;
    if (this.state.password === "" || this.state.password === null) {
      this.setState({
        PasswordErr: true,
        passwordhelperText: "Please enter password",
      });
      formIsValid = false;
    } else if (this.state.password.trim() !== this.state.password) {
      this.setState({
        PasswordErr: true,
        passwordhelperText: "Please enter a valid password",
      });
      formIsValid = false;
    } else {
      this.setState({ PasswordErr: false, passwordhelperText: "" });
    }

    if (
      this.state.ConfirmPassword === "" ||
      this.state.ConfirmPassword === null
    ) {
      this.setState({
        ConfirmPasswordErr: true,
        cpasshelperText: "Please enter confirm password",
      });
      formIsValid = false;
    } else if (this.state.password !== this.state.ConfirmPassword) {
      this.setState({
        ConfirmPasswordErr: true,
        cpasshelperText: "Password does not match",
      });
      formIsValid = false;
    } else {
      this.setState({ ConfirmPasswordErr: false, cpasshelperText: "" });
    }
    return formIsValid;
  };

  ChangePassword = (e) => {
    e.preventDefault();
    if (this.validatePassword(e)) {
      var params = {
        UserId: this.state.UserId,
        Password: this.state.password,
        CurrentPassword: this.state.CurrentPassword,
      };
      // console.log("Pass data", params)
      if (params) {
        this.setState({ loading: true });
        api
          .post(apiBase + "/ajax/player.php?action=ChangePassword", params)
          .then((res) => {
            if (res.Success === 1) {
              this.setState({
                password: "",
                passwordhelperText: "",
                PasswordErr: false,
                ConfirmPassword: "",
                ConfirmPasswordErr: "",
                cpasshelperText: "",
                loading: false,
                openPassword: false,
              });

              cogoToast.success("Password changed successfully");
            } else {
              this.setState({ loading: false });
              cogoToast.error("Something happened bad");
            }
          })
          .catch((err) => {});
      }
    }
  };

  handleClickAway = () => {
    this.setState({ openProfile: null });
  };

  handleClickOpen = (event, idx, type) => {
    var purchaselist = this.state.purchaseInfo.filter(
      (x) => x.PuchaseId === event.PuchaseId
    );
    if (type === "accepted") {
      this.setState({
        Rate: event.Rate,
        open: true,
        purchasearr: purchaselist,
      });
    } else if (type === "acceptedbuyerseller") {
      var index = this.state.purchaseInfo.findIndex(
        (x) => x.PuchaseId === event.PuchaseId
      );
      var requestlist = this.state.purchaseInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ purchaseInfo: requestlist });
      var params = {
        UserId: this.state.UserId,
        // vesselid: requestlist[index]["vesselid"],
        PurchaseId: requestlist[index]["PuchaseId"],
        // AssignToUser: requestlist[index]["CreatedBy"],
        // rate: requestlist[index]["Rate"],
        // IsTransporterStatus: 0,
        Type: "acceptedbuyerseller",
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
      var Id = "buyer";
      this.props.history.push({
        pathname: "/admin/request/",
        state: {
          Id: Id,
        },
      });
    } else if (type === "acceptedbuyer") {
      var index = this.state.purchaseInfo.findIndex(
        (x) => x.PuchaseId === event.PuchaseId
      );
      var requestlist = this.state.purchaseInfo;
      requestlist[index]["NotificationStatus"] = "Inactive";
      this.setState({ purchaseInfo: requestlist });
      var params = {
        UserId: this.state.UserId,
        // vesselid: requestlist[index]["vesselid"],
        PurchaseId: requestlist[index]["PuchaseId"],
        // AssignToUser: requestlist[index]["CreatedBy"],
        // rate: requestlist[index]["Rate"],
        // IsTransporterStatus: 0,
        Type: "acceptedbuyer",
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
      // var Id = "buyer";
      // this.props.history.push({
      //   pathname: '/admin/request/',
      //   state: {
      //     Id: Id,
      //   }
      // });
    } else if (type === "acceptedlocation") {
      this.handleCloseNotification();
      var index = this.state.locationInfo.findIndex((x) => x.id === event.id);
      var locationInfo = this.state.locationInfo;
      locationInfo[index]["Notification"] = "Inactive";
      this.setState({ locationInfo: locationInfo });
      var params = {
        id: event.id,
        Type: "acceptedlocation",
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
    } else if (type === "activevessel") {
      // var index = this.state.vesselInfo.findIndex(x => x. === event.vesselInfoId);
      // var sublist = this.state.vesselInfo;
      // sublist[index]["vesselInfoStatus"] = "Inactive";
      // this.setState({ vesselInfo: sublist });
      var params = {
        UserId: this.state.UserId,
        VesselUserId: event.VesselUserId,
        Type: "activevessel",
      };
      var Id = event.VesselUserId;
      this.props.history.push({
        pathname: "/admin/VesselInfo/" + Id,
        state: {
          Id: Id,
        },
      });
      // cogoToast.success("Rate Changed from : " + event.vesselInfoRate + " to : " + event.CurrentRate);
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then({});
    } else {
      this.props.history("/admin/request");
      // this.setState({ Rate: event.Rate, open1: true, purchasearr: purchaselist });
    }
  };

  handleClose = (e, type) => {
    if (type === "accept") {
      this.setState({ open: false });
    } else if (type == "lock") {
      this.setState({
        openlock: false,
        oldpin: "",
        oldpinErr: false,
        oldpinhelperText: "",
        newpin: "",
        newpinErr: false,
        newpinhelperText: "",
        confirmpin: "",
        confirmpinErr: false,
        confirmpinhelperText: "",
      });
    } else if (type === "changePassword") {
      this.setState({
        openPassword: false,
        password: "",
        passwordhelperText: "",
        PasswordErr: false,
        ConfirmPassword: "",
        ConfirmPasswordErr: "",
        cpasshelperText: "",
      });
    } else {
      this.setState({ open1: false });
    }
  };

  handleChange_value1 = (e, type) => {
    this.setState({ [e.target.name]: e.target.value.replace(/\D/, "") });
    if (type === "sellingrate") {
      if (e.target.name === "sellingrate") {
        if (e.target.value === "" && e.target.value === null) {
          this.setState({
            sellingrateErr: true,
            sellingratehelperText: "Please Enter Selling Rate.",
          });
        } else {
          this.setState({ sellingrateErr: false, sellingratehelperText: "" });
        }
      }
    } else if (type === "requestrate") {
      if (e.target.value === "" && e.target.value === null) {
        this.setState({
          requestrateErr: true,
          requestratehelperText: "Please Enter Request Rate.",
        });
      } else {
        this.setState({ requestrateErr: false, sellingratehelperText: "" });
      }
    } else {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          messageErr: true,
          messagehelperText: "Please input message.",
        });
      } else {
        this.setState({
          message: e.target.value,
          messageErr: false,
          messagehelperText: "",
        });
      }
    }
  };

  handleClickLock = (e, type) => {
    if (type == "lock") {
      let data = localStorage.getItem("Infodata");
      var params = {
        UserId: data,
      };
      // setUserName: data });
      api.post(apiBase + "/ajax/player.php?action=AppLock", params).then({});
      localStorage.removeItem("LockStatus");
      localStorage.setItem("LockStatus", 1);
      this.props.history.push("/auth/lockscreen");
    } else if (type == "pin") {
      this.setState({ openlock: true });
    } else if (type == "Fullscreen") {
      if (
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (document.msfullscreenElement &&
          document.msfullscreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)
      ) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    } else {
      if (
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (document.msfullscreenElement &&
          document.msfullscreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)
      ) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    }
  };

  handlePinChange = (e, type) => {
    let regExp = /^([0-9]+\s?)*$/g;
    if (type == "old") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          oldpin: e.target.value,
          oldpinErr: true,
          oldpinhelperText: "Please enter old pin",
        });
      } else if (
        e.target.value.trim() !== e.target.value ||
        !e.target.value.match(regExp)
      ) {
        this.setState({ oldpin: this.state.oldpin });
      } else if (e.target.value != this.state.currentpin) {
        this.setState({
          oldpin: e.target.value,
          oldpinErr: true,
          oldpinhelperText: "Entered Old Pin is Incorrect",
        });
      } else {
        this.setState({
          oldpin: e.target.value,
          oldpinErr: false,
          oldpinhelperText: "",
        });
      }
    } else if (type == "new") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          newpin: e.target.value,
          newpinErr: true,
          newpinhelperText: "Please enter new pin",
        });
      } else if (
        e.target.value.trim() !== e.target.value ||
        !e.target.value.match(regExp)
      ) {
        this.setState({ newpin: this.state.newpin });
      } else {
        this.setState({
          newpin: e.target.value,
          newpinErr: false,
          newpinhelperText: "",
        });
      }
    } else {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          confirmpin: e.target.value,
          confirmpinErr: true,
          confirmpinhelperText: "Please enter confirm pin",
        });
      } else if (
        e.target.value.trim() !== e.target.value ||
        !e.target.value.match(regExp)
      ) {
        this.setState({ confirmpin: this.state.confirmpin });
      } else if (e.target.value !== this.state.newpin) {
        this.setState({
          confirmpin: e.target.value,
          confirmpinErr: true,
          confirmpinhelperText: "Confirm Pin and New Pin must be same",
        });
      } else {
        this.setState({
          confirmpin: e.target.value,
          confirmpinErr: false,
          confirmpinhelperText: "",
        });
      }
    }
  };

  handleClickShowPin = (e, type) => {
    if (type == "old") {
      this.setState({ showOldPin: !this.state.showOldPin });
    } else if (type == "new") {
      this.setState({ showNewPin: !this.state.showNewPin });
    } else {
      this.setState({ showConfirmPin: !this.state.showConfirmPin });
    }
  };

  validatepin = () => {
    let formIsValid = true;
    if (this.state.currentpin) {
      if (!this.state.oldpin) {
        this.setState({
          loading: false,
          oldpinErr: true,
          oldpinhelperText: "Please enter old pin",
        });
        formIsValid = false;
      } else if (this.state.oldpin != this.state.currentpin) {
        this.setState({
          loading: false,
          oldpinErr: true,
          oldpinhelperText: "Entered old pin is Incorrect",
        });
        formIsValid = false;
      } else if (this.state.oldpin.length < 4) {
        this.setState({
          loading: false,
          oldpinErr: true,
          oldpinhelperText: "Please enter valid old pin",
        });
        formIsValid = false;
      }
    } else {
      this.setState({ loading: false, oldpinErr: false, oldpinhelperText: "" });
    }
    if (this.state.currentpin) {
      if (this.state.oldpin != this.state.currentpin) {
        this.setState({
          loading: false,
          oldpinErr: true,
          oldpinhelperText: "Entered old pin is Incorrect",
        });
        formIsValid = false;
      } else {
        if (!this.state.newpin) {
          this.setState({
            loading: false,
            newpinErr: true,
            newpinhelperText: "Please enter new pin",
          });
          formIsValid = false;
        } else if (this.state.newpin.length < 4) {
          this.setState({
            loading: false,
            newpinErr: true,
            newpinhelperText: "Please enter valid new pin",
          });
          formIsValid = false;
        } else {
          this.setState({
            loading: false,
            newpinErr: false,
            newpinhelperText: "",
          });
        }
        if (!this.state.confirmpin) {
          this.setState({
            loading: false,
            confirmpinErr: true,
            confirmpinhelperText: "Please enter confirm pin",
          });
          formIsValid = false;
        } else if (this.state.confirmpin !== this.state.newpin) {
          this.setState({
            loading: false,
            confirmpinErr: true,
            confirmpinhelperText: "Confirm Pin and New Pin must be same",
          });
          formIsValid = false;
        } else if (this.state.confirmpin.length < 4) {
          this.setState({
            loading: false,
            confirmpinErr: true,
            confirmpinhelperText: "Please enter valid confirm pin",
          });
          formIsValid = false;
        } else {
          this.setState({
            loading: false,
            confirmpinErr: false,
            confirmpinhelperText: "",
          });
        }
      }
    } else {
      if (!this.state.newpin) {
        this.setState({
          loading: false,
          newpinErr: true,
          newpinhelperText: "Please enter new pin",
        });
        formIsValid = false;
      } else if (this.state.newpin.length < 4) {
        this.setState({
          loading: false,
          newpinErr: true,
          newpinhelperText: "Please enter valid new pin",
        });
        formIsValid = false;
      } else {
        this.setState({
          loading: false,
          confirmpinErr: false,
          confirmpinhelperText: "",
        });
      }
      if (!this.state.confirmpin) {
        this.setState({
          loading: false,
          confirmpinErr: true,
          confirmpinhelperText: "Please enter confirm pin",
        });
        formIsValid = false;
      } else if (this.state.confirmpin !== this.state.newpin) {
        this.setState({
          loading: false,
          confirmpinErr: true,
          confirmpinhelperText: "Confirm Pin and New Pin must be same",
        });
        formIsValid = false;
      } else if (this.state.confirmpin.length < 4) {
        this.setState({
          loading: false,
          confirmpinErr: true,
          confirmpinhelperText: "Please enter valid confirm pin",
        });
        formIsValid = false;
      } else {
        this.setState({
          loading: false,
          confirmpinErr: false,
          confirmpinhelperText: "",
        });
      }
    }
    return formIsValid;
  };

  handlePinSubmit = (e) => {
    e.preventDefault();
    if (this.validatepin()) {
      var params = {
        UserId: this.state.UserId,
        OldPin: this.state.oldpin,
        NewPin: this.state.newpin,
        ConfirmPin: this.state.confirmpin,
      };

      if (params !== null) {
        this.setState({ loading: true });
        api
          .post(apiBase + "/ajax/player.php?action=SetupPin", params)
          .then((res) => {
            if (res.Success === 1) {
              this.setState({
                loading: false,
                openlock: false,
                oldpin: "",
                oldpinErr: false,
                oldpinhelperText: "",
                newpin: "",
                newpinErr: false,
                newpinhelperText: "",
                confirmpin: "",
                confirmpinErr: false,
                confirmpinhelperText: "",
              });
              cogoToast.success("Pin created successfully");
            } else {
              this.setState({
                loading: false,
                openlock: false,
                oldpin: "",
                oldpinErr: false,
                oldpinhelperText: "",
                newpin: "",
                newpinErr: false,
                newpinhelperText: "",
                confirmpin: "",
                confirmpinErr: false,
                confirmpinhelperText: "",
              });
              cogoToast.error("Pin create failed");
            }
          });
      }
    }
  };

  signOut = () => {
    let data = localStorage.getItem("Infodata");
    var params = {
      UserId: data,
    };
    // setUserName: data });
    api
      .post(apiBase + "/ajax/player.php?action=AppLogout", params)
      .then((res) => {
        if (res.Success == 1) {
          localStorage.clear();
          const { history } = this.props;
          history.push("/login");
        }
      });

    var params = {
      UserId: data,
    };
    if (params !== null) {
      api
        .post(apiBase + "/ajax/player.php?action=getIndividualGroup", params)
        .then((res) => {
          if (res.Success == 1) {
            for (let r = 0; r < res.Info.Group.length; r++) {
              if (res.Info.Group[r].SentUserId == data) {
                var active = firebase
                  .database()
                  .ref()
                  .child("isActive/");
                var first = "";
                active.once("value", (snapshot) => {
                  first = snapshot.val() && snapshot.val().first_user;
                  if (first == "") {
                    firebase
                      .database()
                      .ref("isActive/" + res.Info.Group[r].IndivudualId + "/")
                      .update({
                        first_user: false,
                      });
                  } else {
                    firebase
                      .database()
                      .ref("isActive/" + res.Info.Group[r].IndivudualId + "/")
                      .update({
                        first_user: false,
                      });
                  }
                });

                var typing = firebase
                  .database()
                  .ref()
                  .child("isTyping/");
                var first = "";
                typing.once("value", (snapshot) => {
                  first = snapshot.val() && snapshot.val().first_user;
                  if (first == "") {
                    firebase
                      .database()
                      .ref("isTyping/" + res.Info.Group[r].IndivudualId + "/")
                      .update({
                        first_user: false,
                      });
                  } else {
                    firebase
                      .database()
                      .ref("isTyping/" + res.Info.Group[r].IndivudualId + "/")
                      .update({
                        first_user: false,
                      });
                  }
                });
              } else {
                var active = firebase
                  .database()
                  .ref()
                  .child("isActive/");
                var second = "";
                active.once("value", (snapshot) => {
                  second = snapshot.val() && snapshot.val().second_user;
                  if (second == "") {
                    firebase
                      .database()
                      .ref("isActive/" + res.Info.Group[r].IndivudualId + "/")
                      .update({
                        second_user: false,
                      });
                  } else {
                    firebase
                      .database()
                      .ref("isActive/" + res.Info.Group[r].IndivudualId + "/")
                      .update({
                        second_user: false,
                      });
                  }
                });

                var typing = firebase
                  .database()
                  .ref()
                  .child("isTyping/");
                var second = "";
                typing.once("value", (snapshot) => {
                  second = snapshot.val() && snapshot.val().second_user;
                  if (second == "") {
                    firebase
                      .database()
                      .ref("isTyping/" + res.Info.Group[r].IndivudualId + "/")
                      .update({
                        second_user: false,
                      });
                  } else {
                    firebase
                      .database()
                      .ref("isTyping/" + res.Info.Group[r].IndivudualId + "/")
                      .update({
                        second_user: false,
                      });
                  }
                });
              }
            }
          }
        })
        .catch((err) => {});
    } else {
      cogoToast.error("something went bad");
    }

    if (params !== null) {
      api
        .post(apiBase + "/ajax/player.php?action=getGroup", params)
        .then((res) => {
          if (res.Success === 1) {
            for (let r = 0; r < res.Info.Group.length; r++) {
              // if (res.Info.Group[r].fkUserId == data) {
              // var active = firebase.database().ref().child('messages/isActive/');
              // active.once('value', (snapshot) => {
              firebase
                .database()
                .ref("/messages/isActive/" + res.Info.Group[r].GroupId + "/")
                .update({
                  [res.Info.Group[r].GroupMemberId]: false,
                });
              // });

              // var typing = firebase.database().ref().child('messages/isTyping/');
              // typing.once('value', (snapshot) => {
              firebase
                .database()
                .ref("/messages/isTyping/" + res.Info.Group[r].GroupId + "/")
                .update({
                  [res.Info.Group[r].GroupMemberId]: false,
                });
              // });

              // }
            }
          }
        });
    }

    var loginactive = firebase
      .database()
      .ref()
      .child("isLoggedInUserActive/");
    var first = "";
    var first_online = "";
    loginactive.once("value", (snapshot) => {
      first = snapshot.val() && snapshot.val()[data];
      first_online = snapshot.val() && snapshot.val()[data + "_online"];

      if (first == null && first_online == null) {
        firebase
          .database()
          .ref("isLoggedInUserActive/")
          .update({
            [data]: false,
            [data + "_online"]: firebase.database.ServerValue.TIMESTAMP,
          });
      } else {
        firebase
          .database()
          .ref("isLoggedInUserActive/")
          .update({
            [data]: false,
            [data + "_online"]: firebase.database.ServerValue.TIMESTAMP,
          });
      }
    });

    localStorage.clear();
    const { history } = this.props;
    history.push("/login");
  };

  getUserMenu() {
    let data = {
      UserID: localStorage.getItem("Infodata"),
    };

    api
      .post(apiBase + "/ajax/player.php?action=getServiceRoll", data)
      .then((res) => {
        if (res.Success == 1) {
          var routesdata = res.Info.service;
          // console.log("routesdata",routesdata);
          var routesObj = [];

          for (let i = 0; i < routesdata.length; i++) {
            let Obj = {};

            (Obj.path = routesdata[i].Path),
              (Obj.name = routesdata[i].ServiceName),
              // Obj.icon = Icons[routesdata[i].Icon],
              (Obj.icon = routesdata[i].Icon),
              (Obj.collapse = routesdata[i].Path == "" ? true : false),
              (Obj.collapseActive = routesdata[i].Path == "" ? true : false),
              (Obj.component = Components[routesdata[i].Component]),
              (Obj.layout = routesdata[i].Layout);

            if (routesdata[i].Path === "") {
              if (
                routesdata[i].ServiceName !== "Vessel" &&
                routesdata[i].ServiceName !== "Truck"
              ) {
                let views = routesdata[i].views;
                let child = [];

                for (let j = 0; j < views.length; j++) {
                  let childObj = {};

                  (childObj.path = views[j].Path),
                    (childObj.name = views[j].ServiceName),
                    // childObj.icon = Icons[views[j].Icon],
                    (childObj.icon = views[j].Icon),
                    (childObj.component = Components[views[j].Component]),
                    (childObj.layout = views[j].Layout);

                  child.push(childObj);
                }

                Obj.views = child;
              } else {
                // let views = routesdata[i].views
                // // let child = []
                // if (views.length > 0) {
                //   for (let j = 0; j < views.length; j++) {
                //     // let childObj = {}
                //     if (routesdata[i].ServiceName == "Truck") {
                //       Obj.path = "/transport",
                //         Obj.name = "Truck & Driver",
                //         Obj.icon = "icon-truckNotify",
                //         Obj.component = Components[views[j].Component],
                //         Obj.collapse = false,
                //         Obj.collapseActive = false,
                //         Obj.layout = views[j].Layout
                //     }
                //     else {
                //       Obj.path = views[j].Path,
                //         // Obj.name = views[j].ServiceName,
                //         Obj.icon = views[j].Icon,
                //         Obj.component = Components[views[j].Component],
                //         Obj.collapse = false,
                //         Obj.collapseActive = false,
                //         Obj.layout = views[j].Layout
                //     }
                //   }
                // }
                // else {
                //   if (routesdata[i].ServiceName == "Truck") {
                //     Obj.path = "/transport",
                //       Obj.name = "",
                //       Obj.icon = "",
                //       Obj.component = "",
                //       Obj.collapse = false,
                //       Obj.collapseActive = false,
                //       Obj.layout = '/admin',
                //       Obj.invisible = false
                //   }
                //   else {
                //     Obj.path = "/dashboard",
                //       // Obj.name = views[j].ServiceName,
                //       Obj.icon = "",
                //       Obj.component = "",
                //       Obj.collapse = false,
                //       Obj.collapseActive = false,
                //       Obj.layout = '/admin'
                //     Obj.invisible = false
                //   }
                // }
              }
            }

            routesObj.push(Obj);
          }

          var List = routesObj;
          // var idx = List.findIndex(x => x.name == "Stock");
          // if (idx != -1) {
          //   let cutOut = List.splice(idx, 1)[0];
          //   List.splice(0, 0, cutOut);
          // }
          this.setState({ dynamicRoutes: List });
        }
      });
  }

  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  activeRoute = (routeName) => {
    // return window.location.href.indexOf(routeName) > -1 ? "active" : "";
    let pathIndex = window.location.href.lastIndexOf("/");
    let currentPath = window.location.href.slice(
      pathIndex,
      window.location.href.length
    );
    return currentPath == routeName ? "active" : "";
  };

  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  createLinks = (routes) => {
    const { classes, color, rtlActive } = this.props;
    return routes.map((prop, key) => {
      if (prop.invisible === false) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes.collapseActive]: this.getCollapseInitialState(
              prop.views
            ),
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive,
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive,
          });
        const itemIcon =
          classes.itemIcon +
          " " +
          cx({
            [classes.itemIconRTL]: rtlActive,
          });
        const caret =
          classes.caret +
          " " +
          cx({
            [classes.caretRTL]: rtlActive,
          });
        const collapseItemMini =
          classes.collapseItemMini +
          " " +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive,
          });
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={(e) => {
                e.preventDefault();
                // console.log("kana",st)
                // this.setState(st);
                let tempRoutes = this.state.dynamicRoutes;
                tempRoutes[key]["collapseActive"] = !tempRoutes[key][
                  "collapseActive"
                ];
                this.setState({ dynamicRoutes: tempRoutes });
              }}
            >
              {prop.icon !== undefined ? (
                // typeof prop.icon === "string" ? (
                //   <Icon className={itemIcon}>{prop.icon}</Icon>
                // ) : (
                //   <prop.icon className={itemIcon} />
                // )
                <Icon
                  className={itemIcon + prop.icon}
                  style={{ width: "auto" }}
                ></Icon>
              ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
              <ListItemText
                primary={rtlActive ? prop.rtlName : prop.name}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      (this.state[prop.state] ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={
                  "color-white" +
                  cx(
                    { [itemText]: prop.icon !== undefined },
                    { [collapseItemText]: prop.icon === undefined }
                  )
                }
              />
            </NavLink>
            <Collapse in={!prop.collapseActive} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.path),
        });
      const collapseItemMini =
        classes.collapseItemMini +
        " " +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive,
        });
      const navLinkClasses =
        classes.itemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.path),
        });
      const itemText =
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.itemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.itemTextRTL]: rtlActive,
        });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive,
        });
      const itemIcon =
        classes.itemIcon +
        " " +
        cx({
          [classes.itemIconRTL]: rtlActive,
        });
      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          <NavLink
            to={prop.layout + prop.path}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon == undefined }
            )}
          >
            {prop.icon !== undefined ? (
              // typeof prop.icon === "string" ? (
              //   <Icon className={itemIcon}>{prop.icon}</Icon>
              // ) : (
              //   <prop.icon className={itemIcon} />
              // )
              <Icon
                className={itemIcon + prop.icon}
                style={{ width: "auto" }}
              ></Icon>
            ) : (
              <span className={collapseItemMini}>
                {rtlActive ? prop.rtlMini : prop.mini}
              </span>
            )}
            <ListItemText
              primary={rtlActive ? prop.rtlName : prop.name}
              disableTypography={true}
              className={
                "color-white" +
                cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )
              }
            />
          </NavLink>
        </ListItem>
      );
    });
  };

  getNewUserAccess = async () => {
    let params = {
      UserId: localStorage.getItem("Infodata"),
      ServiceType: "Sidebar",
    };
    await api
      .post(APIConstant.path.getUserAccessEquator, params)
      .then(async (response) => {
        if (response.success) {
          // console.log("res New Access Equator", res);

          var routesdata = response.data;
          var routesObj = [];

          for (let i = 0; i < routesdata.length; i++) {
            let Obj = {};

            (Obj.path = routesdata[i].Path),
              (Obj.name = routesdata[i].ServiceName),
              // Obj.icon = Icons[routesdata[i].Icon],
              (Obj.icon = routesdata[i].Icon),
              (Obj.collapse = routesdata[i].Path == "" ? true : false),
              (Obj.collapseActive = routesdata[i].Path == "" ? true : false),
              (Obj.component = Components[routesdata[i].Component]),
              (Obj.layout = routesdata[i].Layout);

            if (routesdata[i].Path === "") {
              let views = routesdata[i].SubServices;
              let child = [];

              for (let j = 0; j < views.length; j++) {
                let childObj = {};

                (childObj.path = views[j].Path),
                  (childObj.name = views[j].ServiceName),
                  (childObj.icon = views[j].Icon),
                  (childObj.component = Components[views[j].Component]),
                  (childObj.layout = views[j].Layout);

                child.push(childObj);
              }

              Obj.views = child;
            }

            routesObj.push(Obj);
          }
          var List = routesObj;
          this.setState({ dynamicRoutes: List });
        } else {
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  render() {
    const {
      classes,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive,
      IsProfileUpdated,
    } = this.props;

    const { dynamicRoutes } = this.state;

    var links = (
      <List className={classes.list}>{this.createLinks(dynamicRoutes)}</List>
    );

    const logoNormal =
      classes.logoNormal +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive,
      });

    const logoMini =
      // classes.logoMini +
      "sidebar-logo-mini" +
      cx({
        [classes.logoMiniRTL]: rtlActive,
      });

    const logoClasses =
      // classes.logo +
      " sidebar-logo " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      });

    const sidebarMinimize =
      classes.sidebarMinimize +
      " " +
      cx({ [classes.sidebarMinimizeRTL]: rtlActive });
    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover,
      {
        [classes.dropdownItemRTL]: rtlActive,
      }
    );

    const managerClasses = classNames({
      [classes.managerClasses]: true,
    });

    var brand = (
      <div classNames="p-position-relative">
        <div className={logoClasses}>
          {!this.props.miniActive ? (
            <div className={logoMini}>
              <a
                href={window.location.origin}
                target="_self"
                style={{
                  width: "auto",
                }}
              >
                <img
                  src={logolg}
                  alt="logo"
                  className={classes.img + " logo-img"}
                />
              </a>
            </div>
          ) : (
            <div className={logoMini}>
              <a
                href={window.location.origin}
                target="_self"
                style={{
                  width: "auto",
                }}
              >
                <img src={logotm} alt="logo" className={classes.img} />
              </a>
            </div>
          )}
        </div>
      </div>
    );

    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive,
      });

    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });

    return (
      //------------- @Sidebar - secondary ------------//
      <div ref={this.mainPanel}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            className={{
              paper:
                drawerPaper +
                " custom-sidebar " +
                classes[bgColor + "Background"],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              // user={user}
              headerLinks={<AdminNavbarLinks rtlActive={rtlActive} />}
              links={links}
            />

            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>

        {/* @Sidebar - parimary */}
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " custom-sidebar1 ",
            }}
          >
            {brand}
          </Drawer>
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper:
                drawerPaper +
                " custom-sidebar " +
                classes[bgColor + "Background"],
            }}
          >
            <SidebarWrapper
              className={sidebarWrapper}
              // user={user}
              links={links}
            />
            <div className="sidebarbottom">
              {!this.props.miniActive || !this.state.miniActive ? (
                <p>
                  <span
                    className="p-d-block p-text-center"
                    style={{ fontSize: "15px", color: "#FFFFFF" }}
                  >
                    <b>&copy; {1900 + new Date().getYear()} </b>
                    All rights reserved by
                  </span>
                  <a
                    className="p-d-block p-text-center"
                    style={{
                      fontWeight: "800",
                      fontSize: "15px",
                      color: "#FFFFFF",
                    }}
                    href="https://www.portlinksindia.com/"
                    // className={anchor}
                    target="_blank"
                  >
                    {rtlActive ? "توقيت الإبداعية" : " PORTLINKS INDIA"}
                  </a>
                </p>
              ) : (
                <i className="icon-info p-mt-1"></i>
              )}
            </div>
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue",
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  headerLinks: PropTypes.object,
  links: PropTypes.object,
};

export default withStyles(sidebarStyle)(Sidebar);
