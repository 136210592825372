import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import {
  Dialog,
  DialogTitle,
  TextField,
  FormControlLabel,
  FormControl,
  DialogActions,
  Switch,
  Typography,
  RadioGroup,
  Radio,
  Box,
  DialogContent,
} from "@material-ui/core";
import { Button } from "primereact/button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { CommonConfig } from "CommonConfig";
import moment from "moment";
import { Autocomplete } from "@mui/material";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AccessTime, CalendarToday, Repeat } from "@material-ui/icons";
import APIConstant from "utils/constant";
import api from "utils/api";
import cogoToast from "cogo-toast";
import { InputText } from "primereact/inputtext";

const initialNameState = {
  name: "",
  error: false,
  errorText: "",
};

const allDayOnOptions = [
  {
    label: "don't repeat",
    value: "repeatOff",
  },
  {
    label: "day",
    value: "day",
  },
  {
    label: "week",
    value: "week",
  },
  {
    label: "month",
    value: "month",
  },
  {
    label: "year",
    value: "year",
  },
];

const allDayOffOptions = [
  {
    label: "don't repeat",
    value: "repeatOff",
  },
  {
    label: "minute",
    value: "minute",
  },
  {
    label: "hour",
    value: "hour",
  },
  {
    label: "day",
    value: "day",
  },
  {
    label: "week",
    value: "week",
  },
  {
    label: "month",
    value: "month",
  },
  {
    label: "year",
    value: "year",
  },
];

const Reminder = (props) => {
  const [userId, setUserId] = useState("");
  const [userPageAccess, setUserPageAccess] = useState([]);
  const [reminderId, setReminderId] = useState("");
  const [reminderList, setReminderList] = useState([]);
  const [addReminderDialog, setAddReminderDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [remindFor, setRemindFor] = useState(initialNameState);
  const [description, setDescription] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  const [search, setSearch] = useState("");
  const [isAllDay, setIsAllDay] = useState(true);
  const [dateTime, setDateTime] = useState(initialNameState);
  const [isSendMail, setIsSendMail] = useState(true);
  const [timeUnit, setTimeUnit] = useState("");
  const [repeatEvery, setRepeatEvery] = useState("1");
  const [repeatCount, setRepeatCount] = React.useState("");
  const [repeatMode, setRepeatMode] = React.useState("specific");

  const [rowsPerPageOptions, setRowsPerPageOptions] = useState(
    CommonConfig.dataTableConfig.rowsPerPageOptions
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    CommonConfig.dataTableConfig.rowsPerPage
  );

  useEffect(async () => {
    let data = localStorage.getItem("Infodata");
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");

    // if (lockstatus == 0) {
    if (token !== null) {
      setUserId(data);
      setUserPageAccess(userPageAccess);
      await getReminderList(data);
    } else {
      props.history.push("/login");
    }
    // } else {
    //     this.props.history.push('/auth/lockscreen')
    // }
  }, []);

  const getReminderList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetReminderList, params)
      .then((response) => {
        if (response.Success === 1) {
          setReminderList(response.Data);
        }
      })
      .catch((err) => {});
  };

  const addReminder = () => {
    let params = {
      ReminderId: reminderId,
      UserId: userId,
      Name: remindFor.name,
      Description: description,
      IsAllDay: isAllDay,
      ReminderDate: dateTime.name,
      RepeatOn: timeUnit,
      RepeatTime: repeatEvery,
      RepeatMode: repeatMode,
      RepeatCount: repeatCount,
      IsSendMail: isSendMail,
    };
    api
      .post(APIConstant.path.AddUpdateReminder, params)
      .then((response) => {
        if (response.Success === 1) {
          handleClose("AddReminder");
          getReminderList(userId);
          cogoToast.success(response.Message);
        }
      })
      .catch((err) => {});
  };

  const getReminderById = async (ReminderId) => {
    let params = {
      UserId: userId,
      ReminderId,
    };
    api
      .post(APIConstant.path.GetReminderById, params)
      .then((response) => {
        if (response.Success === 1) {
          let data = response.Data;
          setRemindFor({
            name: data.Name,
            error: false,
            errorText: "",
          });
          setDescription(data.Description);
          setIsAllDay(data.isAllDay);
          setDateTime({
            name: data.ReminderDate,
            error: false,
            errorText: "",
          });
          setRepeatEvery(data.RepeatTime);
          setTimeUnit(data.RepeatOn);
          setRepeatMode(data.RepeatMode);
          setRepeatCount(data.RepeatCount);
          setIsSendMail(data.IsSendMail);
        }
      })
      .catch((err) => {});
  };

  const deleteReminder = () => {
    let params = {
      ReminderId: reminderId,
      UserId: userId,
    };
    api
      .post(APIConstant.path.DeleteReminder, params)
      .then((response) => {
        if (response.Success === 1) {
          handleClose("DeleteMasterType");
          getReminderList(userId);
          cogoToast.success(response.Message);
        }
      })
      .catch((err) => {});
  };

  const handleOpen = async (e, type) => {
    if (type === "AddMaster") {
      setAddReminderDialog(true);
    } else if (type === "ViewMasterType") {
      setReminderId(e._id);
      await getReminderById(e._id);
      setReadOnly(true);
      setAddReminderDialog(true);
    } else if (type === "EditMasterType") {
      setReminderId(e._id);
      await getReminderById(e._id);
      setAddReminderDialog(true);
    } else if (type === "DeleteMasterType") {
      setReminderId(e._id);
      setDeleteDialog(true);
    }
  };

  const handleClose = (type) => {
    if (type === "AddReminder") {
      setAddReminderDialog(false);
      setRemindFor("");
      setReadOnly(false);
      setIsAllDay(true);
      setDateTime(initialNameState);
      setIsSendMail(true);
      setTimeUnit("");
      setRepeatEvery("1");
      setRepeatCount("");
      setRepeatMode("specific");
      setReminderId("");
      setDescription("");
    } else if (type === "DeleteMasterType") {
      setDeleteDialog(false);
      setReminderId("");
    }
  };

  const handleChange = (e, type) => {
    if (type === "reminderFor") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setRemindFor({
          name: "",
          error: true,
          errorText: "Please enter reminder name",
        });
      } else {
        setRemindFor({
          name: e.target.value,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "description") {
      setDescription(e.target.value);
    } else if (type === "isAllDay") {
      setIsAllDay((prev) => !prev);
    } else if (type === "sendMail") {
      setIsSendMail((prev) => !prev);
    } else if (type === "repeatEvery") {
      setRepeatEvery(e.target.value);
    } else if (type === "timeUnit") {
      setTimeUnit(e?.value || "");
    } else if (type === "dateTime") {
      if (CommonConfig.isEmpty(e)) {
        setDateTime({
          name: "",
          error: true,
          errorText: "Please select date",
        });
      } else {
        setDateTime({
          name: e,
          error: false,
          errorText: "",
        });
      }
    }
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  const actionTemplate = (rowData, props) => {
    return (
      <div class="bill-action icon-only-btn" style={{ display: "flex" }}>
        {userPageAccess.includes("View Reminder") ? (
          <div onClick={(e) => handleOpen(rowData, "ViewMasterType")}>
            <i class="icon-view"></i>
          </div>
        ) : null}
        {userPageAccess.includes("Edit Reminder") ? (
          <div onClick={(e) => handleOpen(rowData, "EditMasterType")}>
            <i class="icon-editImage"></i>
          </div>
        ) : null}
        {userPageAccess.includes("Delete Reminder") ? (
          <div onClick={(e) => handleOpen(rowData, "DeleteMasterType")}>
            <i class="icon-delete"></i>
          </div>
        ) : null}
      </div>
    );
  };

  const optionList = isAllDay ? allDayOnOptions : allDayOffOptions;
  return (
    <div className="main-access-contianer">
      <div className="heading-area">
        <div className="heading-area-right">
          <div className="searchbar-area p-as-center">
            <InputText
              type="search"
              onInput={(e) => setSearch(e.target.value)}
              placeholder="Global Search"
              size="30"
              style={{ marginRight: 10 }}
            />
            <i className="icon-search"></i>
          </div>
          {userPageAccess.includes("Add Reminder") ? (
            <div>
              <div
                className="add-btn1"
                onClick={(e) => handleOpen(e, "AddMaster")}
              >
                <i className="icon-add"></i> Add Reminder
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="main-access-card-master">
        <DataTable
          className="main-table table-td"
          value={reminderList}
          editMode="row"
          dataKey="id"
          responsiveLayout="scroll"
          paginator={true}
          rows={rowsPerPage}
          totalRecords={reminderList.length}
          rowsPerPageOptions={rowsPerPageOptions}
          globalFilter={search}
          emptyMessage="No records found"
          responsive={false}
          resizableColumns={true}
          columnResizeMode="fit"
          scrollable={true}
          scrollWidth="auto"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        >
          <Column
            field="Index"
            header="Sr.No"
            body={actionBodyTemplateSrNo}
            style={{ width: "20%" }}
          ></Column>
          <Column
            field="Name"
            header="Reminder Name"
            style={{ width: "20%" }}
          ></Column>
          <Column
            field="Description"
            header="Reminder Description"
            style={{ width: "20%" }}
          ></Column>

          <Column
            field="Action"
            header="Action"
            body={actionTemplate}
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        className="stock-modal"
        open={addReminderDialog}
        onClose={(e) => handleClose("AddReminder")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div>
            {reminderId !== "" ? (readOnly ? "View " : "Edit ") : "Add "}
            Reminder
          </div>
          <div
            className="dialog-close"
            onClick={(e) => handleClose("AddReminder")}
          >
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TextField
              autoFocus
              margin="dense"
              name="ReminderFor"
              label="Reminder For"
              type="text"
              disabled={readOnly}
              value={remindFor.name}
              onChange={(e) => handleChange(e, "reminderFor")}
              fullWidth
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <TextField
              autoFocus
              margin="dense"
              name="description"
              label="Description"
              type="text"
              disabled={readOnly}
              value={description}
              onChange={(e) => handleChange(e, "description")}
              fullWidth
              multiline
              minRows={2}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Typography>All day</Typography>
              <span role="img" aria-label="All Day">
                <AccessTime />
              </span>
              <Switch
                disabled={readOnly}
                checked={isAllDay}
                onChange={(e) => handleChange(e, "isAllDay")}
                color="primary"
                inputProps={{ "aria-label": "all-day-switch" }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography>Calendar</Typography>
              <span role="img" aria-label="calendar">
                <CalendarToday />
              </span>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Datetime
              isValidDate={(currentDate, selectedDate) =>
                currentDate.isAfter(moment().subtract(1, "day"))
              }
              value={moment(dateTime.name)}
              selected={moment(dateTime.name)}
              onChange={(e) => handleChange(e, "dateTime")}
              closeOnSelect={true}
              timeFormat={!isAllDay}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Date"
                  fullWidth
                  disabled={readOnly}
                />
              )}
            />
          </GridItem>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <Typography>Repeat</Typography>
            <span role="img" aria-label="calendar">
              <Repeat />
            </span>
          </div>

          <GridItem xs={12}>
            <div style={{ display: "flex", gap: "40px" }}>
              <Typography style={{ marginTop: "20px" }}>Every</Typography>

              <TextField
                autoFocus
                margin="dense"
                name="repeatEvery"
                label=""
                type="number"
                value={repeatEvery}
                style={{ width: "90px", marginTop: "15px" }}
                disabled={timeUnit === "repeatOff" || readOnly}
                onChange={(e) => handleChange(e, "repeatEvery")}
              />

              <FormControl fullWidth>
                <Autocomplete
                  id="select"
                  name="select"
                  style={{ width: "200px" }}
                  value={
                    timeUnit && optionList.length
                      ? optionList.filter((y) => y.value == timeUnit)[0]
                      : ""
                  }
                  options={optionList}
                  getOptionLabel={(option) =>
                    option.label ? option.label : option
                  }
                  onChange={(e, value) => handleChange(value, "timeUnit")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Select"
                      label="Select"
                      disabled={readOnly}
                    />
                  )}
                />
              </FormControl>
            </div>
          </GridItem>

          {timeUnit !== "repeatOff" && timeUnit !== "" && (
            <GridItem xs={12} sm={12} md={12}>
              <FormControl component="fieldset" style={{ marginTop: "10px" }}>
                <RadioGroup
                  row
                  value={repeatMode}
                  onChange={(e) => setRepeatMode(e.target.value)}
                >
                  <div style={{ width: "100%" }}>
                    <FormControlLabel
                      value="forever"
                      control={<Radio disabled={readOnly} color="primary" />}
                      label="Forever"
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <FormControlLabel
                      value="specific"
                      control={<Radio disabled={readOnly} color="primary" />}
                      label="Specific number of times"
                    />
                    {repeatMode === "specific" && (
                      <TextField
                        label=""
                        type="number"
                        value={repeatCount}
                        onChange={(e) => setRepeatCount(e.target.value)}
                        inputProps={{ min: "1" }}
                        style={{ width: "60px", marginTop: "10px" }}
                        disabled={readOnly}
                      />
                    )}
                  </div>
                </RadioGroup>
              </FormControl>
            </GridItem>
          )}
          <GridItem xs={12} sm={12} md={12}>
            <div
              style={{
                display: "flex",
                marginTop: "20px",
              }}
            >
              <Typography>Send Mail</Typography>
              <Switch
                disabled={readOnly}
                checked={isSendMail}
                onChange={(e) => handleChange(e, "sendMail")}
                color="primary"
                inputProps={{ "aria-label": "send-mail-switch" }}
              />
            </div>
          </GridItem>
        </GridContainer>
        <DialogActions className="p-p-0 dialog-footer1">
          <Button
            className="primary-btn1"
            onClick={(e) => handleClose("AddReminder")}
          >
            Close
          </Button>
          {!readOnly && (
            <Button className="primary-btn" onClick={addReminder} type="submit">
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialog}
        onClose={() => handleClose("DeleteMasterType")}
        aria-labelledby="cha-form-dialog-title"
      >
        <DialogTitle
          id="cha-form-dialog-title"
          className="custom-dialog-header"
        >
          <div>Delete Reminder</div>
          <div
            className="dialog-close"
            onClick={(e) => handleClose("DeleteMasterType")}
          >
            <i className="icon-modal-close"></i>
          </div>
        </DialogTitle>
        <DialogContent className="CHA-dialog-content-area">
          <h6>Are you sure want to delete?</h6>
        </DialogContent>
        <DialogActions className="p-p-0 dialog-footer1">
          <Button
            className="primary-btn1"
            onClick={(e) => handleClose("DeleteMasterType")}
          >
            Close
          </Button>
          <Button
            className="primary-btn"
            onClick={(e) => deleteReminder(e)}
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// Export statement at the bottom of the file
export default Reminder;
