

export default class Loader {

  static show = function () {
      let htmlString = `
      <div id="main-loading">
        
        <div  class="loading-component">
          <div class="MuiBackdrop-root makeStyles-backdrop-315" aria-hidden="true" style="opacity: 1; transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;">
          </div>
          <div class="loader-container">
              <div class="loader-05">
              </div>
          </div>
        </div>
      </div>
      `;
      document.getElementById("main-section").insertAdjacentHTML('beforeend', htmlString)
  }

  static hide = function () {
      if (document.getElementById("main-loading")) {
          document.getElementById("main-loading").remove();
      }
  }
}