/*eslint-disable*/
import React from "react";
import { Switch, Route } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Footer from "components/Footer/Footer.js";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import routes from "routes.js";
import styles from "../assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

const useStyles = makeStyles(styles);

export default function Pages(props) {

  const { ...rest } = props;
  const wrapper = React.createRef();
  const classes = useStyles();

  React.useEffect(() => {

    document.body.style.overflow = "unset";
    return function cleanup() { };
  });

  const getRoutes = routes => {

    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].layout == '/auth' && routes[i].path == '/lockscreen') {
        if (routes[i].collapse) {
          let collapseActiveRoute = getActiveRoute(routes[i].views);
          if (collapseActiveRoute !== activeRoute) {
            return collapseActiveRoute;
          }
        } else {

          if (
            window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
          ) {
            return routes[i].name;
          }

        }
      }
    }
    return activeRoute;
  };

  return (
    window.location.pathname.indexOf("/auth/lockscreen") != -1 ?
      <div>
        <AuthNavbar brandText={getActiveRoute(routes)} {...rest} />


        <div>

          <Switch>
            {getRoutes(routes.filter(x => x.path == '/lockscreen'))}
            {/* <Redirect from="/auth" to="/auth/login-page" /> */}

          </Switch>
          {/* <Footer /> */}
        </div>

      </div >
      : <div>
        {/* <AuthNavbar brandText={getActiveRoute(routes)} {...rest} /> */}

        <div className={classes.wrapper} ref={wrapper}>
          {/* <div
            className={classes.fullPage}
            style={{ backgroundImage: "url(" + getBgImage() + ")" }}
          > */}

          <Switch>
            {getRoutes(routes.filter(x => x.path != '/lockscreen'))}
          </Switch>
          {/* <Redirect from="/auth" to="/auth/login-page" /> */}

          <Footer white />
          {/* </div> */}
        </div>
      </div >
  );
}
