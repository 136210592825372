import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CommonConfig } from "../../CommonConfig.js";
import SimpleBackdrop from "../../utils/general";
import api from "../../utils/api";
import cogoToast from "cogo-toast";
import APIConstant from "../../utils/constant";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import Loader from "components/Shared/Loader/Loader";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Tooltip } from "primereact/tooltip";
class Item extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ItemSearchListHSCode: [],
      ItemMasterID: "",
      loading: false,
      UserId: "",
      Token: "",
      GetItemMasterList: [],
      rowsPerPageOptions: CommonConfig.dataTableConfig.rowsPerPageOptions,
      rowsPerPage: CommonConfig.dataTableConfig.rowsPerPage,
      search: null,
      ItemMasterHsCode: "",
      ItemMasterHsCodeErr: false,
      ItemItemMasterHsCodeErrText: "",
      ItemMasterLabelName: "",
      ItemMasterLabelNameErr: false,
      ItemMasterLabelNameErrText: "",
      AddItemMasterModal: false,
      EditItemMasterTypeModal: false,
      DeleteItemMasterTypeModal: false,
      userPageAccess: [],
      searchDetails: {},
    };
  }

  componentDidMount = async () => {
    let data = localStorage.getItem("Infodata");
    let Userrole = JSON.parse(localStorage.getItem("Userdata"));
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");
    // if (lockstatus == 0) {
    if (token !== null) {
      this.setState({
        UserId: data,
        Token: token,
        userPageAccess: userPageAccess,
      });
      await this.GetItemMasterListByType(data);
    } else {
      this.props.history.push("/login");
    }
    // } else {
    //   this.props.history.push("/auth/lockscreen");
    // }
  };

  actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  actionTemplate = (rowData, props) => {
    return (
      <div class="bill-action icon-only-btn" style={{ display: "flex" }}>
        {this.state.userPageAccess.includes("Edit Item/Goods") ? (
          <div onClick={(e) => this.handleOpenItem(rowData, "EditMasterType")}>
            {" "}
            <i class="icon-editImage"></i>{" "}
          </div>
        ) : null}
        {this.state.userPageAccess.includes("Delete Item/Goods") ? (
          <div
            onClick={(e) =>
              this.handleOpenItem(rowData, "DeleteItemMasterType")
            }
          >
            {" "}
            <i class="icon-delete"></i>{" "}
          </div>
        ) : null}
      </div>
    );
  };

  GetItemMasterListByType = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetItemMasterList, params)
      .then(async (res) => {
        let response = await res;
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserId != "Admin");

          this.setState({ GetItemMasterList: filterArry });
        }
      })
      .catch((err) => {});
  };

  handleOpenItem = (item, type) => {
    if (type === "AddItemMaster") {
      this.setState({ AddItemMasterModal: true });
    } else if (type === "EditMasterType") {
      this.setState({
        AddItemMasterModal: true,
        ItemMasterHsCode: item.product_hsn_code,
        ItemMasterID: item.ItemHSNCodeID,
      });
      this.SearchItemCodeList(item, "Edit");
    } else if (type === "DeleteItemMasterType") {
      this.setState({
        DeleteItemMasterTypeModal: true,
        ItemMasterID: item.ItemHSNCodeID,
      });
    }
  };

  handlehandleClose = (type) => {
    if (type === "AddItemMaster") {
      this.setState({
        AddItemMasterModal: false,
        ItemMasterHsCode: "",
        ItemMasterHsCodeErr: false,
        ItemItemMasterHsCodeErrText: "",
        ItemMasterID: "",
        ItemSearchListHSCode: [],
      });
    } else if (type === "EditMasterType") {
      this.setState({ EditItemMasterTypeModal: false });
    } else if (type === "DeleteItemMasterType") {
      this.setState({ DeleteItemMasterTypeModal: false, ItemMasterID: "" });
    }
  };

  handleChangeItem = (e, type) => {
    if (type === "ItemMasterLabelName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          ItemMasterLabelName: e.target.value,
          ItemMasterLabelNameErr: true,
          ItemMasterLabelNameErrText: `Please enter Type.`,
        });
      } else {
        this.setState({
          ItemMasterLabelName: e.target.value,
          ItemMasterLabelNameErr: false,
          ItemMasterLabelNameErrText: "",
        });
      }
    } else if (type === "ItemMasterHsCode") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          ItemMasterHsCode: e.target.value,
          ItemMasterHsCodeErr: true,
          ItemItemMasterHsCodeErrText: `Please enter Item HsCode/ Product Name.`,
        });
      } else {
        this.setState({
          ItemMasterHsCode: e.target.value,
          ItemMasterHsCodeErr: false,
          ItemItemMasterHsCodeErrText: "",
        });
      }
    }
  };

  validationItem = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(this.state.ItemMasterHsCode)) {
      this.setState({
        ItemMasterHsCode: "",
        ItemMasterHsCodeErr: true,
        ItemItemMasterHsCodeErrText: "Item HsCode is required.",
      });
      formIsValid = false;
    } else {
      this.setState({
        ItemMasterHsCodeErr: false,
        ItemItemMasterHsCodeErrText: "",
      });
    }
    return formIsValid;
  };

  AddItemMaster = () => {
    if (this.validationItem()) {
      try {
        this.setState({ loading: true });
        Loader.show();

        let Selected = this.state.ItemSearchListHSCode.filter(
          (x) => !!x.chapter_name
        ).length
          ? this.state.ItemSearchListHSCode.filter((x) => !!x.chapter_name)
          : [];

        if (Selected.length) {
          Selected = Selected.map((data) => {
            return {
              chapter_name: data.chapter_name,
              product_description: data.product_description,
              product_hsn_code: data.product_hsn_code,
              product_rate: data.product_rate,
              product_cess: CommonConfig.isEmpty(data.product_cess)
                ? 0
                : data.product_cess,
            };
          });
          let params = {
            List: Selected,
            UserID: this.state.UserId,
            ItemHSNCodeID: this.state.ItemMasterID,
          };
          api
            .post(APIConstant.path.AddUpdateMasterItem, params)
            .then((res) => {
              if (res.Success == 1) {
                this.setState({
                  AddItemMasterModal: false,
                  ItemMasterHsCode: "",
                  ItemMasterHsCodeErr: false,
                  ItemItemMasterHsCodeErrText: "",
                  ItemMasterID: "",
                  ItemSearchListHSCode: [],
                });
                this.GetItemMasterListByType(this.state.UserId);
                cogoToast.success(res.Message);
                Loader.hide();
              } else {
                this.setState({ loading: false });
                cogoToast.error(res.Message);
                Loader.hide();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  SearchItemCodeList = async (ItemMasterHsCode, Type) => {
    if (!CommonConfig.isEmpty(ItemMasterHsCode)) {
      this.setState({ loading: true });
      Loader.show();

      let params = {
        ID:
          Type == "Edit" ? ItemMasterHsCode.product_hsn_code : ItemMasterHsCode,
      };
      await api
        .post(APIConstant.path.GetItemHSCodeSearchList, params)
        .then((res) => {
          if (res.Success == 1) {
            let Array = [];

            Array = [
              ...res.Data.map((x) => {
                this.setState({
                  searchDetails: {
                    product_hsn_code: x.product_hsn_code,
                    product_description: x.product_description,
                    product_rate: x.product_rate,
                  },
                });
                return { ...x, IsActiveTab: "0" };
              }),
            ];
            if (Type == "Edit") {
              for (var i = 0; i < Array.length; i++) {
                if (
                  Array[i]["product_hsn_code"] ==
                  ItemMasterHsCode.product_hsn_code
                ) {
                  Array[i]["IsActiveTab"] = "1";
                  Array[i]["chapter_name"] = ItemMasterHsCode.chapter_name;
                  Array[i]["product_rate"] = ItemMasterHsCode.product_rate;
                } else {
                  Array[i]["IsActiveTab"] = "0";
                }
              }
            }

            Loader.hide();
            this.setState({ loading: false, ItemSearchListHSCode: Array });
          } else {
            cogoToast.error(res.Message);
            Loader.hide();
          }
        });
    } else {
      this.setState({
        ItemMasterHsCodeErr: true,
        ItemItemMasterHsCodeErrText: "Please Enter Item HSCode",
      });
    }
  };

  DeleteItemMasterType = () => {
    Loader.show();
    let params = {
      ID: this.state.ItemMasterID,
      UserID: this.state.UserId,
    };
    api.post(APIConstant.path.DeleteItemMaster, params).then((res) => {
      if (res.Success == 1) {
        this.setState({
          loading: false,
          DeleteItemMasterTypeModal: false,
          ItemMasterID: "",
        });
        this.GetItemMasterListByType(this.state.UserId);
        cogoToast.success(res.Message);
        Loader.hide();
      } else {
        this.setState({ loading: false });
        cogoToast.error(res.Message);
        Loader.hide();
      }
    });
  };

  ActiveTab = (value, idx) => {
    let tempList = this.state.ItemSearchListHSCode;
    if (value == "0") {
      tempList[idx]["IsActiveTab"] = "1";
      for (var i = 0; i < tempList.length; i++) {
        if (idx != i) {
          tempList[i]["IsActiveTab"] = "0";
        }
      }
    } else {
      tempList[idx]["IsActiveTab"] = "0";
    }
    this.setState({ ItemSearchListHSCode: tempList });
  };
  SrNoBody = (data, props) => {
    return props.rowIndex + 1;
  };

  RateBody = (data, props) => {
    return <>{data.product_rate + "%"}</>;
  };
  DescriptionBody = (data, props) => {
    return (
      <>
        <Tooltip target=".custom-tooltip"></Tooltip>
        <div className="card">
          <span
            className="custom-tooltip"
            data-pr-tooltip={data.product_description}
          >
            {data.product_description}
          </span>
        </div>
      </>
    );
  };

  handleAddItem = () => {
    let itemSearchListHSCode = [...this.state.ItemSearchListHSCode];
    itemSearchListHSCode.map((item) => {
      item.IsActiveTab = "0";
      return item;
    });
    itemSearchListHSCode.push({
      IsActiveTab: "1",
      Name: "",
      product_hsn_code: this.state.searchDetails.product_hsn_code,
      product_description: this.state.searchDetails.product_description,
      product_rate: this.state.searchDetails.product_rate,
    });
    this.setState({
      ItemSearchListHSCode: itemSearchListHSCode,
    });
  };

  handleDeleteItem = (index) => {
    let itemSearchListHSCode = [...this.state.ItemSearchListHSCode];
    itemSearchListHSCode.splice(index, 1);
    this.setState({
      ItemSearchListHSCode: itemSearchListHSCode,
    });
  };

  render() {
    const {
      UserId,
      search,
      userPageAccess,
      GetItemMasterList,
      ItemMasterLabelName,
      ItemMasterLabelNameErr,
      ItemSearchListHSCode,
      ItemItemMasterHsCodeErrText,
      ItemMasterHsCode,
      ItemMasterHsCodeErr,
      ItemMasterLabelNameErrText,
      AddItemMasterModal,
      EditItemMasterTypeModal,
      DeleteItemMasterTypeModal,
      ItemMasterID,
    } = this.state;

    let filterGetItemMasterList = [];
    if (GetItemMasterList.length) {
      filterGetItemMasterList = GetItemMasterList;
      if (search) {
        filterGetItemMasterList = filterGetItemMasterList.filter((x) => {
          return (
            x.chapter_name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            x.product_description
              .toLowerCase()
              .indexOf(search.toLowerCase()) !== -1 ||
            x.product_hsn_code.toLowerCase().indexOf(search.toLowerCase()) !==
              -1 ||
            x.product_rate.toLowerCase().indexOf(search.toLowerCase()) !== -1
          );
        });
      }
    }

    return (
      <div className="main-access-contianer">
        <div className="heading-area">
          <h1>Item/Goods Master</h1>
          <div className="heading-area-right">
            <div className="searchbar-area p-as-center">
              <InputText
                type="search"
                onInput={(e) => this.setState({ search: e.target.value })}
                placeholder="Global Search"
                size="30"
                style={{ marginRight: 10 }}
              />
              <i className="icon-search"></i>
            </div>
            {userPageAccess.includes("Add Item/Goods") ? (
              <div>
                <div
                  className="add-btn1"
                  onClick={(e) => this.handleOpenItem(e, "AddItemMaster")}
                >
                  <i className="icon-add"></i> Add Item/Goods
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="main-access-card-master">
          {userPageAccess.includes("View Item/Goods") ? (
            <DataTable
              className="main-table table-td"
              value={filterGetItemMasterList}
              editMode="row"
              dataKey="id"
              responsiveLayout="scroll"
              paginator={true}
              rows={this.state.rowsPerPage}
              totalRecords={filterGetItemMasterList.length}
              rowsPerPageOptions={this.state.rowsPerPageOptions}
              globalFilter={search}
              emptyMessage="No records found"
              responsive={false}
              resizableColumns={true}
              columnResizeMode="fit"
              scrollable={true}
              scrollWidth="auto"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            >
              {/* <Column field="Index" header="Sr.No" body={this.actionBodyTemplateSrNo} style={{ width: '20%' }}></Column> */}
              <Column
                field="product_hsn_code"
                header="HSN Code"
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="chapter_name"
                header="Chapter Name "
                style={{ width: "20%" }}
              ></Column>
              {/* <Column field="product_description" header="Product Description" style={{ width: '20%' }}></Column> */}
              <Column
                body={this.DescriptionBody}
                header="Product Description"
                style={{ width: "20%" }}
              ></Column>
              <Column
                body={this.RateBody}
                header="Rate(%)"
                style={{ width: "20%" }}
              ></Column>
              {/* <Column body={this.CESSBody} header="CESS(%)" style={{ width: '20%' }}></Column> */}
              <Column
                field="Action"
                header="Action"
                body={this.actionTemplate}
                headerStyle={{ width: "10%", minWidth: "8rem" }}
                bodyStyle={{ textAlign: "center" }}
              ></Column>
            </DataTable>
          ) : null}
          {/* Add Edit BL Type */}
          <Dialog open={AddItemMasterModal} className="custom-hs-master">
            <DialogTitle
              id="cha-form-dialog-title"
              className="custom-dialog-header"
            >
              <div>{ItemMasterID !== "" ? "Edit" : "Add"} Item/Goods </div>
              <div
                className="dialog-close"
                onClick={(e) => this.handlehandleClose("AddItemMaster")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <DialogContent className="HSCode-dialog-content-area">
              <GridContainer>
                <GridItem md={4}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="Item HsCode"
                    // id="chaNo"
                    label="HS Code/Product Name *"
                    type="text"
                    value={ItemMasterHsCode}
                    onChange={(e) =>
                      this.handleChangeItem(e, "ItemMasterHsCode")
                    }
                    error={ItemMasterHsCodeErr}
                    helperText={ItemItemMasterHsCodeErrText}
                    fullWidth
                  />
                </GridItem>
                <GridItem md={1}>
                  <div className="">
                    <button
                      className="filter-btn table-add-btn p-mt-3 cursor-pointer"
                      onClick={(e) => {
                        this.SearchItemCodeList(ItemMasterHsCode, "Add");
                      }}
                    >
                      <i className="icon-search"></i>{" "}
                    </button>
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem md={12}>
                  {ItemSearchListHSCode.length != 0 ? (
                    <table>
                      <tr>
                        <th></th>
                        <th>ItemName</th>
                        <th>Description</th>
                        <th>HSN Code</th>
                        <th>Rate (%)</th>
                        {/* <th>Action</th> */}
                      </tr>

                      {ItemSearchListHSCode.length != 0 ? (
                        ItemSearchListHSCode.map((x, idx) => {
                          return (
                            <tr
                              className={
                                x.IsActiveTab == "1"
                                  ? "active cursor-pointer "
                                  : "cursor-pointer"
                              }
                            >
                              <td>
                                {!this.state.ItemMasterID &&
                                x.chapter_name &&
                                x.product_rate &&
                                idx === ItemSearchListHSCode.length - 1 ? (
                                  <div onClick={() => this.handleAddItem()}>
                                    <i class="icon-add"></i>{" "}
                                  </div>
                                ) : null}
                              </td>
                              <td>
                                {x.IsActiveTab == "1" ? (
                                  <CustomInput
                                    className="input-no-cont"
                                    labelText="Name"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      onChange: (e) => {
                                        e.stopPropagation();
                                        let tempList = ItemSearchListHSCode;
                                        tempList[idx]["chapter_name"] =
                                          e.target.value;

                                        this.setState({
                                          ItemSearchListHSCode: tempList,
                                        });
                                      },
                                      type: "text",
                                      id: "chapter_name",
                                      name: "chapter_name",
                                      value: x.chapter_name,
                                    }}
                                  />
                                ) : (
                                  x.chapter_name
                                )}
                              </td>
                              <td
                                onClick={(e) =>
                                  this.ActiveTab(x.IsActiveTab, idx)
                                }
                              >
                                {x.product_description}
                              </td>
                              <td
                                onClick={(e) =>
                                  this.ActiveTab(x.IsActiveTab, idx)
                                }
                              >
                                {x.product_hsn_code}
                              </td>
                              <td>
                                {x.IsActiveTab == "1" ? (
                                  <CustomInput
                                    className="input-no-cont"
                                    labelText="Rate (%)"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      onChange: (e) => {
                                        e.stopPropagation();
                                        let tempList = ItemSearchListHSCode;
                                        tempList[idx]["product_rate"] =
                                          e.target.value;

                                        this.setState({
                                          ItemSearchListHSCode: tempList,
                                        });
                                      },
                                      type: "number",
                                      id: "product_rate",
                                      name: "product_rate",
                                      value: x.product_rate,
                                    }}
                                  />
                                ) : (
                                  x.product_rate
                                )}{" "}
                              </td>
                              <td>
                                {ItemSearchListHSCode.length > 1 ? (
                                  <div
                                    onClick={() => this.handleDeleteItem(idx)}
                                  >
                                    <i class="icon-delete"></i>{" "}
                                  </div>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div>No Records Available</div>
                      )}
                    </table>
                  ) : null}
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={(e) => this.handlehandleClose("AddItemMaster")}
              >
                Close
              </Button>
              {!CommonConfig.isObjectEmpty(ItemSearchListHSCode) &&
              ItemSearchListHSCode.length > 0 ? (
                <Button
                  className="primary-btn"
                  onClick={(e) => this.AddItemMaster(e)}
                  type="submit"
                >
                  Submit
                </Button>
              ) : null}
            </DialogActions>
          </Dialog>

          {/* Modal For Delete */}
          <Dialog
            open={DeleteItemMasterTypeModal}
            onClose={() => this.handlehandleClose("DeleteItemMasterType")}
            aria-labelledby="cha-form-dialog-title"
          >
            <DialogTitle
              id="cha-form-dialog-title"
              className="custom-dialog-header"
            >
              <div>Delete Item/Goods</div>
              <div
                className="dialog-close"
                onClick={(e) => this.handlehandleClose("DeleteItemMasterType")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <DialogContent className="CHA-dialog-content-area">
              <h6>Are you sure want to delete?</h6>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={(e) => this.handlehandleClose("DeleteItemMasterType")}
              >
                Close
              </Button>
              <Button
                className="primary-btn"
                onClick={(e) => this.DeleteItemMasterType(e)}
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}
export default Item;
