import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CommonConfig } from "../../CommonConfig.js";
import SimpleBackdrop from "../../utils/general";
import api from "../../utils/api";
import cogoToast from "cogo-toast";
import APIConstant from "../../utils/constant";
// import { FilterMatchMode } from 'primereact/api';
import { Dialog } from "primereact/dialog";

const CompanyInitials = () => {
  const [loading, setLoading] = useState(false);
  const [securityUserID, setSecurityUserID] = useState(null);
  const [role, setRole] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null },
  });
  const [globalFilter, setGlobalFilter] = useState("");
  const [companyInitialsId, setCompanyInitialsId] = useState(null);
  const [companyInitialsList, setCompanyInitialsList] = useState([]);
  const [isDeleteMaster, setIsDeleteMaster] = useState(false);
  const [isAddMaster, setIsAddMaster] = useState(false);
  const [isViewMaster, setIsViewMaster] = useState(false);
  const [viewMasterData, setViewMasterData] = useState({});

  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [nameErrText, setNameErrText] = useState("");

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="table-icon">
        <Button
          className=""
          onClick={() => handleOpen(rowData, "EditMaster")}
          tooltip={"Edit"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-edit"></i>
        </Button>

        {/* <Button className='' onClick={() => handleOpen(rowData, "DeleteMaster")} tooltip={"Delete"} tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}>
          <i className='icon-delete'  ></i>
        </Button> */}
      </div>
    );
  };

  useEffect(() => {
    const loginData = CommonConfig.loginData();
    setSecurityUserID(loginData?._id);
    setRole(loginData?.AccountType);

    getCompanyInitials();

    return () => {};
  }, []);

  const resetScreen = async () => {
    setCompanyInitialsId("");
    setCompanyInitialsList([]);
    setSecurityUserID("");
    setRole("");
    setIsAddMaster(false);
    setIsDeleteMaster(false);
    setIsViewMaster(false);
    setName("");
    setNameErr(false);
    setNameErrText("");
    setLoading(false);
    setSecurityUserID(CommonConfig.loginData()?._id);
    setRole(CommonConfig.loginData()?.AccountType);
    await getCompanyInitials();
  };

  const getCompanyInitials = async () => {
    try {
      setLoading(true);
      let data = {
        SecurityUserID: CommonConfig.loginData()?._id,
      };
      await api
        .post(APIConstant.path.GetCompanyInitials, data)
        .then(async (response) => {
          if (response.Success) {
            setCompanyInitialsList(response.Data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          cogoToast.error(err);
        });
    } catch (err) {
      setLoading(false);
      cogoToast.error(err);
    }
  };

  const handleChange = (e, type) => {
    if (type == "name") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setName("");
        setNameErr(true);
        setNameErrText("Name is required");
      } else {
        setName(e.target.value.toUpperCase());
        setNameErr(false);
        setNameErrText("");
      }
    }
  };

  const validation = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(name)) {
      formIsValid = false;
      setNameErr(true);
      setNameErrText("Name is required");
    } else {
      setNameErr(false);
      setNameErrText("");
    }

    return formIsValid;
  };

  const handleOpen = async (item, type) => {
    if (type === "AddMaster") {
      setIsAddMaster(true);
    } else if (type === "EditMaster") {
      setIsAddMaster(true);
      setCompanyInitialsId(item._id);
      setName(item.Name);
    } else if (type === "DeleteMaster") {
      setCompanyInitialsId(item._id);
      setIsDeleteMaster(true);
    } else if (type === "ViewMaster") {
      // setInsuranceCompanyMasterId(item.InsuranceCompanyMasterID);
      setIsViewMaster(true);
      setViewMasterData(item);
    }
  };

  const handleClose = async (type) => {
    if (type === "AddMaster") {
      setIsAddMaster(false);
      resetScreen();
    } else if (type === "EditMaster") {
      setIsAddMaster(false);
      setCompanyInitialsId(false);
      resetScreen();
    } else if (type === "DeleteMaster") {
      setCompanyInitialsList(null);
      setIsDeleteMaster(false);
      resetScreen();
    } else if (type === "ViewMaster") {
      setIsViewMaster(false);
      setViewMasterData({});
    }
  };

  const handleAddMaster = async (e) => {
    e.preventDefault();
    if (validation()) {
      setLoading(true);
      let data = {
        CompanyInitialsID: companyInitialsId,
        SecurityUserID: securityUserID,
        Name: name,
      };
      try {
        api
          .post(APIConstant.path.AddUpdateCompanyInitials, data)
          .then(async (response) => {
            if (response.Success) {
              setLoading(false);
              cogoToast.success(response.Message);
              await resetScreen();
            } else {
              setLoading(false);
              cogoToast.error(response.Message);
            }
          })
          .catch((err) => {
            setLoading(false);
            cogoToast.error(err);
          });
      } catch (err) {
        setLoading(false);
        cogoToast.error(err);
      }
    }
  };

  const handleDeleteMaster = async (Id) => {
    try {
      setLoading(true);
      let data = {
        SecurityUserID: securityUserID,
        CompanyInitialsID: companyInitialsId,
      };
      await api
        .post(APIConstant.path.DeleteCompanyInitials, data)
        .then(async (response) => {
          if (response.Success) {
            cogoToast.success(response.Message);
            await resetScreen();
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          cogoToast.error(err);
        });
    } catch (err) {
      setLoading(false);
      cogoToast.error(err);
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilter(value);
  };

  return (
    <div className="main-access-contianer user-container">
      <div className="card-header">
        <div className="card-title p-d-lg-flex p-ai-center p-w-100">
          <h3 className="">Company Initials</h3>
          <div className="p-ml-auto p-d-lg-flex p-ai-center">
            <span className="searchbar-area">
              <InputText
                type="search"
                onChange={onGlobalFilterChange}
                placeholder="Global Search"
                size="30"
              />
              <i className="icon-search"></i>
            </span>
          </div>
          <div className="card-actions">
            <Button
              type="button"
              className="add-btn1 p-button p-jc-center"
              onClick={(e) => handleOpen(e, "AddMaster")}
            >
              <i className="icon-add"></i> Add Company Initials
            </Button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <DataTable
          filters={filters}
          className="main-table table-td"
          value={companyInitialsList}
          editMode="row"
          dataKey="id"
          responsiveLayout="scroll"
          paginator={true}
          rows={10}
          totalRecords={companyInitialsList.length}
          rowsPerPageOptions={CommonConfig.dataTableConfig.rowsPerPageOptions}
          emptyMessage="No records found"
          responsive={false}
          resizableColumns={true}
          columnResizeMode="fit"
          scrollable={true}
          scrollWidth="auto"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        >
          <Column
            field="Index"
            header="Sr.No"
            body={actionBodyTemplateSrNo}
            style={{ width: "20%" }}
          ></Column>
          <Column field="Name" header="Name" style={{ width: "20%" }}></Column>
          <Column
            field="Action"
            header="Action"
            body={actionBodyTemplate}
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
        </DataTable>
      </div>

      {/* Delete Confirm Modal */}
      <Dialog
        header="Delete Master"
        visible={isDeleteMaster}
        className="dialog-popup"
        onHide={(e) => handleClose("DeleteMaster")}
        draggable={false}
        closable={false}
        position="top"
      >
        <span>
          Are you sure want to delete the company initials from system ?
        </span>
        <div className="dialog-footer p-mt-4">
          <button
            className="btn-dialog-cancel"
            onClick={(e) => handleClose("DeleteMaster")}
          >
            cancel
          </button>
          <button
            className="add-btn add-btn p-button p-component p-jc-center p-w-100 p-ml-2"
            onClick={() => handleDeleteMaster(companyInitialsId)}
          >
            Delete
          </button>
        </div>
      </Dialog>

      {/* Add Company Initials */}
      <Dialog
        header={
          companyInitialsId ? "Edit Company Initials" : "Add Company Initials"
        }
        visible={isAddMaster}
        className="dialog-popup"
        onHide={(e) => handleClose("Add")}
        draggable={false}
        closable={false}
        position="top"
      >
        <div className="">
          <label>Company Initials Name</label>
          <InputText
            rows={4}
            placeholder="Please enter company initials"
            id="name"
            className="p-w-100 p-mt-2"
            type="text"
            value={name}
            keyfilter={/^[A-Za-z ]+$/}
            maxLength={3}
            onChange={(e) => handleChange(e, "name")}
          />
          {nameErr ? (
            <small id="name2-help" className="p-error block">
              {nameErrText}
            </small>
          ) : null}
        </div>
        <div className="dialog-footer p-mt-4">
          <button
            type="button"
            className="primary-btn1"
            onClick={(e) => handleClose("AddMaster")}
          >
            cancel
          </button>

          <button
            type="button"
            className="primary-btn p-button p-component p-jc-center p-ml-2"
            onClick={(e) => handleAddMaster(e)}
          >
            SUBMIT
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default CompanyInitials;
