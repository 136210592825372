import React, { useState, useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { CommonConfig } from "../../../CommonConfig.js";
import SimpleBackdrop from '../../../utils/general.js';
import api from '../../../utils/api.js';
import cogoToast from 'cogo-toast';
import APIConstant from "../../../utils/constant.js";
import { DialogContent, DialogTitle, DialogActions, FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { Dialog } from 'primereact/dialog';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from '@material-ui/core/TextField';
import Loader from 'components/Shared/Loader/Loader';
import CustomInput from "components/CustomInput/CustomInput";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DatePicker from "react-datetime";
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import { MultiSelect } from "primereact/multiselect";
import "date-fns";
import { apiBase } from "config.js";
import { ColorPicker } from 'primereact/colorpicker';
import Checkbox from '@material-ui/core/Checkbox';
import { Calendar } from 'primereact/calendar';


const GodownDetails = (props) => {
    console.log('props', props)
    let [userId, setUserId] = useState('');



    useEffect(() => {

    }, []);


    return (
        <GridItem xs={12} sm={12} md={12}>
            <div className='ship-large-table p-py-2'>
                <DataTable className='main-table table-td' value={props.values} editMode="row" dataKey="id" responsiveLayout="scroll">
                    <Column header="SrNo." body={props?.SrNoBody}></Column>


                    <Column header="Godown No" body={props?.godownNoTemplate}></Column>
                    <Column header="Cargo Type" body={props?.cargoTypeTemplate}></Column>
                    <Column header="Area" body={props?.areaTemplate}></Column>
                    <Column header="Capacity" body={props?.capacityTemplate}></Column>

                    <Column header="Action" body={props?.ActionBody}></Column>
                </DataTable>
            </div>

        </GridItem>
    )

}

export default GodownDetails;