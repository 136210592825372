import React, { useState, useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { CommonConfig } from "../../../CommonConfig.js";
import SimpleBackdrop from '../../../utils/general.js';
import api from '../../../utils/api.js';
import cogoToast from 'cogo-toast';
import APIConstant from "../../../utils/constant.js";
import { DialogContent, DialogTitle, DialogActions, FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { Dialog } from 'primereact/dialog';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from '@material-ui/core/TextField';
import Loader from 'components/Shared/Loader/Loader';
import moment from "moment";
import { MultiSelect } from "primereact/multiselect";
import "date-fns";
import { apiBase } from "config.js";
import { Calendar } from 'primereact/calendar';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

// Import the FilePond plugins you need
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { Autocomplete } from "@mui/material";
// Register the plugins
registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);


const InsuranceDetails = (props) => {

    let [userId, setUserId] = useState('');

    useEffect(() => {

    }, []);


    return (
        <>
            <GridContainer>

                <GridItem xs={12} sm={6} md={3}>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="Unit"
                        // id="chaNo"
                        label="Policy No*"
                        type="text"
                        value={props?.values?.policyNo}
                        onChange={(e) => props?.handleChange(e, "policyNo")}
                        error={props?.values?.policyNoErr}
                        helperText={props?.values?.policyNoErrText}
                        fullWidth
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <div className="input-control select p-d-flex">
                        <FormControl fullWidth>
                            <Autocomplete
                                id="tags-standard"
                                value={props?.values?.company && props?.values?.companyTypeList.length ? props?.values?.companyTypeList.filter(x => x.value == props?.values?.company)[0] : ''}
                                options={props?.values?.companyTypeList}
                                // getOptionLabel={option => option.label ? option.label : option}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.value}>
                                            {option.label}
                                        </li>
                                    );
                                }}
                                onChange={(e, value) => props?.handleChange(value, "company")}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder="Select Comapny"
                                        error={props?.values?.companyErr}
                                        helperText={props?.values?.companyErrText}
                                        label="Select Comapny"
                                    />
                                )}
                            />
                            <button
                                className="filter-btn cursor-pointer"
                                onClick={(e) =>
                                    props?.handleOpen(e, 'addMasterModal')

                                }
                            >
                                <i className="icon-add"></i>
                            </button>
                        </FormControl>

                    </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <div className="custom-input">
                        <label htmlFor="calendar-24h" className="custom-field-label">
                            Select validity From Date
                        </label>
                        <Calendar placeholder="Select validity Date" className="p-w-100" dateFormat="dd-mm-yy" id="calendar-24h" value={props?.values?.validityFrom} onChange={(e) => props?.handleChange(e, 'validityFrom')} showIcon />

                    </div>
                    {props?.values?.validityFromErr && (<FormHelperText>{props?.values?.validityFromErrText}</FormHelperText>)}
                </GridItem>

                <GridItem xs={12} sm={6} md={3}>
                    <div className="custom-input">
                        <label htmlFor="calendar-24h" className="custom-field-label">
                            Select validity To Date
                        </label>
                        <Calendar placeholder="Select validity Date" className="p-w-100" dateFormat="dd-mm-yy" id="calendar-24h" value={props?.values?.validityTo} onChange={(e) => props?.handleChange(e, 'validityTo')} showIcon />
                    </div>
                    {props?.values?.validityToErr && (<FormHelperText>{props?.values?.validityToErrText}</FormHelperText>)}
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="Unit"
                        // id="chaNo"
                        label="Amount*"
                        type="number"
                        value={props?.values?.amount}
                        onChange={(e) => props?.handleChange(e, "amount")}
                        error={props?.values?.amountErr}
                        helperText={props?.values?.amountErrText}
                        fullWidth
                    />
                </GridItem>

                <GridItem xs={12} sm={6} md={3}>
                    <FilePond
                        files={props?.values?.policyCopy}
                        allowMultiple={false}
                        acceptedFileTypes={['application/pdf', 'jpg']}
                        maxFileSize="2MB"
                        onupdatefiles={(fileItems) =>
                            props?.handleFile(fileItems, 'policyCopy')
                        }
                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                </GridItem>
            </GridContainer>
        </>
    )

}

export default InsuranceDetails;