import React from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { CommonConfig } from "../../CommonConfig.js";
import SimpleBackdrop from '../../utils/general';
import api from '../../utils/api';
import cogoToast from 'cogo-toast';
import APIConstant from "../../utils/constant";
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from '@material-ui/core/TextField';
import Loader from 'components/Shared/Loader/Loader';
import { apiBase } from "config.js";
import * as jsPDF from 'jspdf';
import ReactToPrint from "react-to-print";
import InfiniteScroll from "react-infinite-scroll-component";
class ShipCard extends React.Component {

    constructor(props) {
        super(props);
        this.componentRef = React.createRef(null);
        this.printButtonRef = React.createRef(null);
        this.state = {

            userPageAccess: localStorage.getItem('PageAccess') ? localStorage.getItem('PageAccess') : [],
            loading: false,
            UserId: '',
            Token: '',
            GetMasterList: [],
            rowsPerPageOptions: CommonConfig.dataTableConfig.rowsPerPageOptions,
            rowsPerPage: CommonConfig.dataTableConfig.rowsPerPage,
            search: null,

            AddMasterModal: false,
            EditMasterTypeModal: false,
            DeleteMasterTypeModal: false,
            shipCardList: [],
            userAccess: [],
            ShipCardMasterId: '',
            showDeleteModal: false,
            shipCardReportList: [],
            prevShipCardList: 0,
            recordLimit: 0,
        };


    }


    componentDidMount = async () => {
        let data = localStorage.getItem('Infodata');
        let Userrole = JSON.parse(localStorage.getItem('Userdata'));
        let token = localStorage.getItem('Token');
        let userAccessData = JSON.parse(localStorage.getItem('UserAccessData'));
        let PageAccess = localStorage.getItem('PageAccess');

        let lockstatus = localStorage.getItem('LockStatus');
        if (lockstatus == 0) {
            if (token !== null) {
                this.setState({ UserId: data, Token: token, userAccess: userAccessData, userPageAccess: PageAccess });

                await this.GetShipCardList(data, 10, '');
                // await this.GetShipCardReport(data);
            } else {
                this.props.history.push('/login')
            }
        } else {
            this.props.history.push('/auth/lockscreen')
        }

    };

    GetShipCardReport = async (ShipCardId) => {
        Loader.show();
        let params = {
            ID: ShipCardId
        }
        await api.post(APIConstant.path.GetShipCardReport, params).then(async res => {
            let response = await res;
            if (response.Success === 1) {
                Loader.hide();
                this.setState({ shipCardReportList: response?.Info?.ShipCard });
                // this.exportShipCardPDF();
                this.printButtonRef.click();
            }
            else if (res.Success == -1) {

                localStorage.clear();
                const { history } = this.props;
                history.push('/login')

            }
            else {
                Loader.hide();
            }
        }).catch(err => { console.error(err); });
    };
    GetShipCardList = async (UserId, limit, searchItem) => {
        Loader.show();
        let totalRecord = this.state.recordLimit + limit;
        this.setState({ recordLimit: totalRecord })
        let params = {
            ID: UserId,
            recordLimit: totalRecord,
            SearchItem: searchItem
        }
        await api.post(APIConstant.path.GetShipCardList, params).then(async res => {
            let response = await res;
            if (response.Success === 1) {
                Loader.hide();
                this.setState({ shipCardList: response?.Info?.ShipCard[0] });
            }
            else if (res.Success == -1) {

                localStorage.clear();
                const { history } = this.props;
                history.push('/login')

            }
            else {
                Loader.hide();
            }
        }).catch(err => { console.error(err); });
    };

    DeleteShipCard = async () => {
        Loader.show();
        let params = {
            ID: this.state.ShipCardMasterId,
            UserId: this.state.UserId
        }
        await api.post(APIConstant.path.DeleteShipCard, params).then(async res => {
            let response = await res;
            if (response.Success === 1) {
                Loader.hide();
                this.setState({ showDeleteModal: false, ShipCardMasterId: '' })
                cogoToast.success(response.Message)
                this.GetShipCardList(this.state.UserId, 4, '')
            }
            else {
                Loader.hide();
            }
        }).catch(err => { console.log(err) });
    };

    exportShipCardPDF = async () => {
        // let content = document.getElementById('shipcard-pdf-generate');
        // const doc = new jsPDF.jsPDF('landscape', 'px', 'a4');
        // // doc.setDrawColor(0, 0, 0);
        // doc.setFont('Arial', 'normal');
        // // doc.setFontSize(11);
        // // doc.setTextColor(100);
        // // doc.internal.write(0, "Tw")
        // const div = content;
        // await doc.html(div, {
        //     margin: [20, 20, 20, 20],
        // });
        // doc.setProperties({
        //     title: 'SHIPCARD',
        //     subject: 'Info about PDF',
        //     author: 'PDFAuthor',
        //     keywords: 'generated, javascript, web 2.0, ajax',
        //     creator: 'My Company'
        // })
        // // doc.save('test.pdf'); // save / download
        // doc.output('dataurlnewwindow', { filename: 'SHIPCARD.pdf' });

        //console.log('print');  
        //     let printContents = document.getElementById('shipcard-pdf-generate').innerHTML;
        //     let originalContents = document.body.innerHTML;
        //     document.body.innerHTML = printContents;
        //     // window.print();
        //    document.body.innerHTML = originalContents; 
        var content = document.getElementById('shipcard-pdf-generate');
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();

    }


    fetchMoreData = () => {
        this.setState({ prevShipCardList: this.state.shipCardList.length })
        this.GetShipCardList(this.state.UserId, 4, '');
    }

    handleChange = (e, type) => {
        if (type === "search") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({
                    search: "",
                    recordLimit: 0
                });
                this.GetShipCardList(this.state.UserId, 4, e.target.value);
            } else {
                this.setState({
                    search: e.target.value,
                });
                this.GetShipCardList(this.state.UserId, 4, e.target.value);
            }

        }
    }

    render() {

        const { UserId, shipCardReportList, prevShipCardList, search, shipCardList, userPageAccess, ShipCardMasterId, showDeleteModal, userAccess } = this.state;

        let filterDataList = [];
        if (shipCardList.length) {
            filterDataList = shipCardList;
            if (search) {
                filterDataList = filterDataList.filter((x) => {
                    return (
                        x.VesselName.toLowerCase().indexOf(
                            search.toLowerCase()
                        ) !== -1 ||
                        x.VCNNo.toLowerCase().indexOf(
                            search.toLowerCase()
                        ) !== -1 ||
                        x.VesselAgent.toLowerCase().indexOf(
                            search.toLowerCase()
                        ) !== -1 ||
                        x.VesselRun.toLowerCase().indexOf(
                            search.toLowerCase()
                        ) !== -1 ||
                        x.AddedBy.toLowerCase().indexOf(
                            search.toLowerCase()
                        ) !== -1 ||
                        x.OriginCountry.toLowerCase().indexOf(
                            search.toLowerCase()
                        ) !== -1 ||
                        x.TradeType.toLowerCase().indexOf(
                            search.toLowerCase()
                        ) !== -1 ||
                        x.VesselStatus.toLowerCase().indexOf(
                            search.toLowerCase()
                        ) !== -1 ||
                        x.WarfagePaidDate.toLowerCase().indexOf(
                            search.toLowerCase()
                        ) !== -1 ||
                        x.PortName.toLowerCase().indexOf(
                            search.toLowerCase()
                        ) !== -1 ||
                        x.CargoType.toLowerCase().indexOf(
                            search.toLowerCase()
                        ) !== -1
                    )
                });
            }
        };

        return (

            <div className="ship-dashboard-container p-mt-2">
                <div className="shipcard-pdf-logo"></div>
                <div className="heading-area">
                    <h1>Ship Card</h1>
                    <div className="heading-area-right">
                        <div className="searchbar-area">
                            <InputText type="search" value={this.state.search}
                                onChange={(e) => this.handleChange(e, "search")} placeholder="Global Search" size="30" style={{ marginRight: 10 }} />
                            <i className="icon-search"></i>
                        </div>
                        {userPageAccess.includes('Add Ship Card') ?
                            <div>
                                <div className="add-btn1" onClick={(e) => this.props.history.push('/admin/add-ship-card')}>
                                    <i className="icon-add"></i> Add Ship Card
                                </div>
                            </div>
                            : null}
                    </div>
                </div>

                <div className="ship-dashboard-card">
                    {this.state.loading === true ?
                        <div className="loading">
                            <SimpleBackdrop />
                        </div>
                        : null}
                    <InfiniteScroll
                        dataLength={this.state.shipCardList.length}
                        next={this.fetchMoreData}
                        hasMore={prevShipCardList == this.state.shipCardList.length ? false : true}
                        height={'calc(100vh - 150px)'}
                        loader={<h4>No Data Found...</h4>}
                    >
                        {shipCardList.length ? (
                            filterDataList.map((x, i) => {
                                return (
                                    <div className="ship-item" key={i}>
                                        <div className="ship-header">
                                            <div className="p-d-lg-flex p-ai-center p-jc-between">
                                                <div className="ship-header-item" style={{ width: '25%' }}
                                                // onClick={(e) => this.viewContainerDetails(e, x)}
                                                >
                                                    <div className="icon-box">
                                                        <i className="icon-vessel"></i>
                                                    </div>

                                                    <div className="item-info">
                                                        <p className="item-title">{CommonConfig.isEmpty(x.VesselName) ? '-' : x.VesselName} </p>
                                                        <p className="item-subtitle" style={{ color: '#3A588F' }}>{CommonConfig.isEmpty(x.CargoType) ? '-' : x.CargoType}</p>
                                                    </div>
                                                </div>
                                                <div className="ship-header-item p-mt-3 p-mt-lg-0" style={{ width: '25%' }}
                                                //  onClick={(e) => this.viewContainerDetails(e, x)}
                                                >
                                                    <div className="icon-box">
                                                        <i className="icon-Container"></i>
                                                    </div>
                                                    <div className="item-info">
                                                        <p className="item-title p-text-truncate">{CommonConfig.isEmpty(x.VesselAgent) ? '-' : x.VesselAgent}</p>

                                                        <p className="item-subtitle">VCN No - <strong>{CommonConfig.isEmpty(x.VCNNo) ? '-' : x.VCNNo}</strong></p>
                                                    </div>
                                                </div>
                                                <div className="ship-action p-d-lg-flex p-ai-center p-jc-end" style={{ width: '30%' }}>
                                                    {/* <div className="trade-badge p-text-center" style={{ width: '55%' }}>
                                                    <p className="item-title">{CommonConfig.isEmpty(x.TradeType) ? '-' : x.TradeType.includes('BOTH') ? 'I/E' : x.TradeType} : {CommonConfig.isEmpty(x.VesselRun) ? '-' : x.VesselRun} </p>
                                                </div> */}
                                                    {
                                                        userPageAccess.includes('Edit Ship Card') ?
                                                            <div className="ship-action-item" onClick={(e) =>
                                                                this.props.history.push({
                                                                    pathname: "/admin/add-ship-card",
                                                                    state: {
                                                                        ShipCardMasterId: x.ShipCardMasterId,
                                                                        IsEdit: true,
                                                                    },
                                                                })
                                                            }>
                                                                <i className="icon-editImage"></i>
                                                            </div>

                                                            : null}
                                                    {
                                                        userPageAccess.includes('Delete Ship Card') ?
                                                            <div className="ship-action-item" onClick={(e) =>
                                                                this.setState({ showDeleteModal: true, ShipCardMasterId: x.ShipCardMasterId })
                                                            }>
                                                                <i className="icon-delete"></i>
                                                            </div>
                                                            : null}
                                                    {
                                                        userPageAccess.includes('View Ship Card') ?
                                                            <div className="ship-action-item" onClick={(e) =>
                                                                this.props.history.push({
                                                                    pathname: "/admin/view-ship-card",
                                                                    state: {
                                                                        ID: x.ShipCardMasterId,
                                                                    },
                                                                })
                                                            }>
                                                                <i className="icon-view p-pb-1"></i>
                                                            </div>

                                                            : null}
                                                    {this.state.userPageAccess.includes('Update Ship Card') ?
                                                        <div
                                                            className="ship-action-item"
                                                            href="javascript:;"
                                                            onClick={(e) =>
                                                                this.props.history.push({
                                                                    pathname: "/admin/add-ship-card",
                                                                    state: {
                                                                        ShipCardMasterId: x.ShipCardMasterId,
                                                                        ISUpdate: true
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            <i class="icon-edit"></i>
                                                        </div>
                                                        : null}
                                                    <div className="ship-action-item" onClick={() => this.GetShipCardReport(x.ShipCardMasterId)}><i class="icon-pdf"></i></div>
                                                    <ReactToPrint
                                                        trigger={() => <div className="p-d-none"><button ref={(el) => (this.printButtonRef = el)}>Print</button></div>}
                                                        content={() => this.componentRef}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="p-d-lg-flex p-ai-center">
                                            <div className="ship-footer ship-footer-status p-d-lg-flex p-ai-center p-jc-between" style={{ backgroundColor: x.ColorCode }}>
                                                <p className="p-text-truncate">{CommonConfig.isEmpty(x.VesselStatus) ? '-' : x.VesselStatus}</p>
                                            </div>
                                            <div className="ship-footer p-d-lg-flex p-ai-center p-jc-between">
                                                <div className="footer-item">
                                                    <i className="icon-location-fill"></i>
                                                    <p className="item-subtitle p-text-truncate p-ml-2">
                                                        {CommonConfig.isEmpty(x.PortName + ' ' + ' - ' + ' ' + x.OriginCountry) ? ' - ' : x.PortName + '-' + x.OriginCountry}</p>
                                                    {/* <i className="icon-Right-arrow"></i> */}
                                                </div>
                                                <div className="p-d-flex p-ai-center">
                                                    <div className="footer-item">
                                                        <i className="icon-user-pilot"></i>
                                                        <p className="item-subtitle p-text-truncate p-ml-2">{CommonConfig.isEmpty(x.AddedBy) ? '-' : x.AddedBy}</p>
                                                    </div>
                                                    <div className="footer-item p-ml-4">
                                                        <i className="icon-Date"></i>
                                                        <p className="item-subtitle p-text-truncate p-ml-2">{CommonConfig.isEmpty(x.WarfagePaidDate) ? '-' : x.WarfagePaidDate}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ship-footer ship-footer-trade p-d-lg-flex p-ai-center p-jc-between" >
                                                <p>{CommonConfig.isEmpty(x.TradeType) ? '-' : x.TradeType.includes('BOTH') ? 'I/E' : x.TradeType} <i className="icon-Right-arrow"></i> {CommonConfig.isEmpty(x.VesselRun) ? '-' : x.VesselRun}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            ))
                            :
                            <div>No record found</div>}

                    </InfiniteScroll>

                </div>

                <Dialog open={showDeleteModal} onClose={() => this.setState({ showDeleteModal: false, ShipCardMasterId: '' })} aria-labelledby="cha-form-dialog-title">
                    <DialogTitle id="cha-form-dialog-title" className="custom-dialog-header">
                        <div>Delete Ship Card</div>
                        <div className="dialog-close" onClick={(e) => this.setState({ showDeleteModal: false, ShipCardMasterId: '' })}><i className="icon-modal-close"></i></div>
                    </DialogTitle>
                    <DialogContent className="CHA-dialog-content-area">
                        <h6>Are you sure want to delete?</h6>
                    </DialogContent>
                    <DialogActions className="p-p-0 dialog-footer1">
                        <Button className="primary-btn1" onClick={(e) => this.setState({ showDeleteModal: false, ShipCardMasterId: '' })}>
                            Close
                        </Button>
                        <Button className="primary-btn" onClick={(e) => this.DeleteShipCard(e)} type="submit" >
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

                <div className='p-d-none' >
                    <div id='shipcard-pdf-generate' class='shipcard-pdf-container' ref={(el) => (this.componentRef = el)}>
                        <div class="shipcard-pdf-header">
                            {/* <div class="shipcard-pdf-logo"> */}

                            <h4 class='company-name'>DEENDAYAL PORT AUTHORITY</h4>

                            {/* </div> */}
                            <div class="shipcard-pdf-header-info">
                                <h1>SHIP CARD</h1>
                                <p class="vcn">VCN No: <b>{!CommonConfig.isEmpty(shipCardReportList?.VCNNo) ? shipCardReportList?.VCNNo : '-'}</b></p>
                                <p class="vcn">Vessel Name: <b>{!CommonConfig.isEmpty(shipCardReportList?.VesselName) ? shipCardReportList?.VesselName : '-'}</b></p>
                                <p class="vcn">Vessel Agent: <b>{!CommonConfig.isEmpty(shipCardReportList?.VesselAgent) ? shipCardReportList?.VesselAgent : '-'}</b></p>
                            </div>
                        </div>
                        <div class='company-letterhead-body'>
                            <div className="shipcard-main-info">
                                <div className="shipcard-info-box">
                                    <h4 className='shipcard-info-label'>Commodity</h4>
                                    <p className='shipcard-info-value'><b>{!CommonConfig.isEmpty(shipCardReportList?.CommodityType) ? shipCardReportList?.CommodityType : '-'}</b></p>
                                </div>
                                <div className="shipcard-info-box">
                                    <h4 className='shipcard-info-label'>Trade Type</h4>
                                    <p className='shipcard-info-value'><b>{!CommonConfig.isEmpty(shipCardReportList?.TradeType) ? shipCardReportList?.TradeType : '-'}</b></p>
                                </div>
                                <div className="shipcard-info-box">
                                    <h4 className='shipcard-info-label'>Cargo Type</h4>
                                    <p className='shipcard-info-value'><b>{!CommonConfig.isEmpty(shipCardReportList?.CargoType) ? shipCardReportList?.CargoType : '-'}</b></p>
                                </div>
                                <div className="shipcard-info-box">
                                    <h4 className='shipcard-info-label'>Vessel Run</h4>
                                    <p className='shipcard-info-value'><b>{!CommonConfig.isEmpty(shipCardReportList?.VesselRun) ? shipCardReportList?.VesselRun : '-'}</b></p>
                                </div>
                                <div className="shipcard-info-box">
                                    <h4 className='shipcard-info-label'>100% Wharfage Paid Date</h4>
                                    <p className='shipcard-info-value'><b>{!CommonConfig.isEmpty(shipCardReportList?.WarfagePaidDate) ? shipCardReportList?.WarfagePaidDate : '-'}</b></p>
                                </div>
                                <div className="shipcard-info-box">
                                    <h4 className='shipcard-info-label'>Total Qty</h4>
                                    <p className='shipcard-info-value'><b>{this.state.shipCardReportList?.OtherDetails?.length ? this.state.shipCardReportList?.OtherDetails[0]?.TotalQty : ''}</b></p>
                                </div>
                            </div>
                            {shipCardReportList?.vesselDetailList?.filter((x) => x.jettyPosition !== '').length ?
                                <div className="ship-pdf-table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th><p>Sr. No.</p></th>
                                                <th><p>Jetty Position</p></th>
                                                <th><p>Made fast Time & Date</p></th>
                                                <th><p>Commencement Time & Date</p></th>
                                                <th><p>Completion Time & Date</p></th>
                                                <th><p>Left Berth Time & Date</p></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {shipCardReportList?.vesselDetailList?.length ?
                                                shipCardReportList?.vesselDetailList?.map((x, idx) => {
                                                    return (
                                                        <tr>
                                                            <td><p> {idx + 1}</p></td>
                                                            <td><p>{x.jettyPosition} {x.panelName?.split('To')[0]}{x.panelBollardStart ? <sup className="p-ml-1">{x.panelBollardStart}</sup> : null} to {x.panelName?.split('To')[1]}{x.panelBollard ? <sup className="p-ml-1">{x.panelBollard}</sup> : null}</p></td>
                                                            <td><p>{x.madeFastDateTime}</p></td>
                                                            <td><p>{x.commencementDateTime}</p></td>
                                                            <td><p>{x.completedDate}</p></td>
                                                            <td><p>{x.leftBerthDate}</p></td>
                                                        </tr>
                                                    )
                                                }
                                                ) :
                                                null
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                : null}
                            {shipCardReportList?.stevedoresArray?.filter((x) => x.Stevedores !== '').length ?
                                <>
                                    <div className="section-heading">
                                        <h2>Stevdores Particulars</h2>
                                    </div>

                                    <div className="ship-pdf-table ship-pdf-table-stev">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th rowspan="2"><p>Sr. No.</p></th>
                                                    <th rowspan="2"><p>Stevedore</p></th>
                                                    <th rowspan="2"><p>Commodity</p></th>
                                                    <th rowspan="2"><p>Unit Name</p></th>
                                                    <th colspan="4"><p>{shipCardReportList?.TradeType}</p></th>
                                                    <th colspan="2"><p>Gang</p></th>
                                                    <th colspan="2"><p>{shipCardReportList?.TradeType == 'IMPORT' ? "Origin" : "Destination"}</p></th>
                                                    {/* <th rowspan="2"><p>{shipCardReportList?.TradeType == 'IMPORT' ? "Origin Country" : "Destination Country"}</p></th> */}
                                                </tr>
                                                <tr>
                                                    <th><p>{shipCardReportList?.TradeType == 'IMPORT' ? "Berth" : "Berth"}</p></th>
                                                    <th><p>{shipCardReportList?.TradeType == 'IMPORT' ? "Stream" : "Stream"}</p></th>
                                                    <th><p>{'CBM'}</p></th>
                                                    <th><p>{'Pieces'}</p></th>
                                                    <th><p>Berth</p></th>
                                                    <th><p>Stream</p></th>
                                                    <th><p>Port</p></th>
                                                    <th><p>Country</p></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shipCardReportList?.stevedoresArray?.length ?
                                                    shipCardReportList?.stevedoresArray?.map((x, idx) => {
                                                        return (
                                                            <tr>
                                                                <td><p>{idx + 1}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.Stevedores) ? x.Stevedores : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.commodityType) ? x.commodityType : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.unit) ? x.unit : '-'}</p></td>

                                                                <td><p>{!CommonConfig.isEmpty(shipCardReportList?.TradeType == 'IMPORT' ? x.ImportBerth : x.ExportBerth) ? shipCardReportList?.TradeType == 'IMPORT' ? x.ImportBerth : x.ExportBerth : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(shipCardReportList?.TradeType == 'IMPORT' ? x.ImportStream : x.ExportStream) ? shipCardReportList?.TradeType == 'IMPORT' ? x.ImportStream : x.ExportStream : '-'}</p></td>


                                                                <td><p>{!CommonConfig.isEmpty(shipCardReportList?.TradeType == 'IMPORT' ? x.CBM : x.exportCBM) ? shipCardReportList?.TradeType == 'IMPORT' ? x.CBM : x.exportCBM : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(shipCardReportList?.TradeType == 'IMPORT' ? x.ImportUnitPiecesHandled : x.ExportUnitPiecesHandled) ? shipCardReportList?.TradeType == 'IMPORT' ? x.ImportUnitPiecesHandled : x.ExportUnitPiecesHandled : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.gangBerth) ? x.gangBerth : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.gangStream) ? x.gangStream : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(shipCardReportList?.TradeType == 'IMPORT' ? x.OriginPort : x.destinationPort) ? shipCardReportList?.TradeType == 'IMPORT' ? x.OriginPort : x.destinationPort : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(shipCardReportList?.TradeType == 'IMPORT' ? x.originCountry : x.DestinationCountry)
                                                                    ? shipCardReportList?.TradeType == 'IMPORT' ? x.originCountry : x.DestinationCountry : '-'}</p></td>

                                                            </tr>
                                                        )
                                                    }
                                                    ) :
                                                    null
                                                }
                                            </tbody>
                                        </table>
                                        <div className="particulars-badge-box">
                                            <div>
                                                <p className="extra-info"><b>Berth: </b>{this.state.shipCardReportList?.OtherDetails?.length ? this.state.shipCardReportList?.OtherDetails[0]?.BerthTotal : ''} </p>
                                            </div>
                                            <div>
                                                <p className="extra-info"><b>Stream: </b>{this.state.shipCardReportList?.OtherDetails?.length ? this.state.shipCardReportList?.OtherDetails[0]?.StreamTotal : ''} </p>
                                            </div>
                                            <div>

                                                <p className="extra-info"><b>Total Gangs: </b>{this.state.shipCardReportList?.OtherDetails?.length ? this.state.shipCardReportList?.OtherDetails[0]?.TotalGangBerth : ''} </p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null}
                            {!CommonConfig.isEmpty(shipCardReportList?.barge) ||
                                !CommonConfig.isEmpty(shipCardReportList?.BargeCommodity) ||
                                !CommonConfig.isEmpty(shipCardReportList?.BargeQty) ||
                                !CommonConfig.isEmpty(shipCardReportList?.TotalTonnage) ||
                                !CommonConfig.isEmpty(shipCardReportList?.TonnageBarge) ||
                                !CommonConfig.isEmpty(shipCardReportList?.MFDateTime) ||
                                !CommonConfig.isEmpty(shipCardReportList?.LFDateTime) ?
                                <>
                                    <div className="section-heading">
                                        <h2>Barge Handling Details</h2>
                                    </div>
                                    <div className="ship-pdf-table">
                                        <table>
                                            <thead>
                                                <tr>

                                                    <th><p>Barge</p></th>
                                                    <th><p>Commodity</p></th>
                                                    <th><p>Qty</p></th>
                                                    <th><p>Total Tonnage</p></th>
                                                    <th><p>Number Of Barge</p></th>
                                                    <th><p>MF DateTime</p></th>
                                                    <th><p>LF DateTime</p></th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td><p>{!CommonConfig.isEmpty(shipCardReportList?.barge) ? shipCardReportList?.barge : '-'}</p></td>
                                                    <td><p>{!CommonConfig.isEmpty(shipCardReportList?.BargeCommodity) ? shipCardReportList?.BargeCommodity : '-'}</p></td>
                                                    <td><p>{!CommonConfig.isEmpty(shipCardReportList?.BargeQty) ? shipCardReportList?.BargeQty : '-'}</p></td>
                                                    <td><p>{!CommonConfig.isEmpty(shipCardReportList?.TotalTonnage) ? shipCardReportList?.TotalTonnage : '-'}</p></td>
                                                    <td><p>{!CommonConfig.isEmpty(shipCardReportList?.TonnageBarge) ? shipCardReportList?.TonnageBarge : '-'}</p></td>

                                                    <td><p>{!CommonConfig.isEmpty(shipCardReportList?.MFDateTime) ? shipCardReportList?.MFDateTime : '-'}</p></td>
                                                    <td><p>{!CommonConfig.isEmpty(shipCardReportList?.LFDateTime) ? shipCardReportList?.LFDateTime : '-'}</p></td>
                                                </tr>


                                            </tbody>
                                        </table>
                                        <div className="barge-detail-box">

                                            {!CommonConfig.isEmpty(shipCardReportList?.BunderPanel) || !CommonConfig.isEmpty(shipCardReportList?.HandledBunderArea) ?
                                                <div className="barge-detail-item">
                                                    <div className="barge-box">
                                                        <div className="barge-detail-info">
                                                            <h4 className="barge-detail-label">Bunder Area </h4>
                                                            <p className="barge-detail-value">{!CommonConfig.isEmpty(shipCardReportList?.BunderPanel) ? shipCardReportList?.BunderPanel : '-'}</p>
                                                        </div>
                                                        <div className="barge-detail-info">
                                                            <h4 className="barge-detail-label">Handled Bunder Area </h4>
                                                            <p className="barge-detail-value">{!CommonConfig.isEmpty(shipCardReportList?.HandledBunderArea) ? shipCardReportList?.HandledBunderArea : '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                            {!CommonConfig.isEmpty(shipCardReportList?.OtherPanel) || !CommonConfig.isEmpty(shipCardReportList?.HandledOtherPanel) ?
                                                <div className="barge-detail-item">
                                                    <div className="barge-box">
                                                        <div className="barge-detail-info">
                                                            <h4 className="barge-detail-label">Cargo Jetty</h4>
                                                            <p className="barge-detail-value">{!CommonConfig.isEmpty(shipCardReportList?.OtherPanel) ? shipCardReportList?.OtherPanel : '-'}</p>
                                                        </div>
                                                        <div className="barge-detail-info">
                                                            <h4 className="barge-detail-label">Handled Cargo Jetty</h4>
                                                            <p className="barge-detail-value">{!CommonConfig.isEmpty(shipCardReportList?.HandledOtherPanel) ? shipCardReportList?.HandledOtherPanel : '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                            {!CommonConfig.isEmpty(shipCardReportList?.ZeroPanel) || !CommonConfig.isEmpty(shipCardReportList?.HandledZeroPanel) ?
                                                <div className="barge-detail-item">
                                                    <div className="barge-box">
                                                        <div className="barge-detail-info">
                                                            <h4 className="barge-detail-label">Zero Panel</h4>
                                                            <p className="barge-detail-value">{!CommonConfig.isEmpty(shipCardReportList?.ZeroPanel) ? shipCardReportList?.ZeroPanel : '-'}</p>
                                                        </div>
                                                        <div className="barge-detail-info">
                                                            <h4 className="barge-detail-label">Handled Zero Panel</h4>
                                                            <p className="barge-detail-value">{!CommonConfig.isEmpty(shipCardReportList?.HandledZeroPanel) ? shipCardReportList?.HandledZeroPanel : '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                            {!CommonConfig.isEmpty(shipCardReportList?.TunaPanel) || !CommonConfig.isEmpty(shipCardReportList?.HandledTuna) ?
                                                <div className="barge-detail-item">
                                                    <div className="barge-box">
                                                        <div className="barge-detail-info">
                                                            <h4 className="barge-detail-label">Tuna Panel</h4>
                                                            <p className="barge-detail-value">{!CommonConfig.isEmpty(shipCardReportList?.TunaPanel) ? shipCardReportList?.TunaPanel : '-'}</p>
                                                        </div>
                                                        <div className="barge-detail-info">
                                                            <h4 className="barge-detail-label">Handled Tuna Panel</h4>
                                                            <p className="barge-detail-value">{!CommonConfig.isEmpty(shipCardReportList?.HandledTuna) ? shipCardReportList?.HandledTuna : '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </>
                                : null}
                            {shipCardReportList?.stoppageWorkArray?.filter((x, idx) => x.stoppageDate !== '')?.length ?
                                <>
                                    <div className="section-heading">
                                        <h2>Stoppage Of Work During Operation</h2>
                                    </div>
                                    <div className="ship-pdf-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th><p>Sr. No.</p></th>
                                                    <th><p>Stoppage Date</p></th>
                                                    <th><p>Shift</p></th>
                                                    <th><p>Start Time</p></th>
                                                    <th><p>End Time</p></th>
                                                    <th><p>Total Time</p></th>
                                                    <th><p>Reason</p></th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shipCardReportList?.stoppageWorkArray?.length ?
                                                    shipCardReportList?.stoppageWorkArray?.map((x, idx) => {
                                                        return (
                                                            <tr>
                                                                <td><p>{idx + 1}</p></td>
                                                                <td><p>{x.stoppageDate}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.shift) ? x.shift : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.shiftStartTime) ? x.shiftStartTime : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.shiftEndTime) ? x.shiftEndTime : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.totalTime) ? x.totalTime : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.reason) ? x.reason : '-'}</p></td>


                                                            </tr>
                                                        )
                                                    }
                                                    ) :
                                                    null
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                                : null}
                            {shipCardReportList?.exporterArray?.filter((x, idx) => x.exporterName !== '').length ?
                                <>
                                    <div className="section-heading">
                                        <h2>{shipCardReportList?.TradeType === 'IMPORT' ? 'Importer Details' : 'Exporter Details'}</h2>
                                    </div>
                                    <div className="ship-pdf-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th><p>Sr. No.</p></th>
                                                    <th><p>{shipCardReportList?.TradeType === 'IMPORT' ? 'Importer Name' : 'Exporter Name'}</p></th>
                                                    <th><p>Cargo</p></th>
                                                    <th><p>Commodity</p></th>
                                                    <th><p>Qty</p></th>
                                                    <th><p>CBM</p></th>
                                                    <th><p>Pieces</p></th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shipCardReportList?.exporterArray?.length ?
                                                    shipCardReportList?.exporterArray?.map((x, idx) => {
                                                        return (
                                                            <tr>
                                                                <td><p>{idx + 1}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.exporterName) ? x.exporterName : null}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.exporterCargo) ? x.exporterCargo : null}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.exporterCommodity) ? x.exporterCommodity : null}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.exporterQty) ? x.exporterQty : null}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.CBM) ? x.CBM : null}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.exporterPieces) ? x.exporterPieces : null}</p></td>


                                                            </tr>
                                                        )
                                                    }
                                                    ) :
                                                    null
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                                : null}
                            {shipCardReportList?.portRelatedArray?.filter((x, idx) => x.particular !== '').length ?
                                <>
                                    <div className="section-heading">
                                        <h2>Particular Details</h2>
                                    </div>
                                    <div className="ship-pdf-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th><p>Sr. No.</p></th>
                                                    <th><p>Reciept</p></th>
                                                    <th><p>CHA</p></th>
                                                    <th><p>Particular</p></th>
                                                    <th><p>Rate</p></th>
                                                    <th><p>Qty</p></th>
                                                    <th><p>Amount</p></th>
                                                    <th><p>TDS?</p></th>
                                                    <th><p>Date</p></th>
                                                    <th><p>CBM</p></th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shipCardReportList?.portRelatedArray?.length ?
                                                    shipCardReportList?.portRelatedArray?.map((x, idx) => {
                                                        return (
                                                            <tr>
                                                                <td><p>{idx + 1}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.portReciept) ? x.portReciept : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.portCHA) ? x.portCHA : '-'}</p></td>
                                                                <td><p>{x.particular}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.portRate) ? x.portRate : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.portQty) ? x.portQty : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.portAmount) ? x.portAmount : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.IsTDS == '0' ? 'NO' : 'YES') ? x.IsTDS == '0' ? 'NO' : 'YES' : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.portDate) ? x.portDate : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.CBM) ? x.CBM : '-'}</p></td>


                                                            </tr>
                                                        )
                                                    }
                                                    ) :
                                                    null
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </> : null}
                            {shipCardReportList?.craneArray?.filter((x, idx) => x.craneDate !== '').length ?
                                <>
                                    <div className="section-heading">
                                        <h2>Crane Wise Information</h2>
                                    </div>
                                    <div className="ship-pdf-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th><p>Sr. No.</p></th>
                                                    <th><p>Date</p></th>
                                                    <th><p>Shift</p></th>
                                                    {shipCardReportList?.columnName?.filter((y) => y.TypeName != '')?.length ?
                                                        shipCardReportList?.columnName?.map((j) =>
                                                            <th><p>{j.TypeName}</p></th>
                                                        )


                                                        : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* <tr> */}
                                                {shipCardReportList?.craneArray?.length ?
                                                    shipCardReportList?.craneArray?.map((x, jdx) => {
                                                        return (
                                                            <tr key={jdx}>
                                                                <td><p>{jdx + 1}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.craneDate) ? x.craneDate : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.craneShift) ? x.craneShift : '-'}</p></td>
                                                                {shipCardReportList?.columns?.length ?
                                                                    shipCardReportList?.columns?.map((k, idx) => {
                                                                        return jdx == k?.RowNumber ?
                                                                            (
                                                                                <td key={idx}> <p>{!CommonConfig.isEmpty(k.TypeValue) ? k.TypeValue : '-'}</p></td>
                                                                            ) : null
                                                                    }
                                                                    ) :
                                                                    null
                                                                }
                                                            </tr>
                                                        )
                                                    }
                                                    ) :
                                                    null
                                                }
                                                <tr>
                                                    <td colSpan="2"><p>Total</p></td>
                                                    {shipCardReportList?.columnName?.length ?
                                                        <td><p>{shipCardReportList.columns.reduce((total, k) => {
                                                            return total + (parseInt(k.TypeValue) || 0);
                                                        }, 0)}</p></td>
                                                        : null}
                                                    {shipCardReportList?.columnName?.length ? (
                                                        shipCardReportList?.columnName.map((j, idx) => {
                                                            if (j.TypeName !== '') {
                                                                const columnTotal = shipCardReportList.columns.reduce((total, k) => {
                                                                    if (j.TypeID === k.TypeID && j.TypeName === k.TypeName) {
                                                                        return total + (parseInt(k.TypeValue) || 0);
                                                                    }
                                                                    return total;
                                                                }, 0);
                                                                return <td key={idx}><p>{columnTotal}</p></td>;
                                                            }
                                                            return null;
                                                        })
                                                    ) : null}
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"><p>Tonnage Wise Total</p></td>
                                                    {shipCardReportList?.CraneInformationValue?.length ?
                                                        <td><p>{shipCardReportList.CraneInformationValue.reduce((total, k) => {
                                                            return total + (parseInt(k.labelValue) || 0);
                                                        }, 0)}</p></td>
                                                        : null}
                                                    {shipCardReportList?.CraneInformationValue?.length ? (
                                                        shipCardReportList.CraneInformationValue.map((j, idx) => {
                                                            return <td key={idx}><p>{j.labelValue}</p></td>;
                                                        })) : null}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </> : null}
                            {shipCardReportList?.reasonArray?.filter((x, kdx) => x.reasonDate !== '').length ?
                                <>
                                    <div className="section-heading">
                                        <h2>Reason for Ideling of Vessel</h2>
                                    </div>
                                    <div className="ship-pdf-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th><p>Sr. No.</p></th>
                                                    <th><p>Date</p></th>
                                                    <th><p>Shift</p></th>
                                                    <th><p>Start Time</p></th>
                                                    <th><p>End Time</p></th>
                                                    <th><p>Total Time</p></th>
                                                    <th><p>Remark</p></th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shipCardReportList?.reasonArray?.length ?
                                                    shipCardReportList?.reasonArray?.map((x, kdx) => {
                                                        return (
                                                            <tr>
                                                                <td><p>{kdx + 1}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.reasonDate) ? x.reasonDate : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.reasonShift) ? x.reasonShift : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.reasonFromTime) ? x.reasonFromTime : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.reasonToTime) ? x.reasonToTime : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.reasonTotalDuration) ? x.reasonTotalDuration : '-'}</p></td>
                                                                <td><p>{!CommonConfig.isEmpty(x.reasonRemark) ? x.reasonRemark : '-'}</p></td>
                                                            </tr>
                                                        )
                                                    }
                                                    ) :
                                                    null
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </> : null}
                        </div>
                    </div>
                </div>
                <iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute' }}></iframe>
            </div>
        )
    }
}
export default ShipCard;