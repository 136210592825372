import {
  whiteColor,
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";

const pagesStyle = theme => ({
  wrapper: {
    height: "100vh",
    overflow: "hidden",
    position: "relative",
    top: "0",
    [theme.breakpoints.down("md")]: {
      overflow: "auto !important"
    },
     
  },
  fullPage: {
    padding: "120px 0",
    position: "relative",
    minHeight: "100vh",
    display: "flex!important",
    margin: "0",
    border: "0",
    color: whiteColor,
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      minHeight: "fit-content!important"
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important"
    },
  
   
  }
});

export default pagesStyle;
