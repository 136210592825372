import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0";
import "./App.css";
import login from "../src/views/Login/Login.js";
import Dashboard from "../src/views/Dashboard/Dashboard";
import EditProfile from "./views/Profile/EditProfile";
import Unit from "../src/views/Master/Unit";
import LocationMaster from "../src/views/Master/LocationMaster";
import HSCode from "../src/views/Master/HSCode";
import PortMaster from "../src/views/Master/PortMaster";
import CategoryMaster from "views/Master/CategoryMaster";
import SubCategoryMaster from "views/Master/SubCategoryMaster";
import MakeMaster from "views/Master/MakeMaster";
import ModelMaster from "views/Master/ModelMaster";
import Items from "../src/views/Master/Items";
import EquipmentMaster from "views/Master/EquipmentMaster";
import Stock from "views/Stock/Stock";
import Network from "../src/views/Network/MyNetwork.js";
import ProcessMaster from "views/Master/ProcessMaster";
import Warehouse from "views/Master/WareHouse/Warehouse";
import AddWareHouse from "views/Master/WareHouse/AddWareHouse";
import ViewWarehouse from "views/Master/WareHouse/ViewWarehouse";
import Request from "views/Request/Request";
import TypeMaster from "views/Master/TypeMaster";
import SubTypeMaster from "views/Master/SubTypeMaster";
import CompanyInitials from "views/Configuration/CompanyInitials";
import Reminder from "views/Reminder/Reminder";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Group from "@material-ui/icons/Group";
import MapIcon from "@material-ui/icons/Map";
import ChatIcon from "@material-ui/icons/Chat";

import "assets/equator-scss/style.scss";
import Configuration from "views/Configuration/Configuration";

var dashRoutes = [
  {
    path: "/login",
    component: login,
    invisible: false,
  },
  {
    path: "/editprofile",
    name: "Edit Profile",
    ServiceName: "Profile",
    restricted: true,
    icon: Group,
    component: EditProfile,
    layout: "/admin",
    invisible: false,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    restricted: true,
    ServiceName: "Dashboard",
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/unit",
    name: "Unit",
    ServiceName: "Unit",
    restricted: true,
    icon: MapIcon,
    component: Unit,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/location-master",
    name: "Location Master",
    ServiceName: "Location Master",
    restricted: true,
    icon: MapIcon,
    component: LocationMaster,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/hscode",
    name: "ServiceCode",
    ServiceName: "Service Code",
    restricted: true,
    icon: MapIcon,
    component: HSCode,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/port-master",
    name: "Port Master",
    ServiceName: "Port",
    restricted: true,
    icon: MapIcon,
    component: PortMaster,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/category-master",
    name: "Category Master",
    ServiceName: "Category Master",
    restricted: true,
    icon: MapIcon,
    component: CategoryMaster,
    ServiceNae: "Category Master",
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/sub-category-master",
    name: "SubCategory Master",
    ServiceName: "Sub Category Master",
    restricted: true,
    icon: MapIcon,
    component: SubCategoryMaster,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/make-master",
    name: "Make Master",
    ServiceName: "Make Master",
    restricted: true,
    icon: MapIcon,
    component: MakeMaster,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/model-master",
    name: "Model Master",
    ServiceName: "Model Master",
    restricted: true,
    icon: MapIcon,
    component: ModelMaster,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/type-master",
    name: "Type Master",
    ServiceName: "Type Master",
    restricted: true,
    icon: MapIcon,
    component: TypeMaster,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/sub-type-master",
    name: "Sub Type Master",
    ServiceName: "Sub Type Master",
    restricted: true,
    icon: MapIcon,
    component: SubTypeMaster,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/items",
    name: "Items",
    ServiceName: "Item/Goods",
    restricted: true,
    icon: MapIcon,
    component: Items,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/equipment-master",
    name: "Equipment Master",
    ServiceName: "Equipment Master",
    restricted: true,
    icon: MapIcon,
    component: EquipmentMaster,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/process",
    name: "Process Master",
    ServiceName: "Process Master",
    restricted: true,
    icon: MapIcon,
    component: ProcessMaster,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/Stock",
    name: "Stock",
    ServiceName: "Stock",
    restricted: true,
    icon: MapIcon,
    component: Stock,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/ware-house",
    name: "Warehouse",
    ServiceName: "Warehouse Master",
    restricted: true,
    icon: MapIcon,
    component: Warehouse,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/add-ware-house",
    name: "Add Ware House",
    ServiceName: "Add Ware House",
    icon: MapIcon,
    restricted: true,
    component: AddWareHouse,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/view-ware-house",
    name: "View Ware House",
    ServiceName: "View Ware House",
    icon: MapIcon,
    component: ViewWarehouse,
    restricted: true,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/Network",
    name: "Network",
    ServiceName: "Network",
    restricted: true,
    icon: Group,
    component: Network,
    ServiceName: "Network",
    layout: "/admin",
    invisible: false,
  },
  {
    path: "/request",
    name: "Request",
    icon: ChatIcon,
    component: Request,
    restricted: true,
    ServiceName: "Request",
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/company-initials",
    name: "Company Initials",
    ServiceName: "Company Initials",
    icon: Group,
    component: CompanyInitials,
    layout: "/admin",
    restricted: true,
    invisible: true,
  },
  {
    path: "/configuration",
    name: "Configuration",
    ServiceName: "Configuration",
    icon: Group,
    component: Configuration,
    layout: "/admin",
    restricted: true,
    invisible: true,
  },
  {
    path: "/reminder",
    name: "Reminder",
    ServiceName: "Reminder",
    icon: Group,
    component: Reminder,
    layout: "/admin",
    restricted: true,
    invisible: true,
  }
];

export default dashRoutes;
