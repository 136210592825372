import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CommonConfig } from "../../CommonConfig.js";
import SimpleBackdrop from "../../utils/general";
import api from "../../utils/api";
import cogoToast from "cogo-toast";
import APIConstant from "../../utils/constant";
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  InputLabel,
  FormHelperText,
  Checkbox,
  Dialog,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import Loader from "components/Shared/Loader/Loader";
import { Autocomplete } from "@material-ui/lab";
import { Calendar } from "primereact/calendar";
class EquipmentMaster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      MasterID: "",
      loading: false,
      UserId: "",
      Token: "",
      GetMasterList: [],
      rowsPerPageOptions: CommonConfig.dataTableConfig.rowsPerPageOptions,
      rowsPerPage: CommonConfig.dataTableConfig.rowsPerPage,
      search: null,

      equipmentName: "",
      equipmentNameErr: false,
      equipmentNameErrText: "",

      serialNo: "",

      make: "",
      makeList: [],

      model: "",
      modelList: [],

      AddMasterModal: false,
      EditMasterTypeModal: false,
      DeleteMasterTypeModal: false,

      userPageAccess: [],
      purchaseDate: "",
      sellDate: "",
      remark: "",
      isSellCB: false,
    };
  }

  componentDidMount = async () => {
    let data = localStorage.getItem("Infodata");
    let Userrole = JSON.parse(localStorage.getItem("Userdata"));
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");
    // if (lockstatus == 0) {
    if (token !== null) {
      this.setState({
        UserId: data,
        Token: token,
        userPageAccess: userPageAccess,
      });
      await this.GetMakeMaster(data);
      await this.GetMasterListByType(data);
    } else {
      this.props.history.push("/login");
    }
    // } else {
    //     this.props.history.push('/auth/lockscreen')
    // }
  };

  GetMakeMaster = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetMakeMaster, params)
      .then((response) => {
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserID != "Admin");

          this.setState({ makeList: filterArry });
        }
      })
      .catch((err) => {});
  };

  GetModelMaster = async (MakeId) => {
    let params = {
      UserId: this.state.UserId,
      MakeId,
    };
    api
      .post(APIConstant.path.GetModelMasterByMakeId, params)
      .then((response) => {
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserId != "Admin");

          this.setState({ modelList: filterArry });
        }
      })
      .catch((err) => {});
  };

  actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  actionTemplate = (rowData, props) => {
    return (
      <div class="bill-action icon-only-btn" style={{ display: "flex" }}>
        {this.state.userPageAccess.includes("Edit Equipment Master") ? (
          <div onClick={(e) => this.handleOpen(rowData, "EditMasterType")}>
            {" "}
            <i class="icon-editImage"></i>{" "}
          </div>
        ) : null}
        {this.state.userPageAccess.includes("Delete Equipment Master") ? (
          <div onClick={(e) => this.handleOpen(rowData, "DeleteMasterType")}>
            {" "}
            <i class="icon-delete"></i>{" "}
          </div>
        ) : null}
      </div>
    );
  };

  GetMasterListByType = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetEquipmentMaster, params)
      .then(async (res) => {
        let response = await res;
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserId != "Admin");

          this.setState({ GetMasterList: filterArry });
        }
      })
      .catch((err) => {});
  };

  GetEquipmentMasterById = async (MasterId) => {
    Loader.show();
    let params = {
      MasterId,
    };
    api
      .post(APIConstant.path.GetEquipmentMasterById, params)
      .then(async (res) => {
        let response = await res;
        if (response.Success === 1) {
          Loader.hide();

          if (response.Data.Model) {
            await this.GetModelMaster(response.Data.Make);
          }

          this.setState({
            equipmentName: response.Data.EquipmentName,
            serialNo: response.Data.SerialNo,
            remark: response.Data.Remark,
            MasterID: response.Data._id,
            make: response.Data.Make,
            model: response.Data.Model,
            purchaseDate: response.Data.PurchaseDate
              ? new Date(response.Data.PurchaseDate)
              : "",
            isSellCB: !!response.Data.SellDate,
            sellDate: response.Data.SellDate
              ? new Date(response.Data.SellDate)
              : "",
          });
        } else {
          Loader.hide();
        }
      })
      .catch((err) => {
        Loader.hide();
      });
  };

  handleOpen = async (item, type) => {
    if (type === "AddMaster") {
      this.setState({ AddMasterModal: true });
    } else if (type === "EditMasterType") {
      await this.GetEquipmentMasterById(item.EquipmentId);

      this.setState({
        MasterLabelName: item.label,
        MasterID: item.EquipmentId,
        AddMasterModal: true,
      });
    } else if (type === "DeleteMasterType") {
      this.setState({
        DeleteMasterTypeModal: true,
        MasterID: item.EquipmentId,
      });
    }
  };

  handleClose = (type) => {
    if (type === "AddMaster") {
      this.setState({
        AddMasterModal: false,

        equipmentName: "",
        equipmentNameErr: false,
        equipmentNameErrText: "",

        serialNo: "",

        make: "",
        model: "",
        remark: "",
        purchaseDate: "",
        sellDate: "",
        MasterID: "",
        isSellCB: false,
      });
    } else if (type === "EditMasterType") {
      this.setState({ EditMasterTypeModal: false });
    } else if (type === "DeleteMasterType") {
      this.setState({ DeleteMasterTypeModal: false, MasterID: "" });
    }
  };

  handleChange = (e, type) => {
    if (type === "make") {
      this.setState({
        make: e.value,
      });
      this.GetModelMaster(e._id);
    }
    if (type === "model") {
      this.setState({
        model: e.value,
      });
    } else if (type === "equipmentName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          equipmentName: "",
          equipmentNameErr: true,
          equipmentNameErrText: `Please enter equipment name.`,
        });
      } else {
        this.setState({
          equipmentName: e.target.value,
          equipmentNameErr: false,
          equipmentNameErrText: "",
        });
      }
    } else if (type === "serialNo") {
      this.setState({
        serialNo: e.target.value,
      });
    } else if (type === "remark") {
      this.setState({
        remark: e.target.value,
      });
    } else if (type === "purchaseDate") {
      this.setState({
        purchaseDate: e.value,
      });
    } else if (type === "sellDate") {
      this.setState({
        sellDate: e.value,
      });
    } else if (type === "sellCB") {
      this.setState({
        isSellCB: e.target.checked,
      });
    }
  };

  validation = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(this.state.equipmentName)) {
      this.setState({
        equipmentName: "",
        equipmentNameErr: true,
        equipmentNameErrText: "Equipment name is required.",
      });
      formIsValid = false;
    } else {
      this.setState({ equipmentNameErr: false, equipmentNameErrText: "" });
    }

    return formIsValid;
  };

  AddMaster = () => {
    if (this.validation()) {
      this.setState({ loading: true });

      let params = {
        EquipmentName: this.state.equipmentName,
        SerialNo: this.state.serialNo,
        Make: this.state.make,
        Model: this.state.model,
        UserID: this.state.UserId,
        EquipmentId: this.state.MasterID,
        Remark: this.state.remark,
        PurchaseDate: this.state.purchaseDate,
        SellDate: this.state.sellDate,
      };
      api
        .post(APIConstant.path.AddUpdateEquipmentMaster, params)
        .then((res) => {
          if (res.Success == 1) {
            this.setState({ loading: false });
            this.handleClose("AddMaster");
            this.GetMasterListByType(this.state.UserId);
            cogoToast.success(res.Message);
          } else {
            this.setState({ loading: false });
            cogoToast.error(res.Message);
          }
        });
    }
  };

  DeleteMasterType = () => {
    this.setState({ loading: true });
    let params = {
      EquipmentId: this.state.MasterID,
      UserId: this.state.UserId,
    };
    api.post(APIConstant.path.DeleteEquipmentMaster, params).then((res) => {
      if (res.Success == 1) {
        this.setState({
          loading: false,
          DeleteMasterTypeModal: false,
          MasterID: "",
        });
        this.GetMasterListByType(this.state.UserId);
        cogoToast.success(res.Message);
      } else {
        this.setState({ loading: false });
        cogoToast.error(res.Message);
      }
    });
  };

  render() {
    const {
      UserId,
      search,
      userPageAccess,
      GetMasterList,
      AddMasterModal,
      EditMasterTypeModal,
      DeleteMasterTypeModal,
      MasterID,
      equipmentName,
      equipmentNameErr,
      equipmentNameErrText,
      serialNo,
      make,
      makeList,
      model,
      modelList,
      purchaseDate,
      sellDate,
      remark,
      isSellCB,
    } = this.state;

    let filterGetMasterList = [];
    if (GetMasterList.length) {
      filterGetMasterList = GetMasterList;
      if (search) {
        filterGetMasterList = filterGetMasterList.filter((x) => {
          return (
            x.EquipmentName.toLowerCase().indexOf(search.toLowerCase()) !==
              -1 ||
            x.serialNo.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            x.Make.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            x.Model.toLowerCase().indexOf(search.toLowerCase()) !== -1
          );
        });
      }
    }

    return (
      <div className="main-access-contianer">
        <div className="heading-area">
          <h1>Equipment Master</h1>
          <div className="heading-area-right">
            <div className="searchbar-area p-as-center">
              <InputText
                type="search"
                onInput={(e) => this.setState({ search: e.target.value })}
                placeholder="Global Search"
                size="30"
                style={{ marginRight: 10 }}
              />
              <i className="icon-search"></i>
            </div>
            {userPageAccess.includes("Add Equipment Master") ? (
              <div>
                <div
                  className="add-btn1"
                  onClick={(e) => this.handleOpen(e, "AddMaster")}
                >
                  <i className="icon-add"></i> Add Equipment Master
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="main-access-card-master">
          {this.state.loading === true ? (
            <div className="loading">
              <SimpleBackdrop />
            </div>
          ) : null}
          {userPageAccess.includes("View Equipment Master") ? (
            <DataTable
              className="main-table table-td"
              value={filterGetMasterList}
              editMode="row"
              dataKey="id"
              responsiveLayout="scroll"
              paginator={true}
              rows={this.state.rowsPerPage}
              totalRecords={filterGetMasterList.length}
              rowsPerPageOptions={this.state.rowsPerPageOptions}
              globalFilter={search}
              emptyMessage="No records found"
              responsive={false}
              resizableColumns={true}
              columnResizeMode="fit"
              scrollable={true}
              scrollWidth="auto"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            >
              <Column
                field="Index"
                header="Sr.No"
                body={this.actionBodyTemplateSrNo}
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="EquipmentName"
                header="Equipment Name"
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="SerialNo"
                header="Vehicle no./Serial No."
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="MakeName"
                header="Make Name"
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="ModelName"
                header="Model Name"
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="PurchaseDate"
                header="Purchase Date"
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="SellDate"
                header="Sell Date"
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="Remark"
                header="Remark"
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="Action"
                header="Action"
                body={this.actionTemplate}
                headerStyle={{ width: "10%", minWidth: "8rem" }}
                bodyStyle={{ textAlign: "center" }}
              ></Column>
            </DataTable>
          ) : null}
          <Dialog
            open={AddMasterModal}
            style={{ maxHeight: "calc(100vh - 45px)" }}
            onClose={() => this.handleClose("AddMaster")}
            aria-labelledby="cha-form-dialog-title"
          >
            <DialogTitle
              id="cha-form-dialog-title"
              className="custom-dialog-header"
            >
              <div>{MasterID !== "" ? "Edit" : "Add"} Equipment Master</div>
              <div
                className="dialog-close"
                onClick={(e) => this.handleClose("AddMaster")}
              >
                <i className="icon-modal-close"></i>
              </div>
            </DialogTitle>
            <DialogContent className="CHA-dialog-content-area">
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="equipmentName"
                    // id="chaNo"
                    label="Equipment Name*"
                    type="text"
                    value={equipmentName}
                    onChange={(e) => this.handleChange(e, "equipmentName")}
                    error={equipmentNameErr}
                    helperText={equipmentNameErrText}
                    fullWidth
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="serialNo"
                    // id="chaNo"
                    label="Vehicle no./Serial No.*"
                    type="text"
                    value={serialNo}
                    onChange={(e) => this.handleChange(e, "serialNo")}
                    fullWidth
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <div className="input-control select p-d-flex">
                    <FormControl fullWidth>
                      <Autocomplete
                        id="make"
                        name="make"
                        value={
                          make && makeList.length
                            ? makeList.filter((y) => y.value == make)[0]
                            : ""
                        }
                        options={makeList}
                        getOptionLabel={(option) =>
                          option.label ? option.label : option
                        }
                        onChange={(e, value) =>
                          this.handleChange(value, "make")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Make"
                            label="Make"
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <div className="input-control select p-d-flex">
                    <FormControl fullWidth>
                      <Autocomplete
                        id="model"
                        name="model"
                        value={
                          model && modelList.length
                            ? modelList.filter((y) => y.value == model)[0]
                            : ""
                        }
                        options={modelList}
                        getOptionLabel={(option) =>
                          option.label ? option.label : option
                        }
                        onChange={(e, value) =>
                          this.handleChange(value, "model")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Model"
                            label="Model"
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <Calendar
                    className="p-w-100 p-mt-2"
                    id="purchaseDate"
                    type="date"
                    value={purchaseDate}
                    onChange={(e) => this.handleChange(e, "purchaseDate")}
                    showIcon
                    placeholder="Purchase date"
                    dateFormat="dd/mm/yy"
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Checkbox
                    name={"Sell CB"}
                    checked={isSellCB}
                    onChange={(e) => this.handleChange(e, "sellCB")}
                  />
                  Sell CB
                  {isSellCB && (
                    <Calendar
                      className="p-w-100 p-mt-2"
                      id="sellDate"
                      type="date"
                      value={sellDate}
                      onChange={(e) => this.handleChange(e, "sellDate")}
                      showIcon
                      placeholder="Sell date"
                      dateFormat="dd/mm/yy"
                    />
                  )}
                </GridItem>
                <GridItem xs={12} md={6}>
                  <TextField
                    autoFocus
                    name="remark"
                    // id="chaNo"
                    label="Remark"
                    type="text"
                    value={remark}
                    onChange={(e) => this.handleChange(e, "remark")}
                    fullWidth
                  />
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={(e) => this.handleClose("AddMaster")}
              >
                Close
              </Button>
              <Button
                className="primary-btn"
                onClick={(e) => this.AddMaster(e)}
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          {/* Modal For Delete */}
          <Dialog
            open={DeleteMasterTypeModal}
            onClose={() => this.handleClose("DeleteMasterType")}
            aria-labelledby="cha-form-dialog-title"
          >
            <DialogTitle
              id="cha-form-dialog-title"
              className="custom-dialog-header"
            >
              <div>Delete Equipment Master</div>
              {/* <div className="dialog-close" onClick={(e) => this.handleClose("DeleteMasterType")}><i className="icon-modal-close"></i></div> */}
            </DialogTitle>
            <DialogContent className="CHA-dialog-content-area">
              <h6>Are you sure want to delete?</h6>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={(e) => this.handleClose("DeleteMasterType")}
              >
                Close
              </Button>
              <Button
                className="primary-btn"
                onClick={(e) => this.DeleteMasterType(e)}
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}
export default EquipmentMaster;
