/*eslint-disable*/
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import logo from "../../assets/equator-img/logo/portlinkLogo.svg";
import googleIcon from "../../assets/img/icon/google.svg";
import otpIcon from "../../assets/img/icon/otp.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import firebase from "firebase";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import api from "../../utils/api";
import { apiBase } from "../../config";
import cogoToast from "cogo-toast";
import GoogleLogin from "react-google-login";
import spinners from "../../assets/Spinner.gif";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import "primeflex/primeflex.css";
import { FormControl, FormHelperText } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import APIConstant from "utils/constant";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginError: false,
      username: "",
      usernameErr: false,
      openLoginConfirmByMail: false,
      password: "",
      passwordErr: false,
      GoogleEmail: "",
      google_email: "",
      isloggedIn: false,
      isAuth: false,
      value: "",

      errors: {},
      fields: {},
      isSignedIn: false,

      helperText: "",
      error: false,
      redirect: false,
      loading: false,
      showPassword: false,
      isPhoneVerified: true,
      isVerified: false,
      openLoginConfirm: false,
    };
    this.handleChange_2 = this.handleChange_2.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  googleTranslateElementInit = () => {
    // new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT }, 'google_translate_element')
    new window.google.translate.TranslateElement(
      { pageLanguage: "en" },
      "google_translate_element"
    );
  };

  onSubmit = () => {
    this.props.history.push("/auth/register");
  };

  onSubmit1 = () => {
    this.props.history.push("/auth/loginwithotp");
  };

  redirectforgot = () => {
    this.props.history.push("/auth/forgotpassword");
  };

  componentDidMount() {
    // window.history.forward();
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = this.googleTranslateElementInit;
    let token = localStorage.getItem("Token");
    // let lockstatus = localStorage.getItem('LockStatus');
    // if (lockstatus == 0) {
    if (token !== null) {
      let userrole = JSON.parse(localStorage.getItem("Userdata"));
      let useraccess = JSON.parse(localStorage.getItem("UserAccessData"));
      // if (userrole.UserRoll == "Transporter" && !useraccess.includes('View Vessel')) {
      //   this.props.history.push('/admin/viewtruck')
      // } else if (userrole.UserRoll !== "Transporter" && userrole.UserRoll !== "Driver") {
      //   this.props.history.push('/admin/trucklist')
      // } else if (userrole.UserRoll == "Driver" && !useraccess.includes('View Vessel')) {
      //   this.props.history.push('/admin/drivertruck')
      // }
    } else {
      let data = localStorage.getItem("RegisterInfoData");
      // if (data != null) {
      //   this.GetUserDetailsById(data);
      // }
      // else {
      // }
      this.setState({ userid: data });
    }
    // } else {
    //   this.props.history.push('/auth/lockscreen')
    // }
    try {
      firebase.initializeApp({
        // apiKey: "AIzaSyDrpu0F2oQ6AuRK_lIOa_furDY95zHk83o",
        // authDomain: "chatmodule-d1934.firebaseapp.com",
        // databaseURL: "https://chatmodule-d1934.firebaseio.com",
        // projectId: "chatmodule-d1934",
        // storageBucket: "chatmodule-d1934.appspot.com",

        // apiKey: "AIzaSyAf7p6zotM6aYuSslkSmTAwhi2S8VRPuAM",
        // authDomain: "groupchat-28342.firebaseapp.com",
        // databaseURL: "https://groupchat-28342.firebaseio.com",
        // projectId: "groupchat-28342",
        // storageBucket: "groupchat-28342.appspot.com",
        // messagingSenderId: "638308978905",
        // appId: "1:638308978905:web:f379cc71ac41677378aec8",
        // measurementId: "G-4LSYBE8T5V"

        apiKey: "AIzaSyDMzirvneIfz4aaH9w61eHDpdj7u3EUAWU",
        authDomain: "chat123-60e6d.firebaseapp.com",
        databaseURL: "https://chat123-60e6d.firebaseio.com",
        projectId: "chat123-60e6d",
        storageBucket: "chat123-60e6d.appspot.com",
        messagingSenderId: "660912887197",
        appId: "1:660912887197:web:0db707850c391b75cfcb74",
        measurementId: "G-LYYH3Z3H4H",

        // apiKey: "AIzaSyDa26uWEp5PUP8CLLu4TqOCqtTqPOyQ8wY",
        // authDomain: "chat-c801e.firebaseapp.com",
        // databaseURL: "https://chat-c801e.firebaseio.com",
        // projectId: "chat-c801e",
        // storageBucket: "chat-c801e.appspot.com",
        // messagingSenderId: "464373097413",
        // appId: "1:464373097413:web:0abe990ed3dab807b1a1ac",
        // measurementId: "G-6XWGL2J6Y3"
      });
    } catch (err) {
      if (!/already exists/.test(err.message)) {
        // console.error('Firebase initialization error raised', err.stack)
      }
    }
  }

  handleChange_2 = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    let value = e.target.value.toLowerCase();
    let name = e.target.name;
    if (name === "username") {
      if (value === "" || value === null) {
        this.setState({
          usernameErr: true,
          UserhelperText: "Please enter email or mobile number",
        });
      } else {
        this.setState({ usernameErr: false, UserhelperText: "" });
      }
    }

    if (e.target.name === "password") {
      if (e.target.value === "" || e.target.value === null) {
        this.setState({
          passwordErr: true,
          passwordhelperText: "Please enter password",
        });
      } else {
        this.setState({ passwordErr: false, passwordhelperText: "" });
      }
    }
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  validate(e) {
    let formIsValid = true;
    this.setState({ [e.target.name]: e.target.value });
    let regExp = /^([0-9]{10})|[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (this.state.username === "" || this.state.username === null) {
      this.setState({
        usernameErr: true,
        UserhelperText: "Please enter email or mobile number",
      });
      formIsValid = false;
    } else if (!this.state.username.match(regExp)) {
      this.setState({
        usernameErr: true,
        UserhelperText: "Email or mobile number is not valid",
      });
      formIsValid = false;
    } else {
      this.setState({ usernameErr: false, UserhelperText: "" });
    }

    if (this.state.password === "" || this.state.password === null) {
      this.setState({
        passwordErr: true,
        passwordhelperText: "Please enter password",
      });
      formIsValid = false;
    } else {
      this.setState({ passwordErr: false, passwordhelperText: "" });
    }

    // if (this.state.isVerified) {
    //   this.setState({ robotVerifyErr: false });
    // }
    // else {
    //   this.setState({ robotVerifyErr: true });
    //   formIsValid = false;
    // }

    return formIsValid;
  }

  SendOTPInMailForLogin = () => {
    var params = {
      email_phone: this.state.username,
    };
    this.setState({ loading: true });
    api
      .post(apiBase + "/ajax/player.php?action=SendOTPInMailForLogin", params)
      .then((res) => {
        if (res.Success === 1) {
          this.setState({ loading: false });
          cogoToast.success("OTP sent to your Register Number");
        }
      })
      .catch((err) => {});
  };

  GetRedirectPath = async (UserId) => {
    try {
      let params = {
        UserId: UserId,
      };
      await api
        .post(APIConstant.path.GetRedirectPath, params)
        .then(async (res) => {
          return await res;
          // if (response.Success == 1) {
          //   return res?.Info.Auth
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  handleEnter1 = (event) => {
    if (event.keyCode === 13) {
      console.log("event", event);
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 2].focus();
      event.preventDefault();
      this.handleLoginSubmit(event, false);
    }
  };

  async handleLoginSubmit(event, IsLoginConfirm) {
    event.preventDefault();
    if (this.validate(event)) {
      if (this.state.username !== "" && this.state.password !== "") {
        var params = {
          username: this.state.username,
          password: this.state.password,
          deviceType: "Web",
          IsLoginConfirm: IsLoginConfirm ? IsLoginConfirm : false,
        };
        this.setState({ loading: true });
        api
          .post(APIConstant.path.login, params)
          .then(async (res) => {
            if (res.Success === -1) {
              this.setState({ openLoginConfirm: true, loading: false });
            } else if (res.Success === -2) {
              cogoToast.error(res.Message);
              this.setState({ loading: false });
            } else if (res.Success === 1) {
              let params = {
                UserId: res.Info._id,
              };
              let path = await api.post(
                APIConstant.path.GetRedirectPath,
                params
              );
              localStorage.setItem("Userdata", JSON.stringify(res.Info, true));
              localStorage.setItem(
                "UserAccessData",
                JSON.stringify(res.AccessInfo, true)
              );
              localStorage.setItem("Userdata1", (res.Info, true));
              localStorage.setItem("Infodata", res.Info._id, true);
              localStorage.setItem(
                "IsProfileCompleted",
                res.Info.IsProfileCompleted,
                true
              );
              localStorage.setItem("SideBar", res.Info.UserRole, true);
              localStorage.setItem("Name", res.Info.Name, true);
              localStorage.setItem("Token", res.Info.Token, true);
              localStorage.setItem("LockStatus", res.Info.LockStatus, true);
              if (res.Info.Token) {
                var active = firebase
                  .database()
                  .ref()
                  .child("isLoggedInUserActive/");
                var first = "";
                var first_online = "";
                active.once("value", (snapshot) => {
                  first = snapshot.val() && snapshot.val()[res.Info.UserId];
                  first_online =
                    snapshot.val() &&
                    snapshot.val()[res.Info.UserId + "_online"];

                  if (first == null && first_online == null) {
                    firebase
                      .database()
                      .ref("isLoggedInUserActive/")
                      .update({
                        [res.Info.UserId]: true,
                        [res.Info.UserId + "_online"]: firebase.database
                          .ServerValue.TIMESTAMP,
                      });
                  } else {
                    firebase
                      .database()
                      .ref("isLoggedInUserActive/")
                      .update({
                        [res.Info.UserId]: true,
                        [res.Info.UserId + "_online"]: firebase.database
                          .ServerValue.TIMESTAMP,
                      });
                  }
                });
                console.log("/admin" + path?.Info?.Auth[0]?.Path);
                this.props.history.push(
                  path?.Info?.Auth.length
                    ? "/admin" + path?.Info?.Auth[0]?.Path
                    : "/admin/dashboard"
                );
                cogoToast.success(res.Message);
                this.setState({ loading: false });
              }
            } else if (res.Success === 2) {
              cogoToast.info("Your subscription is expired");
              this.setState({ loading: false });
            } else {
              setTimeout(() => {
                cogoToast.error(res.Message, {
                  hideAfter: 5,
                });
                this.setState({ loading: false });
              }, 3000);

              if (Number(res.Info.isPhoneVerified) === 0) {
                this.setState({ isPhoneVerified: false });
                localStorage.setItem("PhoneUserID", res.Info.UserId, true);
                localStorage.setItem("PhoneNumber", res.Info.PhoneNumber, true);
                localStorage.setItem("UserID", res.Info.UserId);
                if (res.Info.isMailVerified === 0) {
                } else {
                }
                this.SendOTPInMailForLogin();
                this.props.history.push("/auth/phoneverification");
              } else {
              }
            }
          })
          .catch((err) => {});
      }
    }
  }

  async handleGoogleSubmit(ID, IsLoginConfirm, response) {
    var params = {
      google_email: ID,
      IsLoginConfirm: IsLoginConfirm ? IsLoginConfirm : false,
      deviceType: "Web",
    };
    this.setState({ GoogleEmail: ID });
    api
      .post(apiBase + "/ajax/player.php?action=AppSocialLogin", params)
      .then((res) => {
        if (res.Success === 1) {
          localStorage.setItem("Userdata", JSON.stringify(res.Info, true));
          localStorage.setItem("Infodata", res.Info.UserId, true);
          localStorage.setItem(
            "IsProfileCompleted",
            res.Info.IsProfileCompleted,
            true
          );
          localStorage.setItem("Token", res.Info.Token, true);
          localStorage.setItem("LockStatus", res.Info.LockStatus, true);
          localStorage.setItem(
            "UserAccessData",
            JSON.stringify(res.AccessInfo, true)
          );
          if (res.Info.Token) {
            if (res.Info.UserRoll == "Transporter") {
              this.props.history.push("/admin/view-truck");
            } else if (res.Info.UserRoll == "Driver") {
              this.props.history.push("/admin/drivertruck");
            } else {
              this.props.history.push("/");
            }
            cogoToast.success(res.Message);
          }
        } else if (res.Success === 2) {
          cogoToast.info("Your subscription is expired");
          this.setState({ loading: false });
        } else if (res.Success === 0) {
          cogoToast.info(res.Message);
        } else if (res.Success === 3) {
          this.setState({ openLoginConfirmByMail: true, loading: false });
        } else if (res.Success === -1) {
          cogoToast.error(res.Message);
          localStorage.setItem("GoogleFName", response?.profileObj?.name, true);
          localStorage.setItem(
            "GoogleLName",
            response?.profileObj?.familyName,
            true
          );
          localStorage.setItem(
            "GoogleEmail",
            response?.profileObj?.email,
            true
          );
          this.props.history.push("/auth/register");
        }
      })
      .catch((err) => {
        cogoToast.error("Something went wrong");
        console.log("err", err);
      });
  }

  handleEnter(event) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

  tab(e) {
    var keycode1 = e.keyCode ? e.keyCode : e.which;
    if (keycode1 == 0 || keycode1 == 9) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  recaptchaLoaded = () => {
    if (this.state.isVerified === true) {
    } else {
    }
  };

  verifyCallback = (response) => {
    this.setState({ reCaptchaResponse: response });
    if (response) {
      this.setState({ isVerified: true });
      this.setState({ robotVerifyErr: false, verifyHelpertext: "" });
    } else {
      this.setState({
        robotVerifyErr: true,
        verifyHelpertext: "Please verify that your are human",
      });
    }
  };

  responseGoogle = async (response) => {
    if (!response.error) {
      await this.handleGoogleSubmit(
        response?.profileObj?.email,
        false,
        response
      );
    }
  };

  render() {
    const { loading } = this.state;

    return (
      <div className="container-fluid">
        <div className="p-grid p-m-0">
          <div className="p-col-12 p-lg-6 p-as-center p-mt-4 main-auth-area">
            <div
              id="google_translate_element"
              className="login-language"
              style={{
                paddingTop: "0px",
                position: "absolute",
                top: "20px",
                left: "20px",
                zIndex: "99",
                backgroundColor: "white",
                padding: "0px",
              }}
            ></div>

            <div className="p-d-flex p-ai-center p-h-100 p-position-relative">
              <div className="p-w-100">
                <div id="logo" style={{ textAlign: "center" }}>
                  <div className="col text-center login-logo">
                    <img
                      src={logo}
                      className="img-thumbnail rounded-circle"
                      style={{ borderRadius: "50%" }}
                      alt=""
                      width="10%"
                      height="10%"
                    />
                  </div>
                </div>
                <div className="login-form">
                  <h2 className="p-m-0" style={{ textAlign: "center" }}>
                    LOGIN
                  </h2>
                  <h5
                    className="sub-heading p-mb-5 p-mt-0"
                    style={{ textAlign: "center", color: "#06BEE1" }}
                  >
                    to your account
                  </h5>

                  <form
                    method="post"
                    onSubmit={(e) => this.handleLoginSubmit(e, false)}
                  >
                    <div className="loginform-inner">
                      <div className="login-input">
                        <span className="input-icn">
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                        </span>
                        <TextField
                          label="Your Email / Mobile Number"
                          name="username"
                          id="username"
                          className=""
                          autoFocus
                          value={this.state.username}
                          onChange={this.handleChange_2}
                          helperText={this.state.UserhelperText}
                          onKeyPress={this.handleEnter}
                          error={this.state.usernameErr}
                          ref="username"
                          inputProps={{ maxLength: 40 }}
                        />
                      </div>

                      <div className="login-input p-m-0">
                        <span className="input-icn">
                          <i
                            className="fa fa-lock prefix"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <TextField
                          label="Password"
                          type={this.state.showPassword ? "text" : "password"}
                          name="password"
                          id="password"
                          className=""
                          value={this.state.password}
                          onChange={this.handleChange_2}
                          onKeyDown={this.handleEnter1}
                          inputProps={{ minLength: 8, maxLength: 20 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {!this.state.showPassword ? (
                                  <Tooltip
                                    placement="right"
                                    title="Show password"
                                    aria-label="show password"
                                  >
                                    <IconButton
                                      aria-label="Toggle password visibility"
                                      data-tooltip="show password"
                                      onClick={this.handleClickShowPassword}
                                    >
                                      <VisibilityOff />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    placement="right"
                                    title="Hide password"
                                    aria-label="Hide password"
                                  >
                                    <IconButton
                                      aria-label="Toggle password visibility"
                                      data-tooltip=""
                                      onClick={this.handleClickShowPassword}
                                    >
                                      <Visibility />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </InputAdornment>
                            ),
                          }}
                          helperText={this.state.passwordhelperText}
                          error={this.state.passwordErr}
                          ref="password"
                        />
                      </div>

                      <div className="forgot">
                        <p>
                          Forgot
                          <Link to="/auth/forgotpassword">
                            <span> Password?</span>
                          </Link>
                        </p>
                      </div>

                      <div className="login-input p-m-0">
                        <span className="input-icn">
                          <i className="fa fa-refresh" aria-hidden="true"></i>
                        </span>

                        <FormControl
                          error={this.state.robotVerifyErr}
                          style={{ padding: "10px" }}
                        >
                          {/* <GoogleReCaptchaProvider reCaptchaKey="6LcYj2QfAAAAAOH90ctQoHDjDEJJPOK9Doiw8OMk">
                            <GoogleReCaptcha onVerify={handleVerify} />
                          </GoogleReCaptchaProvider> */}
                          {/* <Recaptcha sitekey="6Ldpgd0ZAAAAADzGdKNDFrON5N_InsmaYHc8IlyG" render="explicit"
                            onloadCallback={this.recaptchaLoaded}
                            onKeyDown={this.handleEnter1}
                            verifyCallback={this.verifyCallback}
                            helperText={this.state.verifyHelpertext}
                          /> */}
                          <input type="hidden" name="token" id="token" />
                          <input type="hidden" name="action" id="action" />
                          {/* {this.state.robotVerifyErr && <FormHelperText> <div className="p-text-center"> Please verify that you are human</div></FormHelperText>} */}
                        </FormControl>
                      </div>

                      <div className="login-btn">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={loading}
                        >
                          LOGIN
                        </Button>
                      </div>
                      <br />

                      <div
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          width: "65px",
                          height: "65px",
                          margin: "auto",
                          textAlign: "center",
                        }}
                      >
                        {this.state.loading === true ? (
                          <img src={spinners} alt="loading..." />
                        ) : null}
                      </div>

                      <div>
                        <input
                          type="hidden"
                          className="input-with-icon-grid"
                          name="google_email"
                          id="google_email"
                          value={this.state.value}
                        />
                      </div>

                      <div className="login-snap">
                        <p style={{ textAlign: "center" }}>
                          Don't have an account?
                          <Link to="/auth/register"> Register</Link>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="down-arrow">
                <a href="#mainSection">
                  <i className="fas fa-arrow-down"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            id="mainSection"
            className="p-col-12 p-lg-6 p-p-0 login-outer p-d-flex p-jc-center p-ai-center"
          >
            <div>
              <h2
                className="p-m-0"
                style={{ textAlign: "center", color: "#fff" }}
              >
                Join Hands
              </h2>
              <h5
                className="sub-heading p-mb-3 p-mt-0"
                style={{ textAlign: "center", color: "#ffffff" }}
              >
                By Authentication Process
              </h5>
              <div className="loginwith-btns">
                <GoogleLogin
                  clientId="261127677410-baes9i4it73mn71pfneiiuq0at9gh569.apps.googleusercontent.com"
                  buttonText="Login with Google"
                  onSuccess={(e) => this.responseGoogle(e)}
                  onFailure={(e) => this.responseGoogle(e)}
                  render={(renderProps) => (
                    <Button
                      className="google-btn"
                      onClick={renderProps.onClick}
                    >
                      <div className="p-pr-4">
                        <img
                          src={googleIcon}
                          className="img-thumbnail"
                          alt=""
                          width="35px"
                          height="20px"
                        />
                      </div>
                      <div>LOGIN WITH GOOGLE</div>
                    </Button>
                  )}
                />
                <Button className="otp-btn" onClick={() => this.onSubmit1()}>
                  <div className="p-pr-4">
                    <img
                      src={otpIcon}
                      className="img-thumbnail"
                      alt=""
                      width="35px"
                      height="8px"
                    />
                  </div>
                  <div>LOGIN WITH OTP</div>
                </Button>
              </div>
              <div className="login-snap">
                <p style={{ textAlign: "center" }}>Don't have an account?</p>
                <Link to="/auth/register" className="text-decore">
                  {" "}
                  Register
                </Link>
              </div>
            </div>
          </div>

          {/** Login Confirmation Modal*/}

          <Dialog
            className="login-alert"
            open={this.state.openLoginConfirm}
            onClose={() => this.setState({ openLoginConfirm: false })}
            aria-labelledby="form-dialog-title"
          >
            <form method="post">
              {/* <DialogTitle id="form-dialog-title" className="custom-dialog-header">
                <div> Login Confirmation </div>
                <div className="dialog-close" onClick={() => this.setState({ openLoginConfirm: false })}>
                  <i className="icon-modal-close"></i>
                </div>
              </DialogTitle> */}
              <DialogContent className="login-alert-container">
                <GridContainer>
                  <GridItem md={6}>
                    <div className="login-alert-img">
                      <svg
                        viewBox="0 0 316 309"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Group 886">
                          <g id="g10">
                            <path
                              id="cloud"
                              d="M284.697 22.477L283.222 23.3745C283.34 22.8025 283.424 22.2352 283.424 21.6886C283.424 18.5408 281.328 17.2661 278.743 18.84C277.991 19.2972 277.291 19.9585 276.661 20.7253L276.662 20.7027C276.662 16.4654 273.841 14.7476 270.36 16.8662C266.88 18.9849 264.058 24.1365 264.058 28.3738C264.058 28.5901 264.07 28.7943 264.085 28.9966C263.348 28.8583 262.469 29.0492 261.524 29.6241C258.938 31.198 256.843 35.0241 256.843 38.1719C256.843 38.6056 256.887 38.9989 256.962 39.3592L256.176 39.8362C254.042 41.1354 252.312 43.6698 252.312 45.4977C252.312 47.3257 254.042 47.7537 256.176 46.4554L284.697 29.0963C286.83 27.7971 288.561 25.2626 288.561 23.4347C288.561 21.6068 286.83 21.1778 284.697 22.477Z"
                              fill="#E7E7E5"
                            />
                            <g id="g12">
                              <path
                                id="path14"
                                d="M46.7293 285.346C108.219 316.243 207.915 316.244 269.404 285.347C330.894 254.45 330.894 204.356 269.404 173.46C207.914 142.562 108.218 142.562 46.7286 173.459C-14.7612 204.355 -14.7612 254.45 46.7293 285.346"
                                fill="#F2F3F1"
                              />
                              <path
                                id="path16"
                                d="M254.696 259.497C262.703 263.521 275.686 263.521 283.694 259.497C291.701 255.473 291.701 248.95 283.694 244.925C275.686 240.903 262.703 240.903 254.696 244.925C246.687 248.95 246.687 255.473 254.696 259.497Z"
                                fill="#E7E7E5"
                              />
                              <g id="plant4">
                                <path
                                  id="path18"
                                  d="M42.0176 253.69L49.2674 249.277C49.2674 249.277 55.2484 244.644 58.21 236.04C60.4511 229.529 60.1419 224.576 58.5349 222.094C56.5581 219.04 51.444 219.573 51.2408 226.014C50.8409 238.695 47.331 247.469 42.0176 253.69Z"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path20"
                                  d="M42.0176 253.69C47.331 247.469 50.8409 238.695 51.2408 226.014C51.367 222.015 53.3856 220.294 55.3719 220.294C56.5845 220.294 57.7855 220.936 58.5349 222.094C59.3352 223.331 59.8138 225.18 59.8156 227.53C59.8173 229.897 59.3349 232.772 58.21 236.04C55.2484 244.644 49.2674 249.277 49.2674 249.277L42.0176 253.69Z"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path22"
                                  d="M42.017 254.16C41.8751 254.16 41.7363 254.09 41.6496 253.957C41.5095 253.743 41.56 253.451 41.7625 253.303C58.5363 241.066 55.2868 224.019 55.2523 223.847C55.2003 223.594 55.3538 223.343 55.5942 223.289C55.8369 223.232 56.0726 223.396 56.1245 223.649C56.1607 223.826 59.5507 241.47 42.2704 254.076C42.193 254.133 42.1044 254.16 42.017 254.16"
                                  fill="white"
                                />
                              </g>
                              <g id="plant3">
                                <path
                                  id="path24"
                                  d="M27.5165 262.515C27.5165 262.515 24.9927 260.348 25.6031 257.416C26.2138 254.482 27.8417 252.373 25.0436 248.59C22.2452 244.806 21.5219 239.765 24.8132 237.031C27.3571 234.917 31.3272 237.913 32.7612 232.312C33.807 228.229 33.4501 219.189 38.3909 216.182C43.3309 213.174 46.3988 217.702 45.64 223.833C44.9617 229.319 44.0062 234.601 44.7428 236.039C46.1841 238.85 48.6615 238.472 48.5486 243.626C48.4436 248.425 44.1397 252.397 44.1397 252.397L27.5165 262.515Z"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path26"
                                  d="M35.8083 257.937C35.5856 257.937 35.3931 257.762 35.3656 257.523C34.0883 246.376 37.1322 227.129 39.6493 221.408C39.7525 221.172 40.016 221.068 40.2419 221.18C40.4653 221.289 40.5626 221.568 40.4587 221.803C37.9855 227.426 35.0306 246.756 36.2516 257.411C36.2809 257.668 36.1066 257.902 35.8623 257.933L35.8083 257.937"
                                  fill="white"
                                />
                                <path
                                  id="path28"
                                  d="M35.5683 249.259L35.3724 249.211C32.2763 247.615 30.3138 241.946 30.2315 241.705C30.1482 241.462 30.2685 241.193 30.5003 241.104C30.7324 241.017 30.9873 241.143 31.0716 241.388C31.0901 241.443 32.9933 246.937 35.7646 248.366C35.9859 248.48 36.0776 248.761 35.9696 248.995C35.8917 249.162 35.7332 249.259 35.5683 249.259"
                                  fill="white"
                                />
                                <path
                                  id="path30"
                                  d="M35.5165 253.016C35.3399 253.016 35.1725 252.903 35.1025 252.72C35.0107 252.479 35.1219 252.206 35.3506 252.109C40.5612 249.907 43.8097 243.361 43.8418 243.295C43.9551 243.065 44.2238 242.973 44.4428 243.093C44.662 243.212 44.7477 243.496 44.6348 243.727C44.4971 244.009 41.1992 250.652 35.6828 252.982L35.5165 253.016"
                                  fill="white"
                                />
                              </g>
                              <g id="plant5">
                                <path
                                  id="path32"
                                  d="M217.679 202.407C217.679 202.407 213.776 195.754 213.4 188.511C213.023 181.267 214.531 178.261 216.6 177.398C218.668 176.535 218.825 179.193 219.894 180.768C220.963 182.344 223.125 181.442 223.922 176.543C224.721 171.646 227.651 163.344 233.711 161.76C240.405 160.012 236.814 168.289 235.134 171.519C233.453 174.748 232.143 179.147 233.251 180.344C234.359 181.542 236.077 179.329 237.725 181.38C239.714 183.856 233.531 192.759 229.982 194.919L217.679 202.407"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path34"
                                  d="M223.022 199.606L222.999 199.606C222.753 199.592 222.564 199.37 222.577 199.112C223.559 179.848 232.477 166.506 232.568 166.374C232.711 166.162 232.99 166.114 233.19 166.265C233.39 166.416 233.436 166.71 233.292 166.921C233.204 167.052 224.436 180.184 223.467 199.161C223.456 199.412 223.259 199.606 223.022 199.606Z"
                                  fill="white"
                                />
                                <path
                                  id="path36"
                                  d="M223.534 194.052L223.317 193.993C220.818 192.535 219.434 188.209 219.376 188.025C219.299 187.779 219.425 187.512 219.66 187.431C219.893 187.346 220.147 187.483 220.224 187.729C220.236 187.77 221.556 191.891 223.75 193.17C223.965 193.295 224.043 193.581 223.924 193.809C223.842 193.964 223.69 194.052 223.534 194.052"
                                  fill="white"
                                />
                                <path
                                  id="path38"
                                  d="M223.97 191.2C223.77 191.2 223.588 191.059 223.537 190.846C223.477 190.592 223.621 190.338 223.86 190.274C230.301 188.557 232.532 184.998 232.554 184.963C232.687 184.745 232.963 184.682 233.17 184.82C233.377 184.959 233.439 185.249 233.307 185.467C233.211 185.626 230.894 189.369 224.08 191.185L223.97 191.2"
                                  fill="white"
                                />
                              </g>
                              <g id="plant2">
                                <path
                                  id="path40"
                                  d="M120.606 187.781C120.606 187.781 112.049 185.581 111.603 168.69C111.122 150.457 113.7 129.186 103.608 127.456C93.5159 125.725 87.853 138.772 87.9826 153.932C88.0962 167.243 93.1804 196.125 120.605 201.785L120.606 187.781Z"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path42"
                                  d="M120.605 201.785C93.1807 196.125 88.0966 167.243 87.983 153.932C87.9812 153.765 87.9805 153.598 87.9805 153.431C87.9784 139.393 92.9612 127.301 101.838 127.301C102.411 127.301 103.002 127.352 103.608 127.456C110.876 128.702 111.573 140.084 111.574 153.167C111.574 156.271 111.535 159.471 111.535 162.655C111.535 164.683 111.551 166.705 111.604 168.69C112.049 185.581 120.606 187.781 120.606 187.781L120.605 201.785"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path44"
                                  d="M120.606 200.048L120.525 200.04C114.354 198.856 109.332 195.277 105.597 189.404C94.5099 171.968 98.0036 139.421 99.1503 133.735C99.2011 133.481 99.4376 133.32 99.6795 133.372C99.9204 133.427 100.074 133.676 100.023 133.93C98.8888 139.552 95.4255 171.716 106.338 188.88C109.934 194.534 114.761 197.977 120.685 199.115C120.928 199.162 121.088 199.407 121.044 199.662C121.004 199.889 120.817 200.048 120.606 200.048"
                                  fill="white"
                                />
                              </g>
                              <g id="plant1">
                                <path
                                  id="path46"
                                  d="M120.606 243.413C111.758 244.677 97.3872 244.654 89.1096 237.772C80.8315 230.888 82.5535 224.957 85.7377 222.482C88.9221 220.007 91.6876 217.179 86.2097 211.688C80.7318 206.197 71.9266 197.372 69.0794 184.134C66.2317 170.896 67.6341 162.623 73.2372 159.864C78.6748 157.188 80.1064 161.439 83.4347 170.208C86.7623 178.979 94.2523 171.46 100.655 174.198C107.059 176.935 106.807 182.355 107.211 187.013C107.615 191.671 109.73 192.959 113.354 192.959C116.98 192.959 120.603 195.165 120.604 199.577L120.606 243.413"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path48"
                                  d="M120.606 228.043L120.485 228.025C120.102 227.912 110.952 225.105 97.635 212.208C83.4483 198.469 76.7496 177.871 76.6837 177.665C76.6049 177.418 76.7305 177.151 76.964 177.068C77.1982 176.987 77.451 177.118 77.5294 177.364C77.5945 177.569 84.2246 197.944 98.2382 211.516C111.378 224.239 120.634 227.092 120.726 227.12C120.963 227.191 121.101 227.45 121.035 227.701C120.979 227.907 120.801 228.043 120.606 228.043"
                                  fill="white"
                                />
                                <path
                                  id="path50"
                                  d="M101.341 215.492C101.163 215.492 100.994 215.379 100.925 215.193C98.0617 207.435 97.413 190.85 97.3871 190.148C97.3773 189.888 97.5691 189.67 97.8154 189.659C98.0439 189.662 98.2687 189.851 98.2786 190.111C98.2848 190.281 98.9478 207.241 101.757 214.852C101.846 215.093 101.732 215.366 101.503 215.46L101.341 215.492Z"
                                  fill="white"
                                />
                                <path
                                  id="path52"
                                  d="M101.265 226.651C99.8787 226.651 98.9194 226.536 98.8034 226.521C98.5588 226.491 98.3839 226.257 98.4134 225.998C98.4419 225.741 98.6624 225.55 98.9087 225.588C98.9917 225.596 107.294 226.585 111.108 222.487C111.28 222.299 111.563 222.299 111.739 222.479C111.915 222.66 111.918 222.96 111.745 223.144C108.954 226.144 104.173 226.651 101.265 226.651Z"
                                  fill="white"
                                />
                              </g>
                              <path
                                id="path106"
                                d="M129.402 256.003L213.307 204.933C214.16 204.413 214.161 203.57 213.308 203.05L205.545 198.315C204.692 197.795 203.309 197.794 202.455 198.313L118.527 249.384C117.673 249.903 117.673 250.746 118.527 251.265L126.31 256.003C127.164 256.522 128.548 256.522 129.402 256.003"
                                fill="#DADAD8"
                              />
                              <path
                                id="path108"
                                d="M210.988 7.77404L135.344 53.8156C133.211 55.1148 131.48 58.2739 131.48 60.8714V250.033C131.48 252.631 133.211 253.684 135.344 252.385L210.989 206.344C213.124 205.045 214.853 201.885 214.853 199.288L214.852 10.126C214.852 7.5285 213.123 6.47483 210.988 7.77404"
                                fill="#32515C"
                              />
                              <path
                                id="path110"
                                d="M135.343 53.8149L210.987 7.77339C212.896 6.61247 214.476 7.335 214.791 9.35766C214.501 6.93329 212.915 4.2436 210.987 3.06952L207.841 1.15506C205.707 -0.144153 202.246 -0.144153 200.113 1.15506L124.469 47.1965C123.402 47.8457 122.436 48.9596 121.736 50.2371L132.612 56.8555C133.31 55.5788 134.277 54.465 135.343 53.8149"
                                fill="#263D46"
                              />
                              <path
                                id="path112"
                                d="M120.607 243.413C120.607 246.012 122.337 249.171 124.471 250.469L127.618 252.384C129.547 253.559 132.552 253.661 134.689 252.712C132.868 253.44 131.48 252.358 131.48 250.032V60.8711C131.48 59.5719 131.913 58.1325 132.612 56.8558L121.737 50.2375C121.038 51.5141 120.605 52.9535 120.605 54.2518L120.607 243.413"
                                fill="#17272E"
                              />
                              <path
                                id="path114"
                                d="M137.424 79.0263L208.908 35.5155C210.19 34.7356 211.228 35.3678 211.228 36.9266L211.228 176.9C211.228 178.459 210.19 180.354 208.909 181.133L137.425 224.644C136.144 225.423 135.105 224.791 135.105 223.233V83.2598C135.105 81.7009 136.143 79.8053 137.424 79.0263Z"
                                fill="white"
                              />
                              <path
                                id="path116"
                                d="M173.166 37.9708C172.415 38.4281 171.807 39.5391 171.807 40.4526C171.807 41.367 172.415 41.7368 173.166 41.2796C173.917 40.8233 174.526 39.7122 174.526 38.7978C174.526 37.8843 173.917 37.5136 173.166 37.9708Z"
                                fill="#263D46"
                              />
                              <path
                                id="path118"
                                d="M173.168 209.609C170.203 211.413 167.801 215.801 167.801 219.41C167.801 223.018 170.203 224.481 173.168 222.676C176.131 220.873 178.534 216.485 178.534 212.876C178.534 209.268 176.131 207.805 173.168 209.609Z"
                                fill="#263D46"
                              />
                              <path
                                id="path120"
                                d="M160.328 52.5314C158.826 53.4458 157.609 55.668 157.609 57.4959C157.609 59.3238 158.826 60.0642 160.328 59.1507C161.829 58.2372 163.046 56.0142 163.046 54.1862C163.046 52.3583 161.829 51.6179 160.328 52.5314Z"
                                fill="#263D46"
                              />
                              <path
                                id="path122"
                                d="M166.975 51.9106C166.975 53.1289 167.839 53.5908 168.907 52.9408L186.792 42.0551C187.859 41.4059 188.724 39.8913 188.724 38.673C188.724 37.4547 187.859 36.9927 186.792 37.6428L168.907 48.5285C167.839 49.1776 166.975 50.6923 166.975 51.9106"
                                fill="#263D46"
                              />
                              <path
                                id="path124"
                                d="M126.623 85.083C127.823 85.814 128.798 87.5921 128.798 89.055C128.798 90.517 127.823 91.1096 126.623 90.3777C125.421 89.6467 124.447 87.8696 124.447 86.4067C124.447 84.9448 125.421 84.3521 126.623 85.083"
                                fill="#263D46"
                              />
                              <path
                                id="path126"
                                d="M126.623 102.747C127.823 103.479 128.798 105.257 128.798 106.719C128.798 108.181 127.823 108.773 126.623 108.042C125.421 107.311 124.447 105.533 124.447 104.071C124.447 102.608 125.421 102.017 126.623 102.747Z"
                                fill="#263D46"
                              />
                              <path
                                id="path128"
                                d="M125.95 85.4362C127.151 86.1672 128.125 87.9462 128.125 89.4082C128.125 90.8701 127.151 91.4628 125.95 90.7318C124.748 90.0009 123.775 88.2228 123.775 86.7608C123.775 85.2979 124.749 84.7052 125.95 85.4362Z"
                                fill="#32515C"
                              />
                              <path
                                id="path130"
                                d="M125.95 103.101C127.151 103.832 128.125 105.61 128.125 107.073C128.125 108.535 127.151 109.128 125.95 108.397C124.748 107.665 123.775 105.887 123.775 104.425C123.775 102.963 124.749 102.37 125.95 103.101Z"
                                fill="#32515C"
                              />
                              <g id="formArea">
                                <path
                                  id="path132"
                                  d="M211.956 37.4624C211.956 35.9036 210.918 35.2723 209.637 36.0513L201.081 41.2585L138.152 79.5612C136.872 80.3411 135.834 82.2358 135.834 83.7946L135.835 223.768C135.835 225.327 136.873 225.958 138.153 225.179L201.103 186.862L209.638 181.669C210.918 180.889 211.957 178.993 211.957 177.436L211.956 37.4624Z"
                                  fill="white"
                                />
                                <path
                                  id="path134"
                                  d="M211.957 37.4634H212.403C212.402 36.834 212.255 36.2893 211.949 35.8829C211.646 35.4746 211.173 35.2432 210.674 35.2479C210.264 35.2479 209.839 35.3871 209.415 35.6439L200.859 40.852L137.93 79.1547C137.2 79.6016 136.581 80.327 136.128 81.152C135.677 81.978 135.39 82.9065 135.389 83.7956L135.39 223.769C135.39 224.397 135.537 224.943 135.843 225.349C136.146 225.758 136.62 225.989 137.118 225.984C137.528 225.983 137.952 225.845 138.376 225.587L201.327 187.27L209.862 182.077C210.591 181.63 211.211 180.905 211.664 180.08C212.116 179.254 212.401 178.326 212.404 177.436L212.403 37.4634H211.957H211.511L211.512 177.436C211.513 178.105 211.281 178.904 210.891 179.61C210.506 180.317 209.966 180.93 209.415 181.263L200.881 186.456L137.931 224.772C137.611 224.967 137.332 225.044 137.118 225.044C136.857 225.038 136.688 224.95 136.541 224.762C136.395 224.573 136.282 224.246 136.282 223.769L136.281 83.7956C136.279 83.1267 136.512 82.328 136.9 81.6224C137.286 80.9149 137.826 80.3025 138.376 79.9694L201.305 41.6668L209.861 36.4596C210.181 36.2639 210.46 36.1877 210.674 36.1886C210.935 36.1933 211.104 36.2818 211.252 36.469C211.397 36.659 211.511 36.9864 211.511 37.4634H211.957"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path136"
                                  d="M137.117 225.984C136.82 225.984 136.541 225.909 136.292 225.758C135.71 225.403 135.39 224.697 135.39 223.768L135.389 83.7949C135.389 82.061 136.505 80.0215 137.93 79.1541L200.858 40.8514L201.305 41.6661L138.376 79.9688C137.24 80.6602 136.281 82.4119 136.281 83.7949L136.282 223.768C136.282 224.338 136.448 224.766 136.738 224.943C137.028 225.119 137.463 225.056 137.931 224.772L200.881 186.456L201.327 187.27L138.376 225.586C137.941 225.852 137.513 225.984 137.117 225.984"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path138"
                                  d="M137.116 225.984V225.513C136.89 225.513 136.693 225.459 136.513 225.35H136.514C136.304 225.221 136.143 225.036 136.024 224.775C135.905 224.514 135.835 224.175 135.835 223.767L135.834 83.7942C135.832 83.0332 136.085 82.1573 136.51 81.384C136.933 80.6088 137.526 79.9399 138.152 79.5608L200.694 41.4933L138.152 79.5608C137.492 79.9662 136.931 80.6323 136.516 81.3868C136.103 82.1432 135.836 82.9889 135.834 83.7942L135.835 223.767C135.835 224.1 135.882 224.407 135.987 224.681C136.092 224.952 136.263 225.196 136.514 225.35C136.7 225.464 136.91 225.512 137.116 225.511C137.464 225.51 137.809 225.385 138.152 225.179L138.153 225.178L200.717 187.098L200.94 187.504L201.326 187.269L201.103 186.862L138.153 225.177L138.152 225.178C137.77 225.412 137.416 225.514 137.116 225.513V225.984V226.454C137.608 226.454 138.11 226.291 138.599 225.992L201.936 187.441L201.043 185.813L137.707 224.364C137.463 224.512 137.248 224.572 137.116 224.57L136.959 224.535H136.96C136.921 224.512 136.864 224.454 136.813 224.324C136.763 224.196 136.726 224.004 136.727 223.767L136.726 83.7942C136.723 83.2176 136.937 82.4951 137.288 81.8572C137.636 81.2175 138.123 80.6624 138.598 80.3755L201.913 41.8385L201.021 40.2082L137.706 78.7461C136.906 79.2343 136.229 80.0189 135.737 80.9136C135.248 81.8102 134.943 82.8205 134.941 83.7942L134.942 223.767C134.942 224.289 135.032 224.767 135.219 225.18C135.405 225.593 135.695 225.939 136.067 226.165H136.068C136.386 226.359 136.748 226.455 137.116 226.454V225.984"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path140"
                                  d="M201.326 187.27L200.88 186.456L209.414 181.262C210.55 180.569 211.511 178.818 211.511 177.436L211.51 37.4625C211.51 36.8924 211.343 36.4644 211.054 36.2875C210.765 36.1116 210.329 36.1727 209.86 36.4587L201.304 41.6659L200.857 40.8512L209.413 35.6431C210.176 35.1802 210.917 35.1181 211.5 35.4728C212.081 35.8265 212.402 36.534 212.402 37.4625L212.403 177.436C212.403 179.169 211.287 181.208 209.861 182.076L201.326 187.27"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path142"
                                  d="M201.326 187.27L201.712 187.034L201.49 186.628L209.638 181.669C210.298 181.264 210.859 180.597 211.275 179.842C211.687 179.086 211.954 178.241 211.957 177.436L211.956 37.4625C211.955 37.1285 211.909 36.8219 211.803 36.549C211.698 36.2781 211.527 36.0335 211.277 35.8802C211.09 35.7663 210.88 35.7183 210.674 35.7183C210.327 35.7202 209.98 35.8434 209.637 36.0514L201.467 41.0234L209.637 36.0514C210.02 35.818 210.374 35.7155 210.674 35.7155C210.899 35.7165 211.096 35.771 211.277 35.8802L211.281 35.883L211.277 35.8802C211.486 36.0081 211.646 36.1934 211.766 36.455C211.885 36.7156 211.956 37.0542 211.956 37.4625L211.957 177.436C211.958 178.196 211.705 179.072 211.28 179.845C210.857 180.62 210.264 181.289 209.638 181.668L201.104 186.862L201.326 187.27L201.712 187.034L201.326 187.27L201.549 187.677L210.084 182.483C210.883 181.995 211.561 181.21 212.052 180.315C212.542 179.419 212.847 178.408 212.849 177.436L212.848 37.4625C212.847 36.9413 212.758 36.4625 212.572 36.0495C212.385 35.6365 212.095 35.2912 211.723 35.0654C211.404 34.8707 211.042 34.7738 210.674 34.7747C210.181 34.7747 209.679 34.9394 209.191 35.2357L200.248 40.679L201.14 42.3094L210.083 36.8661C210.327 36.7165 210.542 36.6582 210.674 36.6591L210.83 36.6949C210.869 36.7184 210.926 36.7758 210.977 36.9056C211.027 37.0335 211.064 37.2254 211.064 37.4625L211.065 177.436C211.066 178.012 210.854 178.734 210.502 179.372C210.153 180.012 209.667 180.567 209.191 180.854L200.27 186.284L201.164 187.912L201.549 187.677L201.326 187.27"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path144"
                                  d="M173.894 69.9896C181.902 65.1154 188.393 69.0667 188.393 78.815C188.393 88.5633 181.902 100.417 173.894 105.291C165.887 110.165 159.395 106.214 159.395 96.4658C159.395 86.7175 165.887 74.8637 173.894 69.9896Z"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path146"
                                  d="M173.895 77.6621C176.493 76.0807 178.6 77.363 178.6 80.5249C178.6 83.6878 176.493 87.5336 173.895 89.1151C171.297 90.6956 169.191 89.4143 169.191 86.2514C169.191 83.0885 171.297 79.2436 173.895 77.6621"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path148"
                                  d="M183.064 94.525C183.064 89.3291 179.604 87.2227 175.336 89.8211L172.667 91.4449C168.4 94.0424 164.939 100.361 164.939 105.556V106.954C167.407 107.818 170.512 107.351 173.895 105.291C177.375 103.174 180.566 99.7369 183.064 95.7301V94.525"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path150"
                                  d="M171.794 89.791C170.251 89.791 169.191 88.4946 169.191 86.2509C169.191 83.088 171.297 79.243 173.895 77.6616C174.65 77.2016 175.364 76.9843 175.997 76.9843C177.54 76.9843 178.6 78.2816 178.6 80.5244C178.6 83.6873 176.493 87.5331 173.895 89.1146C173.14 89.5737 172.426 89.791 171.794 89.791"
                                  fill="#FBC2F9"
                                />
                                <path
                                  id="path152"
                                  d="M167.446 107.366C166.556 107.366 165.716 107.226 164.939 106.954V105.556C164.939 100.36 168.4 94.042 172.667 91.4446L175.336 89.8208C176.576 89.0653 177.749 88.7079 178.787 88.7079C181.323 88.7079 183.064 90.8387 183.064 94.5247V95.7298C180.566 99.7365 177.375 103.173 173.895 105.291C171.577 106.702 169.388 107.366 167.446 107.366"
                                  fill="#FBC2F9"
                                />
                                <path
                                  id="path154"
                                  d="M145.841 126.789L164.498 115.433C165.352 114.912 166.044 115.334 166.044 116.373V117.022C166.044 118.062 165.352 119.326 164.498 119.845L145.841 131.201C144.987 131.721 144.295 131.3 144.295 130.26V129.611C144.295 128.571 144.987 127.308 145.841 126.789Z"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path156"
                                  d="M145.15 131.424C144.643 131.424 144.295 130.998 144.295 130.26V129.611C144.295 128.571 144.987 127.308 145.841 126.789L164.498 115.433C164.746 115.281 164.981 115.21 165.189 115.21C165.696 115.21 166.044 115.636 166.044 116.373V117.022C166.044 118.062 165.352 119.325 164.498 119.845L145.841 131.201C145.592 131.352 145.358 131.424 145.15 131.424Z"
                                  fill="#FBC2F9"
                                />
                                <path
                                  id="path158"
                                  d="M145.068 140.497L201.519 106.137C201.947 105.877 202.292 106.088 202.292 106.607V113.551C202.292 114.07 201.947 114.703 201.519 114.962L145.068 149.322C144.641 149.582 144.295 149.372 144.295 148.853V141.908C144.295 141.389 144.641 140.757 145.068 140.497"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path160"
                                  d="M144.722 149.434C144.469 149.434 144.295 149.22 144.295 148.852V141.907C144.295 141.388 144.641 140.757 145.068 140.496L201.519 106.136C201.643 106.061 201.761 106.025 201.865 106.025C202.119 106.025 202.292 106.238 202.292 106.607V113.551C202.292 114.07 201.947 114.702 201.519 114.962L201.115 115.208C200.058 113.783 199.284 112.84 198.583 112.84C198.389 112.84 198.2 112.914 198.011 113.069C197.348 113.618 197.83 114.808 198.914 116.548L145.068 149.322C144.944 149.398 144.827 149.434 144.722 149.434"
                                  fill="#C578C2"
                                />
                                <path
                                  id="path162"
                                  d="M145.841 157.677L164.498 146.321C165.352 145.801 166.044 146.222 166.044 147.262V147.911C166.044 148.95 165.352 150.214 164.498 150.734L145.841 162.09C144.987 162.61 144.295 162.188 144.295 161.149V160.5C144.295 159.46 144.987 158.198 145.841 157.677Z"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path164"
                                  d="M145.15 162.312C144.643 162.312 144.295 161.886 144.295 161.149V160.5C144.295 159.46 144.987 158.198 145.841 157.677L164.498 146.321C164.746 146.17 164.981 146.098 165.189 146.098C165.696 146.098 166.044 146.524 166.044 147.262V147.911C166.044 148.95 165.352 150.214 164.498 150.733L145.841 162.09C145.592 162.241 145.358 162.312 145.15 162.312Z"
                                  fill="#FBC2F9"
                                />
                                <path
                                  id="path166"
                                  d="M145.068 171.386L201.519 137.026C201.947 136.766 202.292 136.976 202.292 137.496V144.44C202.292 144.959 201.947 145.591 201.519 145.851L145.068 180.211C144.641 180.471 144.295 180.261 144.295 179.741V172.797C144.295 172.277 144.641 171.645 145.068 171.386"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path168"
                                  d="M144.722 180.322C144.469 180.322 144.295 180.11 144.295 179.741V172.797C144.295 172.278 144.641 171.646 145.068 171.386L201.519 137.026C201.643 136.951 201.761 136.915 201.865 136.915C202.118 136.915 202.292 137.128 202.292 137.496V144.44C202.292 144.96 201.947 145.592 201.519 145.851L145.068 180.211C144.944 180.287 144.827 180.322 144.722 180.322"
                                  fill="#C578C2"
                                />
                                <path
                                  id="path170"
                                  d="M167.591 175.328L189.874 161.766C190.728 161.246 191.42 161.667 191.42 162.707V169.974C191.42 171.014 190.728 172.277 189.874 172.796L167.591 186.36C166.737 186.879 166.045 186.458 166.045 185.419V178.15C166.045 177.111 166.737 175.847 167.591 175.328"
                                  fill="#893C9B"
                                />
                                <path
                                  id="path172"
                                  d="M166.901 186.582C166.394 186.582 166.045 186.157 166.045 185.419V178.151C166.045 177.111 166.737 175.848 167.591 175.328L189.874 161.766C190.122 161.615 190.356 161.543 190.564 161.543C191.071 161.543 191.42 161.97 191.42 162.707V169.975C191.42 171.014 190.728 172.278 189.874 172.797L167.591 186.36C167.343 186.511 167.109 186.582 166.901 186.582"
                                  fill="#FBC2F9"
                                />
                              </g>
                              <g id="Group 885">
                                <path
                                  id="path174"
                                  d="M234.604 148.895L233.803 149.907L232.803 150.407L232.303 150.907L231.803 151.407L231.303 151.907L232.303 151.448H231.301L230.303 151.948L230.803 151.448V151.948L230.303 152.448L229.803 152.948L229.303 153.407V153.736C229.303 153.736 229.716 154.503 230.303 155.5C230.888 156.497 232.881 158.943 234.604 159C236.328 159.058 249.305 146.486 250.031 145.848C252.355 143.805 253.088 143.505 253.489 145.345C253.614 145.921 253.696 146.578 253.784 147.319C254.063 149.649 255.568 157.986 255.568 157.986C255.568 157.986 262.698 161.628 268.569 159.832C274.44 158.037 276.408 155.452 276.408 155.452L273.471 150.406C274.271 148.323 277.964 139.899 279.123 133.996L260.74 130.867C260.362 130.78 259.76 130.621 258.991 130.408C257.982 130.127 255.445 129.188 253.488 130.982C247.162 136.783 234.604 148.895 234.604 148.895Z"
                                  fill="#FFCCBC"
                                />
                                <path
                                  id="hand"
                                  d="M232.238 146.061C232.238 146.061 219.669 131.352 218.806 129.535C218.154 128.165 218.469 125.994 218.49 124.997C218.511 124.002 218.461 121.793 218.552 121.168C218.712 120.077 217.428 120.432 216.796 121.475C216.149 122.544 216.112 124.502 215.772 124.859C215.433 125.216 212.05 121.061 210.313 119.155C208.576 117.25 207.495 116.106 206.652 116.968C206.044 117.59 206.666 118.74 207.952 120.384C209.239 122.026 209.813 122.789 210.018 123.149C210.224 123.51 209.321 123.635 208.915 124.48C208.599 125.139 209.06 126.105 208.605 126.635C208.149 127.167 207.768 128.076 208.424 128.987C209.261 130.147 210.863 131.762 212.618 132.782C214.373 133.801 215.079 134.15 216.21 135.669C217.342 137.187 227.767 152.809 228.454 153.74C229.141 154.67 230.485 156.573 232.205 156.45C233.926 156.328 237.398 153.589 237.398 153.589L239.178 151.391L239.06 150.263L238.314 150.342L238.261 149.844L238.209 149.347L238.157 148.85L238.105 148.353L236.613 148.509L236.063 148.065L234.572 148.222L233.42 146.834L232.238 146.061Z"
                                  fill="#FFCCBC"
                                />
                              </g>
                              <path
                                id="path176"
                                d="M270.633 246.35L267.338 251.178C267.338 251.178 268.864 252.122 272.125 252.251C275.073 252.37 275.884 251.349 275.993 251.105C276.49 249.978 275.714 247.947 275.5 246.202L270.633 246.35Z"
                                fill="#263D46"
                              />
                              <path
                                id="path178"
                                d="M267.524 248.86C267.524 248.86 263.531 248.592 260.769 248.225C258.007 247.858 255.823 249.279 256.473 250.478C257.122 251.676 258.641 252.673 261.007 253.511C263.035 254.231 265.465 254.754 267.581 255.422C269.751 256.106 271.139 256.889 272.368 256.444C273.738 255.946 274.327 255.369 274.21 254.317C274.094 253.266 273.101 249.879 273.101 249.879L267.524 248.86"
                                fill="#263D46"
                              />
                              <path
                                id="path180"
                                d="M275.992 241.5C275.992 241.5 275.493 245.885 275.454 246.827C275.416 247.767 271.395 248.069 270.633 246.35L270.508 240.989L275.992 241.5"
                                fill="#F47084"
                              />
                              <path
                                id="path182"
                                d="M271.939 244.198L273.377 250.85C273.377 250.85 273.513 251.662 270.226 252.177C266.938 252.695 261.785 250.366 260.906 249.892C260.029 249.418 259.925 248.476 263.153 248.665C266.38 248.854 267.524 248.861 267.524 248.861L266.477 245.911L271.939 244.198"
                                fill="#F6879B"
                              />
                              <path
                                id="path184"
                                d="M278.707 133.669L280.979 133.008C280.979 133.008 285.74 148.876 286.266 150.404C286.792 151.932 287.386 153.562 286.362 155.453C285.142 157.703 278.707 166.764 278.707 166.764L274.623 162.55L279.256 153.599L275.158 144.845L278.707 133.669"
                                fill="#FFCCBC"
                              />
                              <path
                                id="path186"
                                d="M255.311 113.043C255.311 113.043 253.489 109.969 255.408 106.204C257.67 101.768 262.726 99.6042 268.743 100.434C271.523 100.818 274.804 103.223 276.603 106.897C278.581 110.931 278.766 119.062 279.256 122.137L255.311 113.043Z"
                                fill="#263D46"
                              />
                              <path
                                id="path188"
                                d="M255.174 114.78C255.174 114.78 253.744 117.65 253.992 117.911C254.24 118.171 255.193 118.383 255.193 118.383L255.174 114.78Z"
                                fill="#F4C4B5"
                              />
                              <path
                                id="path190"
                                d="M259.512 105.707C257.957 106.776 255.281 108.73 255.163 115.178C255.043 121.797 255.93 123.931 256.858 124.698C257.484 125.215 261.149 124.954 262.724 124.563C264.297 124.174 268.823 121.817 270.451 118.234C272.366 114.017 271.979 107.844 269.128 106.053C265.117 103.529 260.828 104.803 259.512 105.707"
                                fill="#FFCCBC"
                              />
                              <path
                                id="path192"
                                d="M275.993 155.126C278.745 159.64 281.36 163.617 281.918 168.691C283.097 179.406 279.23 191.656 278.41 199.579C278.41 199.579 281.007 203.138 281.004 207.321C281.003 210.595 275.994 241.5 275.994 241.5C275.994 241.5 272.35 242.67 270.509 240.989L267.066 184.134L268.88 177.213L275.993 155.126"
                                fill="#893C9B"
                              />
                              <path
                                id="path194"
                                d="M273.365 241.896C272.756 241.896 272.118 241.82 271.538 241.604C271.17 239.239 270.585 235.512 269.935 231.503L267.066 184.136L268.504 207.877L268.743 187.516L271.518 178.618C276.847 176.665 278.707 173.103 278.707 173.103C273.907 175.931 268.88 177.213 268.88 177.213C276.755 174.744 279.329 171.198 280.237 166.154C280.307 165.763 280.34 165.372 280.34 164.98C280.34 163.515 279.89 162.052 279.26 160.676C280.586 163.191 281.596 165.758 281.918 168.69C282.073 170.093 282.14 171.521 282.14 172.962C282.137 182.537 279.122 192.692 278.41 199.579C278.41 199.579 281.007 203.138 281.004 207.32C281.003 210.594 275.994 241.499 275.994 241.499C275.994 241.499 274.76 241.896 273.365 241.896V241.896ZM276.475 155.913C276.298 155.635 276.136 155.372 275.993 155.125C276.153 155.389 276.315 155.652 276.475 155.913Z"
                                fill="#893C9B"
                              />
                              <path
                                id="path196"
                                d="M267.066 184.134L268.88 177.212C268.88 177.212 273.907 175.931 278.707 173.103C278.707 173.103 276.847 176.665 271.518 178.618L268.743 187.516L268.504 207.877L267.066 184.134"
                                fill="#893C9B"
                              />
                              <path
                                id="path198"
                                d="M268.504 207.877L267.066 184.136L268.88 177.212C268.88 177.212 273.907 175.931 278.707 173.103C278.707 173.103 276.847 176.665 271.518 178.618L268.743 187.516L268.504 207.877"
                                fill="#893C9B"
                              />
                              <path
                                id="path200"
                                d="M275.992 155.125C277.465 157.658 280.983 161.991 280.236 166.154C279.329 171.198 276.754 174.744 268.879 177.212L261.493 205.412C261.493 205.412 265.339 209.207 266.515 212.816C267.69 216.425 271.938 244.197 271.938 244.197C271.938 244.197 270.625 246.458 266.476 245.911C266.476 245.911 252.011 211.079 250.988 208.837C249.967 206.594 249.304 204.917 249.764 201.264C250.224 197.612 255.152 157.658 255.152 157.658L275.992 155.125"
                                fill="#893C9B"
                              />
                              <path
                                id="path202"
                                d="M267.722 245.997C267.339 245.997 266.924 245.971 266.476 245.911C266.476 245.911 252.01 211.08 250.988 208.837C250.192 207.089 249.614 205.686 249.615 203.422C249.615 202.782 249.663 202.071 249.763 201.265C250.224 197.613 255.151 157.659 255.151 157.659C255.151 157.659 259.742 160.004 264.587 160.004C265.782 160.004 266.992 159.862 268.152 159.506C274.023 157.711 275.992 155.126 275.992 155.126C277.326 157.421 280.341 161.195 280.339 164.98C280.339 165.373 280.306 165.764 280.236 166.154C279.329 171.199 276.754 174.745 268.879 177.213L267.066 184.136L261.493 205.413C261.493 205.413 265.339 209.208 266.515 212.817C267.689 216.426 271.938 244.198 271.938 244.198C271.938 244.198 270.893 245.997 267.722 245.997Z"
                                fill="#893C9B"
                              />
                              <path
                                id="path204"
                                d="M278.707 133.669L260.325 130.54C259.948 130.453 259.341 130.292 258.573 130.08L258.571 130.079C258.571 130.079 257.788 142.23 253.073 145.018H253.072C253.184 145.598 253.281 146.25 253.369 146.992C253.648 149.321 255.152 157.659 255.152 157.659C255.152 157.659 262.282 161.301 268.153 159.505C274.024 157.71 275.993 155.125 275.993 155.125L273.056 150.079C273.856 147.996 277.548 139.572 278.707 133.669"
                                fill="#893C9B"
                              />
                              <path
                                id="path206"
                                d="M275.156 144.845C276.482 141.44 278.028 137.123 278.706 133.669C278.028 137.123 276.482 141.44 275.156 144.845Z"
                                fill="#FBCAD2"
                              />
                              <path
                                id="path208"
                                d="M264.588 160.003C259.743 160.003 255.152 157.659 255.152 157.659C255.152 157.659 253.648 149.321 253.369 146.992C253.281 146.25 253.184 145.598 253.072 145.018H253.073C257.788 142.23 258.571 130.079 258.571 130.079L258.573 130.08C259.341 130.292 259.948 130.453 260.325 130.54L278.707 133.669C278.029 137.122 276.484 141.44 275.158 144.844C274.218 147.259 273.388 149.214 273.056 150.079L275.993 155.125C275.993 155.125 274.024 157.71 268.153 159.505C266.992 159.861 265.783 160.003 264.588 160.003"
                                fill="#C578C2"
                              />
                              <path
                                id="path210"
                                d="M273.857 148.095C273.782 148.277 267.948 151.573 266.215 152.077C268.947 151.935 271.218 151.258 273.056 150.079L273.857 148.095Z"
                                fill="#893C9B"
                              />
                              <path
                                id="path212"
                                d="M259.054 106.033C259.054 106.033 258.197 112.151 260.273 115.739C260.273 115.739 261.052 113.304 263.014 113.769C264.99 114.238 265.072 116.943 263.701 118.476C262.328 120.01 260.801 119.455 260.801 119.455C260.801 119.455 260.493 123.877 260.327 127.584C260.168 131.157 259.223 139.371 263.18 142.673C267.137 145.976 277.055 147.091 281.703 140.24C284.29 136.429 280.854 129.692 279.257 122.138C277.787 115.192 276.96 104.899 269.97 103.248C262.98 101.597 259.054 106.033 259.054 106.033"
                                fill="#263D46"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </GridItem>
                  <GridItem md={6} className="p-as-center">
                    <div className="login-alert-details">
                      <div className="alert-icon">
                        <svg
                          width="67"
                          height="67"
                          viewBox="0 0 67 67"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M28.3038 9C30.6132 5 36.3868 5 38.6962 9L57.3157 41.25C59.6251 45.25 56.7383 50.25 52.1195 50.25H14.8805C10.2617 50.25 7.3749 45.25 9.6843 41.25L28.3038 9Z"
                            fill="#FFC969"
                          />
                          <path
                            d="M33.4999 19.7061C32.3964 19.7061 31.5293 20.5841 31.5293 21.7016V33.4752C31.5293 34.5927 32.3964 35.4708 33.4999 35.4708C34.6034 35.4708 35.4705 34.5927 35.4705 33.4752V21.7016C35.4705 20.5841 34.6034 19.7061 33.4999 19.7061Z"
                            fill="#423E4F"
                          />
                          <path
                            d="M33.4999 37.835C32.9875 37.835 32.4753 38.0321 32.1204 38.426C31.7657 38.7807 31.5293 39.2931 31.5293 39.8055C31.5293 40.318 31.7264 40.8302 32.1204 41.1851C32.475 41.5397 32.9874 41.7761 33.4999 41.7761C34.0124 41.7761 34.5245 41.579 34.8794 41.1851C35.2341 40.8304 35.4705 40.318 35.4705 39.8055C35.4705 39.2931 35.2734 38.7809 34.8794 38.426C34.5245 38.0318 34.0124 37.835 33.4999 37.835Z"
                            fill="#423E4F"
                          />
                        </svg>
                      </div>
                      <h1>Login Alert</h1>
                      <p>
                        It seems like you are already logged in on other device.
                        Are you sure want to logout from all other devices and
                        continue?
                      </p>
                      <div className="p-d-flex">
                        <Button
                          className="primary-btn1 p-mr-3"
                          onClick={() =>
                            this.setState({ openLoginConfirm: false })
                          }
                        >
                          Cancel
                        </Button>
                        <Button
                          className="primary-btn2"
                          variant="contained"
                          onClick={(e) => this.handleLoginSubmit(e, true)}
                          disabled={this.state.loading}
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </DialogContent>
            </form>
          </Dialog>
          {/** Login Confirmation BY mail Modal*/}

          <Dialog
            open={this.state.openLoginConfirmByMail}
            onClose={() => this.setState({ openLoginConfirmByMail: false })}
            aria-labelledby="form-dialog-title"
          >
            <form method="post">
              <DialogTitle
                id="form-dialog-title"
                className="custom-dialog-header"
              >
                <div> Login Confirmation </div>
                <div
                  className="dialog-close"
                  onClick={() =>
                    this.setState({ openLoginConfirmByMail: false })
                  }
                >
                  <i className="icon-modal-close"></i>
                </div>
              </DialogTitle>
              <DialogContent>
                <div>
                  <p>
                    It seems like you are already logged in on other device. Are
                    you sure want to logout from all other devices and continue?
                  </p>
                </div>
              </DialogContent>
              <DialogActions className="p-p-0 dialog-footer1">
                <Button
                  className="primary-btn1"
                  onClick={() =>
                    this.setState({ openLoginConfirmByMail: false })
                  }
                >
                  Cancel
                </Button>
                <Button
                  className="primary-btn"
                  variant="contained"
                  onClick={(e) =>
                    this.handleGoogleSubmit(this.state.GoogleEmail, true)
                  }
                  disabled={this.state.loading}
                >
                  Continue
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default Login;
