import React, { useEffect } from "react";

import cx from "classnames";
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import Support from "components/Support/Support.js";

import routesMain from "routes.js";
import api from "../utils/api";
import { apiBase } from "../config";

import styles from "../assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import APIConstant from "utils/constant";

let routesRestricted = routesMain.filter((x) => x.restricted);
var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { ...rest } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [IsProfileCompleted, setIsProfileCompleted] = React.useState("0");
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState("");
  // const [logo] = React.useState(require("logo.png"));
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("white");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [IsHover, setIsHover] = React.useState(false);
  let [routes, setRoutes] = React.useState(routesRestricted);
  const [IsContactHover, setIsContactHover] = React.useState(false);
  const [logo, setLogo] = React.useState(require("logo.png"));
  const [path, setPath] = React.useState("");
  const [masterAccess, setMasterAccess] = React.useState([]);
  const [lockStatus, setLockStatus] = React.useState(0);
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });

  const mainPanel = React.createRef();

  React.useEffect(() => {
    let ps;

    if (navigator.platform.indexOf("Win") > -1) {
      document.body.style.overflow = "hidden";
      ps = new PerfectScrollbar(document.body);
    }

    getUserUrl();
    getGlobalMasterAccess();
    window.addEventListener("resize", resizeFunction);

    let IsProfileCompleted = localStorage.getItem("IsProfileCompleted");
    setIsProfileCompleted(IsProfileCompleted);
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1 && ps) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, []);

  useEffect(() => {
    let lStatus = localStorage.getItem("LockStatus");

    setLockStatus(lStatus);
    if (lStatus == 1) {
      props.history.push("/auth/lockscreen");
    }
  }, [lockStatus]);

  const getUserUrl = async () => {
    let data = {
      UserID: localStorage.getItem("Infodata"),
    };

    await api
      .post(apiBase + "/ajax/player.php?action=getUserUrl", data)
      .then((res) => {
        if (res.Success == 1) {
          var routesdata = res.Info.service;
          setRoutes(
            routesMain.filter(
              (x) =>
                routesdata.map((y) => y.ServiceName).includes(x.ServiceName) ||
                routesdata.map((y) => y.ServiceMainName).includes(x.ServiceName)
            )
          );
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const getGlobalMasterAccess = async () => {
    let params = {
      UserId: localStorage.getItem("Infodata"),
    };
    await api
      .post(APIConstant.path.getGlobalMasterAccess, params)
      .then(async (res) => {
        let response = await res;
        if (response.Success === 1) {
          localStorage.setItem(
            "GlobalMasterAccess",
            res?.Info?.Data?.length ? JSON.stringify(res?.Info?.Data) : []
          );
        } else {
          // localStorage.setItem("PageAccess", []);
          console.log("Error: ", response);
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  // functions for changeing the states from components
  const handleImageClick = (image) => {
    setImage(image);
  };

  const handleColorClick = (color) => {
    setColor(color);
  };

  const handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        setLogo(require("logo.png"));
        break;
      default:
        setLogo(require("logo.png"));
        break;
    }
    setBgColor(bgColor);
  };

  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };

  const handleContactClick = () => {
    if (IsContactHover === false) {
      setIsContactHover(true);
    } else {
      setIsContactHover(false);
    }
  };

  const handleHoverClick = () => {
    if (IsHover === false) {
      setIsHover(true);
    } else {
      setIsHover(false);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    // }
    return activeRoute;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      if (prop.path === "/") {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
            render={(props) => <prop.component {...props} />}
          />
        );
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            render={(props) => <prop.component {...props} />}
          />
        );
      } else {
        return null;
      }
    });
  };

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // console.log("layout props",props)

  return (
    <div
      id="main-section"
      className={classes.wrapper}
      style={{ padding: "15px", position: "relative" }}
    >
      {/*
       <div className="set-up-profile">
        <div className="p-d-flex p-ai-center p-jc-between prof-detail">

          <div className="p-d-flex p-ai-center">
            <div className="p-mr-3 prof-text">Your Profile is incomplete, Please fillup all details for accessing other area</div>
            <div className="primary-btn2">Edit Profile</div>
          </div>
          <div className="request-status-icon p-p-0">
            <div class="icon-btn bgcolor-white"><i class="icon-cancel common-blue "></i></div>
          </div>
        </div>

        <div className="prof-bg-img1">
          <img src={prosvg1} />
        </div>
        <div className="prof-bg-img2">
          <img src={prosvg2} />
        </div>
        <div className="prof-bg-img3">
          <img src={prosvg1} />
        </div>
        <div className="prof-bg-img4">
          <img src={prosvg2} />
        </div>
        <div className="prof-bg-img5">
          <img src={prosvg1} />
        </div>
        <div className="prof-bg-img6">
          <img src={prosvg2} />
        </div>
      </div> */}
      <div className="p-d-flex">
        <Sidebar
          IsProfileUpdated={
            props.location.IsProfileUpdated != undefined &&
            props.location.IsProfileUpdated
              ? true
              : false
          }
          routes={routes}
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          logoText={"PORT LINKS INDIA"}
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          brandText={getActiveRoute(routes)}
          {...rest}
          bgColor={bgColor}
          {...rest}
        />

        <div className={mainPanelClasses} ref={mainPanel}>
          <AdminNavbar
            miniActive={miniActive}
            sidebarMinimize={sidebarMinimize.bind(this)}
            open={mobileOpen}
            color={color}
            bgColor={bgColor}
            brandText={getActiveRoute(routes)}
            handleDrawerToggle={handleDrawerToggle}
            IsProfileCompleted={
              localStorage.getItem("IsProfileCompleted") == 1 ? true : false
            }
            {...rest}
          />

          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>{getRoutes(routes)}</Switch>
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <Switch>{getRoutes(routes)}</Switch>
            </div>
          )}
          {getRoute() ? <Footer fluid /> : null}
          <FixedPlugin
            handleImageClick={handleImageClick}
            handleColorClick={handleColorClick}
            handleBgColorClick={handleBgColorClick}
            color={color}
            bgColor={bgColor}
            bgImage={image}
            handleFixedClick={handleFixedClick}
            fixedClasses={fixedClasses}
            sidebarMinimize={sidebarMinimize.bind(this)}
            miniActive={miniActive}
          />

          <div>
            <Support
              handleImageClick={handleImageClick}
              handleColorClick={handleColorClick}
              handleBgColorClick={handleBgColorClick}
              color={color}
              bgColor={bgColor}
              bgImage={image}
              handleHoverClick={handleHoverClick}
              handleContactClick={handleContactClick}
              fixedClasses={fixedClasses}
              IsHover={IsHover}
              IsContactHover={IsContactHover}
              sidebarMinimize={sidebarMinimize.bind(this)}
              miniActive={miniActive}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
