/*eslint-disable*/
import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Badge from "@material-ui/core/Badge";
import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import { CommonConfig } from "../../CommonConfig.js";
import "primeicons/primeicons.css";
import Tab from "@material-ui/core/Tab";
import api from "../../utils/api";
import { apiBase, individual_profile_image_link } from "../../config";
import { Link } from "react-router-dom";
import place from "../../assets/equator-img/place.svg";
import APIConstant from "utils/constant";
import cross from "../../assets/images/crossmarkblank.png";
import yes from "../../assets/images/yesmarkblank.png";
import cogoToast from "cogo-toast";
import SimpleBackdrop from "../../utils/general";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import User from "../../assets/images/user.jpg";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import "react-google-places-autocomplete/dist/index.min.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import moment from "moment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

class Request extends React.Component {
  constructor(props) {
    super(props);
    let columns = [
      {
        field: "id",
        header: "Sr.No",
        sortable: true,
        filterMatchMode: "contains",
        id: 0,
      },
      {
        field: "TruckNumber",
        body: this.actionTemplate3.bind(this),
        header: "Truck Number",
        sortable: true,
        filterMatchMode: "contains",
        id: 1,
      },
      {
        field: "Driver1",
        header: "Driver 1",
        sortable: true,
        filterMatchMode: "contains",
        id: 2,
      },
      {
        field: "Driver2",
        header: "Driver 2",
        sortable: true,
        filterMatchMode: "contains",
        id: 3,
      },
      {
        body: this.actionTemplate2.bind(this),
        header: "Action",
        sortable: false,
        filter: false,
        filterMatchMode: "contains",
        id: 4,
      },
    ];
    this.state = {
      token: "",
      cols: columns,
      arr: [],
      arr1: [],
      rowData: "",
      selectedPage: 0,
      rowsPerPageOptions: CommonConfig.dataTableConfig.rowsPerPageOptions,
      str: "",
      rowsPerPage: CommonConfig.dataTableConfig.rowsPerPage,
      globalFilter: null,
      isEdit: false,
      networkitems: [],
      DistanceTruck: [],
      TruckLongitude: "",
      TruckLatitude: "",
      opencCancelLocationRequest: false,
      getPurchaseVesselList: [],
      serviceList: [],
      buyList: [],
      purchaseInfo: [],
      truckInfo: [],
      locationInfo: [],
      TruckOrgin: [],
      friendList: [],
      transporterInfo: [],
      buyVesselAcceptList: [],
      value: 0,
      value1: 0,
      value2: 0,
      items: [],
      TruckDistance: "",
      setValue: 0,
      type: "",
      UserId: "",
      UserRole: "",
      TruckRequestList: [],
      buyVesselList: [],
      Networklist: [],
      Networklist1: [],
      buyVesselList1: [],
      locationlist: [],
      getBuySentVesselList: [],
      purchaseid: "",
      PurchaseUserId: "",
      PurchaseFrom: "",
      VesselId: "",
      data: "",
      GroupList: [],
      TruckList: [],
      TruckReceivedList: [],
      loading: false,
      isbuyVesselList: false,
      ismysentVesselList: false,
      isGroupCreated: false,
      invisible: "",
      notificationClicked: false,
      generalnotificationClicked: false,
      count: 1,
      rateChange: [],

      open1: false,
      firstName: "",
      secondName: "",
      emailId: "",
      contactNum: "",
      profilePic: "",
      companyname: "",

      open: false,
      currentrate: "",
      sellingrate: "",
      sellingratehelperText: "",
      sellingrateErr: false,
      items: [],
      profileitems: [],

      open2: false,
      open3: false,
      opentransporter: false,
      openreceiver: false,
      opendestination: false,
      openaccepttransporter: false,
      openTruckDetail: false,
      currenttruckrate: "",
      sellingtruckrate: "",
      sellingtruckratehelperText: "",
      sellingtruckrateErr: false,
      items1: [],
      assignTruck: 0,
      getAssignTruck: [],
      reqQuantity: "",
      currentQuantity: "",
      reqQuantityErr: false,
      reqQuantityhelperText: "",
      opencCancelNetworkRequest: false,
      option: [],
      numoftrucks: "",
      numoftrucksErr: false,
      originLatitude: "",
      originLongitude: "",
      destinationLatitude: "",
      GeoLatitude: "",
      destinationLongitude: "",
      partyName: "",
      textOperations: [],
      assigned: 0,
      GeoLongitude: "",
      phoneclick: 0,
      TextArr: {
        id: 0,
        placename: "",
        IsAllOtherOperator: true,
        isDeleted: false,
      },
      getTransporter: [],
      getTruckDetails: [],
      items5: [],
      cancelitem: [],
      opendelete: false,
    };
    this.colOptions = [];
    for (let col of columns) {
      this.colOptions.push({ label: col.header, value: col });
    }
    this.onColumnToggle = this.onColumnToggle.bind(this);
  }

  componentDidMount() {
    let token = localStorage.getItem("Token");
    let lockstatus = localStorage.getItem("LockStatus");
    // if (lockstatus == 0) {
    if (token !== null) {
      let userdata = JSON.parse(localStorage.getItem("Userdata"));
      let data = localStorage.getItem("Infodata");

      this.setState({
        UserId: data,
        UserRole: userdata.UserRoll,
        serviceList: localStorage.getItem("PageAccess"),
      });
      this.getNetworkRequest(data, token);
      this.getAllBuyVesselsRequest(data, token);
      this.getAllMyBuyVesselsRequest(data, token);
      this.getlocationrequest(data, token);
      this.getGroup(data, token);
      this.getTruck(data, token);
      this.getTruckRequest(data, token);
    } else {
      this.props.history.push("/login");
    }
    // } else if (lockstatus == null) {
    //     this.props.history.push('/login')
    // } else {
    //     this.props.history.push('/auth/lockscreen')
    // }
  }

  getPageString = (e) => {
    let firstPage = e.first + 1;
    let l = e.rows * (e.page + 1);
    let lastPage = l < this.state.arr.length ? l : this.state.arr.length;
    var str =
      firstPage +
      " to " +
      lastPage +
      " out of " +
      this.state.arr.length +
      " records";
    this.setState({ selectedPage: e.first, str: str, rowsPerPage: e.rows });
  };

  onColumnToggle(event) {
    let valueInOrder = event.value.sort((x, y) => {
      return x.id - y.id;
    });
    this.setState({ cols: valueInOrder });
  }

  actionTemplate2(rowData, column) {
    var PIndex = this.state.textOperations.findIndex(
      (x) => x.id === rowData.id[0]
    );
    var NewTextDetails = this.state.textOperations;
    if (NewTextDetails.length !== 0) {
      if (NewTextDetails[PIndex]["TruckId"] === "") {
        if (rowData.IsTruckAssigned == false) {
          return (
            <div>
              <Button
                onClick={(e) => this.fromAdd(e, rowData, "add")}
                color="rose"
                className="primary-btn2 p-mb-0"
              >
                Assign
              </Button>
            </div>
          );
        } else {
          return <div>Already assigned</div>;
        }
      }
      return (
        <div>
          <Button
            onClick={(e) => this.fromAdd(e, rowData, "clear")}
            className="primary-btn1 p-w-100 border-dark-blue p-mb-0"
          >
            Assigned
          </Button>
        </div>
      );
    } else {
      if (rowData.IsTruckAssigned == false) {
        return (
          <div>
            <Button
              onClick={(e) => this.fromAdd(e, rowData, "add")}
              color="rose"
              className="primary-btn2 p-mb-0"
            >
              Assign
            </Button>
          </div>
        );
      } else {
        return <div>Already assigned</div>;
      }
    }
  }

  actionTemplate3(rowData, column) {
    return (
      <div>
        <Link /*onClick={(e) => this.openDialog(rowData)}*/>
          <div>{rowData.TruckNumber}</div>{" "}
          <div>
            ({rowData.Weight} MTN , {rowData.StringMapName})
          </div>
        </Link>
      </div>
    );
  }

  fromAdd = (e, rowData, type) => {
    if (type == "add") {
      var PIndex = this.state.textOperations.findIndex(
        (x) => x.id === rowData.id[0]
      );
      var NewTextDetails = this.state.textOperations;
      var len = this.state.assignTruck;
      NewTextDetails[PIndex]["TruckId"] = rowData.TruckId;
      len = NewTextDetails.filter((x) => x.TruckId !== "").length;
      if (len === this.state.option) {
        var arrfilter = this.state.arr;
        var Truckidfilter = NewTextDetails.filter((x) => x.TruckId !== "");
        Truckidfilter = Truckidfilter.map((x) => {
          return x.TruckId;
        });
        var ex = [];
        for (let i = 0; i < arrfilter.length; i++) {
          for (let j = 0; j < Truckidfilter.length; j++) {
            if (Truckidfilter[j] === arrfilter[i].TruckId) {
              ex[j] = arrfilter[i];
            }
          }
        }
        this.setState(
          {
            arr: ex,
            textOperations: NewTextDetails,
            assignTruck: NewTextDetails.filter((x) => x.TruckId !== "").length,
            assigned: 1,
          },
          function() {}
        );
      } else {
        this.setState(
          {
            textOperations: NewTextDetails,
            assignTruck: NewTextDetails.filter((x) => x.TruckId !== "").length,
            assigned: 1,
          },
          function() {}
        );
      }
    } else {
      var PIndex = this.state.textOperations.findIndex(
        (x) => x.id === rowData.id[0]
      );
      var NewTextDetails = this.state.textOperations;
      var len = this.state.assignTruck;
      NewTextDetails[PIndex]["TruckId"] = "";
      len = NewTextDetails.filter((x) => x.TruckId !== "").length;

      if (len == this.state.option) {
        var arrfilter = this.state.arr1;
        var Truckidfilter = NewTextDetails.filter((x) => x.TruckId !== "");
        Truckidfilter = Truckidfilter.map((x) => {
          return x.TruckId;
        });
        var ex = [];
        for (let i = 0; i < arrfilter.length; i++) {
          for (let j = 0; j < Truckidfilter.length; j++) {
            if (Truckidfilter[j] == arrfilter[i].TruckId) {
              ex[j] = arrfilter[i];
            }
          }
        }
        this.setState(
          {
            arr: ex,
            textOperations: NewTextDetails,
            assignTruck: NewTextDetails.filter((x) => x.TruckId !== "").length,
            assigned: 1,
          },
          function() {}
        );
      } else {
        this.setState(
          {
            arr: this.state.arr1,
            textOperations: NewTextDetails,
            assignTruck: NewTextDetails.filter((x) => x.TruckId !== "").length,
            assigned: 1,
          },
          function() {}
        );
      }
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChange1 = (event, newValue) => {
    this.setState({ value1: newValue });
  };

  handleChange2 = (event, newValue) => {
    this.setState({ value2: newValue });
  };

  getAllBuyVesselsRequest = (UserId, token) => {
    var params = {
      UserId: UserId,
      token: token,
    };
    if (params !== null) {
      api
        .post(
          apiBase + "/ajax/player.php?action=getAllBuyVesselsRequest",
          params
        )
        .then((res) => {
          if (res.Success === 1) {
            this.setState({ buyVesselList: res.Info.request });
            var getBuyVesselList = [];
            var requestlength = res.Info.request.length;
            for (let i = 0; i < requestlength; i++) {
              getBuyVesselList.push({
                label: res.Info.request[i].AddedBy,
                Status: res.Info.request[i].Status,
                IsUserStatus: res.Info.request[i].IsUserStatus,
                Name: res.Info.request[i].Name,
                NetworkId: res.Info.request[i].NetworkId,
                last_name: res.Info.request[i].last_name,
                Created_at: res.Info.request[i].Created_at,
                Updated_at: res.Info.request[i].Updated_at,
                companyname: res.Info.request[i].cmpny_name,
                Email: res.Info.request[i].Email,
                ProfilePic: res.Info.request[i].ProfilePic,
                VesselName: res.Info.request[i].VesselName,
                Quantity: res.Info.request[i].Quantity,
                OriginalQuantity: res.Info.request[i].OriginalQuantity,
                PhoneNumber: res.Info.request[i].PhoneNumber,
                QualityName: res.Info.request[i].QualityName,
                RequestRate: res.Info.request[i].Rate,
                OriginalRate: res.Info.request[i].rate,
                PuchaseId: res.Info.request[i].PuchaseId,
                VesselId: res.Info.request[i].fkVesselId,
                PurchaseUserId: res.Info.request[i].PurchaseUserId,
                PurchaseFrom: res.Info.request[i].PurchaseFrom,
                ParentVesselUserId: res.Info.request[i].ParentVesselUserId,
                VesselUserId: res.Info.request[i].VesselUserId,
              });
            }
            var statusList = getBuyVesselList.map((obj) => {
              return obj.Status;
            });
            this.setState({
              isbuyVesselList: true,
              buyVesselList: getBuyVesselList,
              status: statusList,
              purchaseid: getBuyVesselList[0].PuchaseId,
              PurchaseUserId: getBuyVesselList[0].PurchaseUserId,
              PurchaseFrom: getBuyVesselList[0].PurchaseFrom,
              VesselId: getBuyVesselList[0].VesselId,
            });
          } else if (res.Success == -1) {
            let data = localStorage.getItem("Infodata");
            var params = {
              UserId: data,
            };
            api
              .post(apiBase + "/ajax/player.php?action=AppLogout", params)
              .then((res) => {
                if (res.Success == 1) {
                  localStorage.clear();
                  const { history } = this.props;
                  history.push("/login");
                }
              });
          }
        })
        .catch((err) => {});
    } else {
      alert("Id is Not Selected");
    }
  };

  getNetworkRequest = (UserId, token) => {
    var params = {
      UserId: UserId,
      token: token,
    };
    if (params !== null) {
      api
        .post(APIConstant.path.GetNetworkRequest, params)
        .then((res) => {
          if (res.Success === 1) {
            this.setState({ Networklist: res.Info.request });
          } else if (res.Success == -1) {
            let data = localStorage.getItem("Infodata");
            var params = {
              UserId: data,
            };
            // setUserName: data });
            api
              .post(apiBase + "/ajax/player.php?action=AppLogout", params)
              .then((res) => {
                if (res.Success == 1) {
                  localStorage.clear();
                  const { history } = this.props;
                  history.push("/login");
                }
              });
          }
        })
        .catch((err) => {});
    } else {
      alert("Id is Not Selected");
    }
  };

  openModal = (item, type) => {
    if (type === "cancellocationrequest") {
      this.setState({ opencCancelLocationRequest: true, items: item });
    } else if (type === "cancelnetworkrequest") {
      this.setState({ opencCancelNetworkRequest: true, items: item });
    } else if (type === "accepttheirs") {
      this.setState({
        open: true,
        sellingrate: item.RequestRate,
        reqQuantity: item.Quantity,
        currentQuantity: item.OriginalQuantity,
        items: item,
        currentrate: item.OriginalRate,
        type: type,
      });
    } else if (type === "acceptbuyertheirs") {
      this.setState({
        open: true,
        sellingrate: item.RequestRate,
        reqQuantity: item.Quantity,
        currentQuantity: item.OriginalQuantity,
        items: item,
        currentrate: item.OriginalRate,
        type: type,
      });
    } else if (type === "profile") {
      this.setState({
        open1: true,
        profileitems: item,
        companyname: item.companyname,
        firstName: item.Name,
        secondName: item.last_name,
        contactNum: item.PhoneNumber,
        emailId: item.Email,
        profilePic: item.ProfilePic,
        networkitems: item,
      });
    } else if (type === "transporterlist") {
      var params = {
        UserId: this.state.UserId,
        RequestId: item.RequestId,
        Type: "Transporter",
      };
      if (params !== null) {
        api
          .post(
            apiBase + "/ajax/player.php?action=getAcceptedTransporterlist",
            params
          )
          .then((res) => {
            if (res.Success === 1) {
              this.setState({ getTransporter: res.Info.Transporter });
            }
          })
          .catch((err) => {});
      }
      this.setState({
        opentransporter: true,
        items1: item,
        currenttruckrate: item.Rate,
      });
    } else if (type === "acceptsamerate") {
      var TruckOrgin = item;
      var params = {
        UserId: this.state.UserId,
        Type: "notassigned",
      };
      if (params !== null) {
        api
          .post(apiBase + "/ajax/player.php?action=getTruckOrders", params)
          .then((res) => {
            if (res.Success === 1) {
              var formattedData = [];
              var textOperation = this.state.textOperations;
              for (var i = 0; i < res.Info.Orders.length; i++) {
                formattedData.push({
                  id: [i + 1],
                  TruckNumber: res.Info.Orders[i].TruckNumber,
                  TruckId: res.Info.Orders[i].TruckId,
                  IsTruckAssigned:
                    res.Info.Orders[i].IsTruckAssigned == "1" ? true : false,
                  Weight: res.Info.Orders[i].Weight,
                  StringMapName: res.Info.Orders[i].StringMapName,
                  Driver1: res.Info.Orders[i].Driver1
                    ? res.Info.Orders[i].Driver1
                    : "N/A",
                  Driver2: res.Info.Orders[i].Driver2
                    ? res.Info.Orders[i].Driver2
                    : "N/A",
                });
                var temp = {
                  id: i + 1,
                  IsAllOtherOperator: false,
                  isDeleted: false,
                  // "Driver1": res.Info.Orders[i].DriverName.Driver1 ? res.Info.Orders[i].DriverName.Driver1 : "",
                  // "Driver2": res.Info.Trucks[i].DriverName.Driver2 ? res.Info.Trucks[i].DriverName.Driver2 : ""
                  TruckId: "",
                };
                textOperation.push(temp);
              }
              let lastPage =
                this.state.rowsPerPage < formattedData.length
                  ? this.state.rowsPerPage
                  : formattedData.length;
              let str =
                "1 to " +
                lastPage +
                " out of " +
                formattedData.length +
                " records";
              this.setState({
                arr1: formattedData,
                arr: formattedData,
                str: str,
              });
              this.TruckDistance(formattedData, item);
            }
          })
          .catch((err) => {});
      }
      this.setState({
        open3: true,
        items1: item,
        TruckLatitude: item.OriginLatitude,
        TruckLongitude: item.OriginLongitude,
        TruckOrgin: TruckOrgin,
        currenttruckrate: item.Rate,
        option: Number(item.NumOfTrucks),
      });
    } else if (type === "truckDetails") {
      var params = {
        UserId: this.state.UserId,
        TransporterUserId: item.fkUserId,
        RequestId: item.fkRequestId,
        Type: "Truck",
      };
      if (params !== null) {
        api
          .post(
            apiBase + "/ajax/player.php?action=getAcceptedTransporterlist",
            params
          )
          .then((res) => {
            if (res.Success === 1) {
              this.setState({ getTruckDetails: res.Info.Transporter });
            }
          })
          .catch((err) => {});
      }
      this.setState({
        opentransporter: false,
        openTruckDetail: true,
        items1: item,
        currenttruckrate: item.Rate,
      });
    } else if (type === "acceptreceiveddifferentrate") {
      this.setState({
        openreceiver: true,
        items: item,
        currenttruckrate: item.Rate,
      });
    } else if (type === "opendestination") {
      if (item.destination == "") {
        this.setState({
          opendestination: true,
          items: item,
          currenttruckrate: item.Rate,
        });
      } else {
        this.setState({
          opendestination: true,
          items: item,
          currenttruckrate: item.Rate,
          sDestination: item.destination,
        });
      }
    } else if (type === "accepttransporterdifferentrate") {
      this.setState({
        openaccepttransporter: true,
        items: item,
        currenttruckrate: item.Rate,
      });
    } else if (type === "openTruckDetail") {
      this.setState({
        openTruckDetail: true,
        items1: item.TruckAssigned,
        currenttruckrate: item.Rate,
      });
    } else if (type == "resend") {
      this.setState({
        open: true,
        sellingrate: item.RequestRate,
        reqQuantity: item.Quantity,
        currentQuantity: item.OriginalQuantity,
        items: item,
        currentrate: item.OriginalRate,
        type: type,
      });
    } else {
      var params = {
        UserId: this.state.UserId,
        Type: "notassigned",
      };
      if (params !== null) {
        api
          .post(apiBase + "/ajax/player.php?action=getTruckOrders", params)
          .then((res) => {
            if (res.Success === 1) {
              var formattedData = [];
              var textOperation = this.state.textOperations;
              for (var i = 0; i < res.Info.Orders.length; i++) {
                formattedData.push({
                  id: [i + 1],
                  TruckNumber: res.Info.Orders[i].TruckNumber,
                  TruckId: res.Info.Orders[i].TruckId,
                  IsTruckAssigned:
                    res.Info.Orders[i].IsTruckAssigned == "1" ? true : false,
                  Weight: res.Info.Orders[i].Weight,
                  StringMapName: res.Info.Orders[i].StringMapName,
                  Driver1: res.Info.Orders[i].Driver1
                    ? res.Info.Orders[i].Driver1
                    : "N/A",
                  Driver2: res.Info.Orders[i].Driver2
                    ? res.Info.Orders[i].Driver2
                    : "N/A",
                });
                var temp = {
                  id: i + 1,
                  IsAllOtherOperator: false,
                  isDeleted: false,
                  TruckId: "",
                };
                textOperation.push(temp);
              }
              let lastPage =
                this.state.rowsPerPage < formattedData.length
                  ? this.state.rowsPerPage
                  : formattedData.length;
              let str =
                "1 to " +
                lastPage +
                " out of " +
                formattedData.length +
                " records";
              this.setState({
                arr1: formattedData,
                arr: formattedData,
                str: str,
              });
              this.TruckDistance(formattedData, item);
            }
          })
          .catch((err) => {});
      }
      this.setState({
        open2: true,
        items1: item,
        TruckOrgin: TruckOrgin,
        TruckLatitude: item.OriginLatitude,
        TruckLongitude: item.OriginLongitude,
        currenttruckrate: item.Rate,
        option: Number(item.NumOfTrucks),
      });
      // }
    }
  };

  validate = (type) => {
    let formIsValid = true;
    var place = this.state.textOperations.filter(
      (x) => x.isDeleted !== true && x.TruckId !== ""
    );
    var truck = place.map((x) => {
      return x.TruckId;
    });
    if (type === "vesselrate") {
      if (this.state.sellingrate === "" || this.state.sellingrate === null) {
        this.setState({
          sellingrateErr: true,
          sellingratehelperText: "Please enter sellling rate.",
        });
        formIsValid = false;
      } else if (
        this.state.currentrate &&
        this.state.sellingrate > Number(this.state.currentrate)
      ) {
        this.setState({
          sellingrateErr: true,
          sellingratehelperText: "Selling rate must not exceed current rate",
        });
        formIsValid = false;
      } else {
        this.setState({ sellingrateErr: false, sellingratehelperText: "" });
      }
    } else if (type === "truckrate") {
      if (
        this.state.sellingtruckrate === "" ||
        this.state.sellingtruckrate === null
      ) {
        this.setState({
          sellingtruckrateErr: true,
          sellingtruckratehelperText: "Please enter sellling rate.",
        });
        formIsValid = false;
      }
      // else if (this.state.sellingtruckrate > Number(this.state.currenttruckrate)) {
      //     this.setState({ sellingtruckrateErr: true, sellingtruckratehelperText: 'Selling rate must not exceed current rate' });
      //     formIsValid = false;
      // }
      else {
        this.setState({
          sellingtruckrateErr: false,
          sellingtruckratehelperText: "",
        });
      }
    } else if (type === "assign") {
      if (truck.length > 0) {
        formIsValid = true;
      } else {
        formIsValid = false;
        cogoToast.error("Please assign atleast one truck to proceed");
      }
    } else {
      if (this.state.numoftrucks === "" || this.state.numoftrucks === null) {
        this.setState({ numoftrucksErr: true });
        formIsValid = false;
      } else {
        this.setState({ numoftrucksErr: false });
      }
    }
    return formIsValid;
  };

  handleChange_value1 = (e, type) => {
    this.setState({
      [e.target.name]: e.target.value,
      // .replace(/\D/, '')
    });

    let phoneRegExp = /^[0-9]*\.?[0-9]*$/;
    if (type === "sellingrate") {
      if (e.target.name === "sellingrate") {
        if (e.target.value === "" && e.target.value === null) {
          this.setState({
            sellingrateErr: true,
            sellingratehelperText: "Please Enter Selling Rate.",
          });
        } else if (
          e.target.value.trim() !== e.target.value ||
          !e.target.value.match(phoneRegExp)
        ) {
          this.setState({ sellingrate: this.state.sellingrate });
        } else {
          let RateRegex = /^(\$|)([1-9]\d{0,2}(\,\d{3})*|([1-9]\d*))(\.\d{2})?$/;
          if (!RateRegex.test(e.target.value)) {
            this.setState({
              sellingrateErr: true,
              sellingratehelperText: "Only 2 digits allowed after decimal(.)",
            });
          } else {
            this.setState({
              sellingrateErr: false,
              sellingratehelperText: "",
              sellingrate: e.target.value,
            });
          }
        }
      }
    } else if (type === "reqQuantity") {
      if (e.target.name === "reqQuantity") {
        if (e.target.value === "" && e.target.value === null) {
          this.setState({
            reqQuantityErr: true,
            reqQuantityhelperText: "Please quentity",
          });
        } else if (
          e.target.value.trim() !== e.target.value ||
          !e.target.value.match(phoneRegExp)
        ) {
          this.setState({ reqQuantity: this.state.reqQuantity });
        } else {
          let RateRegex = /^(\$|)([1-9]\d{0,2}(\,\d{3})*|([1-9]\d*))(\.\d{2})?$/;
          if (!RateRegex.test(e.target.value)) {
            this.setState({
              reqQuantityErr: true,
              reqQuantityhelperText: "Only 2 digits allowed after decimal(.)",
            });
          } else {
            this.setState({
              reqQuantityErr: false,
              reqQuantityhelperText: "",
              reqQuantity: e.target.value,
            });
          }
        }
      }
    } else {
      if (e.target.name === "sellingtruckrate") {
        let phoneRegExp = /^[0-9]*\.?[0-9]*$/;
        if (e.target.value === "" || e.target.value === null) {
          this.setState({
            sellingtruckrateErr: true,
            sellingtruckratehelperText: "Please enter approval rate.",
          });
        } else if (
          e.target.value.trim() !== e.target.value ||
          !e.target.value.match(phoneRegExp)
        ) {
          this.setState({ sellingtruckrate: this.state.sellingtruckrate });
        } else {
          let RateRegex = /^(\$|)([1-9]\d{0,2}(\.\d{2})*|([1-9]\d*))(\.\d{2})?$/;
          if (!RateRegex.test(e.target.value)) {
            this.setState({
              sellingtruckrate: e.target.value,
              sellingtruckrateErr: true,
              sellingtruckratehelperText:
                "Only 2 digits allowed after decimal(.)",
            });
          } else {
            this.setState({
              sellingtruckrateErr: false,
              sellingtruckratehelperText: "",
              sellingtruckrate: e.target.value,
            });
          }
        }
      }
    }
  };

  UpdatedRequestStatus = (item, type) => {
    if (type == "acceptmine") {
      var params = {
        UserId: this.state.UserId,
        PuchaseId: item.PuchaseId,
        PurchaseFrom: item.PurchaseFrom,
        PurchaseUserId: item.PurchaseUserId,
        VesselId: item.VesselId,
        Quantity: item.Quantity,
        Rate: item.RequestRate,
        ParentVesselUserId: item.ParentVesselUserId,
        VesselUserId: item.VesselUserId,
        Type: "acceptmine",
      };

      if (params !== null) {
        this.setState({ loading: true, buyVesselList: [] });
        api
          .post(apiBase + "/ajax/player.php?action=soldVessels", params)
          .then((res) => {
            if (res.Success === 1) {
              this.getAllBuyVesselsRequest(this.state.UserId, this.state.token);
              this.setState({ loading: false });
              cogoToast.success(res.Message);
            } else if (res.Success == -1) {
              let data = localStorage.getItem("Infodata");
              var params = {
                UserId: data,
              };
              // setUserName: data });
              api
                .post(apiBase + "/ajax/player.php?action=AppLogout", params)
                .then((res) => {
                  if (res.Success == 1) {
                    localStorage.clear();
                    const { history } = this.props;
                    history.push("/login");
                  }
                });
            } else {
              this.setState({ loading: false });
              cogoToast.error(res.Message);
            }
          })
          .catch((err) => {});
      } else {
        this.setState({ loading: false });
        alert("in Else");
      }
    } else if (type == "acceptbuyermine") {
      // this.setState({ loading: true })
      var params = {
        UserId: this.state.UserId,
        PuchaseId: item.PuchaseId,
        PurchaseFrom: item.PurchaseFrom,
        PurchaseUserId: item.PurchaseUserId,
        VesselId: item.VesselId,
        Quantity: item.Quantity,
        Rate: item.Rate,
        ParentVesselUserId: item.ParentVesselUserId,
        VesselUserId: item.VesselUserId,
        Type: "acceptbuyermine",
      };
      if (params !== null) {
        this.setState({ loading: true, buyVesselList1: [] });
        api
          .post(apiBase + "/ajax/player.php?action=soldVessels", params)
          .then((res) => {
            if (res.Success === 1) {
              // this.getAllBuyVesselsRequest(this.state.UserId);
              this.getAllMyBuyVesselsRequest(
                this.state.UserId,
                this.state.token
              );
              this.setState({ loading: false });
              cogoToast.success(res.Message);
            } else if (res.Success == -1) {
              let data = localStorage.getItem("Infodata");
              var params = {
                UserId: data,
              };
              // setUserName: data });
              api
                .post(apiBase + "/ajax/player.php?action=AppLogout", params)
                .then((res) => {
                  if (res.Success == 1) {
                    localStorage.clear();
                    const { history } = this.props;
                    history.push("/login");
                  }
                });
            } else {
              this.setState({ loading: false });
              cogoToast.error(res.Message);
            }
          })
          .catch((err) => {});
      } else {
        this.setState({ loading: false });
        alert("in Else");
      }
    } else if (type == "acceptbuyertheirs") {
      if (this.validate("vesselrate")) {
        // this.setState({ loading: true })
        var params = {
          UserId: this.state.UserId,
          PuchaseId: item.PuchaseId,
          PurchaseFrom: item.PurchaseFrom,
          PurchaseUserId: item.PurchaseUserId,
          VesselId: item.VesselId,
          Rate: this.state.sellingrate,
          ParentVesselUserId: item.ParentVesselUserId,
          reqQuantity: this.state.reqQuantity,
          VesselUserId: item.VesselUserId,
          Type: "acceptbuyertheirs",
        };
        if (params !== null) {
          this.setState({ loading: true, buyVesselList1: [] });
          api
            .post(apiBase + "/ajax/player.php?action=soldVessels", params)
            .then((res) => {
              if (res.Success === 1) {
                // this.getAllBuyVesselsRequest(this.state.UserId);
                this.getAllMyBuyVesselsRequest(
                  this.state.UserId,
                  this.state.token
                );
                this.setState({
                  loading: false,
                  open: false,
                  sellingrate: "",
                  items: [],
                });
                cogoToast.success(res.Message);
              } else if (res.Success == -1) {
                let data = localStorage.getItem("Infodata");
                var params = {
                  UserId: data,
                };
                // setUserName: data });
                api
                  .post(apiBase + "/ajax/player.php?action=AppLogout", params)
                  .then((res) => {
                    if (res.Success == 1) {
                      localStorage.clear();
                      const { history } = this.props;
                      history.push("/login");
                    }
                  });
              } else {
                this.setState({ loading: false });
                cogoToast.error(res.Message);
              }
            })
            .catch((err) => {});
        } else {
          this.setState({ loading: false });
          alert("in Else");
        }
      }
    } else if (type === "acceptnetwork") {
      var params = {
        UserId: this.state.UserId,
        NetworkId: item.NetworkId,
        Type: "accept",
      };
      if (params !== null) {
        this.setState({ loading: true });
        api
          .post(APIConstant.path.AcceptRejectNetwork, params)
          .then((res) => {
            if (res.Success === 1) {
              this.getNetworkRequest(this.state.UserId, this.state.token);
              this.setState({
                loading: false,
                open: false,
                sellingrate: "",
                items: [],
              });
              cogoToast.success(res.Message);
            } else if (res.Success == -1) {
              let data = localStorage.getItem("Infodata");
              var params = {
                UserId: data,
              };
              // setUserName: data });
              api
                .post(apiBase + "/ajax/player.php?action=AppLogout", params)
                .then((res) => {
                  if (res.Success == 1) {
                    localStorage.clear();
                    const { history } = this.props;
                    history.push("/login");
                  }
                });
            } else {
              this.setState({ loading: false });
              cogoToast.error(res.Message);
            }
          })
          .catch((err) => {});
      } else {
        this.setState({ loading: false });
        alert("in Else");
      }
    } else if (type == "resend") {
      var params = {
        Type: "request",
        rate: this.state.sellingrate,
        Quantity: this.state.reqQuantity,
        PurchaseId: item.PuchaseId,
        fkUserId: item.PurchaseUserId,
        VesselId: item.fkVesselId,
        VesselUserId: item.VesselUserId,
        VesselStatus: 0,

        // message: ""
      };
      api
        .post(
          apiBase + "/ajax/player.php?action=updateNotificationStatus",
          params
        )
        .then((res) => {
          if (res.Success === 1) {
            this.setState({
              loading: false,
              open: false,
              click: false,
              requestrate: "",
            });
            cogoToast.success("Request for the vessel sent successfully");
          } else if (res.Success == -1) {
            let data = localStorage.getItem("Infodata");
            var params = {
              UserId: data,
            };
            // setUserName: data });
            api
              .post(apiBase + "/ajax/player.php?action=AppLogout", params)
              .then((res) => {
                if (res.Success == 1) {
                  localStorage.clear();
                  const { history } = this.props;
                  history.push("/login");
                }
              });
          } else {
            this.setState({
              loading: false,
              open: false,
              click: false,
              requestrate: "",
            });
            cogoToast.error("Request failed");
          }
        });
    } else {
      if (this.validate("vesselrate")) {
        // this.setState({ loading: true })
        var params = {
          UserId: this.state.UserId,
          PuchaseId: item.PuchaseId,
          PurchaseFrom: item.PurchaseFrom,
          PurchaseUserId: item.PurchaseUserId,
          VesselId: item.VesselId,
          Rate: this.state.sellingrate,
          ParentVesselUserId: item.ParentVesselUserId,
          reqQuantity: this.state.reqQuantity,
          VesselUserId: item.VesselUserId,
          Type: "accepttheirs",
        };
        if (params !== null) {
          this.setState({ loading: true, buyVesselList: [] });
          api
            .post(apiBase + "/ajax/player.php?action=soldVessels", params)
            .then((res) => {
              if (res.Success === 1) {
                this.getAllBuyVesselsRequest(
                  this.state.UserId,
                  this.state.token
                );
                this.setState({
                  loading: false,
                  open: false,
                  sellingrate: "",
                  items: [],
                });
                cogoToast.success(res.Message);
              } else if (res.Success == -1) {
                let data = localStorage.getItem("Infodata");
                var params = {
                  UserId: data,
                };
                // setUserName: data });
                api
                  .post(apiBase + "/ajax/player.php?action=AppLogout", params)
                  .then((res) => {
                    if (res.Success == 1) {
                      localStorage.clear();
                      const { history } = this.props;
                      history.push("/login");
                    }
                  });
              } else {
                this.setState({ loading: false });
                cogoToast.error(res.Message);
              }
            })
            .catch((err) => {});
        } else {
          this.setState({ loading: false });
          alert("in Else");
        }
      }
    }
  };

  CancelRequestStatus = (id, type) => {
    if (type === "reject") {
      this.setState({ loading: true });
      this.state.purchaseid = id;
      var params = {
        UserId: this.state.UserId,
        PuchaseId: this.state.purchaseid,
        Type: "request",
      };
      if (params !== null) {
        api
          .post(apiBase + "/ajax/player.php?action=CancelRequestStatus", params)
          .then((res) => {
            if (res.Success === 1) {
              this.getAllBuyVesselsRequest(this.state.UserId, this.state.token);
              this.setState({ loading: false });
              cogoToast.success(res.Message);
            } else if (res.Success == -1) {
              let data = localStorage.getItem("Infodata");
              var params = {
                UserId: data,
              };
              // setUserName: data });
              api
                .post(apiBase + "/ajax/player.php?action=AppLogout", params)
                .then((res) => {
                  if (res.Success == 1) {
                    localStorage.clear();
                    const { history } = this.props;
                    history.push("/login");
                  }
                });
            }
          })
          .catch((err) => {});
      } else {
        alert("in Else");
      }
    } else {
      this.setState({ loading: true });
      var params = {
        UserId: this.state.UserId,
        NetworkId: id,
        Type: "Rejected",
      };
      if (params !== null) {
        api
          .post(APIConstant.path.AcceptRejectNetwork, params)
          .then((res) => {
            if (res.Success === 1) {
              this.getNetworkRequest(this.state.UserId, this.state.token);
              this.setState({
                loading: false,
                opencCancelNetworkRequest: false,
              });
              cogoToast.success(res.Message);
            } else if (res.Success == -1) {
              let data = localStorage.getItem("Infodata");
              var params = {
                UserId: data,
              };
              // setUserName: data });
              api
                .post(apiBase + "/ajax/player.php?action=AppLogout", params)
                .then((res) => {
                  if (res.Success == 1) {
                    localStorage.clear();
                    const { history } = this.props;
                    history.push("/login");
                  }
                });
            }
          })
          .catch((err) => {});
      } else {
        alert("in Else");
      }
    }
  };

  CancellocationRequestStatus = (item) => {
    // this.setState({ loading: true })
    var params = {
      UserId: this.state.UserId,
      LocationId: item.id,
    };
    if (params !== null) {
      api
        .post(apiBase + "/ajax/player.php?action=deletePendingLocation", params)
        .then((res) => {
          if (res.Success === 1) {
            this.getlocationrequest(this.state.UserId, this.state.token);
            this.setState({
              loading: false,
              opencCancelLocationRequest: false,
            });
            this.setState({ loading: false });
            cogoToast.success(res.Message);
          } else if (res.Success == -1) {
            let data = localStorage.getItem("Infodata");
            var params = {
              UserId: data,
            };
            // setUserName: data });
            api
              .post(apiBase + "/ajax/player.php?action=AppLogout", params)
              .then((res) => {
                if (res.Success == 1) {
                  localStorage.clear();
                  const { history } = this.props;
                  history.push("/login");
                }
              });
          }
        })
        .catch((err) => {});
    } else {
      alert("in Else");
    }
  };

  CancelNetworkRequestStatus = (item) => {
    this.setState({ loading: true });
    var params = {
      UserId: this.state.UserId,
      NetworkId: item.NetworkId,
      Type: "network",
    };
    if (params !== null) {
      api
        .post(apiBase + "/ajax/player.php?action=CancelRequestStatus", params)
        .then((res) => {
          if (res.Success === 1) {
            this.getSentNetworkRequest(this.state.UserId, this.state.token);
            this.setState({ loading: false });
            cogoToast.success(res.Message);
          } else if (res.Success == -1) {
            let data = localStorage.getItem("Infodata");
            var params = {
              UserId: data,
            };
            // setUserName: data });
            api
              .post(apiBase + "/ajax/player.php?action=AppLogout", params)
              .then((res) => {
                if (res.Success == 1) {
                  localStorage.clear();
                  const { history } = this.props;
                  history.push("/login");
                }
              });
          }
        })
        .catch((err) => {});
    } else {
      alert("in Else");
    }
  };

  selectType = (e, val, type) => {
    e.preventDefault();
    if (type === "toggle") {
      var PIndex = this.state.TruckList.findIndex(
        (x) => x.RequestId === val.RequestId
      );
      var NewTextDetails = this.state.TruckList;
      NewTextDetails[PIndex]["RequestStatus"] =
        NewTextDetails[PIndex]["RequestStatus"] == 1 ? 0 : 1;
      if (NewTextDetails[PIndex]["RequestStatus"] == 1) {
        NewTextDetails[PIndex]["RequestStatus"] = 1;
      } else {
        NewTextDetails[PIndex]["RequestStatus"] = 0;
      }
      this.setState({ TruckList: NewTextDetails });
      var params = {
        UserId: this.state.UserId,
        RequestId: val.RequestId,
        togglestatus: NewTextDetails[PIndex]["RequestStatus"],
        Type: "truckrequest",
      };
      if (params !== null) {
        this.setState({ loading: true });
        api
          .post(APIConstant.path.UpdateNetworkToggleStatus, params)
          .then((res) => {
            if (res.Success === 1) {
              this.setState({ loading: false, TruckList: [] });
              this.getTruck(this.state.UserId, this.state.token);
            }
          });
      }
    } else if (type === "numoftrucks") {
      if (this.state.numoftrucks !== "" || this.state.numoftrucks !== null) {
        this.setState({ numoftrucksErr: false });
        this.setState({ numoftrucks: val });
      } else {
        this.setState({ numoftrucksErr: true });
      }
    } else {
      this.props.history.push({
        pathname: "/admin/trucklist/",
        state: {
          item: val,
        },
      });
    }
  };

  UpdateTruckRequestStatus = (item, type) => {
    // if (this.validate("numoftrucks")) {

    if (type === "acceptsamerate") {
      if (this.validate("assign")) {
        var place = this.state.textOperations.filter(
          (x) => x.isDeleted !== true && x.TruckId !== ""
        );

        var params = {
          UserId: this.state.UserId,
          AcceptId: item.AcceptId,
          vesselid: item.vesselid,
          RequestId: item.RequestId,
          AssignToUser: item.CreatedBy,
          numoftrucks: this.state.assignTruck,
          Totaltruck: item.NumOfTrucks,
          rate: item.Rate,
          place: place.map((x) => {
            return x.TruckId;
          }),
          IsTransporterStatus: 0,
          Type: "request",
        };
        if (params !== null) {
          this.setState({ loading: true });
          api
            .post(
              apiBase + "/ajax/player.php?action=AcceptTruckRequestStatus",
              params
            )
            .then((res) => {
              if (res.Success === 1) {
                this.getTruckRequest(this.state.UserId, this.state.token);
                this.setState({ loading: false, open3: false, option: [] });
                cogoToast.success(res.Message);
              } else if (res.Success == -1) {
                let data = localStorage.getItem("Infodata");
                var params = {
                  UserId: data,
                };
                // setUserName: data });
                api
                  .post(apiBase + "/ajax/player.php?action=AppLogout", params)
                  .then((res) => {
                    if (res.Success == 1) {
                      localStorage.clear();
                      const { history } = this.props;
                      history.push("/login");
                    }
                  });
              }
            })
            .catch((err) => {});
        } else {
          // alert("in Else");
        }
      }
    } else if (type === "acceptreceiver") {
      if (this.validate("truckrate")) {
        var params = {
          UserId: this.state.UserId,
          AcceptId: item.AcceptId,
          Destination: this.state.sDestination,
          rate: this.state.sellingtruckrate,
          Type: "receiver",
        };
        if (params !== null) {
          this.setState({ loading: true });
          api
            .post(
              apiBase + "/ajax/player.php?action=AcceptTruckRequestStatus",
              params
            )
            .then((res) => {
              if (res.Success === 1) {
                this.setState({
                  loading: false,
                  openreceiver: false,
                  option: [],
                });
                cogoToast.success(res.Message);
                this.getTruck(this.state.UserId, this.state.token);
              } else if (res.Success == -1) {
                let data = localStorage.getItem("Infodata");
                var params = {
                  UserId: data,
                };
                // setUserName: data });
                api
                  .post(apiBase + "/ajax/player.php?action=AppLogout", params)
                  .then((res) => {
                    if (res.Success == 1) {
                      localStorage.clear();
                      const { history } = this.props;
                      history.push("/login");
                    }
                  });
              }
            })
            .catch((err) => {});
        } else {
          alert("in Else");
        }
      }
    } else if (type === "accepttransporterdifferent") {
      if (this.validate("truckrate")) {
        var params = {
          UserId: this.state.UserId,
          AcceptId: item.AcceptId,
          rate: this.state.sellingtruckrate,
          Type: "transporter",
        };
        if (params !== null) {
          this.setState({ loading: true });
          api
            .post(
              apiBase + "/ajax/player.php?action=AcceptTruckRequestStatus",
              params
            )
            .then((res) => {
              if (res.Success === 1) {
                this.getTruckRequest(this.state.UserId, this.state.token);
                this.setState({
                  loading: false,
                  openaccepttransporter: false,
                  option: [],
                });
                cogoToast.success(res.Message);
                // this.getTruckRequest(this.state.UserId);
              } else if (res.Success == -1) {
                let data = localStorage.getItem("Infodata");
                var params = {
                  UserId: data,
                };
                // setUserName: data });
                api
                  .post(apiBase + "/ajax/player.php?action=AppLogout", params)
                  .then((res) => {
                    if (res.Success == 1) {
                      localStorage.clear();
                      const { history } = this.props;
                      history.push("/login");
                    }
                  });
              }
            })
            .catch((err) => {});
        } else {
          alert("in Else");
        }
      }
    } else if (type === "accepttransportersamerate") {
      var params = {
        UserId: this.state.UserId,
        AcceptId: item.AcceptId,
        Type: "transportersame",
      };
      if (params !== null) {
        this.setState({ loading: true });
        api
          .post(
            apiBase + "/ajax/player.php?action=AcceptTruckRequestStatus",
            params
          )
          .then((res) => {
            if (res.Success === 1) {
              this.getTruckRequest(this.state.UserId, this.state.token);
              this.setState({
                loading: false,
                openaccepttransporter: false,
                option: [],
              });
              cogoToast.success(res.Message);
              // this.getTruckRequest(this.state.UserId);
            } else if (res.Success == -1) {
              let data = localStorage.getItem("Infodata");
              var params = {
                UserId: data,
              };
              // setUserName: data });
              api
                .post(apiBase + "/ajax/player.php?action=AppLogout", params)
                .then((res) => {
                  if (res.Success == 1) {
                    localStorage.clear();
                    const { history } = this.props;
                    history.push("/login");
                  }
                });
            }
          })
          .catch((err) => {});
      } else {
        alert("in Else");
      }
    } else if (type === "acceptmine") {
      var params = {
        UserId: this.state.UserId,
        AcceptId: item.AcceptId,
        Destination: this.state.sDestination,
        Type: "receiversame",
      };
      if (params !== null) {
        this.setState({ loading: true });
        api
          .post(
            apiBase + "/ajax/player.php?action=AcceptTruckRequestStatus",
            params
          )
          .then((res) => {
            if (res.Success === 1) {
              this.setState({
                loading: false,
                openaccepttransporter: false,
                opendestination: false,
                option: [],
              });
              cogoToast.success(res.Message);
              this.getTruck(this.state.UserId, this.state.token);
            } else if (res.Success == -1) {
              let data = localStorage.getItem("Infodata");
              var params = {
                UserId: data,
              };
              // setUserName: data });
              api
                .post(apiBase + "/ajax/player.php?action=AppLogout", params)
                .then((res) => {
                  if (res.Success == 1) {
                    localStorage.clear();
                    const { history } = this.props;
                    history.push("/login");
                  }
                });
            }
          })
          .catch((err) => {});
      } else {
        alert("in Else");
      }
    } else {
      if (this.validate("truckrate")) {
        if (this.validate("assign")) {
          var place = this.state.textOperations.filter(
            (x) => x.isDeleted !== true && x.TruckId !== ""
          );

          var params = {
            UserId: this.state.UserId,
            AcceptId: item.AcceptId,
            vesselid: item.vesselid,
            AssignToUser: item.CreatedBy,
            RequestId: item.RequestId,
            numoftrucks: this.state.assignTruck,
            Totaltruck: item.NumOfTrucks,
            rate: this.state.sellingtruckrate,
            place: place.map((x) => {
              return x.TruckId;
            }),
            IsTransporterStatus: 1,
            Type: "requestdifferent",
          };
          if (params !== null) {
            this.setState({ loading: true });
            api
              .post(
                apiBase + "/ajax/player.php?action=AcceptTruckRequestStatus",
                params
              )
              .then((res) => {
                if (res.Success === 1) {
                  this.getTruckRequest(this.state.UserId, this.state.token);
                  this.setState({ loading: false, open2: false, option: [] });
                  cogoToast.success(res.Message);
                } else if (res.Success == -1) {
                  let data = localStorage.getItem("Infodata");
                  var params = {
                    UserId: data,
                  };
                  // setUserName: data });
                  api
                    .post(apiBase + "/ajax/player.php?action=AppLogout", params)
                    .then((res) => {
                      if (res.Success == 1) {
                        localStorage.clear();
                        const { history } = this.props;
                        history.push("/login");
                      }
                    });
                }
              })
              .catch((err) => {});
          } else {
            alert("in Else");
          }
        }
      }
    }
    // }
  };

  CancelTruckRequestStatus = (item, type) => {
    this.setState({ loading: true });
    if (type == "cancel") {
      var params = {
        UserId: this.state.UserId,
        AssignToUser: item.CreatedBy,
        RequestId: item.RequestId,
        vesselid: item.vesselid,
        Totaltruck: item.NumOfTrucks,
        AcceptId: item.AcceptId,
        rate: item.Rate,
        Type: "cancel",
      };
    } else if (type == "canceltransporter") {
      var params = {
        UserId: this.state.UserId,
        AcceptId: item.AcceptId,
        Type: "canceltransporter",
      };
    } else if (type == "canceltrucksentrequest") {
      var params = {
        UserId: this.state.UserId,
        RequestId: item.RequestId,
        Type: "canceltrucksentrequest",
      };
    } else {
      var params = {
        UserId: this.state.UserId,
        AcceptId: item.AcceptId,
        Type: "reject",
      };
    }
    if (params !== null) {
      api
        .post(
          apiBase + "/ajax/player.php?action=CancelTruckRequestStatus",
          params
        )
        .then((res) => {
          if (res.Success === 1) {
            this.getTruckRequest(this.state.UserId, this.state.token);
            this.getTruck(this.state.UserId, this.state.token);
            this.setState({
              loading: false,
              opendelete: false,
              cancelitem: [],
            });
            cogoToast.success(res.Message);
          } else if (res.Success == -1) {
            let data = localStorage.getItem("Infodata");
            var params = {
              UserId: data,
            };
            // setUserName: data });
            api
              .post(apiBase + "/ajax/player.php?action=AppLogout", params)
              .then((res) => {
                if (res.Success == 1) {
                  localStorage.clear();
                  const { history } = this.props;
                  history.push("/login");
                }
              });
          }
        })
        .catch((err) => {});
    } else {
      // alert("in Else");
    }
  };

  UpdateGroupRequestStatus = (item) => {
    this.setState({ loading: true });
    var params = {
      UserId: this.state.UserId,
      GroupMemberId: item.GroupMemberId,
    };
    if (params !== null) {
      api
        .post(
          apiBase + "/ajax/player.php?action=UpdateGroupRequestStatus",
          params
        )
        .then((res) => {
          if (res.success === 1) {
            this.getGroup(this.state.UserId, this.state.token);
            this.setState({ loading: false });
            cogoToast.success(res.message);
          } else if (res.Success == -1) {
            let data = localStorage.getItem("Infodata");
            var params = {
              UserId: data,
            };
            // setUserName: data });
            api
              .post(apiBase + "/ajax/player.php?action=AppLogout", params)
              .then((res) => {
                if (res.Success == 1) {
                  localStorage.clear();
                  const { history } = this.props;
                  history.push("/login");
                }
              });
          } else {
            this.setState({ loading: false });
            cogoToast.error(res.message);
          }
        })
        .catch((err) => {});
    } else {
      this.setState({ loading: false });
      // alert("in Else");
    }
  };

  handleNetwork = (e, item) => {
    this.setState({ loading: true });
    var params = {
      UserId: this.state.UserId,
      SentToUser: item.PurchaseUserId,
      Type: "mynetwork",
    };
    if (params != null) {
      api
        .post(APIConstant.path.CreateNetwork, params)
        .then((res) => {
          if (res.Success === 1) {
            this.setState({ loading: false, open1: false });
            this.getAllBuyVesselsRequest(this.state.UserId);
            cogoToast.success("Request sent successfully");
          } else {
            cogoToast.error("Something happening bad");
          }
        })
        .catch((err) => {});
    } else {
      this.setState({ loading: false });
      // alert("in Else");
    }
  };

  CancelGroupRequestStatus = (item) => {
    this.setState({ loading: true });
    var params = {
      UserId: this.state.UserId,
      GroupMemberId: item.GroupMemberId,
    };
    if (params !== null) {
      api
        .post(
          apiBase + "/ajax/player.php?action=CancelGroupRequestStatus",
          params
        )
        .then((res) => {
          if (res.success === 1) {
            this.getGroup(this.state.UserId, this.state.token);
            cogoToast.success(res.message);
            this.setState({ loading: false });
          } else if (res.Success == -1) {
            let data = localStorage.getItem("Infodata");
            var params = {
              UserId: data,
            };
            // setUserName: data });
            api
              .post(apiBase + "/ajax/player.php?action=AppLogout", params)
              .then((res) => {
                if (res.Success == 1) {
                  localStorage.clear();
                  const { history } = this.props;
                  history.push("/login");
                }
              });
          }
        })
        .catch((err) => {});
    } else {
      alert("in Else");
    }
  };

  getAllMyBuyVesselsRequest(UserId, token) {
    // this.setState({ loading: true })
    var params = {
      UserId: UserId,
      token: token,
    };
    if (params !== null) {
      api
        .post(
          apiBase + "/ajax/player.php?action=getAllMyBuyVesselsRequest",
          params
        )
        .then((res) => {
          if (res.Success === 1) {
            // this.setState({ loading: false })
            var getBuySentVesselList = [];
            var requestlength = res.Info.request.length;
            for (let i = 0; i < requestlength; i++) {
              getBuySentVesselList.push({
                label: res.Info.request[i].AddedBy,
                Status: res.Info.request[i].Status,
                IsUserStatus: res.Info.request[i].IsUserStatus,
                Name: res.Info.request[i].Name,
                VesselName: res.Info.request[i].VesselName,
                Quantity: res.Info.request[i].Quantity,
                PhoneNumber: res.Info.request[i].PhoneNumber,
                QualityName: res.Info.request[i].QualityName,
                Rate: res.Info.request[i].Rate,
                Updated_at: res.Info.request[i].Updated_at,
                Created_at: res.Info.request[i].Created_at,
                last_name: res.Info.request[i].last_name,
                companyname: res.Info.request[i].cmpny_name,
                Email: res.Info.request[i].Email,
                ProfilePic: res.Info.request[i].ProfilePic,

                OriginalQuantity: res.Info.request[i].OriginalQuantity,
                PhoneNumber: res.Info.request[i].PhoneNumber,

                RequestRate: res.Info.request[i].Rate,
                OriginalRate: res.Info.request[i].rate,
                PuchaseId: res.Info.request[i].PuchaseId,
                VesselId: res.Info.request[i].fkVesselId,
                PurchaseUserId: res.Info.request[i].PurchaseUserId,
                PurchaseFrom: res.Info.request[i].PurchaseFrom,
                ParentVesselUserId: res.Info.request[i].ParentVesselUserId,
                VesselUserId: res.Info.request[i].VesselUserId,
              });
            }
            this.setState({
              buyVesselList1: getBuySentVesselList.sort(function(a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.

                return (
                  new Date(b.Updated_at == "" ? b.Created_at : b.Updated_at) -
                  new Date(a.Updated_at == "" ? a.Created_at : a.Updated_at)
                );
              }),
              ismysentVesselList: true,
            });
          } else if (res.Success == -1) {
            let data = localStorage.getItem("Infodata");
            var params = {
              UserId: data,
            };
            // setUserName: data });
            api
              .post(apiBase + "/ajax/player.php?action=AppLogout", params)
              .then((res) => {
                if (res.Success == 1) {
                  localStorage.clear();
                  const { history } = this.props;
                  history.push("/login");
                }
              });
          }
        })
        .catch((err) => {});
    } else {
      cogoToast.error("some thing Bad");
    }
  }

  getlocationrequest(UserId, token) {
    // this.setState({ loading: true })
    var params = {
      UserId: UserId,
      token: token,
    };
    if (params !== null) {
      api
        .post(apiBase + "/ajax/player.php?action=getlocationlist", params)
        .then((res) => {
          if (res.Success === 1) {
            this.setState({ locationlist: res.Info.location });
          } else if (res.Success == -1) {
            let data = localStorage.getItem("Infodata");
            var params = {
              UserId: data,
            };
            // setUserName: data });
            api
              .post(apiBase + "/ajax/player.php?action=AppLogout", params)
              .then((res) => {
                if (res.Success == 1) {
                  localStorage.clear();
                  const { history } = this.props;
                  history.push("/login");
                }
              });
          }
        })
        .catch((err) => {});
    } else {
      cogoToast.error("some thing Bad");
    }
  }

  getGroup = (UserId, token) => {
    var params = {
      UserId: UserId,
      token: token,
    };
    if (params !== null) {
      api
        .post(apiBase + "/ajax/player.php?action=getGroupRequest", params)
        .then((res) => {
          if (res.Success === 1) {
            this.setState({ GroupList: res.Info.Group });
            var getGroupList = [];
            var grouplength = res.Info.Group.length;
            for (let i = 0; i < grouplength; i++) {
              getGroupList.push({
                GroupMemberId: res.Info.Group[i].GroupMemberId,
                GroupName: res.Info.Group[i].GroupName,
                type: res.Info.Group[i].type,
                status: res.Info.Group[i].status,
              });
            }
            this.setState({ GroupList: getGroupList });
          } else if (res.Success == -1) {
            let data = localStorage.getItem("Infodata");
            var params = {
              UserId: data,
            };
            api
              .post(apiBase + "/ajax/player.php?action=AppLogout", params)
              .then((res) => {
                if (res.Success == 1) {
                  localStorage.clear();
                  const { history } = this.props;
                  history.push("/login");
                }
              });
          }
        })
        .catch((err) => {});
    } else {
      alert("Id is Not Selected");
    }
  };

  getTruck = (UserId, token) => {
    var params = {
      UserId: UserId,
      token: token,
    };
    api
      .post(apiBase + "/ajax/player.php?action=getTruckRequest", params)
      .then((res) => {
        if (res.Success === 1) {
          this.setState({ TruckList: res.Info.Truck });
        }
      })
      .catch((err) => {});
  };

  getTruckRequest = (UserId, token) => {
    var params = {
      UserId: UserId,
      token: token,
    };
    api
      .post(
        apiBase + "/ajax/player.php?action=getTransporterTruckRequest",
        params
      )
      .then((res) => {
        if (res.Success == 1) {
          this.setState({
            TruckRequestList: res.Info.Truck.sort(function(a, b) {
              return new Date(b.Date) - new Date(a.Date);
            }),
            originLatitude: res.Info.Truck[0].OriginLatitude,
            originLongitude: res.Info.Truck[0].OriginLongitude,
            destinationLatitude: res.Info.Truck[0].DestinationLatitude,
            destinationLongitude: res.Info.Truck[0].DestinationLongitude,
            partyName: res.Info.Truck[0].PartyName,
          });
        }
      })
      .catch((err) => {});
  };

  toggle(res) {
    window.open(
      "https://www.google.com/maps/dir/?api=1&origin=" +
        res.Origin +
        "&destination=" +
        res.Destination +
        "&travelmode=driving",
      "_blank"
    );
  }

  toAdd = (structured_formatting) => {
    this.setState({ destination: structured_formatting.description });
    geocodeByAddress(structured_formatting.description)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) =>
        this.setState({ DstLatitude: lat, DstLongitude: lng })
      );
  };

  handleClose = (e, type) => {
    if (type === "cancellocationrequest") {
      this.setState({
        opencCancelLocationRequest: false,
        items: [],
        loading: false,
        phoneclick: 0,
      });
    } else if (type === "cancelnetworkrequest") {
      this.setState({
        opencCancelNetworkRequest: false,
        items: [],
        loading: false,
        phoneclick: 0,
      });
    } else if (type === "sellingrate") {
      this.setState({ open: false, items: [], sellingrate: "" });
    } else if (type === "profile") {
      this.setState({
        open1: false,
        profileitems: [],
        firstName: "",
        secondName: "",
        emailId: "",
        contactNum: "",
      });
    } else if (type === "sellingsametruckrate") {
      this.setState({
        open3: false,
        textOperations: [],
        assignTruck: 0,
        items1: [],
        sellingtruckrate: "",
      });
    } else if (type === "assigntruck") {
      this.setState({ opentransporter: false, items1: [], getTransporter: [] });
    } else if (type === "acceptreceiver") {
      this.setState({
        openreceiver: false,
        items1: [],
        getTransporter: [],
        phoneclick: 0,
        sellingtruckrate: "",
      });
    } else if (type === "truckdetails") {
      this.setState({
        openTruckDetail: false,
        items1: [],
        getTruckDetails: [],
      });
    } else if (type === "accepttransporter") {
      this.setState({
        openaccepttransporter: false,
        items1: [],
        getTruckDetails: [],
      });
    } else if (type == "back") {
      this.setState({
        openTruckDetail: false,
        opentransporter: true,
        items1: [],
        getTruckDetails: [],
      });
    } else if (type == "destination") {
      this.setState({
        opendestination: false,
        textOperations: [],
        assignTruck: 0,
        items: [],
        sellingtruckrate: "",
      });
    } else {
      this.setState({
        open2: false,
        textOperations: [],
        assignTruck: 0,
        items1: [],
        sellingtruckrate: "",
        sellingtruckrateErr: false,
        sellingtruckratehelperText: "",
      });
    }
  };
  TruckDistance = (ID, Truck) => {
    let params = {
      UserId: localStorage.getItem("Infodata"),
      VehicleNo: ID.TruckNumber,
    };
    api.post(APIConstant.path.GetVehicleLiveStatus, params).then((data) => {
      if (data.Success == 1) {
        this.calculateDistanceForTruckRequest(
          Truck.OriginLongitude,
          Truck.OriginLatitude,
          data.Info.VehicleTracking.cordinate[0],
          data.Info.VehicleTracking.cordinate[1]
        );
      }
    });
  };
  calculateDistanceForTruckRequest = (latSrc1, lngDst1, latSrc2, lngDst2) => {
    var R = 6371.071;
    var radlat1 = (Math.PI * Number(latSrc1)) / 180;
    var radlat2 = (Math.PI * Number(latSrc2)) / 180;
    var difflat = radlat2 - radlat1;
    var difflon = ((lngDst2 - lngDst1) * Math.PI) / 180;
    var distance =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(radlat1) *
              Math.cos(radlat2) *
              Math.sin(difflon / 2) *
              Math.sin(difflon / 2)
        )
      );
    var Distance = parseFloat(distance).toFixed(2);

    this.setState({ DistanceTruck: Distance });
  };

  render() {
    const {
      buyVesselList1,
      locationlist,
      GroupList,
      Networklist,
      TruckList,
      TruckRequestList,
      UserRole,
    } = this.state;
    return (
      <div className="stock-outer">
        {this.state.loading === true ? (
          <div className="loading">
            <SimpleBackdrop />
          </div>
        ) : null}

        <React.Fragment>
          <AppBar position="static" className="cs-tabs">
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              aria-label="simple tabs example"
            >
              {this.state.serviceList.includes("Location Request") ? (
                <Tab
                  label={
                    <Badge
                      color="primary"
                      className="request-badge"
                      badgeContent={0}
                    >
                      <span
                        style={{
                          position: "relative",
                          top: "-12px",
                          right: "20px",
                        }}
                      >
                        LOCATION
                      </span>
                    </Badge>
                  }
                  {...a11yProps(0)}
                />
              ) : (
                "null"
              )}
              {this.state.serviceList.includes("Network Request") ? (
                <Tab
                  label={
                    <Badge
                      color="primary"
                      className="request-badge"
                      badgeContent={this.state.Networklist.length}
                    >
                      <span
                        style={{
                          position: "relative",
                          top: "-12px",
                          right: "20px",
                        }}
                      >
                        NETWORK
                      </span>
                    </Badge>
                  }
                  {...a11yProps(1)}
                />
              ) : null}
              {this.state.serviceList.includes("Group Request") ? (
                <Tab
                  label={
                    <Badge
                      color="primary"
                      className="request-badge"
                      badgeContent={this.state.GroupList.length}
                    >
                      <span
                        style={{
                          position: "relative",
                          top: "-12px",
                          right: "20px",
                        }}
                      >
                        Group
                      </span>
                    </Badge>
                  }
                  {...a11yProps(2)}
                />
              ) : null}
            </Tabs>
          </AppBar>
          <TabPanel
            className="main-card truck-list-container p-px-4"
            value={this.state.value}
            index={0}
          >
            {this.state.locationlist.length !== 0 ? (
              locationlist.map((item, key) => {
                return (
                  <div className="request-outer" key={key}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={5} className="p-pl-0">
                        <div className="request-text-box">
                          <div className="req-main-name">
                            {" "}
                            <i className="icon-location-fill p-pr-2"></i>{" "}
                            {item.placeName}{" "}
                          </div>
                          <div className="p-d-flex p-text-truncate p-pt-1">
                            <div className="tag-item">
                              {" "}
                              <i className="icon-accuracy"></i> &nbsp;-{" "}
                              {item.accuracy}
                            </div>
                            <div className="req-semi-main-name">
                              {item.address}
                            </div>
                          </div>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={3}>
                        <div className="request-text-box">
                          <div className="p-d-flex ">
                            <div className="request-place-img">
                              <img src={place} />
                            </div>
                            <div>
                              <div className="p-d-flex">
                                <div class="req-place-lable">Latitude:</div>
                                <div>{item.latitude}</div>
                              </div>
                              <div className="p-d-flex">
                                <div class="req-place-lable">Longitude:</div>
                                <div>{item.longitude}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        <div className="p-text-right">
                          <div className="light-color text-small p-text-right">
                            {moment(item.CreatedOn).format("DD-MM-YYYY")}
                          </div>
                          <div className="p-pt-0 request-status-icon">
                            {item.status === "Pending" ? (
                              <div className="bg-orange action-btn fs-12">
                                <i class="icon-pending"></i>
                                <span> Pending</span>

                                <i
                                  className="icon-close"
                                  style={{ color: "rgb(32, 33, 36)" }}
                                  onClick={(e) =>
                                    this.openModal(
                                      item,
                                      "cancellocationrequest"
                                    )
                                  }
                                ></i>
                              </div>
                            ) : item.status === "Rejected" ? (
                              <div className="bg-red action-btn fs-12">
                                <i class="icon-reject"> </i>
                                <span>Rejected</span>
                              </div>
                            ) : (
                              <div className="bg-green action-btn fs-12">
                                <i class="icon-tick"></i> <span>Accepted </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                );
              })
            ) : (
              <div className="null-div">There is no Sent request</div>
            )}
          </TabPanel>
          <TabPanel
            className="main-card truck-list-container p-p-3"
            value={this.state.value}
            index={1}
          >
            <GridContainer>
              {this.state.Networklist.length !== 0 ? (
                Networklist.map((item, key) => {
                  return (
                    <>
                      {item.SRNetworkRequest == "sent" ? (
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            key={key}
                            className="request-outer network-rec-section"
                          >
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <div className="p-d-flex p-jc-between">
                                  <div className="request-text-box network-text p-d-flex">
                                    <div className="request-user-img ">
                                      <img
                                        src={
                                          item.ProfilePic == "" ||
                                          item.ProfilePic == null ||
                                          item.ProfilePic == undefined
                                            ? User
                                            : individual_profile_image_link +
                                              item.ProfilePic
                                        }
                                      />
                                    </div>
                                    <div className="text-box">
                                      <Link>
                                        <div
                                          className="req-main-name"
                                          onClick={(e) =>
                                            this.openModal(item, "profile")
                                          }
                                        >
                                          {item.Name}
                                        </div>
                                      </Link>
                                      <div className="req-semi-main-name p-text-truncate">
                                        {item.companyname}
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="light-color text-small p-text-truncate p-text-right">
                                      {moment(item.CreatedOn).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </div>
                                    <div className="request-status-icon p-pt-0">
                                      {item.Status === "Pending" ? (
                                        <div class="icon-btn">
                                          <i
                                            className="icon-tick buyer-green"
                                            onClick={() =>
                                              this.UpdatedRequestStatus(
                                                item,
                                                "acceptnetwork"
                                              )
                                            }
                                          ></i>{" "}
                                        </div>
                                      ) : null}
                                      {item.Status === "Pending" ? (
                                        <div class="icon-btn">
                                          <i
                                            className="icon-cancel seller-red"
                                            onClick={(e) =>
                                              this.openModal(
                                                item,
                                                "cancelnetworkrequest"
                                              )
                                            }
                                          ></i>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </GridItem>
                            </GridContainer>
                          </div>
                        </GridItem>
                      ) : (
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            key={key}
                            className="request-outer network-sent-section"
                          >
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <div className="p-d-flex p-jc-between">
                                  <div className="request-text-box">
                                    <div className="p-d-flex">
                                      <div className="request-user-img">
                                        <img
                                          src={
                                            item.ProfilePic == "" ||
                                            item.ProfilePic == null ||
                                            item.ProfilePic == undefined
                                              ? User
                                              : individual_profile_image_link +
                                                item.ProfilePic
                                          }
                                        />
                                      </div>
                                      <div>
                                        <Link>
                                          <div
                                            className="req-main-name"
                                            onClick={(e) =>
                                              this.openModal(item, "profile")
                                            }
                                          >
                                            {item.Name}
                                          </div>
                                        </Link>
                                        <div className="req-semi-main-name p-text-truncate">
                                          {item.companyname}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="light-color text-small p-text-right">
                                      {moment(item.CreatedOn).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </div>
                                    <div className="request-status-icon p-pt-0">
                                      {item.Status === "Pending" ? (
                                        <div className="bg-orange action-btn fs-12 p-text-capitalize">
                                          <i class="icon-pending"></i>
                                          <span>Pending</span>
                                          <i
                                            className="icon-close"
                                            style={{ color: "rgb(32, 33, 36)" }}
                                            onClick={(e) =>
                                              this.openModal(
                                                item,
                                                "cancelnetworkrequest"
                                              )
                                            }
                                          ></i>
                                        </div>
                                      ) : item.Status === "Rejected" ? (
                                        <div className="bg-red action-btn fs-12 p-text-capitalize">
                                          <i class="icon-cancel seller-red"></i>
                                          <span>Rejected</span>
                                        </div>
                                      ) : (
                                        <div className="bg-green action-btn fs-12 p-text-capitalize">
                                          <i class="icon-tick buyer-green"></i>{" "}
                                          <span>Accepted </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </GridItem>
                            </GridContainer>
                          </div>
                        </GridItem>
                      )}
                    </>
                  );
                })
              ) : (
                <div className="null-div">There is no new network request</div>
              )}
            </GridContainer>
          </TabPanel>
          <TabPanel
            className="main-card truck-list-container"
            value={this.state.value}
            index={2}
          >
            {this.state.GroupList.length !== 0 ? (
              GroupList.map((item, key) => {
                return (
                  <div key={key} className="request-outer">
                    <GridContainer>
                      <GridItem xs={5}>
                        <div className="request-text-box">
                          <h5>{item.GroupName}</h5>
                        </div>
                      </GridItem>
                      <GridItem xs={5}>
                        <div className="request-text-box">
                          <h5>{item.type}</h5>
                        </div>
                      </GridItem>
                      <GridItem xs={2}>
                        <div className="request-status-icon">
                          {item.status === "pending" ? (
                            <img
                              style={{ height: "25px", width: "25px" }}
                              src={yes}
                              onClick={() =>
                                this.UpdateGroupRequestStatus(item)
                              }
                            />
                          ) : null}
                          {item.status === "pending" ? (
                            <img
                              style={{ height: "25px", width: "25px" }}
                              src={cross}
                              onClick={() =>
                                this.CancelGroupRequestStatus(item)
                              }
                            />
                          ) : null}
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                );
              })
            ) : (
              <div className="null-div">There is no new Group request</div>
            )}
          </TabPanel>
        </React.Fragment>
        <Dialog
          open={this.state.open}
          onClose={(e) => this.handleClose(e, "sellingrate")}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className="custom-dialog-header" id="form-dialog-title">
            <div>Request Rate</div>

            <div
              className="dialog-close"
              onClick={(e) => this.handleClose(e, "sellingrate")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <React.Fragment>
            <DialogContent className="dialog-content-area">
              <TextField
                autoFocus
                margin="dense"
                name="sellingrate"
                id="sellingrate"
                label="Enter Request Rate"
                type="text"
                value={this.state.sellingrate}
                onChange={(e) => this.handleChange_value1(e, "sellingrate")}
                error={this.state.sellingrateErr}
                helperText={this.state.sellingratehelperText}
                fullWidth
              />
              <label>
                <p>Current Rate: {this.state.currentrate}</p>
              </label>
              <TextField
                // autoFocus
                margin="dense"
                name="reqQuantity"
                id="reqQuantity"
                label="Enter Quantity"
                type="text"
                value={this.state.reqQuantity}
                onChange={(e) => this.handleChange_value1(e, "reqQuantity")}
                error={this.state.reqQuantityErr}
                helperText={this.state.reqQuantityhelperText}
                fullWidth
              />
              <label>
                <p>Available Quantity: {this.state.currentQuantity}</p>
              </label>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              {this.state.loading === true ? (
                <div className="loading">
                  <SimpleBackdrop />
                </div>
              ) : null}
              <React.Fragment>
                <Button
                  className="primary-btn1"
                  onClick={(e) => this.handleClose(e, "sellingrate")}
                  color="primary"
                  disabled={this.state.loading}
                >
                  Cancel
                </Button>
                {this.state.type == "accepttheirs" ? (
                  <Button
                    className="primary-btn"
                    onClick={(e) =>
                      this.UpdatedRequestStatus(
                        this.state.items,
                        "accepttheirs"
                      )
                    }
                    color="primary"
                    disabled={this.state.loading}
                  >
                    Confirm
                  </Button>
                ) : this.state.type == "resend" ? (
                  <Button
                    className="primary-btn"
                    onClick={(e) =>
                      this.UpdatedRequestStatus(this.state.items, "resend")
                    }
                    color="primary"
                    disabled={this.state.loading}
                  >
                    Confirm
                  </Button>
                ) : (
                  <Button
                    className="primary-btn"
                    onClick={(e) =>
                      this.UpdatedRequestStatus(
                        this.state.items,
                        "acceptbuyertheirs"
                      )
                    }
                    color="primary"
                    disabled={this.state.loading}
                  >
                    Confirm
                  </Button>
                )}
              </React.Fragment>
            </DialogActions>
          </React.Fragment>
        </Dialog>

        <Dialog
          className="assign-truck-modal"
          open={this.state.open2}
          onClose={(e) => this.handleClose(e, "sellingtruckrate")}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className="custom-dialog-header" id="form-dialog-title">
            <div>Assign Truck</div>
            <div className="req-semi-main-name p-text-truncate">
              <i className="icon-location-fill p-pr-2"></i>{" "}
              {this.state.items1.Origin == ""
                ? this.state.items1.PortName
                : this.state.items1.Origin}{" "}
              -{" "}
              {this.state.items1.Destination == ""
                ? this.state.items1.destination == ""
                  ? "N/A"
                  : this.state.items1.destination
                : this.state.items1.Destination}
            </div>
            <div
              className="dialog-close"
              onClick={(e) => this.handleClose(e, "sellingtruckrate")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <React.Fragment>
            <DialogContent className="assign-truck-section">
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <label>
                    <div>Truck Assigned: </div>
                    <div className="fw-500 color-black">
                      {this.state.assignTruck}/{this.state.option}
                    </div>
                  </label>
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="sellingtruckrate"
                    id="sellingtruckrate"
                    label="Enter Approval Rate"
                    type="text"
                    value={this.state.sellingtruckrate}
                    onChange={(e) =>
                      this.handleChange_value1(e, "sellingtruckrate")
                    }
                    error={this.state.sellingtruckrateErr}
                    helperText={this.state.sellingtruckratehelperText}
                    fullWidth
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={4} className="p-as-center">
                  <div className="vbn-message message-yellow message-yellow-color p-d-inline-block p-mb-0">
                    <div>Requested Rate: {this.state.currenttruckrate}</div>
                  </div>
                </GridItem>
              </GridContainer>
              <div className="assign-truck-details">
                <div className="d-flex p-jc-between p-ai-center p-px-2">
                  <h2>Truck Details</h2>
                </div>
                <GridContainer className="p-mt-2">
                  {this.state.arr.length !== 0 ? (
                    this.state.arr.map((item, key) => {
                      var PIndex = this.state.textOperations.findIndex(
                        (x) => x.id === item.id[0]
                      );
                      var NewTextDetails = this.state.textOperations;

                      return (
                        <GridItem xs={12} sm={6} md={4} key={key}>
                          <div className="assign-truck-box">
                            <div className="d-flex p-jc-between border-bottom-light p-pb-2 p-mb-2">
                              <div>
                                <div className="assign-truck-no">
                                  {item.TruckNumber}
                                </div>
                                <div className="assign-truck-label">
                                  {CommonConfig.thousandsSeparators(
                                    CommonConfig.twoDigits(item.Weight)
                                  )}{" "}
                                  MTN
                                </div>
                                <div className="assign-truck-label">
                                  {item.StringMapName}
                                </div>
                                <div>
                                  <span>
                                    <i className="icon-truck"></i> Distance:{" "}
                                    {this.state.DistanceTruck == 0
                                      ? 0
                                      : this.state.DistanceTruck}{" "}
                                    KM approx
                                  </span>
                                </div>
                              </div>
                              <div className="assign-truck-tag green-icon">
                                {CommonConfig.getInitials(item.TruckNumber)}
                              </div>
                            </div>
                            <div>
                              <div className="fs-12 p-my-1 color-disable ">
                                Drivers
                              </div>
                              <div className="p-d-flex p-ai-center p-mb-2">
                                <i className="icon-steering"></i>
                                <div className="fw-500">{item.Driver1}</div>
                              </div>
                              <div className="p-d-flex p-ai-center p-mb-2">
                                <i className="icon-steering"></i>
                                <div className="fw-500">{item.Driver2}</div>
                              </div>

                              {/* <Button className="primary-btn2 p-mb-0">Assign</Button> */}
                              {NewTextDetails.length !== 0 ? (
                                NewTextDetails[PIndex]["TruckId"] === "" ? (
                                  item.IsTruckAssigned == false ? (
                                    <div>
                                      <Button
                                        onClick={(e) =>
                                          this.fromAdd(e, item, "add")
                                        }
                                        className="primary-btn2 p-mb-0"
                                      >
                                        Assign
                                      </Button>
                                    </div>
                                  ) : (
                                    <div>Already assigned</div>
                                  )
                                ) : (
                                  <div>
                                    <Button
                                      onClick={(e) =>
                                        this.fromAdd(e, item, "clear")
                                      }
                                      className="primary-btn1 p-w-100 border-dark-blue p-mb-0"
                                    >
                                      Assigned
                                    </Button>
                                  </div>
                                )
                              ) : item.IsTruckAssigned == false ? (
                                <div>
                                  <Button
                                    onClick={(e) =>
                                      this.fromAdd(e, item, "add")
                                    }
                                    className="primary-btn2 p-mb-0"
                                  >
                                    Assign
                                  </Button>
                                </div>
                              ) : (
                                <div>Already assigned</div>
                              )}
                            </div>
                          </div>
                        </GridItem>
                      );
                    })
                  ) : (
                    <div>There is no records</div>
                  )}
                </GridContainer>
              </div>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              {this.state.loading === true ? (
                <div className="loading">
                  <SimpleBackdrop />
                </div>
              ) : null}
              <React.Fragment>
                <Button
                  className="primary-btn1"
                  onClick={(e) => this.handleClose(e, "sellingtruckrate")}
                  color="primary"
                  disabled={this.state.loading}
                >
                  Cancel
                </Button>
                <Button
                  className="primary-btn"
                  onClick={(e) =>
                    this.UpdateTruckRequestStatus(
                      this.state.items1,
                      "acceptdifferentrate"
                    )
                  }
                  color="primary"
                  disabled={this.state.loading}
                >
                  Confirm
                </Button>
              </React.Fragment>
            </DialogActions>
          </React.Fragment>
        </Dialog>

        <Dialog
          className="assign-truck-modal"
          open={this.state.open3}
          onClose={(e) => this.handleClose(e, "sellingsametruckrate")}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className="custom-dialog-header" id="form-dialog-title">
            <div>Assign Truck</div>
            <div className="req-semi-main-name p-text-truncate">
              <i className="icon-location-fill p-pr-2"></i>{" "}
              {this.state.items1.Origin == ""
                ? this.state.items1.PortName
                : this.state.items1.Origin}{" "}
              -{" "}
              {this.state.items1.Destination == ""
                ? this.state.items1.destination == ""
                  ? "N/A"
                  : this.state.items1.destination
                : this.state.items1.Destination}
            </div>
            <div
              className="dialog-close"
              onClick={(e) => this.handleClose(e, "sellingsametruckrate")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <React.Fragment>
            <DialogContent className="assign-truck-section">
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <label>
                    <div>Truck Assigned:</div>
                    <div className="fw-500 color-black">
                      {this.state.assignTruck}/{this.state.option}
                    </div>
                  </label>
                </GridItem>
              </GridContainer>
              <div className="assign-truck-details">
                <div className="d-flex p-jc-between p-ai-center p-px-2">
                  <h2>Truck Details</h2>
                </div>
                <GridContainer className="p-mt-2">
                  {this.state.arr.length !== 0 ? (
                    this.state.arr.map((item, key) => {
                      var PIndex = this.state.textOperations.findIndex(
                        (x) => x.id === item.id[0]
                      );
                      var NewTextDetails = this.state.textOperations;

                      return (
                        <GridItem xs={12} sm={6} md={4} key={key}>
                          <div className="assign-truck-box">
                            <div className="d-flex p-jc-between border-bottom-light p-pb-2 p-mb-2">
                              <div>
                                <div className="assign-truck-no">
                                  {item.TruckNumber}
                                </div>
                                <div className="assign-truck-label">
                                  {CommonConfig.thousandsSeparators(
                                    CommonConfig.twoDigits(item.Weight)
                                  )}{" "}
                                  MTN
                                </div>
                                <div className="assign-truck-label">
                                  {item.StringMapName}
                                </div>
                                <div>
                                  <span>
                                    <i className="icon-truck"></i> Distance:{" "}
                                    {this.state.DistanceTruck == 0
                                      ? 0
                                      : this.state.DistanceTruck}{" "}
                                    KM approx
                                  </span>
                                </div>
                              </div>
                              <div className="assign-truck-tag green-icon">
                                {CommonConfig.getInitials(item.TruckNumber)}
                              </div>
                            </div>
                            <div>
                              <div className="fs-12 p-my-1 color-disable ">
                                Drivers
                              </div>
                              <div className="p-d-flex p-ai-center p-mb-2">
                                <i className="icon-steering"></i>
                                <div className="fw-500">{item.Driver1}</div>
                              </div>
                              <div className="p-d-flex p-ai-center p-mb-2">
                                <i className="icon-steering"></i>
                                <div className="fw-500">{item.Driver2}</div>
                              </div>

                              {/* <Button className="primary-btn2 p-mb-0">Assign</Button> */}
                              {NewTextDetails.length !== 0 ? (
                                NewTextDetails[PIndex]["TruckId"] === "" ? (
                                  item.IsTruckAssigned == false ? (
                                    <div>
                                      <Button
                                        onClick={(e) =>
                                          this.fromAdd(e, item, "add")
                                        }
                                        className="primary-btn2 p-mb-0"
                                      >
                                        Assign
                                      </Button>
                                    </div>
                                  ) : (
                                    <div>Already assigned</div>
                                  )
                                ) : (
                                  <div>
                                    <Button
                                      onClick={(e) =>
                                        this.fromAdd(e, item, "clear")
                                      }
                                      className="primary-btn1 p-w-100 border-dark-blue p-mb-0"
                                    >
                                      Assigned
                                    </Button>
                                  </div>
                                )
                              ) : item.IsTruckAssigned == false ? (
                                <div>
                                  <Button
                                    onClick={(e) =>
                                      this.fromAdd(e, item, "add")
                                    }
                                    className="primary-btn2 p-mb-0"
                                  >
                                    Assign
                                  </Button>
                                </div>
                              ) : (
                                <div>Already assigned</div>
                              )}
                            </div>
                          </div>
                        </GridItem>
                      );
                    })
                  ) : (
                    <div>There is no records</div>
                  )}
                </GridContainer>
              </div>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              {this.state.loading === true ? (
                <div className="loading">
                  <SimpleBackdrop />
                </div>
              ) : null}
              <React.Fragment>
                <Button
                  className="primary-btn1"
                  onClick={(e) => this.handleClose(e, "sellingsametruckrate")}
                  color="primary"
                  disabled={this.state.loading}
                >
                  Cancel
                </Button>
                <Button
                  className="primary-btn"
                  onClick={(e) =>
                    this.UpdateTruckRequestStatus(
                      this.state.items1,
                      "acceptsamerate"
                    )
                  }
                  color="primary"
                  disabled={this.state.loading}
                >
                  Confirm
                </Button>
              </React.Fragment>
            </DialogActions>
          </React.Fragment>
        </Dialog>

        <Dialog
          open={this.state.opentransporter}
          onClose={(e) => this.handleClose(e, "assigntruck")}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className="custom-dialog-header" id="form-dialog-title">
            <div>Assigned Truck</div>
            <div
              className="dialog-close"
              onClick={(e) => this.handleClose(e, "assigntruck")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <React.Fragment>
            <DialogContent className="dialog-content-area">
              <GridContainer>
                <GridItem xs={4}>
                  <div className="vib-detail last">
                    <h5>Transporter Name</h5>
                  </div>
                </GridItem>
                <GridItem xs={4}>
                  <div className="vib-detail last">
                    <h5>Trucks Assigned</h5>
                  </div>
                </GridItem>
                <GridItem xs={4}>
                  <div className="vib-detail last">
                    <h5>Action</h5>
                  </div>
                </GridItem>
              </GridContainer>
              <span className="devider-full"></span>
              {this.state.getTransporter.map((item, key) => {
                return (
                  <GridContainer>
                    <GridItem xs={4}>
                      <div
                        className="vib-detail last" /*onClick={(e) => this.handleChange_value1(item.fkUserId, item.VesselId,item.VesselUserId)}*/
                      >
                        {/* <h5>Date</h5> */}
                        <p className="color-green">
                          {item.TransporterName} {item.ContactName}
                        </p>
                      </div>
                    </GridItem>
                    <GridItem xs={4}>
                      <div className="vib-detail last">
                        <p className="color-green">{item.NumOfTrucks}</p>
                      </div>
                    </GridItem>
                    <GridItem xs={4}>
                      {" "}
                      <div className="vib-detail last">
                        <p className="color-light-green">
                          <VisibilityIcon
                            onClick={(e) =>
                              this.openModal(item, "truckDetails")
                            }
                          />
                        </p>
                      </div>
                    </GridItem>
                  </GridContainer>
                );
              })}
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              {this.state.loading === true ? (
                <div className="loading">
                  <SimpleBackdrop />
                </div>
              ) : null}
              <React.Fragment>
                <Button
                  className="primary-btn1"
                  onClick={(e) => this.handleClose(e, "assigntruck")}
                  color="primary"
                  disabled={this.state.loading}
                >
                  Cancel
                </Button>
              </React.Fragment>
            </DialogActions>
          </React.Fragment>
        </Dialog>

        <Dialog
          open={this.state.openreceiver}
          onClose={(e) => this.handleClose(e, "acceptreceiver")}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className="custom-dialog-header" id="form-dialog-title">
            <div>Request Rate</div>
            <div
              className="dialog-close"
              onClick={(e) => this.handleClose(e, "acceptreceiver")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <React.Fragment>
            <DialogContent
              className="dialog-content-area"
              style={{ overflowY: "unset" }}
            >
              <TextField
                autoFocus
                margin="dense"
                name="sellingtruckrate"
                id="sellingtruckrate"
                label="Enter Request Rate"
                type="text"
                value={this.state.sellingtruckrate}
                onChange={(e) =>
                  this.handleChange_value1(e, "sellingtruckrate")
                }
                error={this.state.sellingtruckrateErr}
                helperText={this.state.sellingtruckratehelperText}
                fullWidth
              />
              <label>
                <p>Requested Rate: {this.state.currenttruckrate}</p>
              </label>
              {this.state.items.sDestination == "" &&
              this.state.items.Destination == "" &&
              this.state.phoneclick == 0 ? (
                <GooglePlacesAutocomplete
                  listViewDisplayed="auto"
                  onSelect={(structured_formatting) =>
                    this.toAdd(structured_formatting)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="destination"
                      name="destination" /*error={this.state.routeErr} helperText={this.state.routehelperText}*/
                      label="Select Destination"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
              ) : (this.state.items.sDestination !== "" ||
                  this.state.items.Destination !== "") &&
                this.state.phoneclick == 0 ? (
                <>
                  <div className="p-d-flex p-ai-center p-jc-between ">
                    <div className="fw-500">
                      Destination:{" "}
                      {this.state.items.sDestination == ""
                        ? this.state.items.Destination
                        : this.state.items.sDestination}
                    </div>
                    <div className="cursor-pointer">
                      <i
                        className="icon-editImage"
                        onClick={() => this.setState({ phoneclick: 1 })}
                      ></i>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <GooglePlacesAutocomplete
                    style={{ position: "initial" }}
                    listViewDisplayed="auto"
                    onSelect={(structured_formatting) =>
                      this.toAdd(structured_formatting)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="destination"
                        name="destination" /*error={this.state.routeErr} helperText={this.state.routehelperText}*/
                        label="Select Destination"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                  <label>
                    <p>
                      Destination:{" "}
                      {this.state.items.sDestination == ""
                        ? this.state.items.Destination
                        : this.state.items.sDestination}
                    </p>
                  </label>
                </>
              )}
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              {this.state.loading === true ? (
                <div className="loading">
                  <SimpleBackdrop />
                </div>
              ) : null}
              <React.Fragment>
                <Button
                  className="primary-btn1"
                  onClick={(e) => this.handleClose(e, "acceptreceiver")}
                  color="primary"
                  disabled={this.state.loading}
                >
                  Cancel
                </Button>
                <Button
                  className="primary-btn"
                  onClick={(e) =>
                    this.UpdateTruckRequestStatus(
                      this.state.items,
                      "acceptreceiver"
                    )
                  }
                  color="primary"
                  disabled={this.state.loading}
                >
                  Confirm
                </Button>
              </React.Fragment>
            </DialogActions>
          </React.Fragment>
        </Dialog>

        <Dialog
          open={this.state.opendestination}
          onClose={(e) => this.handleClose(e, "destination")}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className="custom-dialog-header" id="form-dialog-title">
            <div>Add Destination</div>
            <div
              className="dialog-close"
              onClick={(e) => this.handleClose(e, "destination")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <React.Fragment>
            <DialogContent className="dialog-content-area">
              <GooglePlacesAutocomplete
                style={{ position: "initial" }}
                onSelect={(structured_formatting) =>
                  this.toAdd(structured_formatting)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="destination"
                    name="destination" /*error={this.state.routeErr} helperText={this.state.routehelperText}*/
                    label="Select Destination"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              {this.state.loading === true ? (
                <div className="loading">
                  <SimpleBackdrop />
                </div>
              ) : null}
              <React.Fragment>
                <Button
                  className="primary-btn1"
                  onClick={(e) => this.handleClose(e, "destination")}
                  color="primary"
                  disabled={this.state.loading}
                >
                  Cancel
                </Button>
                <Button
                  className="primary-btn"
                  onClick={(e) =>
                    this.UpdateTruckRequestStatus(
                      this.state.items,
                      "acceptmine"
                    )
                  }
                  color="primary"
                  disabled={this.state.loading}
                >
                  Submit
                </Button>
              </React.Fragment>
            </DialogActions>
          </React.Fragment>
        </Dialog>

        <Dialog
          open={this.state.openaccepttransporter}
          onClose={(e) => this.handleClose(e, "accepttransporter")}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className="custom-dialog-header" id="form-dialog-title">
            <div>Approval Rate</div>
            <div
              className="dialog-close"
              onClick={(e) => this.handleClose(e, "accepttransporter")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <React.Fragment>
            <DialogContent className="dialog-content-area">
              <TextField
                autoFocus
                margin="dense"
                name="sellingtruckrate"
                id="sellingtruckrate"
                label="Enter Approval Rate"
                type="text"
                value={this.state.sellingtruckrate}
                onChange={(e) =>
                  this.handleChange_value1(e, "sellingtruckrate")
                }
                error={this.state.sellingtruckrateErr}
                helperText={this.state.sellingtruckratehelperText}
                fullWidth
              />
              <label>
                <p>Requested Rate: {this.state.currenttruckrate}</p>
              </label>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              {this.state.loading === true ? (
                <div className="loading">
                  <SimpleBackdrop />
                </div>
              ) : null}
              <React.Fragment>
                <Button
                  className="primary-btn1"
                  onClick={(e) => this.handleClose(e, "accepttransporter")}
                  color="primary"
                  disabled={this.state.loading}
                >
                  Cancel
                </Button>
                <Button
                  className="primary-btn1"
                  onClick={(e) =>
                    this.UpdateTruckRequestStatus(
                      this.state.items,
                      "accepttransporterdifferent"
                    )
                  }
                  color="primary"
                  disabled={this.state.loading}
                >
                  Confirm
                </Button>
              </React.Fragment>
            </DialogActions>
          </React.Fragment>
        </Dialog>

        <Dialog
          open={this.state.openTruckDetail}
          onClose={(e) => this.handleClose(e, "truckdetail")}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className="custom-dialog-header" id="form-dialog-title">
            <div>Assigned Truck</div>

            <div
              className="dialog-close"
              onClick={(e) => this.handleClose(e, "truckdetails")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <React.Fragment>
            <DialogContent className="dialog-content-area">
              <GridContainer>
                <GridItem xs={4}>
                  <div className="vib-detail last">
                    <h5>Truck Number</h5>
                  </div>
                </GridItem>
                <GridItem xs={4}>
                  <div className="vib-detail last">
                    <h5>Truck Capacity</h5>
                  </div>
                </GridItem>
                <GridItem xs={4}>
                  <div className="vib-detail last">
                    <h5>Truck BodyType</h5>
                  </div>
                </GridItem>
              </GridContainer>
              <span className="devider-full"></span>
              {this.state.items1.length &&
                this.state.items1.map((item, key) => {
                  return (
                    <GridContainer>
                      <GridItem xs={4}>
                        <div className="vib-detail last">
                          <p className="color-green">{item.TruckNumber}</p>
                        </div>
                      </GridItem>
                      <GridItem xs={4}>
                        <div className="vib-detail last">
                          <p className="color-green">{item.TruckCapacity}</p>
                        </div>
                      </GridItem>
                      <GridItem xs={4}>
                        <div className="vib-detail last">
                          <p className="color-green">{item.BodyType}</p>
                        </div>
                      </GridItem>
                    </GridContainer>
                  );
                })}
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <React.Fragment>
                <Button
                  className="primary-btn1"
                  onClick={(e) => this.handleClose(e, "truckdetails")}
                  color="primary"
                  disabled={this.state.loading}
                >
                  Cancel
                </Button>
              </React.Fragment>
              {this.state.loading === true ? (
                <div className="loading">
                  <SimpleBackdrop />
                </div>
              ) : null}
            </DialogActions>
          </React.Fragment>
        </Dialog>

        <Dialog
          className="profile-details-modal"
          open={this.state.open1}
          onClose={(e) => this.handleClose(e, "profile")}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className="custom-dialog-header" id="form-dialog-title">
            <div>Profile Details</div>
            <div
              className="dialog-close"
              onClick={(e) => this.handleClose(e, "profile")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <React.Fragment>
            <DialogContent className="profile-content">
              <div className="p-d-flex p-flex-column p-flex-md-row p-pt-2 p-pb-4">
                <div>
                  <div className="Profile-dialog-img">
                    <img
                      src={
                        this.state.profilePic == "" ||
                        this.state.profilePic == null ||
                        this.state.profilePic == undefined
                          ? User
                          : individual_profile_image_link +
                            this.state.profilePic
                      }
                    />
                  </div>
                </div>
                <div className="p-w-100">
                  <table className="profile-table">
                    <tr>
                      <td className="table-label">Name:</td>
                      <td className="table-label-info">
                        {this.state.firstName} {this.state.secondName}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-label">Email ID:</td>
                      <td className="table-label-info">
                        {" "}
                        {this.state.emailId}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-label">Contact No:</td>
                      <td className="table-label-info">
                        {" "}
                        {this.state.contactNum}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              {this.state.loading === true ? (
                <div className="loading">
                  <SimpleBackdrop />
                </div>
              ) : null}
              {this.state.networkitems.NetworkId == "" ? (
                <Button
                  className="primary-btn"
                  onClick={(e) =>
                    this.handleNetwork(e, this.state.networkitems)
                  }
                  color="primary"
                  disabled={this.state.loading}
                >
                  Add To Network
                </Button>
              ) : null}
            </DialogActions>
          </React.Fragment>
        </Dialog>

        <Dialog
          open={this.state.opencCancelNetworkRequest}
          onClose={(e) => this.handleClose(e, "cancelnetworkrequest")}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className="custom-dialog-header" id="form-dialog-title">
            <div>Network Request</div>

            <div
              className="dialog-close"
              onClick={(e) => this.handleClose(e, "cancelnetworkrequest")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <React.Fragment>
            <DialogContent className="dialog-content-area  p-d-flex p-ai-center">
              <div className="vib-header">
                Are you sure you want to cancel network request ?
              </div>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={(e) => this.handleClose(e, "cancelnetworkrequest")}
                color="primary"
                disabled={this.state.loading}
              >
                Cancel
              </Button>

              <Button
                className="primary-btn1"
                onClick={(e) =>
                  this.CancelRequestStatus(
                    this.state.items.NetworkId,
                    "rejectnetwork"
                  )
                }
                color="primary"
                disabled={this.state.loading}
              >
                Cancel Network
              </Button>
            </DialogActions>
          </React.Fragment>
        </Dialog>
        <Dialog
          open={this.state.opencCancelLocationRequest}
          onClose={(e) => this.handleClose(e, "cancellocationrequest")}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className="custom-dialog-header" id="form-dialog-title">
            <div>Location Request</div>

            <div
              className="dialog-close"
              onClick={(e) => this.handleClose(e, "cancellocationrequest")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <React.Fragment>
            <DialogContent className="dialog-content-area  p-d-flex p-ai-center">
              <div>Are you sure you want to cancel location request ?</div>
            </DialogContent>
            <DialogActions className="p-p-0 dialog-footer1">
              <Button
                className="primary-btn1"
                onClick={(e) => this.handleClose(e, "cancellocationrequest")}
                color="primary"
                disabled={this.state.loading}
              >
                No
              </Button>

              <Button
                className="primary-btn"
                onClick={(e) =>
                  this.CancellocationRequestStatus(this.state.items)
                }
                color="primary"
                disabled={this.state.loading}
              >
                Yes
              </Button>
            </DialogActions>
          </React.Fragment>
        </Dialog>
      </div>
    );
  }
}
export default Request;
