import React, { Component } from 'react';
// import Sidebar from 'components/Sidebar/Sidebar.js';
class icon extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.actionTemplate = this.actionTemplate.bind(this);
        // this.actionTemplate1 = this.actionTemplate1.bind(this);
    }
    actionTemplate() {
        return <div>
            {/* <Button type="button" tooltip="Edit" tooltipOptions={{ position: 'bottom' }} icon="pi pi-pencil" className="p-button-warning" style={{ marginRight: '.5em' }}></Button> */}
            <span style={{ float: "left",marginTop: "7px",marginLeft: "8px",marginRight: "19px"}} className="fas fa-truck"></span>
        </div>;
    }

    // actionTemplate1() {
    //     return <div>
    //         {/* <Button type="button" tooltip="Edit" tooltipOptions={{ position: 'bottom' }} icon="pi pi-pencil" className="p-button-warning" style={{ marginRight: '.5em' }}></Button> */}
    //         <img style={{ float: "left",marginTop: "7px",marginLeft: "8px",marginRight: "19px"}} src="https://img.icons8.com/material/48/000000/purchase-order--v1.png"/>
    //     </div>;
    // }

    render(){
    //    console.log("icon",this.props)
    return (
    <div>
        {/* <Sidebar actionTemplate={this.props.actionTemplate}/> */}
        {this.actionTemplate()}
        {/* {this.actionTemplate1()} */}
        {/* <SideBar
            // actionTemplate = {this.actionTemplate}
        /> */}
        {/* <i style={{ float: "left",marginTop: "7px",marginLeft: "8px",marginRight: "19px"}} class="fas fa-truck"></i> */}
        {/* <img style={{ float: "left",marginTop: "7px",marginLeft: "8px",marginRight: "19px"}} src="https://img.icons8.com/material/48/000000/purchase-order--v1.png"/> */}
    </div>
        )
    }
}
export {icon};