import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CommonConfig } from "../../../CommonConfig.js";
import SimpleBackdrop from "../../../utils/general.js";
import api from "../../../utils/api.js";
import cogoToast from "cogo-toast";
import APIConstant from "../../../utils/constant.js";
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import { Dialog } from "primereact/dialog";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import Loader from "components/Shared/Loader/Loader";
import CustomInput from "components/CustomInput/CustomInput";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DatePicker from "react-datetime";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { MultiSelect } from "primereact/multiselect";
import "date-fns";
import { apiBase } from "config.js";
import { ColorPicker } from "primereact/colorpicker";
import Checkbox from "@material-ui/core/Checkbox";
import { Calendar } from "primereact/calendar";

const ViewWarehouse = (props) => {
  let [userId, setUserId] = useState("");
  let [wareHouseId, setWareHouseId] = useState("");
  let [wareHouseList, setWareHouseList] = useState([]);
  let [userPageAccess, setUserPageAccess] = useState([]);

  useEffect(() => {
    let data = localStorage.getItem("Infodata");
    let Userrole = JSON.parse(localStorage.getItem("Userdata"));
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");
    // if (lockstatus == 0) {
    if (token !== null) {
      setUserId(data);
      setUserPageAccess(userPageAccess);
      if (props?.location?.state && props?.location?.state?.WareHouseId) {
        getWareHouseById(props?.location?.state?.WareHouseId);
      }
    } else {
      props.history.push("/login");
    }
    // } else {
    //     props.history.push('/auth/lockscreen')
    // }
  }, []);

  const getWareHouseById = async (ID) => {
    try {
      Loader.show();
      let data = {
        ID: ID,
      };

      await api
        .post(APIConstant.path.GetWareHouseDetailById, data)
        .then((response) => {
          if (response.Success == 1) {
            Loader.hide();
            setWareHouseList(response?.Data);
          } else {
            Loader.hide();
            setWareHouseList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="main-access-contianer">
      <div className="heading-area">
        <h1>Warehouse Detail</h1>
        {/* <div className="heading-area-right">
                    <div className="searchbar-area p-as-center">
                        <InputText type="search" onInput={(e) => setSearch(e.target.value)} placeholder="Global Search" size="30" style={{ marginRight: 10 }} />
                        <i className="icon-search"></i>
                    </div>
                    {userPageAccess.includes('Add Ware House') ?
                        <div>
                            <div className="add-btn1" onClick={(e) => props.history.push("add-ware-house")}>
                                <i className="icon-add"></i> Add WareHouse Master
                            </div>
                        </div>
                        : null}
                </div> */}
      </div>

      <div className="main-access-card-master">
        {/* {loading === true ?
                    <div className="loading">
                        <SimpleBackdrop />
                    </div>
                    : null} */}

        <div className="status-badge">
          <label>EDI No</label>
          <p>
            {CommonConfig.isEmpty(wareHouseList?.EdiNo)
              ? "-"
              : wareHouseList?.EdiNo}
          </p>
        </div>
        <div className="status-badge">
          <label>Warehouse Doc</label>
          <a
            href={
              wareHouseList?.WareHouseFile
                ? apiBase + wareHouseList.WareHouseFile
                : "#"
            }
            without
            rel="noopener noreferrer"
            target="_blank"
          >
            <button type="button" label="View File" />
          </a>
        </div>
        <div className="status-badge">
          <label>Warehouse Name</label>
          <p>
            {CommonConfig.isEmpty(wareHouseList?.WareHouseName)
              ? "-"
              : wareHouseList?.WareHouseName}
          </p>
        </div>
        <div className="status-badge">
          <label>Warehouse Type</label>
          <p>
            {CommonConfig.isEmpty(wareHouseList?.WareHouseTypeName)
              ? "-"
              : wareHouseList?.WareHouseTypeName}
          </p>
        </div>
        <div className="status-badge">
          <label>Type</label>
          <p>
            {CommonConfig.isEmpty(wareHouseList?.TypeName)
              ? "-"
              : wareHouseList?.TypeName}
          </p>
        </div>
        <div className="status-badge">
          <label>Address</label>
          <p>
            {CommonConfig.isEmpty(wareHouseList?.Address)
              ? "-"
              : wareHouseList?.Address}
          </p>
        </div>
        {/* file */}
        {/* Insurance details */}
        <div className="status-badge">
          <label>Policy No</label>
          <p>
            {CommonConfig.isEmpty(wareHouseList?.PolicyNo)
              ? "-"
              : wareHouseList?.PolicyNo}
          </p>
        </div>
        <div className="status-badge">
          <label>Insurance Doc</label>
          <a
            href={
              wareHouseList?.InsuranceFile
                ? apiBase + wareHouseList?.InsuranceFile
                : "#"
            }
            without
            rel="noopener noreferrer"
            target="_blank"
          >
            <button type="button" label="View File" />
          </a>
        </div>
        <div className="status-badge">
          <label>Company</label>
          <p>
            {CommonConfig.isEmpty(wareHouseList?.InsuranceCompanyName)
              ? "-"
              : wareHouseList?.InsuranceCompanyName}
          </p>
        </div>
        <div className="status-badge">
          <label>Insurance Validity From</label>
          <p>
            {CommonConfig.isEmpty(wareHouseList?.InsuranceValidityFrom)
              ? "-"
              : wareHouseList?.InsuranceValidityFrom}
          </p>
        </div>
        <div className="status-badge">
          <label>Insurance Validity To</label>
          <p>
            {CommonConfig.isEmpty(wareHouseList?.InsuranceValidityTo)
              ? "-"
              : wareHouseList?.InsuranceValidityTo}
          </p>
        </div>
        <div className="status-badge">
          <label>Amount</label>
          <p>
            {CommonConfig.isEmpty(wareHouseList?.InsuranceAmount)
              ? "-"
              : wareHouseList?.InsuranceAmount}
          </p>
        </div>

        {/* file */}
        {/* solvency details  */}
        <div className="status-badge">
          <label>Solvency Doc</label>
          <a
            href={
              wareHouseList?.SolvencyDoc?.File
                ? apiBase + wareHouseList.SolvencyDoc.File
                : "#"
            }
            without
            rel="noopener noreferrer"
            target="_blank"
          >
            <button type="button" label="View File" />
          </a>
        </div>
        <div className="status-badge">
          <label>Solvency Validity From</label>
          <p>
            {CommonConfig.isEmpty(wareHouseList?.SolvencyValidityFrom)
              ? "-"
              : wareHouseList?.SolvencyValidityFrom}
          </p>
        </div>
        <div className="status-badge">
          <label>Solvency Validity To</label>
          <p>
            {CommonConfig.isEmpty(wareHouseList?.SolvencyValidityTo)
              ? "-"
              : wareHouseList?.SolvencyValidityTo}
          </p>
        </div>
        {/* Fire fighting details  */}
        <div className="status-badge">
          <label>Fire Fighting Doc</label>
          <a
            href={
              wareHouseList?.FireFightingDoc?.File
                ? apiBase + wareHouseList.FireFightingDoc.File
                : "#"
            }
            without
            rel="noopener noreferrer"
            target="_blank"
          >
            <button type="button" label="View File" />
          </a>
        </div>
        <div className="status-badge">
          <label>Fire Fighting Validity From</label>
          <p>
            {CommonConfig.isEmpty(wareHouseList?.FireFightingValidityFrom)
              ? "-"
              : wareHouseList?.FireFightingValidityFrom}
          </p>
        </div>
        <div className="status-badge">
          <label>Fire Fighting Validity To</label>
          <p>
            {CommonConfig.isEmpty(wareHouseList?.FireFightingValidityTo)
              ? "-"
              : wareHouseList?.FireFightingValidityTo}
          </p>
        </div>
        {/* file  */}

        <label>Godown details</label>
        <div className="ship-pdf-table">
          <table>
            <thead>
              <tr>
                <th>
                  <p>Godown No</p>
                </th>
                <th>
                  <p>Cargo Type</p>
                </th>
                <th>
                  <p>Area</p>
                </th>
                <th>
                  <p>Capacity</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {wareHouseList?.WareHouseGodownDetails?.length
                ? wareHouseList?.WareHouseGodownDetails?.map((x) => {
                    return (
                      <tr>
                        <td>
                          <p>{x.godownNo}</p>
                        </td>
                        <td>
                          <p>{x.CargoType}</p>
                        </td>
                        <td>
                          <p>{x.area}</p>
                        </td>
                        <td>
                          <p>{x.capacity}</p>
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewWarehouse;
