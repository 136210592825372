import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CommonConfig } from "../../../CommonConfig.js";
import SimpleBackdrop from "../../../utils/general.js";
import api from "../../../utils/api.js";
import cogoToast from "cogo-toast";
import APIConstant from "../../../utils/constant.js";
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import { Dialog } from "primereact/dialog";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import Loader from "components/Shared/Loader/Loader";
import CustomInput from "components/CustomInput/CustomInput";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DatePicker from "react-datetime";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { MultiSelect } from "primereact/multiselect";
import "date-fns";
import { apiBase } from "config.js";
import { ColorPicker } from "primereact/colorpicker";
import Checkbox from "@material-ui/core/Checkbox";
import { Calendar } from "primereact/calendar";

const Warehouse = (props) => {
  let [userId, setUserId] = useState("");
  let [search, setSearch] = useState("");
  let [wareHouseId, setWareHouseId] = useState("");
  let [wareHouseList, setWareHouseList] = useState([]);
  let [userPageAccess, setUserPageAccess] = useState([]);
  let [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    let data = localStorage.getItem("Infodata");
    let Userrole = JSON.parse(localStorage.getItem("Userdata"));
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess") || [];
    let lockstatus = localStorage.getItem("LockStatus");
    // if (lockstatus == 0) {
    if (token !== null) {
      setUserId(data);
      setUserPageAccess(userPageAccess);
      getWareHouseList(data);
    } else {
      props.history.push("/login");
    }
    // } else {
    //     props.history.push('/auth/lockscreen')
    // }
  }, []);

  const getWareHouseList = async (UserId) => {
    try {
      // Loader.show();
      let data = {
        ID: UserId,
      };

      await api
        .post(APIConstant.path.GetWareHouseList, data)
        .then((response) => {
          if (response.Success == 1) {
            Loader.hide();
            setWareHouseList(response?.Data);
          } else {
            Loader.hide();
            setWareHouseList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const deleteWareHouse = async () => {
    try {
      Loader.show();
      let data = {
        ID: wareHouseId,
        UserId: userId,
      };

      await api
        .post(APIConstant.path.DeleteWareHouse, data)
        .then((response) => {
          handleClose();
          if (response.Success == 1) {
            Loader.hide();
            getWareHouseList(userId);
          } else {
            Loader.hide();
            getWareHouseList(userId);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const handleClose = (type) => {
    if (type == "deleteNodal") {
      setDeleteModal(false);
      setWareHouseId("");
    }
  };

  return (
    <div className="main-access-contianer">
      <div className="heading-area">
        <h1>Warehouse Master</h1>
        <div className="heading-area-right">
          <div className="searchbar-area p-as-center">
            <InputText
              type="search"
              onInput={(e) => setSearch(e.target.value)}
              placeholder="Global Search"
              size="30"
              style={{ marginRight: 10 }}
            />
            <i className="icon-search"></i>
          </div>
          {userPageAccess.includes("Add Warehouse") ? (
            <div>
              <div
                className="add-btn1"
                onClick={(e) => props.history.push("add-ware-house")}
              >
                <i className="icon-add"></i> Add Warehouse Master
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="main-access-card-master">
        {/* {loading === true ?
                    <div className="loading">
                        <SimpleBackdrop />
                    </div>
                    : null} */}

        {wareHouseList?.length ? (
          wareHouseList?.map((x, i) => {
            return (
              <div className="ship-item" key={i}>
                <div className="ship-header">
                  <div className="p-d-lg-flex p-ai-center p-jc-between">
                    <div
                      className="ship-header-item"
                      style={{ width: "25%" }}
                      // onClick={(e) => this.viewContainerDetails(e, x)}
                    >
                      <div className="icon-box">
                        <i className="icon-vessel"></i>
                      </div>

                      <div className="item-info">
                        <label>EDI Code</label>
                        <p className="item-title">
                          {CommonConfig.isEmpty(x.EDI) ? "-" : x.EDI}{" "}
                        </p>
                        <p
                          className="item-subtitle"
                          style={{ color: "#3A588F" }}
                        >
                          {CommonConfig.isEmpty(x.WareHouseTypeName)
                            ? "-"
                            : x.WareHouseTypeName}
                        </p>
                      </div>
                    </div>
                    <div
                      className="ship-header-item p-mt-3 p-mt-lg-0"
                      style={{ width: "25%" }}
                      //  onClick={(e) => this.viewContainerDetails(e, x)}
                    >
                      <div className="icon-box">
                        <i className="icon-Container"></i>
                      </div>
                      <div className="item-info">
                        <p className="item-title p-text-truncate">
                          {CommonConfig.isEmpty(x.Address) ? "-" : x.Address}
                        </p>

                        <p className="item-subtitle">
                          Policy No -{" "}
                          <strong>
                            {CommonConfig.isEmpty(x.PolicyNo)
                              ? "-"
                              : x.PolicyNo}
                          </strong>
                        </p>
                        <p className="item-subtitle">
                          Company -{" "}
                          <strong>
                            {CommonConfig.isEmpty(x.InsuranceCompanyName)
                              ? "-"
                              : x.InsuranceCompanyName}
                          </strong>
                        </p>
                        <p className="item-subtitle">
                          Amount -{" "}
                          <strong>
                            {CommonConfig.isEmpty(x.InsuranceAmount)
                              ? "-"
                              : x.InsuranceAmount}
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div
                      className="p-d-lg-flex p-ai-center"
                      style={{ width: "30%", gap: "10px" }}
                    >
                      {userPageAccess?.includes("Edit Warehouse") ? (
                        <div
                          className="ship-action-item"
                          onClick={(e) =>
                            props.history.push({
                              pathname: "/admin/add-ware-house",
                              state: {
                                WareHouseId: x.WareHouseMasterId,
                                IsEdit: true,
                              },
                            })
                          }
                        >
                          <i className="icon-editImage"></i>
                        </div>
                      ) : null}
                      {userPageAccess?.includes("Delete Warehouse") ? (
                        <div
                          className="ship-action-item"
                          onClick={(e) => {
                            setWareHouseId(x?.WareHouseMasterId);
                            setDeleteModal(true);
                          }}
                        >
                          <i className="icon-delete"></i>
                        </div>
                      ) : null}
                      {userPageAccess?.includes("View Warehouse") ? (
                        <div
                          className="ship-action-item"
                          onClick={(e) =>
                            props.history.push({
                              pathname: "/admin/view-ware-house",
                              state: {
                                WareHouseId: x.WareHouseMasterId,
                              },
                            })
                          }
                        >
                          <i className="icon-view"></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div>No record found</div>
        )}

        {/* Modal For Delete */}
        <Dialog
          visible={deleteModal}
          onHide={() => handleClose("deleteNodal")}
          aria-labelledby="cha-form-dialog-title"
        >
          <DialogTitle
            id="cha-form-dialog-title"
            className="custom-dialog-header"
          >
            <div>Delete Warehosue</div>
            <div
              className="dialog-close"
              onClick={(e) => handleClose("deleteNodal")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <DialogContent className="CHA-dialog-content-area">
            <h6>Are you sure want to delete?</h6>
          </DialogContent>
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="primary-btn1"
              onClick={(e) => handleClose("deleteNodal")}
            >
              Close
            </Button>
            <Button
              className="primary-btn"
              onClick={deleteWareHouse}
              type="submit"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default Warehouse;
